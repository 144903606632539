const envs = {
  dev: 'development',
  qa: 'qa',
  stg: 'staging',
};
type Env = 'dev' | 'qa' | 'stg';

export default function getEnvFromSubdomain() {
  const [, env] = window.location.hostname.split('.');

  if (env && env.match(/dev|qa|stg/)) {
    return envs[env as Env];
  } else {
    return 'production';
  }
}
