import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  Tooltip,
} from '@chakra-ui/core';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import registerUserSchema from '~/models/schemas/registerUserSchema';
import { RootState } from '~/store';
import { cellPhoneMask } from '~/utils/masks';

import Button from '../Button';
import Input from '../Form/Input';

interface RegisterInputs {
  name: string;
  phone: string;
}

interface LoginFormProps {
  isVisible: boolean;
  canContinue: boolean;
  onClickContinue?: () => void;
  onFormSubmit: (data: RegisterInputs) => void;
  helperText?: React.ReactNode;
}

const LoginForm: React.FC<LoginFormProps> = ({
  isVisible,
  onFormSubmit,
  onClickContinue,
  canContinue,
  helperText,
}) => {
  const user = useSelector((state: RootState) => state.user.user);
  const loading = useSelector((state: RootState) => state.auth.loading);
  const userName = user?.clientInfo?.name;
  const userPhone = user?.clientInfo?.phone;

  const { register, handleSubmit, setValue, errors } = useForm<RegisterInputs>({
    resolver: useYupValidationResolver(registerUserSchema),
    defaultValues: {
      name: userName,
      phone: cellPhoneMask(
        userPhone?.length >= 12 ? userPhone?.substring(2, userPhone?.length) : null,
      ),
    },
  });

  const handleOnClickContinue = useCallback(() => {
    onClickContinue && onClickContinue();
    if (isVisible) {
      handleSubmit(onFormSubmit)();
    }
  }, [handleSubmit, onClickContinue, onFormSubmit, isVisible]);

  return (
    <Box d="flex" w="100%" py={4} flexDirection="column">
      <Box as="form" d={isVisible ? 'flex' : 'none'} flexDir="column" pt={4} pb={8}>
        <Text fontSize="1.7rem" fontWeight="700" pt={2} pb={8}>
          Insira o seu nome e telefone para continuar:
        </Text>
        <Box d="flex" flexDir={['column', 'column', 'column', 'row']}>
          <FormControl
            mb={6}
            w="100%"
            mr={[0, 0, 0, 6]}
            isInvalid={!!errors.name}
            isDisabled={loading}
          >
            <FormLabel htmlFor="name" fontWeight="700" fontSize="sm">
              Nome
            </FormLabel>
            <InputGroup>
              <Input placeholder="Insira seu nome" name="name" type="text" register={register()} />
              {!!errors.name && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors.name?.message}
                    bg="red.400"
                    zIndex={1500}
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
          <FormControl mb={6} w="100%" isInvalid={!!errors.phone} isDisabled={loading}>
            <FormLabel htmlFor="phone" fontWeight="700" fontSize="sm">
              Telefone
            </FormLabel>
            <InputGroup>
              <InputLeftElement h="50px" w="60px">
                <Box
                  fontSize="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  background="transparent"
                  h="40px"
                  w="60px"
                  borderRight="1px solid #DBDBDB"
                >
                  <Text fontSize="1.6rem" as="span" color="#383539">
                    +55
                  </Text>
                </Box>
              </InputLeftElement>
              <Input
                p="20px 20px 20px 75px"
                placeholder="Insira seu telefone"
                name="phone"
                type="text"
                register={register()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValue('phone', cellPhoneMask(e.target.value), { shouldValidate: true })
                }
              />
              {!!errors.phone && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors.phone?.message}
                    bg="red.400"
                    zIndex={1500}
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
        </Box>
        {helperText}
      </Box>
      <Button
        flex="1"
        bg="green.300"
        type="button"
        onClick={handleOnClickContinue}
        isDisabled={!canContinue}
        isLoading={loading}
      >
        Continuar
      </Button>
    </Box>
  );
};

export default LoginForm;
