import { InputProps, Textarea as ChakraTextarea } from '@chakra-ui/core';
import React from 'react';
const Textarea: React.FC<InputProps<HTMLTextAreaElement>> = ({ children, ...rest }) => {
  return (
    <ChakraTextarea {...rest} borderRadius="0.4rem" focusBorderColor="gray.700">
      {children}
    </ChakraTextarea>
  );
};

export default Textarea;
