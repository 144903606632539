import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { add, isWithinInterval } from 'date-fns';
import { toast } from 'react-toastify';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';

import Order from '~/models/Order';
import PaginatedResult from '~/models/PaginatedResult';
import { advertiserMenu, pixPendingPayment, viewOrder } from '~/routes/routeMap';
import { apiWebMenu } from '~/services/api';
import { sendOrderConcludedEvent } from '~/services/mixPanel';
import AuthActions from '~/store/ducks/auth';
import { onlyNumbers } from '~/utils/masks';

import { RootState } from '..';
import { AdvertiserTypes, Creators as AdvertiserCreators } from '../ducks/advertiser';
import { BagStateType, Creators as BagCreators } from '../ducks/bag';
import { Creators as KuppiBoostCreators } from '../ducks/kuppiBoost';
import {
  Creators as OrderCreators,
  OnCreateOrderRequest,
  OnGetMyOrder,
  OnGetMyOrders,
  OnReOrderRequest,
} from '../ducks/order';
import { OrderTypes } from '../ducks/order';

const getState = (state: any) => state;

const {
  createOrderSuccess,
  createOrderFailure,
  setMyOrders,
  setMyOrder,
  setPreviousOrders,
  setOrdersPagination,
  setForceJoinWSOrderStatus: setJoinWSOrderStatus,
} = OrderCreators;
const { getData } = AdvertiserCreators;
const { cleanBag } = BagCreators;
const { cleanKuppiBoost } = KuppiBoostCreators;

export function* createOrderRequest({ orderData }: OnCreateOrderRequest) {
  try {
    const globalState: RootState = yield select((state: RootState) => state);
    const lastAdvertiser: string = yield select(
      (state: RootState) => state.advertiser?.lastAdvertiser,
    );
    const bag: BagStateType = yield select((state: RootState) => state.bag);
    const { bagDiscounts, priceWithoutDiscount, priceWithDiscount } = bag;

    const facebookCampaignId = globalState.kuppiBoost?.facebook_campaign_id;
    const {
      change,
      deliveryFee,
      deliveryType,
      isValidToLoyaltyCard,
      orderType,
      clientId,
      schedule,
      subtotal,
    } = orderData;

    const deliveryAddress = orderData.deliveryAddress
      ? {
          ...orderData.deliveryAddress,
          zipcode: onlyNumbers(orderData.deliveryAddress.zipcode),
        }
      : null;

    let orderDiscount: any = {
      activeCoupon: null,
      discounts: [],
    };
    if (bagDiscounts.activeCoupon) {
      orderDiscount.activeCoupon = bagDiscounts.activeCoupon;
      orderDiscount.discounts = bagDiscounts.discounts[bagDiscounts.activeCoupon].map((coupon) => ({
        id: coupon.id,
      }));

      if (orderDiscount.discounts.length === 0) {
        orderDiscount.activeCoupon = null;
      }
    }

    const orderProducts = orderData.products.map((product) => ({
      id: product.id,
      productQuantity: product.productQuantity,
      obs: product.obs || null,
      selectedComplements: product.selectedComplements.map((complement) => ({
        id: complement.id,
        options: complement.options
          .filter(({ is_selected, quantity }) => is_selected || quantity > 0)
          .map((option) => ({
            id: option.id,
            quantity: option.quantity,
          })),
      })),
    }));

    const response: AxiosResponse = yield call(
      apiWebMenu.post,
      `/anunciante/${lastAdvertiser}/order`,
      {
        clientId,
        subtotal: Number(subtotal.toFixed(2)),
        total:
          deliveryType === 'delivery'
            ? Number((Number(priceWithDiscount) + Number(deliveryFee)).toFixed(2))
            : Number(priceWithDiscount.toFixed(2)),
        totalDiscount: Number(
          (Number(priceWithoutDiscount) - Number(priceWithDiscount)).toFixed(2),
        ),
        change: Number(change.toFixed(2)),
        deliveryFee: Number(deliveryFee.toFixed(2)),
        deliveryType,
        schedule,
        isValidToLoyaltyCard,
        orderType,
        facebookCampaignId: facebookCampaignId,
        paymentMethod: {
          id: orderData.advertiserPaymentMethod.payment_method_id,
        },
        bagDiscounts: orderDiscount,
        deliveryAddress,
        products: orderProducts,
      },
    );

    // MixPanel Event
    sendOrderConcludedEvent('Funnel 6 - Concluded Successfully', {
      id: globalState.user.user.clientInfo.id.toString(),
      webMenuId: globalState.advertiser.advertiserInfo.id.toString(),
      orderType: orderData.orderType,
      deliveryType: orderData.deliveryType,
      paymentMethod: orderData.advertiserPaymentMethod.paymentMethod.name,
      amount: globalState.bag.priceWithDiscount.toString(),
    });

    const { orders, newOrder } = response.data;

    const ordersInProgress = orders.data.filter((order: Order) => {
      return order.orderStatus.status !== 'finished' && order.orderStatus.status !== 'canceled'
        ? true
        : false;
    });

    const ordersPrevious = orders.data.filter((order: Order) => {
      return order.orderStatus.status === 'finished' || order.orderStatus.status === 'canceled'
        ? true
        : false;
    });

    yield put(createOrderSuccess(ordersInProgress, ordersPrevious));
    yield put(cleanBag());

    let orderRoute = viewOrder;
    if (newOrder?.paymentMethod?.slug === 'pix_copia_cola') {
      orderRoute = pixPendingPayment;
    }

    yield put(setJoinWSOrderStatus());

    yield put(
      push(orderRoute, {
        orderSummaryData: orderData,
        order: newOrder,
      }),
    );
    yield put(cleanKuppiBoost());
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(AuthActions.logOut());
      toast.error('Sessão inválida, efetue o login novamente');
    } else {
      Sentry.captureException(err);
      const lastAdvertiser: string = yield select(
        (state: RootState) => state.advertiser?.lastAdvertiser,
      );

      yield put(createOrderFailure());
      const message =
        err.response?.data?.error?.message || 'Falha ao criar o pedido. Contate o suporte.';
      toast.error(message);
      const code = err.response?.data?.error?.code;
      if (code === 'coupon_already_used') {
        yield put(cleanBag());
        yield put(push(advertiserMenu));
      }
      if (code === 'invalid_order_type') {
        yield put(getData(lastAdvertiser));
      }
    }
  }
}

export function* getMyOrders({ page, perPage }: OnGetMyOrders) {
  try {
    const { advertiser, user } = yield select(getState);
    const advertiserInfo = advertiser?.advertiserInfo;
    const userInfo = user?.userInfo;

    if (!advertiserInfo && 'localStorage' in window) {
      yield put(push(advertiserMenu));
      return;
    }
    const response: AxiosResponse<PaginatedResult<Order>> = yield call(
      apiWebMenu.get,
      `/anunciante/${advertiserInfo.slug}/client-info/${userInfo.id}/my-orders`,
      { params: { page: page, per_page: perPage } },
    );

    const result = response.data;
    const orders = result.data;

    const expiredOrdersToCancel = [] as number[];

    const ordersInProgress = orders.filter((order: Order) => {
      const pedingPaymentAt =
        order.orderStatus.status !== 'canceled' &&
        order.advertiserOrderPayment?.pix_expiration_at &&
        !order.orderStatus.new_at
          ? new Date(order.advertiserOrderPayment?.pix_expiration_at)
          : null;

      const paymentNotExpired =
        pedingPaymentAt &&
        isWithinInterval(new Date(), {
          start: pedingPaymentAt,
          end: add(pedingPaymentAt, {
            hours: 1,
          }),
        });

      if (pedingPaymentAt && !paymentNotExpired) {
        expiredOrdersToCancel.push(order.id);
      }

      const orderNotConcluded = !['finished', 'canceled'].includes(order.orderStatus.status);

      return orderNotConcluded || paymentNotExpired;
    });

    const ordersPrevious = orders.filter((order: Order) => {
      return ['finished', 'canceled'].includes(order.orderStatus.status);
    });

    if (expiredOrdersToCancel.length) {
      const response: AxiosResponse<Order[]> = yield call(
        apiWebMenu.put,
        `/anunciante/${advertiserInfo.slug}/client-info/${userInfo.id}/order/pending-payment/cancel`,
        {
          ids: expiredOrdersToCancel,
        },
      );

      ordersPrevious.unshift(...response.data);
    }

    yield put(setMyOrders(ordersInProgress));
    yield put(setPreviousOrders(ordersPrevious));
    yield put(
      setOrdersPagination({
        lastPage: result.lastPage,
        page: result.page,
        perPage: result.perPage,
        total: result.total,
      }),
    );
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(AuthActions.logOut());
      toast.error('Sessão inválida, efetue o login novamente');
    } else {
      Sentry.captureException(err);
      yield put(createOrderFailure());
      toast.error('Falha ao coletar os pedidos. Contate o suporte.');
    }
  }
}

export function* getOrder({ id, slug }: OnGetMyOrder) {
  try {
    // Force get updated advertiser data
    yield put(getData(slug));
    // Wait the setData execution to continue the getOrder execution
    yield take([AdvertiserTypes.SET_DATA]);

    const { advertiser } = yield select(getState);
    const advertiserInfo = advertiser?.advertiserInfo;

    const response: AxiosResponse<Order[]> = yield call(
      apiWebMenu.get,
      `/anunciante/${advertiserInfo.slug}/order/${id}`,
    );

    const order = response.data[0];
    if (!order) {
      yield put(push(advertiserMenu));
      return;
    }
    yield put(setMyOrder(order));
  } catch (err) {
    Sentry.captureException(err);
    yield put(createOrderFailure());
    toast.error('Falha ao coletar o pedido. Contate o suporte.');
  }
}

export function* reOrderRequest({ orderId }: OnReOrderRequest) {
  try {
    const lastAdvertiser: string = yield select(
      (state: RootState) => state.advertiser?.lastAdvertiser,
    );

    const response: AxiosResponse = yield call(
      apiWebMenu.post,
      `/anunciante/${lastAdvertiser}/order/${orderId}/payment/new`,
    );

    const { orders, order } = response.data;

    const ordersInProgress = orders.data.filter((order: Order) => {
      return order.orderStatus.status !== 'finished' && order.orderStatus.status !== 'canceled'
        ? true
        : false;
    });

    const ordersPrevious = orders.data.filter((order: Order) => {
      return order.orderStatus.status === 'finished' || order.orderStatus.status === 'canceled'
        ? true
        : false;
    });

    yield put(createOrderSuccess(ordersInProgress, ordersPrevious));
    yield put(cleanBag());

    yield put(
      push(pixPendingPayment, {
        order: order,
      }),
    );
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(AuthActions.logOut());
      toast.error('Sessão inválida, efetue o login novamente');
    } else {
      Sentry.captureException(err);
      yield put(createOrderFailure());
      const lastAdvertiser: string = yield select(
        (state: RootState) => state.advertiser?.lastAdvertiser,
      );

      const message =
        err.response?.data?.error?.message || 'Falha ao criar o pedido. Contate o suporte.';
      toast.error(message);
      const code = err.response?.data?.error?.code;
      if (code === 'coupon_already_used') {
        yield put(cleanBag());
        yield put(push(advertiserMenu));
      }
      if (code === 'invalid_order_type') {
        yield put(getData(lastAdvertiser));
      }
    }
  }
}

export default all([
  takeLatest(OrderTypes.CREATE_ORDER_REQUEST, createOrderRequest),
  takeLatest(OrderTypes.GET_MY_ORDERS, getMyOrders),
  takeLatest(OrderTypes.GET_MY_ORDER, getOrder),
  takeLatest(OrderTypes.REORDER_REQUEST, reOrderRequest),
]);
