import {
  Box,
  Heading,
  Icon,
  Image,
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import platform from 'platform';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';

import Button from '~/components/Button';
import LazyImage from '~/components/LazyImage';
import usePWAInstall from '~/hooks/usePWAInstall';
import WebManifest from '~/models/WebManifest';
import { baseUrlWebMenu } from '~/services/api';
import { sendWebAppRequest } from '~/services/mixPanel';
import { RootState } from '~/store';
import { Creators as AuthActions } from '~/store/ducks/auth';
const { setLoading } = AuthActions;

interface PWAContextProps {
  supportsPWA: boolean;
  promptInstall: () => void;
  initializeHeadTags: (advertiserSlug: string) => Promise<void>;
  newServiceWorkerDetected: boolean;
  isStandalone: boolean;
  hasTrackedStandalone: boolean;
}

const PWAContext = createContext<PWAContextProps>({
  supportsPWA: false,
  promptInstall: () => {},
  initializeHeadTags: async (advertiserSlug: string) => {},
  newServiceWorkerDetected: false,
  isStandalone: false,
  hasTrackedStandalone: false,
});

function PWAProvider({ children }: { children: React.ReactNode }) {
  const dispatch = useDispatch();
  const { supportsPWA, beforeInstallEvent } = usePWAInstall();
  const {
    isOpen: isPwaModalOpen,
    onOpen: onPwaModalOpen,
    onClose: onPwaModalClose,
  } = useDisclosure();
  const {
    isOpen: isUpdateModalOpen,
    onOpen: onUpdateModalOpen,
    onClose: onUpdateModalClose,
  } = useDisclosure();
  const [registration, setRegistration] = useState<ServiceWorkerRegistration>(null);
  const [newServiceWorkerDetected, setNewServiceWorkerDetected] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [hasTrackedStandalone, setHasTrackedStandalone] = useState<boolean>(false);
  const [isStandalone, setIsStandalone] = useState<boolean>(false);

  const isChrome = platform?.name?.toLowerCase() === 'chrome';
  const isFirefox = platform?.name?.toLowerCase() === 'firefox';
  const isSafari = platform?.name?.toLowerCase() === 'safari';
  const isIphone = platform?.product?.match(/iPhone/);
  const isMobile =
    ['ios', 'android'].find((os) => platform?.os?.family?.toLowerCase() === os) !== undefined;

  const globalState = useSelector((state: RootState) => state);

  const makeLinkElement = useCallback((rel: string, href: string, type?: string) => {
    const link = document.createElement('link');
    link.href = href;
    link.rel = rel;
    if (type) link.type = type;
    return link;
  }, []);

  const initializeHeadTags = useCallback(
    async (advertiserSlug: string) => {
      if (advertiserSlug) {
        const doesManifestExists = document.querySelector('head link[rel="manifest"]');
        const doesAppleTouchIconExists = document.querySelector(
          'head link[rel="apple-touch-icon"]',
        );
        const doesFaviconExists = document.querySelector('head link[rel="icon"]');
        const head = document.getElementsByTagName('head')[0];
        const host = encodeURIComponent(window.location.host);

        try {
          const manifestUrl = `${baseUrlWebMenu}/anunciante/${advertiserSlug}/manifest?host=${host}`;
          const response = await fetch(manifestUrl);

          const manifest: WebManifest = await response.json();
          const icon192 = manifest.icons.find((icon) => icon.sizes === '192x192');

          if (!doesManifestExists) {
            head.appendChild(makeLinkElement('manifest', manifestUrl));
          }

          if (!doesAppleTouchIconExists && icon192) {
            head.appendChild(makeLinkElement('apple-touch-icon', icon192.src, icon192.type));
          }
          if (!doesFaviconExists && icon192) {
            head.appendChild(makeLinkElement('icon', icon192.src, icon192.type));
          }
        } catch (err) {
          console.log('Manifest generation error: ', err);
        }
      }
    },
    [makeLinkElement],
  );

  const promptInstall = useCallback(() => {
    if (isSafari) {
      if ('standalone' in window?.navigator && (window?.navigator as any).standalone === true) {
        return;
      }
    } else {
      if (window?.matchMedia('(display-mode: standalone)').matches) {
        return;
      }
    }
    if (isIphone && !isSafari) {
      return;
    }
    if (isMobile) {
      onPwaModalOpen();
    }
  }, [onPwaModalOpen, isIphone, isSafari, isMobile]);

  const onClickInstall = useCallback(() => {
    if (supportsPWA) {
      ReactGA.event({
        category: 'PWA',
        action: 'Tentou instalar o PWA através do botão instalar (pop-up)',
      });
      beforeInstallEvent?.prompt();

      // MixPanel Event
      sendWebAppRequest('Web App(PWA) Funnel - Step 1 - Request', {
        advertiserId: globalState.advertiser?.advertiserInfo?.id?.toString(),
        segment: globalState.advertiser?.advertiserInfo?.advertiserCategory?.name,
        loyaltyCardElegible: globalState.advertiser?.advertiserInfo?.loyaltyCard
          ? globalState.advertiser?.advertiserInfo?.loyaltyCard?.is_active?.toString()
          : 'false',
      });
    }
  }, [supportsPWA, beforeInstallEvent]);

  const handleOnAcceptUpdateServiceWorker = useCallback(() => {
    if (registration && registration.waiting) {
      let preventDevToolsReloadLoop: boolean;
      navigator?.serviceWorker.addEventListener('controllerchange', (event) => {
        /*
         * Ensure refresh is only called once.
         * This works around a bug in "force update on reload".
         */
        if (preventDevToolsReloadLoop) {
          return;
        }

        preventDevToolsReloadLoop = true;

        // Finally, refresh the page
        window.location.href = window?.location?.href;
      });
      setIsUpdating(true);
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });

      // Force set auth loading to false, avoiding loading became lock the web menu screen
      dispatch(setLoading(false));

      onUpdateModalClose();
    }
    // Fixed ios safari pwa update modal do not close after update accept
    else if (isSafari || isIphone) {
      window.location.href = window?.location?.href;
    }
  }, [registration, onUpdateModalClose]);

  useEffect(() => {
    const handleNewServiceWorker = (event: any) => {
      const eventRegistration = event.detail.registration;
      setRegistration(eventRegistration);
      setNewServiceWorkerDetected(true);
      onUpdateModalOpen();
      onPwaModalClose();
    };
    document.addEventListener('onswupdate', handleNewServiceWorker);
    return () => document.removeEventListener('onswupdate', handleNewServiceWorker);
  }, [onUpdateModalOpen, onPwaModalClose]);

  useEffect(() => {
    if (!hasTrackedStandalone) {
      const isInStandalone = window.matchMedia('(display-mode: standalone)').matches;
      const isSafariStandalone = (window.navigator as any).standalone === true;
      if (isInStandalone || isSafariStandalone) {
        setIsStandalone(isInStandalone || isSafariStandalone);
        ReactGA.event({
          category: 'PWA',
          action: 'Abriu app através da tela inicial',
        });

        // MixPanel Event
        sendWebAppRequest('Web App(PWA) Funnel - Step 2 - Access as Web App', {
          advertiserId: globalState.advertiser?.advertiserInfo?.id?.toString(),
          segment: globalState.advertiser?.advertiserInfo?.advertiserCategory?.name,
          loyaltyCardElegible: globalState.advertiser?.advertiserInfo?.loyaltyCard
            ? globalState.advertiser?.advertiserInfo?.loyaltyCard?.is_active?.toString()
            : 'false',
        });
      }
    }
    setHasTrackedStandalone(true);
  }, [hasTrackedStandalone]);

  return (
    <PWAContext.Provider
      value={{
        supportsPWA,
        promptInstall,
        initializeHeadTags,
        newServiceWorkerDetected,
        isStandalone,
        hasTrackedStandalone,
      }}
    >
      <ChakraModal
        isOpen={isUpdateModalOpen}
        onClose={onUpdateModalClose}
        size="xl"
        isCentered
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent borderRadius="2rem">
          <Box
            pos="relative"
            minH="138px"
            bg="white"
            borderTopRightRadius="2rem"
            borderTopLeftRadius="2rem"
          >
            <LazyImage
              src="/images/update-pwa-alert.png"
              w="100%"
              h="138px"
              borderTopRightRadius="2rem"
              borderTopLeftRadius="2rem"
              objectFit="cover"
            />
            <Box pos="absolute" right="2.5rem" top="50%" transform="translateY(-50%)" py={2}>
              <Heading fontSize="3rem" fontWeight="700">
                Ops!
              </Heading>
              <Heading fontSize="2rem" fontWeight="700">
                Versão desatualizada
              </Heading>
            </Box>
          </Box>
          <Box p={8}>
            <ModalBody>
              <Text fontSize="md" mb={4}>
                Opa, notamos que você está utilizando uma versão desatualizada. Para continuar
                usando nosso app, por favor <strong>atualize</strong> agora mesmo!
              </Text>
            </ModalBody>

            <ModalFooter flexDir="column" alignItems="center">
              <Button
                w="100%"
                mb={4}
                onClick={handleOnAcceptUpdateServiceWorker}
                isLoading={isUpdating}
              >
                Atualizar agora
              </Button>
            </ModalFooter>
          </Box>
        </ModalContent>
      </ChakraModal>

      <ChakraModal isOpen={isPwaModalOpen} onClose={onPwaModalClose} size="xl" isCentered>
        <ModalOverlay />
        {isFirefox && !supportsPWA ? (
          <Image
            src="/images/pointing-arrow.png"
            pos="fixed"
            bottom="0"
            right="-1rem"
            zIndex={1400}
          />
        ) : null}
        {isChrome && !supportsPWA ? (
          <Image
            src="/images/pointing-arrow.png"
            pos="fixed"
            top="0"
            right="-0.5rem"
            transform="scaleX(-100%) rotate(180deg)"
            zIndex={1400}
          />
        ) : null}
        {isSafari && !supportsPWA ? (
          <Image
            src="/images/pointing-arrow.png"
            pos="fixed"
            bottom="0"
            left="50%"
            transform="translateX(-50%)"
            zIndex={1400}
          />
        ) : null}
        <ModalContent borderRadius="2rem">
          <LazyImage
            src="/images/add-pwa-homescreen.png"
            h="200px"
            borderTopRightRadius="2rem"
            borderTopLeftRadius="2rem"
          />
          <Box p={8}>
            <ModalBody>
              <Heading fontSize="lg" fontWeight="700" mb={4}>
                Adicione nosso site na tela inicial do seu celular.
              </Heading>
              <Text fontSize="md" mb={4}>
                Basta adicionar o app na tela inicial do seu celular para ter mais agilidade sempre
                que precisar.
              </Text>
              {(isFirefox || isChrome) && !supportsPWA ? (
                <Text fontSize="md">
                  <strong>
                    Toque em <Box as={BiDotsVerticalRounded} d="inline-block" />
                  </strong>{' '}
                  e depois em <em>Adicionar à tela</em>.
                </Text>
              ) : null}
              {isSafari && !supportsPWA ? (
                <Text fontSize="md">
                  <strong>
                    Toque em <Icon name="ios-share" size="1.8rem" fill="none" d="inline-block" />
                  </strong>{' '}
                  e depois em <em>Adicionar à tela de início</em>.
                </Text>
              ) : null}
              {supportsPWA ? (
                <Text fontSize="md">
                  Aproximadamente <strong>11MB de espaço</strong> serão utilizados em seu
                  dispositivo.
                </Text>
              ) : null}
            </ModalBody>

            <ModalFooter flexDir="column" alignItems="center">
              {supportsPWA ? (
                <Button w="100%" mb={4} onClick={onClickInstall}>
                  Adicionar a tela inicial
                </Button>
              ) : null}
              <Button
                variant="ghost"
                textDecoration="underline"
                fontSize="md"
                color="blue.500"
                flex="1"
                onClick={onPwaModalClose}
              >
                Não, obrigado
              </Button>
            </ModalFooter>
          </Box>
        </ModalContent>
      </ChakraModal>
      {children}
    </PWAContext.Provider>
  );
}

export { PWAContext, PWAProvider };
