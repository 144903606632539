import { Input, InputProps as ChakraInputProps } from '@chakra-ui/core';
import React from 'react';
export type InputProps = ChakraInputProps;

const InputLine: React.FC<ChakraInputProps> = ({
  h = '50px',
  background = 'transparent',
  fontSize = 'xl',
  fontWeight = '700',
  p = 2,
  border = 'none',
  borderBottom = '1px solid',
  borderColor = 'gray.400',
  borderRadius = 0,
  _focus = {
    borderColor: 'gray.700',
  },
  ...rest
}) => {
  return (
    <Input
      p={p}
      h={h}
      w="8rem"
      border={border}
      borderBottom={borderBottom}
      borderColor={borderColor}
      borderRadius={borderRadius}
      fontSize={fontSize}
      fontWeight={fontWeight}
      _focus={_focus}
      background={background}
      {...rest}
    />
  );
};

export default InputLine;
