import produce from 'immer';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import Address, { AddressFields, CreateAddressDTO } from '~/models/Address';

/* ============== ACTION TYPES ============== */

enum TypesNames {
  SAVE_ADDRESS = 'SAVE_ADDRESS',
  SET_MY_ADDRESSES = 'SET_MY_ADDRESSES',
  FETCH_MY_ADDRESSES = 'FETCH_MY_ADDRESSES',
  DELETE_ADDRESS = 'DELETE_ADDRESS',
  CLEAR_CURRENT_ADDRESS = 'CLEAR_CURRENT_ADDRESS',
  SET_CURRENT_ADDRESS = 'SET_CURRENT_ADDRESS',
  SET_FETCHED_ADDRESS = 'SET_FETCHED_ADDRESS',
  CLEAR_FETCHED_ADDRESS = 'CLEAR_FETCHED_ADDRESS',
  FETCH_ADDRESS_BY_LATLNG = 'FETCH_ADDRESS_BY_LATLNG',
  FETCH_ADDRESS_BY_ADDRESS_FIELDS = 'FETCH_ADDRESS_BY_ADDRESS_FIELDS',
  FETCH_ADDRESS_BY_ZIPCODE = 'FETCH_ADDRESS_BY_ZIPCODE',
  SET_ADVERTISER_DELIVER_IN_CURRENT_ADDRESS = 'SET_ADVERTISER_DELIVER_IN_CURRENT_ADDRESS',
  SET_ADDRESS_DEFAULT = 'SET_ADDRESS_DEFAULT',
  SAVE_ADDRESS_FROM_CURRENT_LOCATION = 'SAVE_ADDRESS_FROM_CURRENT_LOCATION',
}

export interface OnSaveAddress extends Action<TypesNames.SAVE_ADDRESS> {
  address: CreateAddressDTO;
}

export interface OnSetMyAddress extends Action<TypesNames.SET_MY_ADDRESSES> {
  addresses: Address[];
}

export interface OnFetchMyAddresses extends Action<TypesNames.FETCH_MY_ADDRESSES> {}

export interface OnDeleteAddress extends Action<TypesNames.DELETE_ADDRESS> {
  address: Address;
}

export interface OnClearCurrentAddress extends Action<TypesNames.CLEAR_CURRENT_ADDRESS> {}
export interface OnSetCurrentAddress extends Action<TypesNames.SET_CURRENT_ADDRESS> {
  address: Address;
}

export interface OnSetAddressDefault extends Action<TypesNames.SET_ADDRESS_DEFAULT> {
  address: Address;
}

export interface OnSetFetchedAddress extends Action<TypesNames.SET_FETCHED_ADDRESS> {
  address: Address;
}
export interface OnClearFetchedAddress extends Action<TypesNames.CLEAR_FETCHED_ADDRESS> {}

export interface OnFetchAddressByLatLng extends Action<TypesNames.FETCH_ADDRESS_BY_LATLNG> {
  latitude: number;
  longitude: number;
}

export interface OnFetchAddressByAddressFields
  extends Action<TypesNames.FETCH_ADDRESS_BY_ADDRESS_FIELDS> {
  fields: AddressFields;
  notRedirect?: boolean;
}

export interface OnFetchAddressByZipcode extends Action<TypesNames.FETCH_ADDRESS_BY_ZIPCODE> {
  zipcode: string;
}

export interface OnSetAdvertiserDeliverInCurrentAddress
  extends Action<TypesNames.SET_ADVERTISER_DELIVER_IN_CURRENT_ADDRESS> {
  advertiserDeliverInCurrentAddress: boolean | null;
}

/* ============== ACTION CREATORS AND TYPES ============== */
export const { Types, Creators } = createActions<
  {
    [TypesNames.SAVE_ADDRESS]: string;
    [TypesNames.SET_MY_ADDRESSES]: string;
    [TypesNames.FETCH_MY_ADDRESSES]: string;
    [TypesNames.DELETE_ADDRESS]: string;
    [TypesNames.CLEAR_CURRENT_ADDRESS]: string;
    [TypesNames.SET_CURRENT_ADDRESS]: string;
    [TypesNames.SET_FETCHED_ADDRESS]: string;
    [TypesNames.FETCH_ADDRESS_BY_LATLNG]: string;
    [TypesNames.FETCH_ADDRESS_BY_ZIPCODE]: string;
    [TypesNames.CLEAR_FETCHED_ADDRESS]: string;
    [TypesNames.SET_ADVERTISER_DELIVER_IN_CURRENT_ADDRESS]: string;
    [TypesNames.FETCH_ADDRESS_BY_ADDRESS_FIELDS]: string;
    [TypesNames.SET_ADDRESS_DEFAULT]: string;
  },
  {
    saveAddress: (address: CreateAddressDTO) => OnSaveAddress;
    setMyAddresses: (addresses: Address[]) => OnSetMyAddress;
    fetchMyAddresses: () => OnFetchMyAddresses;
    deleteAddress: (address: Address) => OnDeleteAddress;
    clearCurrentAddress: () => OnClearCurrentAddress;
    setCurrentAddress: (address: Address) => OnSetCurrentAddress;
    setAddressDefault: (address: Address) => OnSetAddressDefault;
    setFetchedAddress: (address: Address) => OnSetFetchedAddress;
    fetchAddressByLatlng: (latitude: number, longitude: number) => OnFetchAddressByLatLng;
    fetchAddressByZipcode: (zipcode: string) => OnFetchAddressByZipcode;
    clearFetchedAddress: () => OnClearFetchedAddress;
    setAdvertiserDeliverInCurrentAddress: (
      advertiserDeliverInCurrentAddress: boolean | null,
    ) => OnSetAdvertiserDeliverInCurrentAddress;
    fetchAddressByAddressFields: (
      fields: AddressFields,
      notRedirect?: boolean,
    ) => OnFetchAddressByAddressFields;
  }
>({
  saveAddress: ['address'],
  setMyAddresses: ['addresses'],
  fetchMyAddresses: null,
  deleteAddress: ['address'],
  clearCurrentAddress: null,
  clearFetchedAddress: null,
  setCurrentAddress: ['address'],
  setAddressDefault: ['address'],
  setFetchedAddress: ['address'],
  fetchAddressByLatlng: ['latitude', 'longitude'],
  fetchAddressByZipcode: ['zipcode'],
  setAdvertiserDeliverInCurrentAddress: ['advertiserDeliverInCurrentAddress'],
  fetchAddressByAddressFields: ['fields', 'notRedirect'],
});

export const AddressesTypes = Types;
export default Creators;

/* ============== INITIAL STATE ============== */
export interface AddressesStateType {
  currentAddress: Address;
  addresses: Address[];
  fetchedAddress?: Address;
  loading: boolean;
  doesAdvertiserDeliversInCurrentAddress?: boolean;
}

export const INITIAL_STATE: AddressesStateType = {
  addresses: [],
  currentAddress: null,
  fetchedAddress: null,
  loading: false,
  doesAdvertiserDeliversInCurrentAddress: false,
};

/* ============== REDUCERS ============== */

export const saveAddressReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

export const fetchMyAddressesReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

export const setMyAddressesReducer = (state = INITIAL_STATE, { addresses }: OnSetMyAddress) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.addresses = addresses;
  });

export const setCurrentAddressReducer = (state = INITIAL_STATE, { address }: OnSetCurrentAddress) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.currentAddress = address;
  });

export const setAddressDefaultReducer = (state = INITIAL_STATE, { address }: OnSetAddressDefault) =>
  produce(state, (draft) => {});

export const setFetchedAddressReducer = (state = INITIAL_STATE, { address }: OnSetFetchedAddress) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.fetchedAddress = address;
  });

export const clearCurrentAddressReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.currentAddress = null;
  });

export const clearFetchedAddressReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.fetchedAddress = null;
  });

export const fetchAnyAddressReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

export const setAdvertiserDeliverInCurrenAddressReducer = (
  state = INITIAL_STATE,
  { advertiserDeliverInCurrentAddress }: OnSetAdvertiserDeliverInCurrentAddress,
) =>
  produce(state, (draft) => {
    draft.doesAdvertiserDeliversInCurrentAddress = advertiserDeliverInCurrentAddress;
  });

export const cleanDeliversInCurrentAddressReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.doesAdvertiserDeliversInCurrentAddress = false;
  });

export const reducer = createReducer<typeof INITIAL_STATE, any>(INITIAL_STATE, {
  [Types.SAVE_ADDRESS]: saveAddressReducer,
  [Types.SET_MY_ADDRESSES]: setMyAddressesReducer,
  [Types.FETCH_MY_ADDRESSES]: fetchMyAddressesReducer,
  [Types.SET_CURRENT_ADDRESS]: setCurrentAddressReducer,
  [Types.SET_ADDRESS_DEFAULT]: setAddressDefaultReducer,
  [Types.CLEAR_CURRENT_ADDRESS]: clearCurrentAddressReducer,
  [Types.FETCH_ADDRESS_BY_LATLNG]: fetchAnyAddressReducer,
  [Types.FETCH_ADDRESS_BY_ZIPCODE]: fetchAnyAddressReducer,
  [Types.FETCH_ADDRESS_BY_ADDRESS_FIELDS]: fetchAnyAddressReducer,
  [Types.SET_FETCHED_ADDRESS]: setFetchedAddressReducer,
  [Types.SET_ADVERTISER_DELIVER_IN_CURRENT_ADDRESS]: setAdvertiserDeliverInCurrenAddressReducer,
  [Types.CLEAR_FETCHED_ADDRESS]: clearFetchedAddressReducer,
});
