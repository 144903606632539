import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import BottomBar from '~/components/BottomBar';
import Button from '~/components/Button';
import Form from '~/components/Form';
import Input from '~/components/Form/Input';
import GoToButton from '~/components/GoToButton';
import Select from '~/components/Select';
import UnsupportedAddressModal from '~/components/UnsupportedAddressModal';
import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import Address from '~/models/Address';
import AdvertiserNeighborhoodAreaConfig from '~/models/AdvertiserNeighborhoodAreaConfig';
import OrderSummary from '~/models/OrderSummary';
import confirmAddressNeighborhoodSchema from '~/models/schemas/confirmAddressNeighborhoodSchema';
import { advertiserMenu, listAddress } from '~/routes/routeMap';
import { RootState } from '~/store';
import AddressActions from '~/store/ducks/addresses';

import { State, states } from '../states';

interface ConfirmAddressInputs {
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
}

interface UseLocationProps {
  onConfirmBackTo: string;
  orderSummaryData: OrderSummary;
}

const ConfirmAddressNeighborhood: React.FC = () => {
  const dispatch = useDispatch();
  const { saveAddress, setAdvertiserDeliverInCurrentAddress } = AddressActions;

  const location = useLocation<UseLocationProps>();
  const state = location?.state;
  const globalState = useSelector((state: RootState) => state);
  const advertiserInfo = globalState?.advertiser?.advertiserInfo;
  const deliveryTakeawayConfig = advertiserInfo?.deliveryTakeawayConfig;
  const neighborhoodAreaConfigs = advertiserInfo?.neighborhoodAreaConfigs;
  const neighborhoods = neighborhoodAreaConfigs?.map((n) => ({
    label: `${n.district.name}, ${n.district.locality.name} - ${n.district.locality.uf}`,
    value: String(n.district.id),
  }));
  const isUsingNeighborhoodArea =
    !deliveryTakeawayConfig?.is_using_range_area &&
    deliveryTakeawayConfig?.is_using_neighborhood_area;

  const { loading } = globalState.addresses;

  const { register, handleSubmit, errors, watch } = useForm<ConfirmAddressInputs>({
    resolver: useYupValidationResolver(confirmAddressNeighborhoodSchema),
  });
  const selectedNeighborhood = watch('neighborhood');
  const [selectedNeighborhoodAreaConfig, setSelectedNeighborhoodAreaConfig] =
    useState<AdvertiserNeighborhoodAreaConfig>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ufs, setUfs] = useState<State[]>([]);

  const addressSubmited = useRef(false);

  useEffect(() => {
    if (!advertiserInfo) {
      dispatch(push(advertiserMenu));
    }
  }, [advertiserInfo]);

  useEffect(() => {
    const wasAddressSubmited = addressSubmited.current;
    return () => {
      if (wasAddressSubmited) {
        addressSubmited.current = false;
      }
    };
  }, [globalState, location, addressSubmited]);

  const fetchUfsFromIbge = useCallback(() => {
    setUfs(states.sort((prev, next) => prev.nome.localeCompare(next.nome)));
  }, []);

  useEffect(() => {
    fetchUfsFromIbge();
  }, [fetchUfsFromIbge]);

  useEffect(() => {
    const selectedDistrictId = Number(selectedNeighborhood);
    const foundNeighborhoodAreaConfig = neighborhoodAreaConfigs?.find(
      (n) => n.dne_district_id === selectedDistrictId,
    );
    setSelectedNeighborhoodAreaConfig(() => foundNeighborhoodAreaConfig ?? null);
  }, [selectedNeighborhood, neighborhoodAreaConfigs]);

  const onSubmit = useCallback(
    async (data: ConfirmAddressInputs) => {
      if (!isUsingNeighborhoodArea) {
        return;
      }

      let neighborhood: string;
      let foundNeighborhoodAreaConfig;

      foundNeighborhoodAreaConfig = neighborhoodAreaConfigs?.find(
        (n) => n.dne_district_id === Number(data.neighborhood),
      );
      neighborhood = foundNeighborhoodAreaConfig ? foundNeighborhoodAreaConfig.district.name : null;

      if (!foundNeighborhoodAreaConfig) {
        onOpen();
        return;
      }
      dispatch(setAdvertiserDeliverInCurrentAddress(true));

      const addressDetails: Address = {
        street: data.street,
        number: data.number.trim() === '' ? 'S/N' : data.number,
        complement: data.complement,
        neighborhood,
        city: foundNeighborhoodAreaConfig.district.locality.name,
        state: foundNeighborhoodAreaConfig.district.locality.uf,
        dne_district_id: foundNeighborhoodAreaConfig.dne_district_id,
        zipcode: null,
        latitude: null,
        longitude: null,
      };

      dispatch(saveAddress(addressDetails));
      addressSubmited.current = true;

      if (state?.onConfirmBackTo) {
        dispatch(
          push(state?.onConfirmBackTo, {
            ...state,
            orderSummaryData: { ...state.orderSummaryData, deliveryAddress: addressDetails },
          }),
        );
      } else {
        dispatch(push(advertiserMenu));
      }
    },
    [
      dispatch,
      state,
      saveAddress,
      setAdvertiserDeliverInCurrentAddress,
      neighborhoodAreaConfigs,
      onOpen,
      isUsingNeighborhoodArea,
    ],
  );

  const onClickSelectAnotherAddress = () => {
    dispatch(push(listAddress));
  };

  return (
    <>
      <Box px="3rem" pt={8} display="flex" alignItems="center" justifyContent="flex-start" w="100%">
        <GoToButton />
        <Text
          as="span"
          fontSize="1.5rem"
          fontWeight="bold"
          display="flex"
          alignItems="center"
          justifyContent="center"
          w="100%"
          pr="1rem"
        >
          Meu Endereço
        </Text>
      </Box>

      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingBottom: '10rem' }}
      >
        <FormControl pt="4rem" mb={6} isInvalid={!!errors.neighborhood}>
          <FormLabel fontSize="1.5rem" fontWeight="bold">
            Localidade
          </FormLabel>
          <Select placeholder="Selecione sua localidade" name="neighborhood" register={register}>
            {neighborhoods?.map((n) => (
              <option key={n.value} value={n.value}>
                {n.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={6}>
          <FormControl mr={4} flex={1}>
            <FormLabel fontSize="1.5rem" fontWeight="bold" color="gray.500">
              Estado
            </FormLabel>
            <InputGroup>
              <Select
                placeholder="Selecione sua localidade"
                name="state"
                isDisabled
                value={selectedNeighborhoodAreaConfig?.district?.uf ?? ''}
              >
                {ufs.map((uf) => (
                  <option key={uf.id} value={uf.sigla}>
                    {uf.nome}
                  </option>
                ))}
              </Select>
            </InputGroup>
          </FormControl>
          <FormControl flex={1}>
            <FormLabel fontSize="1.5rem" fontWeight="bold" color="gray.500">
              Cidade
            </FormLabel>
            <InputGroup>
              <Input
                placeholder="Selecione sua localidade"
                type="text"
                name="city"
                isDisabled
                value={selectedNeighborhoodAreaConfig?.district?.locality?.name ?? ''}
              />
            </InputGroup>
          </FormControl>
        </Box>

        <FormControl mb={6} isInvalid={!!errors.street}>
          <FormLabel fontSize="1.5rem" fontWeight="bold">
            Sua Rua
          </FormLabel>
          <InputGroup>
            <Input
              placeholder="Ex: Rua Antonio Cabral"
              type="text"
              autoComplete="off"
              name="street"
              register={register}
            />
            {!!errors.street && (
              <InputRightElement h="50px" w="50px">
                <Tooltip
                  aria-label="Erro."
                  placement="top"
                  hasArrow
                  label={errors.street?.message}
                  bg="red.400"
                >
                  <IconButton
                    aria-label="Erro."
                    icon="warning"
                    color="red.300"
                    _focus={undefined}
                  />
                </Tooltip>
              </InputRightElement>
            )}
          </InputGroup>
        </FormControl>

        <Box pt="1rem" display="flex" alignItems="center" justifyContent="space-between">
          <FormControl mr={4} isInvalid={!!errors.number}>
            <FormLabel fontSize="1.5rem" fontWeight="bold" htmlFor="number">
              Seu número
            </FormLabel>
            <InputGroup>
              <Input
                placeholder="Ex: 345"
                type="text"
                name="number"
                id="number"
                register={register}
              />
              {!!errors.number && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors.number?.message}
                    bg="red.400"
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
          <FormControl isInvalid={!!errors.complement} width="100%">
            <FormLabel fontSize="1.5rem" fontWeight="bold" htmlFor="complement">
              Complemento
            </FormLabel>
            <InputGroup>
              <Input
                placeholder="Ex: Quadra/Lote/Apt"
                type="text"
                id="complement"
                name="complement"
                register={register}
              />
              {!!errors.complement && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors.complement?.message}
                    bg="red.400"
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
        </Box>

        <BottomBar zIndex={600}>
          <Box d="flex" w="100%" py={4}>
            <Button
              flex="1"
              type="submit"
              bg="green.300"
              isLoading={loading}
              isDisabled={Object.keys(errors).length > 0 || loading}
            >
              Confirmar Endereço
            </Button>
          </Box>
        </BottomBar>

        <UnsupportedAddressModal
          isOpen={isOpen}
          onClose={onClose}
          onClickAction={onClickSelectAnotherAddress}
        />
      </Form>
    </>
  );
};

export default ConfirmAddressNeighborhood;
