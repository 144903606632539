import {
  Box,
  Divider,
  Icon,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { RiCheckboxCircleFill, RiHistoryLine } from 'react-icons/ri';
import { TiDeleteOutline, TiLocationOutline } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import AlertDialog from '~/components/AlertDialog';
import BottomBar from '~/components/BottomBar';
import Button from '~/components/Button';
import Input from '~/components/Form/Input';
import GoToButton from '~/components/GoToButton';
import UnsupportedAddressModal from '~/components/UnsupportedAddressModal';
import useDynamicDeliveryFee from '~/hooks/useDynamicDelivery';
import Address, { CreateAddressDTO } from '~/models/Address';
import OrderSummary from '~/models/OrderSummary';
import {
  advertiserMenu,
  autocompleteAddress,
  confirmAddressGPS,
  confirmAddressNeighborhood,
} from '~/routes/routeMap';
import { getAddressByLatLng, getLatLngByAddressFields } from '~/services/address';
import { RootState } from '~/store';
import AddressActions from '~/store/ducks/addresses';
import { getDistanceBetweenAddresses } from '~/utils/location';

import { ConfirmAddressFields } from '../AutocompleteAddress';
import ConfirmCurrentLocation from '../ConfirmCurrentLocation';
import AddressItem from './AddressItem';
import { CustonText, CustonTextComplement } from './styles';

interface UseLocationProps {
  onConfirmBackTo: string;
  orderSummaryData: OrderSummary;
  selectExistingAddress: boolean;
}

const ListAddress: React.FC = () => {
  const location = useLocation<UseLocationProps>();

  const [deleteAddress, setDeleteAddress] = useState<Address | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [foundCurrentLocation, setFoundCurrentLocation] = useState<CreateAddressDTO>(null);

  const { state } = location;
  const globalState = useSelector((state: RootState) => state);

  const { advertiserInfo } = globalState.advertiser;
  const deliveryTakeawayConfig = advertiserInfo?.deliveryTakeawayConfig;
  const neighborhoodAreaConfigs = advertiserInfo?.neighborhoodAreaConfigs;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const { addresses, currentAddress } = globalState.addresses;

  useEffect(() => {
    if (!advertiserInfo || !advertiserInfo?.user || !advertiserInfo?.delivery_range_area) {
      dispatch(push(advertiserMenu));
    }
  }, [advertiserInfo]);

  const {
    fetchMyAddresses,
    setAdvertiserDeliverInCurrentAddress,
    setCurrentAddress,
    setAddressDefault,
    deleteAddress: deleteAddressAction,
  } = AddressActions;

  const validateIfAdvertiserDeliversInCurrentAddress = useCallback(
    (address: Address, advertiserAddress: Address) => {
      if (
        deliveryTakeawayConfig?.is_using_neighborhood_area &&
        !deliveryTakeawayConfig?.is_using_range_area
      ) {
        const foundNeighborhoodAreaConfig = neighborhoodAreaConfigs.find(
          (n) =>
            n.district.name.toLowerCase() === address?.neighborhood?.toLowerCase() &&
            n.district.uf.toLowerCase() === address?.state?.toLowerCase() &&
            n.district.id === address.dne_district_id,
        );
        return foundNeighborhoodAreaConfig !== undefined;
      }
      const distanceToAdvertiser = getDistanceBetweenAddresses(address, advertiserAddress);
      return distanceToAdvertiser <= advertiserInfo?.delivery_range_area * 1000;
    },
    [advertiserInfo?.delivery_range_area, deliveryTakeawayConfig, neighborhoodAreaConfigs],
  );

  const handleOnSetCurrentAddress = useCallback(
    (address: Address) => {
      const advertiserAddress = advertiserInfo?.user?.address[0];
      const advertiserDeliversInCurrentAddress = validateIfAdvertiserDeliversInCurrentAddress(
        address,
        advertiserAddress,
      );
      dispatch(setAdvertiserDeliverInCurrentAddress(advertiserDeliversInCurrentAddress));
      if (!advertiserDeliversInCurrentAddress) {
        dispatch(setCurrentAddress(null));
        onOpen();
        return;
      }

      dispatch(setCurrentAddress(address));
      if (address?.id) {
        dispatch(setAddressDefault(address));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      onOpen,
      setCurrentAddress,
      setAdvertiserDeliverInCurrentAddress,
      advertiserInfo?.user?.address,
      validateIfAdvertiserDeliversInCurrentAddress,
    ],
  );

  const getCurrentLocationFromCoords = useCallback(
    async ({ latitude, longitude }: { latitude: number; longitude: number }) => {
      try {
        setLoading(true);
        const foundLocation = await getAddressByLatLng({
          latitude,
          longitude,
        });
        setFoundCurrentLocation(() => foundLocation);
      } catch (err) {
        toast.error('Não foi possível obter sua localização atual.');
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const onCloseBottomBar = useCallback(() => {
    setFoundCurrentLocation(null);
  }, []);

  const onSubmitCurrentLocation = useCallback(
    async (data: ConfirmAddressFields) => {
      try {
        setLoading(true);

        const foundLocation = await getLatLngByAddressFields({
          street: foundCurrentLocation.street,
          number: data.number,
          neighborhood: data.neighborhood,
          city: foundCurrentLocation.city,
          state: foundCurrentLocation.state,
          zipcode: data.zipcode,
        });

        dispatch(
          push(confirmAddressGPS, {
            ...state,
            addressDetails: {
              street: foundLocation.street,
              number: data.number.trim() === '' ? 'S/N' : data.number,
              complement: data.complement,
              neighborhood: data.neighborhood,
              city: foundLocation.city,
              state: foundLocation.state,
              zipcode: data.zipcode,
              lat: foundLocation.latitude,
              lng: foundLocation.longitude,
            },
          }),
        );
      } catch (err) {
        toast.error('Ocorreu um erro ao buscar seu endereço.');
      } finally {
        setLoading(false);
      }
    },
    [dispatch, foundCurrentLocation, state],
  );

  const handleCurrentPosition = useCallback(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          getCurrentLocationFromCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          const errorMessage =
            error.code === 1
              ? 'Não foi possível obter sua localização, permissão negada.'
              : 'Não foi possível obter sua localização, erro inesperado.';
          toast.error(errorMessage);
        },
      );
    }
  }, [getCurrentLocationFromCoords]);

  const delivery = useDynamicDeliveryFee({
    advertiserAddress: advertiserInfo?.user?.address[0],
    userAddress: currentAddress,
    deliveryFee: advertiserInfo?.delivery_fee,
    deliveryDynamicFeeMaxDist: advertiserInfo?.delivery_dynamic_fee_max_dist,
    deliveryDynamicFee: advertiserInfo?.delivery_dynamic_fee,
    deliveryDynamicFeeExceededAmount: advertiserInfo?.delivery_dynamic_fee_exceeded_amount,
    deliveryDynamicFeeFreeDist: advertiserInfo?.delivery_dynamic_fee_free_dist,
    deliveryType: state?.orderSummaryData?.deliveryType,
  });

  useEffect(() => {
    if (advertiserInfo) {
      dispatch(fetchMyAddresses());
    }
  }, [dispatch, fetchMyAddresses]);

  const handleOnClickConfirmAdress = useCallback(() => {
    const orderSummaryData = {
      ...state?.orderSummaryData,
      deliveryAddress: currentAddress,
      deliveryFee: delivery.value,
    };
    dispatch(push(state.onConfirmBackTo, { orderSummaryData }));
  }, [dispatch, state, currentAddress, delivery.value]);

  const { isOpen: AlertIsOpen, onOpen: AlertOnOpen, onClose: AlertOnClose } = useDisclosure();

  const handleOnClickDeleteAddress = (addressItem: Address) => {
    setDeleteAddress(addressItem);
    AlertOnOpen();
  };

  const handleDeleteAddress = () => {
    dispatch(deleteAddressAction(deleteAddress));
    AlertOnClose();
  };

  const handleDeleteAddressCancel = () => {
    setDeleteAddress(null);
    AlertOnClose();
  };

  const handleSetAddressFromCurrentLocation = useCallback(() => {
    handleCurrentPosition();
  }, [handleCurrentPosition]);

  return (
    <>
      <Box
        px="3rem"
        pt={8}
        pb={3}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        w="100%"
      >
        <GoToButton
          to={state?.onConfirmBackTo ? state?.onConfirmBackTo : advertiserMenu}
          state={state}
        />
        <Text
          as="span"
          fontSize="lg"
          fontWeight="bold"
          display="flex"
          alignItems="center"
          justifyContent="center"
          w="100%"
          pr="1rem"
        >
          Endereço de Entrega
        </Text>
      </Box>

      <Box
        px="3rem"
        pt="2rem"
        pb={state?.onConfirmBackTo ? '9rem' : 4}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDir="column"
      >
        {deliveryTakeawayConfig?.is_using_range_area &&
        !deliveryTakeawayConfig?.is_using_neighborhood_area ? (
          <Box w="100%" my="1rem">
            <InputGroup>
              <Input
                p="20px 20px 20px 50px"
                placeholder="Digite seu endereço com número"
                name="address-kuppi-search"
                onFocus={() => dispatch(push(autocompleteAddress, { ...state }))}
              />
              <InputLeftElement h="50px" w="50px">
                <Box
                  fontSize="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  background="transparent"
                  h="50px"
                  w="50px"
                >
                  <Icon size="1.5rem" name="search" color="#9b999b" />
                </Box>
              </InputLeftElement>
            </InputGroup>
          </Box>
        ) : (
          <Box w="100%" my="1rem">
            <Button
              variant="ghost"
              onClick={() => dispatch(push(confirmAddressNeighborhood, { ...state }))}
              fontWeight="600"
              color="gray.400"
              bg="#EFEFEF"
              justifyContent="space-between"
              border="2px solid"
              borderColor="#EFEFEF"
              _focus={{
                boxShadow: '0px 0px 12px #349B2C40',
              }}
              w="100%"
            >
              <Text as="span" mr={4}>
                Cadastre seu endereço
              </Text>
              <Box as={TiLocationOutline} size="2.4rem" color="#9b999b" />
            </Button>
          </Box>
        )}

        {deliveryTakeawayConfig?.is_using_range_area &&
          !deliveryTakeawayConfig?.is_using_neighborhood_area && (
            <AddressItem
              onClick={handleSetAddressFromCurrentLocation}
              px="20px"
              py="16px"
              d={['flex', 'flex', 'flex', 'flex', 'flex', 'none']}
            >
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-start"
                flexDir="column"
                w="auto"
                h="100%"
                pr="1rem"
              >
                {loading ? <Spinner size="md" /> : <Icon name="verify-address" size="2rem" />}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                flexDir="column"
                justifyContent="center"
                w="100%"
              >
                <Text
                  as="span"
                  fontSize="md"
                  fontWeight="bold"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  w="100%"
                >
                  Usar localização atual
                </Text>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  flexDir="column"
                  justifyContent="center"
                  w="100%"
                >
                  <Text as="span" fontSize="15px">
                    Ativar localização
                  </Text>
                </Box>
              </Box>
            </AddressItem>
          )}

        <Divider w="100%" />

        {addresses?.map((item) => (
          <Box w="100%" key={item.id}>
            <AddressItem isSelected={item?.id === currentAddress?.id}>
              <Box
                onClick={() => handleOnSetCurrentAddress(item)}
                display="flex"
                alignItems="flex-start"
                justifyContent="center"
                w="100%"
              >
                <Box
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDir="column"
                  w="auto"
                  h="100%"
                  pr="1rem"
                >
                  <Icon
                    as={item?.id === currentAddress?.id ? RiCheckboxCircleFill : RiHistoryLine}
                    size="2rem"
                  ></Icon>
                </Box>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  flexDir="column"
                  justifyContent="center"
                  w="100%"
                >
                  <Box display="flex" alignItems="flex-start" justifyContent="center">
                    <CustonText fontSize="15px" fontWeight="bold" as="span">
                      {item.street}
                    </CustonText>
                    <Text as="span" fontSize="15px" fontWeight="bold">
                      {`${item.number ? `, ${item.number} ` : ', S/N '}`}
                    </Text>
                  </Box>
                  {item.complement && (
                    <CustonTextComplement fontSize="15px" fontWeight="bold" as="span">
                      {item.complement}
                    </CustonTextComplement>
                  )}
                  <Text fontSize="15px" as="span">
                    {item.neighborhood ? `${item.neighborhood},` : ''}{' '}
                    {` ${item.city} - ${item.state}`}
                  </Text>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-start"
                flexDir="column"
                w="25px"
                h="auto"
                ml="1rem"
                as={TiDeleteOutline}
                onClick={() => handleOnClickDeleteAddress(item)}
              />
            </AddressItem>
          </Box>
        ))}
      </Box>
      {state?.onConfirmBackTo && (
        <BottomBar>
          <Box d="flex" w="100%" py={4}>
            <Button
              flex="1"
              bg="green.300"
              isDisabled={!currentAddress}
              onClick={handleOnClickConfirmAdress}
            >
              Confirmar endereço
            </Button>
          </Box>
        </BottomBar>
      )}
      {state?.selectExistingAddress && (
        <BottomBar>
          <Box d="flex" w="100%" py={4}>
            <Button
              flex="1"
              bg="green.300"
              onClick={() => {
                dispatch(push(advertiserMenu));
                toast.success('Endereço de entrega definido com sucesso!');
              }}
            >
              Confirmar endereço
            </Button>
          </Box>
        </BottomBar>
      )}
      {foundCurrentLocation && (
        <ConfirmCurrentLocation
          foundAddress={foundCurrentLocation}
          onCloseBottomBar={onCloseBottomBar}
          onSubmitCurrentLocation={onSubmitCurrentLocation}
          loading={loading}
        />
      )}

      <UnsupportedAddressModal isOpen={isOpen} onClose={onClose} />

      <AlertDialog
        isOpen={AlertIsOpen}
        onClose={AlertOnClose}
        onClickYes={handleDeleteAddress}
        onClickNo={handleDeleteAddressCancel}
        heading="Excluir endereço"
        message="Você tem certeza que deseja excluir o endereço?"
      />
    </>
  );
};

export default ListAddress;
