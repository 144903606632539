import { Box, Image, Text } from '@chakra-ui/core';
import React, { useCallback } from 'react';

import BottomBar, { BottomBarProps } from '~/components/BottomBar';
import Button from '~/components/Button';
import LoyaltyCardRules from '~/components/LoyaltyCardRules';
import LoyaltyCard from '~/models/LoyaltyCard';
import UserLoyaltyCard from '~/models/UserLoyaltyCard';
import { formatCurrencyBRL } from '~/utils/currency';

interface LoyaltyCardModalProps extends BottomBarProps {
  isVisible: boolean;
  advertiserLoyaltyCard: LoyaltyCard;
  userLoyaltyCard: UserLoyaltyCard;
}

const LoyaltyCardModal: React.FC<LoyaltyCardModalProps> = ({
  isVisible,
  onClose,
  advertiserLoyaltyCard,
  userLoyaltyCard,
}) => {
  const currentLoyaltyCard = userLoyaltyCard ? userLoyaltyCard : advertiserLoyaltyCard;

  const handleOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  if (isVisible) {
    return (
      <BottomBar onClose={handleOnClose} isOverlayVisible={true}>
        <Box
          d="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          px="2rem"
          pt="2.5rem"
        >
          <Text as="span" fontSize="1.7rem" textAlign="center">
            Faça seu pedido por aqui e preencha
            <br /> nosso cartão fidelidade
          </Text>

          <Text as="span" fontSize="1.5rem" textAlign="center" mt="2rem" fontWeight="bold">
            Compre {currentLoyaltyCard?.amount_of_purchases} vez
            {currentLoyaltyCard?.amount_of_purchases > 1 ? 'es' : ''} e ganhe{' '}
            {currentLoyaltyCard?.type === 'fixed_cart'
              ? formatCurrencyBRL(currentLoyaltyCard?.cart_discount_fixed)
              : `${currentLoyaltyCard?.cart_discount_percentage}%`}{' '}
            de desconto no próximo pedido
          </Text>

          <Box d="flex" mt="2rem">
            <Image src="/images/fidelity_complete.png" h="10rem" />
          </Box>

          <Box w="100%" mt="4rem">
            <Button
              fontSize="lg"
              w="100%"
              h="46px"
              mb="1rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
              onClick={handleOnClose}
            >
              <Text fontSize="1.6rem" as="span">
                Prosseguir para o cardápio
              </Text>
            </Button>
          </Box>
        </Box>

        <LoyaltyCardRules currentLoyaltyCard={currentLoyaltyCard} />
      </BottomBar>
    );
  } else {
    return null;
  }
};

export default LoyaltyCardModal;
