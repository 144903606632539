import { Box, Button, Heading, Image, Stack, Text } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { amplitudeTrackEvent } from '~/components/Amplitude';
import BottomBar from '~/components/BottomBar';
import ComponentButton from '~/components/Button';
import CircleToggle from '~/components/CircleToggle';
import InputLine from '~/components/Form/InputLine';
import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import AdvertiserPaymentMethod from '~/models/AdvertiserPaymentMethod';
import OrderSummary from '~/models/OrderSummary';
import { advertiserMenu, orderReview } from '~/routes/routeMap';
import { sendPaymentMethodEvent } from '~/services/mixPanel';
import { RootState } from '~/store';
import { isPaymentMethodDisabled } from '~/utils/bag';
import { formatCurrencyBRL } from '~/utils/currency';
import { numberMask } from '~/utils/masks';

interface UseLocationProps {
  orderSummaryData: OrderSummary;
}

const SelectPaymentMethod: React.FC = () => {
  const dispatch = useDispatch();

  const location = useLocation<UseLocationProps>();
  const { state } = location;
  const orderSummaryData = state?.orderSummaryData;
  const globalState = useSelector((state: RootState) => state);
  const { advertiserInfo } = globalState.advertiser;
  const { bagDiscounts } = globalState.bag;
  const paymentMethods: AdvertiserPaymentMethod[] = advertiserInfo?.advertiserPaymentMethod;
  const paymentMethodsOnline: AdvertiserPaymentMethod[] =
    advertiserInfo?.advertiserPaymentMethodOnline;
  const paymentMethodsOffline: AdvertiserPaymentMethod[] =
    advertiserInfo?.advertiserPaymentMethodOffline;
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<AdvertiserPaymentMethod>(undefined);
  const [isChangeModalVisible, setIsChangeModalVisible] = useState<boolean>(false);
  const [changeValue, setChangeValue] = useState<string>(null);

  useEffect(() => {
    if (!orderSummaryData) {
      dispatch(push(advertiserMenu));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSummaryData, dispatch, paymentMethods]);

  const handleClickOnPaymentMethod = useCallback((method: AdvertiserPaymentMethod) => {
    setSelectedPaymentMethod(method);

    // Amplitude Event
    amplitudeTrackEvent('selected_payment_info', {
      advertiser_info_id: globalState?.advertiser?.advertiserInfo?.id,
      advertiser_name: globalState?.advertiser?.advertiserInfo?.name,
      advertiser_category:
        globalState?.advertiser?.advertiserInfo?.advertiserCategory?.name?.toString(),
      user_id: globalState?.user?.user ? globalState?.user?.user?.id : null,
      order_payment_info: method,
    });
  }, []);

  const handleCloseBottomBar = () => {
    setSelectedPaymentMethod(undefined);
    setIsChangeModalVisible(false);
    setChangeValue(null);
  };

  const handleClickNoChangeButton = () => {
    orderSummaryData.change = changeValue ? Number(changeValue) : 0;
    orderSummaryData.advertiserPaymentMethod = selectedPaymentMethod;

    dispatch(push(orderReview, { orderSummaryData }));

    // Mixpanel Event
    sendPaymentMethodEvent('Funnel 5 - Payment Method', {
      id: orderSummaryData.clientId.toString(),
      webMenuId: orderSummaryData.products[0].advertiser_info_id.toString(),
      orderType: orderSummaryData.orderType,
      deliveryType: orderSummaryData.deliveryType,
      paymentMethod: orderSummaryData.advertiserPaymentMethod.paymentMethod.name,
    });
  };

  return (
    <Box as="main" flexDir="column" w="100%" px="3rem" mt="2.5rem" pb={40} bg="white">
      <HeaderWithBackButton headerTitle="Forma de pagamento" mb={9} />
      {bagDiscounts?.activeCoupon &&
        (!bagDiscounts.paymentMethods.isAcceptCard ||
          !bagDiscounts.paymentMethods.isAcceptMoney ||
          !bagDiscounts.paymentMethods.isAcceptPix) && (
          <Text
            fontSize="sm"
            mt={2}
            mb="1.4rem"
            px={4}
            py={2}
            borderRadius="0.5rem"
            bg="gray.100"
            textAlign="center"
          >
            O cupom escolhido não aceita todas as formas de pagamento
          </Text>
        )}
      {paymentMethodsOnline?.length > 0 && (
        <>
          <Box as="section" mb={3}>
            <Heading
              bg="gray.50"
              as="h1"
              fontSize="lg"
              fontWeight="700"
              py={6}
              mx="-3rem"
              px="3rem"
            >
              Pague online
            </Heading>
          </Box>
          <Box as="section" d="flex" flexDir="column" mb={5}>
            {paymentMethodsOnline?.map((method, idx) => (
              <Button
                key={`${method.paymentMethod?.slug}-${idx}`}
                py={4}
                pl={3}
                mb={4}
                height="4rem"
                border="1px solid #DBDBDB"
                outline={0}
                borderRadius={7}
                flexDir="row"
                isDisabled={isPaymentMethodDisabled(bagDiscounts, method)}
                onClick={() => handleClickOnPaymentMethod(method)}
                variant="ghost"
              >
                <Image h="2rem" mr={8} src={method.paymentMethod?.image_url} />
                <Text fontSize="1.3rem">{method.paymentMethod?.name}</Text>

                <CircleToggle
                  ml={'auto'}
                  mr={5}
                  isActive={
                    selectedPaymentMethod?.paymentMethod?.slug === method.paymentMethod?.slug
                  }
                />
              </Button>
            ))}
          </Box>
        </>
      )}

      {paymentMethodsOffline?.length > 0 && (
        <>
          <Box as="section" mb={4}>
            <Heading
              bg="gray.50"
              as="h1"
              fontSize="lg"
              fontWeight="700"
              py={6}
              mx="-3rem"
              px="3rem"
            >
              Pague na entrega
            </Heading>
          </Box>
          <Box as="section" d="flex" flexDir="column">
            {paymentMethodsOffline?.map((method, idx) => (
              <Button
                key={`${method.paymentMethod?.slug}-${idx}`}
                py={4}
                pl={3}
                mb={4}
                height="4rem"
                border="1px solid #DBDBDB"
                outline={0}
                borderRadius={7}
                flexDir="row"
                isDisabled={isPaymentMethodDisabled(bagDiscounts, method)}
                onClick={() => handleClickOnPaymentMethod(method)}
                variant="ghost"
              >
                <Image h="2rem" mr={8} src={method.paymentMethod?.image_url} />
                <Text fontSize="1.3rem">{method.paymentMethod?.name}</Text>

                <CircleToggle
                  ml={'auto'}
                  mr={5}
                  isActive={
                    selectedPaymentMethod?.paymentMethod?.slug === method.paymentMethod?.slug
                  }
                />
              </Button>
            ))}
          </Box>
        </>
      )}

      {selectedPaymentMethod?.paymentMethod?.slug === 'cash' ? (
        <BottomBar isOverlayVisible onClose={handleCloseBottomBar}>
          {isChangeModalVisible ? (
            <Stack spacing={16} alignItems="center" py={10} px={4}>
              <Heading as="h4" fontSize="lg">
                Troco pra quanto?
              </Heading>
              <Text fontSize="sm" textAlign="center">
                Seu pedido deu {formatCurrencyBRL(orderSummaryData.total)}. Digite quanto vai pagar
                em dinheiro para que o entregador leve seu troco.
              </Text>
              <Box d="flex" alignItems="center" justifyContent="center">
                <Text as="span" fontSize="xl" fontWeight="700" mr={4}>
                  R$
                </Text>
                <InputLine
                  placeholder="0,00"
                  value={changeValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setChangeValue(numberMask(String(e.target.value)))
                  }
                />
              </Box>
              <ComponentButton
                w="100%"
                isDisabled={Number(changeValue) <= orderSummaryData.total}
                onClick={handleClickNoChangeButton}
              >
                Continuar
              </ComponentButton>
            </Stack>
          ) : (
            <Stack spacing={16} alignItems="center" py={10}>
              <Heading as="h4" fontSize="lg">
                Vai precisar de troco?
              </Heading>
              <Box d="flex" alignItems="center" justifyContent="space-between" w="100%">
                <ComponentButton flex="1" mr={2} onClick={handleClickNoChangeButton}>
                  Não
                </ComponentButton>
                <ComponentButton flex="1" onClick={() => setIsChangeModalVisible(true)}>
                  Sim
                </ComponentButton>
              </Box>
            </Stack>
          )}
        </BottomBar>
      ) : (
        <BottomBar>
          <Stack spacing={16} alignItems="center" py={3} px={4}>
            <ComponentButton
              w="100%"
              isDisabled={!selectedPaymentMethod}
              onClick={handleClickNoChangeButton}
            >
              Continuar
            </ComponentButton>
          </Stack>
        </BottomBar>
      )}
    </Box>
  );
};

export default SelectPaymentMethod;
