import styled from '@emotion/styled';

export const FileInput = styled('input')`
  position: absolute;
  width: 10rem;
  height: 10rem;
  top: 0;
  z-index: 0;
  background: transparent;
  border: none;
  opacity: 0;
`;
