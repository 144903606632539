import { useEffect, useState } from 'react';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => void;
  userChoice: Promise<'accepted' | 'dismissed'>;
}

const usePWAInstall = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [beforeInstallEvent, setBeforeInstallEvent] = useState<BeforeInstallPromptEvent | null>(
    null,
  );

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      setSupportsPWA(true);
      setBeforeInstallEvent(e);
    };
    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('transitionend', handler);
  }, []);

  return {
    supportsPWA,
    beforeInstallEvent,
  };
};

export default usePWAInstall;
