import { Box, Divider, Heading, Image, Stack, Text } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import ListItem from '~/components/ListItem';
import Order from '~/models/Order';
import BagPriceDescription from '~/pages/Bag/BagPriceDescription';
import { advertiserMenu } from '~/routes/routeMap';
import { RootState } from '~/store';
import { formatAddress } from '~/utils/address';
import { formatCurrencyBRL } from '~/utils/currency';

interface UseLocationProps {
  order: Order;
}

const OrderDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { state } = useLocation<UseLocationProps>();
  const order = state?.order;
  const advertiserInfo = useSelector((state: RootState) => state.advertiser.advertiserInfo);

  const [discountInOrder, setDiscountInOrder] = useState<boolean>(false);
  const [discountInOrderPercent, setDiscountInOrderPercent] = useState<number>();

  useEffect(() => {
    if (!order) {
      dispatch(push(advertiserMenu));
    }
  }, [order, dispatch]);

  useEffect(() => {
    const filter = order?.orderCoupon?.filter((item: any) => {
      return item?.userCoupon?.coupon?.type === 'percentage_cart' ? true : false;
    });

    if (filter?.length > 0) {
      setDiscountInOrder(true);
      setDiscountInOrderPercent(
        filter[0]?.userCoupon?.coupon?.couponBenefit?.cart_discount_percentage,
      );
    } else {
      setDiscountInOrder(false);
      setDiscountInOrderPercent(0);
    }
  }, [order]);

  const getItemPrice = useCallback((item: any) => {
    let price = item?.product?.amount;

    let allPrice = price;
    item?.orderProductComplement?.forEach((option: any) => {
      allPrice += option?.amount * option?.quantity;
    });

    return allPrice;
  }, []);

  return (
    <Box as="main" flexDir="column" w="100%" px="3rem" mt="2.5rem" pb={40}>
      <HeaderWithBackButton headerTitle="Detalhe do pedido" mb={16} />
      <Box d="flex" alignItems="center" mb={3}>
        <Heading as="h2" fontSize="lg">
          {advertiserInfo?.name}
        </Heading>
      </Box>

      <Stack spacing={0} as="section">
        {order?.orderProduct?.map((item: any) => (
          <ListItem key={item.id}>
            <Box d="flex" alignItems="flex-start" justifyContent="space-between" w="100%">
              <Box d="flex" alignItems="flex-start" flexDir="column">
                <Text fontSize="sm">
                  {item?.quantity}x {item?.product?.name}
                </Text>
              </Box>
              <Box d="flex" alignItems="flex-start" justifyContent="center">
                <Text as="span" textAlign="center" fontSize="sm" fontWeight="700">
                  {formatCurrencyBRL(getItemPrice(item) * item?.quantity)}
                </Text>
              </Box>
            </Box>
            <Box d="flex" alignItems="flex-start" flexDir="column">
              {item.orderProductComplement.map((complement: any) => (
                <Box w="100%" key={complement?.id} d="flex" justifyContent="space-between">
                  <>
                    <Text fontSize="sm">
                      {complement?.quantity}x {complement?.productComplementOption?.name}
                    </Text>
                  </>
                </Box>
              ))}
            </Box>
          </ListItem>
        ))}
      </Stack>

      <Box as="section">
        <Stack as="footer" spacing={3}>
          <BagPriceDescription
            descriptionTitle="Subtotal"
            descriptionPrice={order?.amount}
            color="gray.400"
          />
          {order?.delivery_type === 'delivery' && (
            <BagPriceDescription
              descriptionTitle="Taxa de delivery"
              descriptionPrice={order?.delivery_fee > 0 ? order?.delivery_fee : 'Grátis'}
              color="gray.400"
            />
          )}
          {order?.discount && order?.discount > 0 && (
            <BagPriceDescription
              descriptionTitle="Descontos"
              descriptionPrice={order?.discount * -1}
              color="red.300"
            />
          )}
          <BagPriceDescription
            my={6}
            descriptionTitle="Total"
            descriptionPrice={order?.total_amount}
            color="gray.800"
            fontWeight="700"
            fontSize="lg"
          />
        </Stack>
        <Divider />

        <Stack spacing={4} py={6} w="100%">
          <Text fontSize="md" color="gray.400">
            {order?.delivery_type === 'delivery' ? 'Endereço de entrega' : 'Endereço de retirada'}
          </Text>
          <Text fontSize="md" fontWeight="700">
            {formatAddress(order?.address)}
          </Text>
        </Stack>

        <Divider w="100%" m={0} />

        {!!discountInOrder && (
          <>
            <Stack spacing={4} py={6} w="100%">
              <Text fontSize="md" color="gray.400">
                Benefícios do pedido
              </Text>
              <Box d="flex" alignItems="center">
                <Image src="/favicon.png" mr={4} h="2rem" />
                <Text fontSize="md" fontWeight="700">
                  Você economizou <Text as="span">{discountInOrderPercent}%</Text> na compra toda.
                </Text>
              </Box>
            </Stack>

            <Divider w="100%" m={0} />
          </>
        )}

        {!discountInOrder && !!order?.discount && (
          <>
            <Stack spacing={4} py={6} w="100%">
              <Text fontSize="md" color="gray.400">
                Benefícios do pedido
              </Text>
              <Box d="flex" alignItems="center">
                <Image src="/favicon.png" mr={4} h="2rem" />
                <Text fontSize="md" fontWeight="700">
                  Você está economizando {formatCurrencyBRL(order?.discount)}.
                </Text>
              </Box>
            </Stack>

            <Divider w="100%" m={0} />
          </>
        )}

        <Stack spacing={2} py={6} w="100%">
          <Text fontSize="md" color="gray.400">
            Forma de Pagamento
          </Text>
          <Text fontSize="md" fontWeight="700">
            {order?.paymentMethod?.slug === 'cash' ? (
              <ListItem
                key={order?.paymentMethod?.slug}
                py={2}
                borderTop="0"
                as="button"
                outline={0}
                flexDir="row"
                alignItems="center"
              >
                <Image h="2rem" mr={4} src={'/images/cash.svg'} />
                <Text fontSize="md" fontWeight="700" textAlign="left">
                  Dinheiro, Troco {formatCurrencyBRL(order?.change)}
                </Text>
              </ListItem>
            ) : (
              <ListItem
                key={order?.paymentMethod?.slug}
                py={2}
                borderTop="0"
                as="button"
                outline={0}
                flexDir="row"
                alignItems="center"
              >
                <Image h="2rem" mr={8} src={order?.paymentMethod?.image_url} />
                <Text fontSize="md" fontWeight="700" textAlign="left">
                  {order?.paymentMethod?.name}
                </Text>
              </ListItem>
            )}{' '}
          </Text>
        </Stack>
      </Box>
    </Box>
  );
};

export default OrderDetails;
