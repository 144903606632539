import { Box, Image, Text } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import pixHelpPic from '~/assets/images/logo_pix/pix_help.png';
import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import Order from '~/models/Order';
import PaymentData from '~/models/Order';

interface UseLocationProps {
  order: Order;
  orderList?: string;
  goBackToMyOrders: boolean;
  paymentData: PaymentData;
}

const PixPaymentHelp: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation<UseLocationProps>();

  const handleOnClickBackToPixPending = () => {
    dispatch(push('/pedido/pix-pendente', location.state));
  };

  return (
    <Box as="main" flexDir="column" w="100%" px="3rem" mt="2.5rem" pb={40}>
      <HeaderWithBackButton
        justifyContent="center"
        alignItems="center"
        headerTitle="Pagamento"
        mb={8}
        onClick={handleOnClickBackToPixPending}
      />
      <Box flexDir="column" w="100%" mt="2.5rem">
        <Image src={pixHelpPic} m="0px auto" />

        <Text fontSize="1.605rem" fontWeight="bold" textAlign="center" mt={34}>
          Como pagar pedidos com o Pix
        </Text>

        <Text fontSize="1.6rem" fontWeight="bold" textAlign="center" mt={26}>
          1° passo
        </Text>

        <Text fontSize="1.4rem" fontWeight={400} textAlign="center" mt={4}>
          Clique em “Copiar código”
        </Text>

        <Text fontSize="1.6rem" fontWeight="bold" textAlign="center" mt={15}>
          2° passo
        </Text>

        <Text fontSize="1.4rem" fontWeight={400} textAlign="center" mt={4}>
          Abra um aplicativo em que tenha o Pix habilitado e cole o código copiado
        </Text>

        <Text fontSize="1.6rem" fontWeight="bold" textAlign="center" mt={15}>
          3° passo
        </Text>

        <Text fontSize="1.4rem" fontWeight={400} textAlign="center" mt={4}>
          Confirme o valor e faça o pagamento, ele será confirmado em até 2 minutos :)
        </Text>
      </Box>
    </Box>
  );
};

export default PixPaymentHelp;
