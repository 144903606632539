import { Box, BoxProps, Text } from '@chakra-ui/core';
import React from 'react';

interface TimelineProps extends BoxProps {
  active: boolean;
}

const Timeline: React.FC<TimelineProps> = ({ active, children, ...rest }) => {
  return (
    <Box {...rest} zIndex={3} mb="1rem">
      <Box d="flex" alignItems="center" justifyContent="flex-start">
        <Box
          w="1.5rem"
          minW="1.5rem"
          h="1.5rem"
          minH="1.5rem"
          borderRadius="100%"
          mr="1rem"
          background={
            active ? 'linear-gradient(180deg, #2FFE5F 0%, #2CFE88 25.6%, #2CFE89 100%);' : '#dbdbdb'
          }
        />
        <Text fontSize="sm" color={active ? 'black.400' : 'gray.300'}>
          {children}
        </Text>
      </Box>
    </Box>
  );
};

export default Timeline;
