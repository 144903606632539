import produce from 'immer';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import Address from '~/models/Address';
import ClientInfo from '~/models/ClientInfo';
import File from '~/models/File';
import LastCalculatedAddress from '~/models/LastCalculatedAddress';
import User from '~/models/User';
import UserLoyaltyCard from '~/models/UserLoyaltyCard';

import { Types as AuthTypes } from './auth';

/* ============== ACTION TYPES ============== */

enum TypesNames {
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOG_OUT = 'LOG_OUT',

  UPDATE_PERSONAL_INFO = 'UPDATE_PERSONAL_INFO',
  SUCCESS_PERSONAL_INFO = 'SUCCESS_PERSONAL_INFO',
  FAILURE_PERSONAL_INFO = 'FAILURE_PERSONAL_INFO',

  FETCH_USER_LOYALTY_CARD = 'FETCH_USER_LOYALTY_CARD',
  SET_USER_LOYALTY_CARD = 'SET_USER_LOYALTY_CARD',

  FETCH_USER_RESCUED_LOYALTY_CARDS = 'FETCH_USER_RESCUED_LOYALTY_CARDS',
  SET_USER_RESCUED_LOYALTY_CARDS = 'SET_USER_RESCUED_LOYALTY_CARDS',

  SET_SELECTED_NEIGHBORHOOD_AREA_CONFIG = 'SET_SELECTED_NEIGHBORHOOD_AREA_CONFIG',
  SET_LAST_CALCULATED_ADDRESS = 'SET_LAST_CALCULATED_ADDRESS',
  SET_HAS_UPDATED_THE_ADDRESS = 'SET_HAS_UPDATED_THE_ADDRESS',
}

export interface OnLoginSuccess extends Action<TypesNames.LOGIN_SUCCESS> {
  user: User;
}

export interface OnLogOut extends Action<TypesNames.LOG_OUT> {}

export interface OnUpdatePersonalInfo extends Action<TypesNames.UPDATE_PERSONAL_INFO> {
  data: {
    name: string;
    cpf: string;
    birth_at: string;
    gender: string;
    avatar: {
      base64Content: string;
      name: string;
      type: string;
      sub_type: string;
    };
    cleanAvatar: boolean;
  };
}
export interface OnSuccessPersonalInfo extends Action<TypesNames.SUCCESS_PERSONAL_INFO> {
  user: any;
}

export interface OnFailurePersonalInfo extends Action<TypesNames.FAILURE_PERSONAL_INFO> {}

export interface OnFetchUserLoyaltyCard extends Action<TypesNames.FETCH_USER_LOYALTY_CARD> {
  advertiserLoyaltyCardId: number;
}
export interface OnSetUserLoyaltyCard extends Action<TypesNames.SET_USER_LOYALTY_CARD> {
  loyaltyCard: UserLoyaltyCard;
}

export interface OnFetchUserRescuedLoyaltyCards
  extends Action<TypesNames.FETCH_USER_RESCUED_LOYALTY_CARDS> {
  advertiserLoyaltyCardId: number;
}

export interface OnSetUserRescuedLoyaltyCards
  extends Action<TypesNames.SET_USER_RESCUED_LOYALTY_CARDS> {
  loyaltyCards: UserLoyaltyCard[];
}

export interface OnSetSelectedNeighborhoodAreaConfig
  extends Action<TypesNames.SET_SELECTED_NEIGHBORHOOD_AREA_CONFIG> {
  neighborhoodAreaConfigId: number;
}

export interface OnSetLastCalculatedAddress extends Action<TypesNames.SET_LAST_CALCULATED_ADDRESS> {
  lastCalculatedAddress: LastCalculatedAddress;
}

export interface OnSetHasUpdatedTheAddress extends Action<TypesNames.SET_HAS_UPDATED_THE_ADDRESS> {
  hasUpdatedTheAddress: boolean;
}

export const { Types, Creators } = createActions<
  {
    [TypesNames.UPDATE_PERSONAL_INFO]: string;
    [TypesNames.SUCCESS_PERSONAL_INFO]: string;
    [TypesNames.FAILURE_PERSONAL_INFO]: string;
    [TypesNames.FETCH_USER_LOYALTY_CARD]: string;
    [TypesNames.SET_USER_LOYALTY_CARD]: string;
    [TypesNames.FETCH_USER_RESCUED_LOYALTY_CARDS]: string;
    [TypesNames.SET_USER_RESCUED_LOYALTY_CARDS]: string;
    [TypesNames.SET_SELECTED_NEIGHBORHOOD_AREA_CONFIG]: string;
    [TypesNames.SET_LAST_CALCULATED_ADDRESS]: string;
    [TypesNames.SET_HAS_UPDATED_THE_ADDRESS]: string;
  },
  {
    updatePersonalInfo: (data: OnUpdatePersonalInfo) => OnUpdatePersonalInfo;
    successPersonalInfo: (user: any) => OnSuccessPersonalInfo;
    failurePersonalInfo: () => OnFailurePersonalInfo;
    fetchUserLoyaltyCard: (advertiserLoyaltyCardId: number) => OnFetchUserLoyaltyCard;
    setUserLoyaltyCard: (loyaltyCard: UserLoyaltyCard) => OnSetUserLoyaltyCard;
    fetchUserRescuedLoyaltyCards: (
      advertiserLoyaltyCardId: number,
    ) => OnFetchUserRescuedLoyaltyCards;
    setUserRescuedLoyaltyCards: (loyaltyCards: UserLoyaltyCard[]) => OnSetUserRescuedLoyaltyCards;
    setSelectedNeighborhoodAreaConfig: (
      neighborhoodAreaConfigId: number,
    ) => OnSetSelectedNeighborhoodAreaConfig;
    setLastCalculatedAddress: (
      lastCalculatedAddress: LastCalculatedAddress,
    ) => OnSetLastCalculatedAddress;
    setHasUpdatedTheAddress: (hasUpdatedTheAddress: boolean) => OnSetHasUpdatedTheAddress;
  }
>({
  updatePersonalInfo: ['data'],
  successPersonalInfo: ['user'],
  failurePersonalInfo: null,
  fetchUserLoyaltyCard: ['advertiserLoyaltyCardId'],
  setUserLoyaltyCard: ['loyaltyCard'],
  rescueUserLoyaltyCard: ['userLoyaltyCardId'],
  setUserCoupon: ['coupon'],
  fetchUserRescuedLoyaltyCards: ['advertiserLoyaltyCardId'],
  setUserRescuedLoyaltyCards: ['loyaltyCards'],
  setSelectedNeighborhoodAreaConfig: ['neighborhoodAreaConfigId'],
  setLastCalculatedAddress: ['lastCalculatedAddress'],
  setHasUpdatedTheAddress: ['hasUpdatedTheAddress'],
});

export const UserTypes = Types;
export default Creators;

/* ============== INITIAL STATE ============== */
export interface UserStateType {
  user: User;
  userInfo: ClientInfo;
  avatar: File;
  address: Address[];
  loading: boolean;
  loyaltyCard: UserLoyaltyCard;
  rescuedLoyaltyCards: UserLoyaltyCard[];
  loadingLoyaltyCard: boolean;
  selectedNeighborhoodAreaConfigId: number;
  lastCalculatedAddress: LastCalculatedAddress;
  hasUpdatedTheAddress: boolean;
}

export const INITIAL_STATE: UserStateType = {
  user: null,
  userInfo: null,
  avatar: null,
  address: [],
  loading: false,
  loyaltyCard: null,
  rescuedLoyaltyCards: [],
  loadingLoyaltyCard: false, // Force to true to show first loading in loyalty card page
  selectedNeighborhoodAreaConfigId: null,
  lastCalculatedAddress: null,
  hasUpdatedTheAddress: null,
};

/* ============== REDUCERS ============== */

export const loginSuccessReducer = (state = INITIAL_STATE, { user }: OnLoginSuccess) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.user = user;
    draft.userInfo = user.clientInfo;
    draft.avatar = user.avatar;
    draft.address = user.address;
  });

export const logOutReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.user = null;
    draft.userInfo = null;
    draft.avatar = null;
    draft.address = [];
    draft.loyaltyCard = null;
    draft.loadingLoyaltyCard = false;
  });

export const updatePersonalInfoReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

export const successPersonalInfoReducer = (
  state = INITIAL_STATE,
  { user }: OnSuccessPersonalInfo,
) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.user = user;
    draft.userInfo = user.clientInfo;
    draft.avatar = user.avatar;
  });

export const failurePersonalInfoReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = false;
  });

export const fetchUserLoyaltyCardReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loadingLoyaltyCard = true;
  });

export const setUserLoyaltyCardReducer = (
  state = INITIAL_STATE,
  { loyaltyCard }: OnSetUserLoyaltyCard,
) =>
  produce(state, (draft) => {
    draft.loadingLoyaltyCard = false;
    draft.loyaltyCard = loyaltyCard;
  });

export const fetchUserRescuedLoyaltyCardsReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loadingLoyaltyCard = true;
  });

export const setUserRescuedLoyaltyCardsReducer = (
  state = INITIAL_STATE,
  { loyaltyCards }: OnSetUserRescuedLoyaltyCards,
) =>
  produce(state, (draft) => {
    draft.loadingLoyaltyCard = false;
    draft.rescuedLoyaltyCards = loyaltyCards;
  });

export const setSelectedNeighborhoodAreaConfigReducer = (
  state = INITIAL_STATE,
  { neighborhoodAreaConfigId }: OnSetSelectedNeighborhoodAreaConfig,
) =>
  produce(state, (draft) => {
    draft.selectedNeighborhoodAreaConfigId = neighborhoodAreaConfigId;
  });

export const setLastCalculatedAddressReducer = (
  state = INITIAL_STATE,
  { lastCalculatedAddress }: OnSetLastCalculatedAddress,
) =>
  produce(state, (draft) => {
    draft.lastCalculatedAddress = lastCalculatedAddress;
  });

export const setHasUpdatedTheAddresReducer = (
  state = INITIAL_STATE,
  { hasUpdatedTheAddress }: OnSetHasUpdatedTheAddress,
) =>
  produce(state, (draft) => {
    draft.hasUpdatedTheAddress = hasUpdatedTheAddress;
  });

export const reducer = createReducer<typeof INITIAL_STATE, any>(INITIAL_STATE, {
  [AuthTypes.LOGIN_SUCCESS]: loginSuccessReducer,
  [AuthTypes.LOG_OUT]: logOutReducer,
  [UserTypes.UPDATE_PERSONAL_INFO]: updatePersonalInfoReducer,
  [UserTypes.SUCCESS_PERSONAL_INFO]: successPersonalInfoReducer,
  [UserTypes.FAILURE_PERSONAL_INFO]: failurePersonalInfoReducer,
  [UserTypes.FETCH_USER_LOYALTY_CARD]: fetchUserLoyaltyCardReducer,
  [UserTypes.SET_USER_LOYALTY_CARD]: setUserLoyaltyCardReducer,
  [UserTypes.FETCH_USER_RESCUED_LOYALTY_CARDS]: fetchUserRescuedLoyaltyCardsReducer,
  [UserTypes.SET_USER_RESCUED_LOYALTY_CARDS]: setUserRescuedLoyaltyCardsReducer,
  [Types.SET_SELECTED_NEIGHBORHOOD_AREA_CONFIG]: setSelectedNeighborhoodAreaConfigReducer,
  [Types.SET_LAST_CALCULATED_ADDRESS]: setLastCalculatedAddressReducer,
  [Types.SET_HAS_UPDATED_THE_ADDRESS]: setHasUpdatedTheAddresReducer,
});
