import { ColorModeProvider, CSSReset, ThemeProvider as ChakraThemeProvider } from '@chakra-ui/core';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import history from '~/routes/history';
import { persistor, store } from '~/store';
import GlobalStyles from '~/styles/global';
import theme from '~/styles/theme';

import { FacebookProvider } from './FacebookContext';
import { PWAProvider } from './PWAContext';
import { ScheduleProvider } from './ScheduleContext';
import { WebSocketProvider } from './WebSocketContext';

const GA_TRACKING_CODE = process.env.REACT_APP_GA_TRACKING_CODE;

ReactGA.initialize(GA_TRACKING_CODE);

history.listen((location) => {
  ReactGA.pageview(location.pathname + location.search);
  ReactPixel.pageView();
});

const AppContainer: React.FC = ({ children }) => {
  return (
    <ChakraThemeProvider theme={theme}>
      <ColorModeProvider value="light">
        <EmotionThemeProvider theme={theme}>
          <Provider store={store}>
            {/* @ts-ignore */}
            <PersistGate persistor={persistor}>
              <PWAProvider>
                <FacebookProvider>
                  <WebSocketProvider>
                    <ScheduleProvider>
                      {/* @ts-ignore */}
                      <ConnectedRouter history={history}>{children}</ConnectedRouter>
                      <CSSReset />
                      <GlobalStyles />
                      <ToastContainer autoClose={5000} position="bottom-center" />
                    </ScheduleProvider>
                  </WebSocketProvider>
                </FacebookProvider>
              </PWAProvider>
            </PersistGate>
          </Provider>
        </EmotionThemeProvider>
      </ColorModeProvider>
    </ChakraThemeProvider>
  );
};

export default AppContainer;
