import { Flex } from '@chakra-ui/core';
import styled from '@emotion/styled';

export const VisibleMobileOnly = styled(Flex)`
  display: flex;
`;

export const VisibleDesktopOnly = styled(Flex)`
  display: none;
  @media screen and (min-width: 520px) {
    display: flex;
  }
`;

export const ContainerDesktop = styled.div`
  display: flex;
  width: 480px;

  align-items: center;
  justify-content: center;

  background-repeat: no-repeat;
  background-size: 40%;

  border: solid 10px red;
`;

export const ContainerMenu = styled.div`
  display: flex;
  width: 410px;
  position: relative;
  margin-left: 35px;
`;

export const Title = styled.div`
  color: #000;
  font-size: 50px;
  font-weight: 400;
  font-family: 'Courier New', Courier, monospace;
`;
