import { Heading } from '@chakra-ui/core';
import styled from '@emotion/styled';

import { mainGradient } from '~/styles/gradients';

interface BarCompletionProps {
  progress: number;
}

export const CustonHeading = styled(Heading)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 24ch;

  @media screen and (max-width: 475px) {
    max-width: 22ch;
  }

  @media screen and (max-width: 400px) {
    max-width: 18ch;
  }

  @media screen and (max-width: 340px) {
    max-width: 15ch;
  }
`;

export const LoyaltyBar = styled.div`
  background: #ededed;
  position: absolute;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: flex-start;
  justify-content: flex-start;

  width: 90%;
  border-radius: 10px;
`;

export const BarCompletion = styled.div<BarCompletionProps>`
  align-self: flex-start;

  height: 100%;
  padding: 1px;

  width: ${({ progress }) => (progress ? `${progress}%` : 0)};

  background: ${mainGradient};

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  ${({ progress }) =>
    progress === 100 &&
    `
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    `}
`;
