import styled from '@emotion/styled';

import Button from '~/components/Button';

export const CustonButton = styled(Button)`
  position: absolute;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 10px 10px 0px;
  padding: 0;
  top: 15px;
  right: 1em;
  background: transparent;
  z-index: 3;
`;
