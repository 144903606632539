import { Box, InputGroup, InputLeftElement, Text } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Input from '~/components/Form/Input';
import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import TopRoundedBox from '~/components/TopRoundedBox';
import { advertiserMenu, myProfile } from '~/routes/routeMap';
import { RootState } from '~/store';
import { phoneMask } from '~/utils/masks';

interface RegisterInputs {
  email: string;
  phone: string;
}

const ChangeContacts: React.FC = () => {
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const { user, userInfo } = globalState.user;

  const { register, setValue } = useForm<RegisterInputs>();

  useEffect(() => {
    if (!user) {
      dispatch(push(advertiserMenu));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (user) {
      user.email && setValue('email', user.email);
      userInfo.phone && setValue('phone', phoneMask(userInfo.phone));
    }
  }, [userInfo, user, setValue]);

  return (
    <Box as="main" flexDir="column" w="100%" bg="green.400">
      <HeaderWithBackButton headerTitle="Dados de Contato" px="3rem" to={myProfile} />

      <TopRoundedBox as="section">
        <Box pt="4rem" px="3rem">
          <Box
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            mb="3rem"
          >
            <Text
              as="span"
              fontSize="1.5rem"
              fontWeight="bold"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              w="100%"
              pb="1rem"
            >
              Celular
            </Text>
            <InputGroup w="100%">
              <InputLeftElement h="50px" w="60px">
                <Box
                  fontSize="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  background="transparent"
                  h="40px"
                  w="60px"
                  borderRight="1px solid #DBDBDB"
                >
                  <Text fontSize="1.6rem" as="span" color="#383539">
                    +55
                  </Text>
                </Box>
              </InputLeftElement>

              <Input
                p="20px 20px 20px 75px"
                placeholder="Sua celular"
                name="phone"
                type="text"
                register={register({ required: true })}
                isDisabled
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValue('phone', phoneMask(e.target.value))
                }
                isRequired
              />
            </InputGroup>
          </Box>
        </Box>
      </TopRoundedBox>
    </Box>
  );
};

export default ChangeContacts;
