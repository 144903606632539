import { Box, BoxProps, Spinner, Text } from '@chakra-ui/core';
import isNumber from 'lodash/isNumber';
import React from 'react';

import { formatCurrencyBRL } from '~/utils/currency';

export interface BagPriceDescriptionProps extends BoxProps {
  descriptionTitle: string;
  descriptionPrice?: number | string;
  loading?: boolean;
}

const BagPriceDescription: React.FC<BagPriceDescriptionProps> = ({
  descriptionPrice,
  descriptionTitle,
  color,
  fontWeight = '400',
  fontSize = 'sm',
  loading = false,
  ...rest
}) => (
  <Box d="flex" alignItems="center" justifyContent="space-between" {...rest}>
    <Text color={color} as="span" fontSize={fontSize} fontWeight={fontWeight}>
      {descriptionTitle}
    </Text>
    {loading ? (
      <Spinner size="md" color="gray.300" />
    ) : (
      <Text color={color} as="span" fontSize={fontSize} fontWeight={fontWeight}>
        {isNumber(descriptionPrice) ? formatCurrencyBRL(descriptionPrice) : descriptionPrice}
      </Text>
    )}
  </Box>
);

export default BagPriceDescription;
