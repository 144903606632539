import { Box, Divider, Icon, Text, useTheme } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import { format } from 'date-fns';
import addHours from 'date-fns/addHours';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserLoyaltyCard from '~/models/UserLoyaltyCard';
import { loyaltyCardDetail } from '~/routes/routeMap';
import { RootState } from '~/store';
import { formatCurrencyBRL } from '~/utils/currency';

interface LoyaltyCardProps {
  userLoyaltyCard: UserLoyaltyCard;
}

const LoyaltyCardItem: React.FC<LoyaltyCardProps> = ({ userLoyaltyCard }) => {
  const dispatch = useDispatch();

  const handleOnClickItem = () => {
    dispatch(
      push(loyaltyCardDetail, {
        userLoyaltyCard,
      }),
    );
  };

  const userAmountOfPurchases = useMemo(() => {
    return Number(userLoyaltyCard?.qty_purchases) || 0;
  }, [userLoyaltyCard]);

  const loyaltyCardDescription = useMemo(() => {
    if (userLoyaltyCard?.type === 'percentage_cart')
      return `Vale ${userLoyaltyCard?.cart_discount_percentage}% de desconto`;
    if (userLoyaltyCard?.type === 'fixed_cart') {
      return `Vale ${formatCurrencyBRL(userLoyaltyCard?.cart_discount_fixed)} de desconto`;
    }
  }, [userLoyaltyCard]);

  const isCardCompleted = useMemo(() => {
    return userLoyaltyCard?.is_rescued;
  }, [userLoyaltyCard]);

  const isCouponUsed = useMemo(() => {
    return userLoyaltyCard?.is_rescued_benefit_used;
  }, [userLoyaltyCard]);

  const theme = useTheme();

  return (
    <Box
      mt={3}
      d="flex"
      cursor="pointer"
      p={4}
      borderRadius="1rem"
      alignItems="center"
      shadow="0px -3px 10px rgba(0, 0, 0, 0.1)"
      onClick={handleOnClickItem}
    >
      <Box d="flex" flexDir="column" w="100%">
        <Text
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          fontSize="md"
          fontWeight="700"
          mb={2}
        >
          {isCardCompleted && !isCouponUsed ? (
            <>
              <Icon name="voucher" size="2.2rem" color={theme.colors.green[600]} />
              <Text color="gray.400" fontSize="sm" fontWeight="400" mb={2}>
                Cupom disponível
              </Text>
            </>
          ) : (
            <>
              <Icon name="voucher" size="2.2rem" color={theme.colors.gray[400]} />
              {isCouponUsed && (
                <Text color="gray.400" fontSize="sm" fontWeight="400" mb={2}>
                  Cupom já utilizado
                </Text>
              )}
            </>
          )}
        </Text>
        {isCardCompleted ? (
          <Text color="gray.400" fontSize="sm" fontWeight="400" mb={2}>
            Concluído em{' '}
            {format(new Date(userLoyaltyCard.rescued_at), 'dd/MM/yyyy @ HH:mm').replace('@', 'às')}
          </Text>
        ) : (
          <Text color="gray.400" fontSize="sm" fontWeight="400" mb={2}>
            Complete até {format(addHours(new Date(userLoyaltyCard?.expiry_at), 3), 'dd/MM/yyyy')}
          </Text>
        )}
        <Text
          d="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          fontSize="sm"
          fontWeight="400"
        >
          {loyaltyCardDescription}
          <Text as="span" fontSize="md" pl={4} fontWeight="700">
            {userAmountOfPurchases}/{userLoyaltyCard?.amount_of_purchases}
          </Text>
        </Text>
        <Divider
          width={`${(userAmountOfPurchases / userLoyaltyCard?.amount_of_purchases) * 100}%`}
          borderWidth="3px"
          borderColor="green.400"
        />
      </Box>
    </Box>
  );
};

export default LoyaltyCardItem;
