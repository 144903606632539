import { Heading, Image, Text } from '@chakra-ui/core';
import styled from '@emotion/styled';

export const CustonImage = styled(Image)`
  filter: blur(1.5px);
  -webkit-filter: blur(1.5px);
`;

export const CustonText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30ch;

  @media screen and (max-width: 475px) {
    max-width: 28ch;
  }

  @media screen and (max-width: 400px) {
    max-width: 22ch;
  }

  @media screen and (max-width: 340px) {
    max-width: 18ch;
  }
`;

export const CustonHeading = styled(Heading)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 24ch;

  @media screen and (max-width: 475px) {
    max-width: 22ch;
  }

  @media screen and (max-width: 400px) {
    max-width: 18ch;
  }

  @media screen and (max-width: 340px) {
    max-width: 15ch;
  }
`;
