import { Box, Divider, Icon, Image, Stack, Text } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import addMinutes from 'date-fns/addMinutes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiPhone, FiUser } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import { amplitudeTrackEvent } from '~/components/Amplitude';
import BenefitCardCodeCoupon from '~/components/BenefitCardCodeCoupon';
import BottomBar from '~/components/BottomBar';
import Button from '~/components/Button';
import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import ListItem from '~/components/ListItem';
import SelectOptionButton from '~/components/SelectOptionButton';
import useDynamicDeliveryFee from '~/hooks/useDynamicDelivery';
import useSlug from '~/hooks/useSlug';
import OrderSummary from '~/models/OrderSummary';
import { listAddress } from '~/routes/routeMap';
import {
  advertiserMenu,
  bag,
  orderItemDetails,
  orderReview,
  selectOrderSchedule,
  selectPaymentMethod,
} from '~/routes/routeMap';
import { sendOrderCreationEvent } from '~/services/mixPanel';
import { RootState } from '~/store';
import AdvertiserActions from '~/store/ducks/advertiser';
import BagActions from '~/store/ducks/bag';
import Creators from '~/store/ducks/order';
import { formatAddress } from '~/utils/address';
import { isPaymentMethodDisabled } from '~/utils/bag';
import { formatCurrencyBRL } from '~/utils/currency';
import { phoneMask } from '~/utils/masks';

import useOrderSchedules from '../../hooks/useOrderSchedules';
import { HorizontalScroll } from '../AdvertiserMenu/styles';
import BagPriceDescription from '../Bag/BagPriceDescription';
import OrderScheduleOption from './OrderScheduleOption';
import SeeMoreScheduleOptions from './SeeMoreScheduleOptions';
import { CustomText, Subtitle } from './styles';

export interface UseLocationProps {
  orderSummaryData: OrderSummary;
}

const { createOrderRequest } = Creators;
const { setBagDiscountActiveCoupon, cleanActiveDiscount } = BagActions;

const OrderReview: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation<UseLocationProps>();
  const { state } = location;

  const orderSummaryDataLocation = state?.orderSummaryData;
  const [orderSummaryData, setOrderSummaryData] = useState<OrderSummary>(orderSummaryDataLocation);
  const slug = useSlug();
  const globalState = useSelector((state: RootState) => state);
  const { isLoggedin } = globalState?.auth;
  const { user } = globalState?.user;
  const userState = globalState?.user;
  const { advertiserInfo, advertiserIsOpen, lastAdvertiser } = globalState?.advertiser;
  const takeAwayTimeAvg = advertiserInfo?.take_away_time_avg;
  const deliveryTimeAvg = advertiserInfo?.delivery_time_avg;
  const isAcceptScheduled = advertiserInfo?.ordersConfig?.is_accepting_scheduled_order;
  const { getData } = AdvertiserActions;
  const { currentAddress, doesAdvertiserDeliversInCurrentAddress, loading, addresses } =
    globalState?.addresses;
  const { createOrderLoading } = globalState?.order;
  const { itemsInBag, bagDiscounts, priceWithoutDiscount, priceWithDiscount } = globalState?.bag;

  useEffect(() => {
    if (!advertiserInfo) {
      dispatch(push(advertiserMenu));
    }
  }, [advertiserInfo]);

  // Verifify if the bag is empty, go to home page
  useEffect(() => {
    if (itemsInBag?.length === 0) {
      dispatch(push(advertiserMenu));
    }
  }, []);

  useEffect(() => {
    if (slug) {
      dispatch(getData(slug));
    }
  }, [dispatch, slug, getData]);

  useEffect(() => {
    if (!orderSummaryDataLocation) {
      dispatch(push(advertiserMenu));
      return;
    }
    setOrderSummaryData(orderSummaryDataLocation);
  }, [orderSummaryDataLocation, dispatch, lastAdvertiser]);

  const delivery = useDynamicDeliveryFee({
    advertiserAddress: advertiserInfo?.user?.address[0],
    userAddress: currentAddress,
    deliveryFee: advertiserInfo?.delivery_fee,
    deliveryDynamicFeeMaxDist: advertiserInfo?.delivery_dynamic_fee_max_dist,
    deliveryDynamicFee: advertiserInfo?.delivery_dynamic_fee,
    deliveryDynamicFeeExceededAmount: advertiserInfo?.delivery_dynamic_fee_exceeded_amount,
    deliveryDynamicFeeFreeDist: advertiserInfo?.delivery_dynamic_fee_free_dist,
    deliveryType: orderSummaryData?.deliveryType,
  });

  const { nextAvailableScheduleDay, selectedSchedule, selectSchedule, hasSelectedScheduleOnce } =
    useOrderSchedules();

  useEffect(() => {
    if (!orderSummaryData || delivery?.loading) {
      return;
    }
    if (
      orderSummaryData?.total !==
      orderSummaryData?.subtotal + delivery?.value - orderSummaryData?.totalDiscount
    ) {
      setOrderSummaryData((prevState) => {
        const total = prevState?.subtotal + delivery?.value - prevState?.totalDiscount;
        const wasPreviousCash = prevState?.advertiserPaymentMethod?.payment_method_slug === 'cash';
        const shouldClearPaymentMethod = prevState?.total !== total && wasPreviousCash;
        return {
          ...prevState,
          deliveryFee: delivery?.value,
          total,
          advertiserPaymentMethod: shouldClearPaymentMethod
            ? null
            : prevState?.advertiserPaymentMethod,
        };
      });
    }
  }, [delivery?.value, delivery?.loading, orderSummaryData]);

  useEffect(() => {
    setOrderSummaryData((prevState) => {
      if (!selectedSchedule) {
        return prevState;
      }
      let schedulingEndAt = selectedSchedule?.timeTo;
      if (selectedSchedule?.type === 'immediate') {
        const minutesToAdd =
          prevState?.deliveryType === 'delivery' ? deliveryTimeAvg : takeAwayTimeAvg;
        schedulingEndAt = addMinutes(selectedSchedule?.timeFrom, minutesToAdd);
      }
      return {
        ...prevState,
        orderType: selectedSchedule?.type || 'immediate',
        schedule: {
          id: selectedSchedule.id,
          schedulingStartAt: selectedSchedule?.timeFrom,
          schedulingEndAt,
        },
      };
    });
  }, [selectedSchedule, deliveryTimeAvg, takeAwayTimeAvg]);

  useEffect(() => {
    if (!orderSummaryDataLocation || !advertiserIsOpen) {
      dispatch(push(advertiserMenu));
    }
  }, [orderSummaryDataLocation, advertiserIsOpen, dispatch]);

  const handleOnClickChosePaymentMethod = useCallback(() => {
    dispatch(push(selectPaymentMethod, { orderSummaryData }));
  }, [dispatch, orderSummaryData]);

  const handleOnClickConfirmOrder = useCallback(() => {
    dispatch(createOrderRequest(orderSummaryData));
    // Amplitude Event
    if (orderSummaryData?.advertiserPaymentMethod?.paymentMethod?.slug !== 'pix_chave') {
      amplitudeTrackEvent('purchase_confirmed', {
        advertiser_info_id: globalState?.advertiser?.advertiserInfo?.id,
        advertiser_name: globalState?.advertiser?.advertiserInfo?.name,
        advertiser_category:
          globalState?.advertiser?.advertiserInfo?.advertiserCategory?.name?.toString(),
        user_id: globalState?.user?.user ? globalState?.user?.user?.id : null,
        order_payment_info: orderSummaryData?.advertiserPaymentMethod?.paymentMethod?.slug,
        order_type: orderSummaryData?.orderType,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, orderSummaryData, isLoggedin]);

  const handleOnClickOrderItem = useCallback(() => {
    dispatch(push(orderItemDetails, { orderSummaryData }));
  }, [dispatch, orderSummaryData]);

  const handleOnClickSeeMoreOrderSchedules = useCallback(() => {
    dispatch(
      push(selectOrderSchedule, {
        orderSummaryData,
      }),
    );
  }, [dispatch, orderSummaryData]);

  const handleOnClickChangeAddress = useCallback(() => {
    dispatch(
      push(listAddress, {
        onConfirmBackTo: orderReview,
        orderSummaryData,
      }),
    );
  }, [dispatch, orderSummaryData]);

  useEffect(() => {
    if (
      doesAdvertiserDeliversInCurrentAddress &&
      currentAddress &&
      !orderSummaryData?.deliveryAddress &&
      orderSummaryData?.deliveryType === 'delivery' &&
      !loading
    ) {
      setOrderSummaryData((prevState) => ({
        ...prevState,
        deliveryAddress: currentAddress,
      }));
    } else if (!currentAddress && orderSummaryData?.deliveryAddress) {
      setOrderSummaryData((prevState) => ({
        ...prevState,
        deliveryAddress: null,
      }));
    } else if (
      orderSummaryData?.deliveryType === 'take_away' &&
      !loading &&
      isLoggedin &&
      orderSummaryData?.deliveryAddress
    ) {
      setOrderSummaryData((prevState) => ({
        ...prevState,
        deliveryAddress: null,
      }));
    }
  }, [
    orderSummaryData,
    currentAddress,
    addresses,
    orderSummaryDataLocation,
    location,
    globalState,
    loading,
    isLoggedin,
    doesAdvertiserDeliversInCurrentAddress,
  ]);

  useEffect(() => {
    // MixPanel Event
    sendOrderCreationEvent('Funnel 4 - Delivery/TakeAway', {
      id: orderSummaryData?.clientId.toString(),
      webMenuId: orderSummaryData?.products[0]?.advertiser_info_id?.toString(),
      orderType: orderSummaryData?.orderType,
      deliveryType: orderSummaryData?.deliveryType,
      slug: slug,
    });
  }, []);

  const renderSchedules = useMemo(() => {
    if (selectedSchedule && orderSummaryData?.schedule?.id && hasSelectedScheduleOnce) {
      return (
        <OrderScheduleOption
          isSelected={selectedSchedule?.id === orderSummaryData?.schedule?.id}
          w="100%"
          schedule={selectedSchedule}
          timeToImmediate={
            orderSummaryData?.deliveryType === 'delivery' ? deliveryTimeAvg : takeAwayTimeAvg
          }
        />
      );
    }
    const schedules =
      nextAvailableScheduleDay?.schedules?.length > 3
        ? nextAvailableScheduleDay?.schedules?.slice(0, 3)
        : nextAvailableScheduleDay?.schedules;
    return (
      <>
        {schedules?.map((schedule, idx, schedules) => (
          <OrderScheduleOption
            key={schedule.id}
            isSelected={selectedSchedule?.id === schedule?.id}
            onClick={() => selectSchedule(schedule?.id, nextAvailableScheduleDay?.id)}
            w="100%"
            schedule={schedule}
            timeToImmediate={
              orderSummaryData?.deliveryType === 'delivery' ? deliveryTimeAvg : takeAwayTimeAvg
            }
            mr={schedules?.length > 1 ? 4 : 0}
          />
        ))}
        {nextAvailableScheduleDay?.schedules?.length > 1 && (
          <SeeMoreScheduleOptions onClick={handleOnClickSeeMoreOrderSchedules} />
        )}
      </>
    );
  }, [
    selectSchedule,
    selectedSchedule,
    handleOnClickSeeMoreOrderSchedules,
    nextAvailableScheduleDay,
    orderSummaryData,
    deliveryTimeAvg,
    takeAwayTimeAvg,
    hasSelectedScheduleOnce,
  ]);

  const cartDiscountPercentage = useMemo(() => {
    if (bagDiscounts?.discounts['cart']?.length > 0) {
      return bagDiscounts?.discounts['cart'][0]?.couponBenefit?.cart_discount_percentage;
    }
    return 0;
  }, [bagDiscounts]);

  const loyaltyDiscountAmount = useMemo(() => {
    if (bagDiscounts?.discounts['loyalty']?.length > 0) {
      return formatCurrencyBRL(
        bagDiscounts?.discounts['loyalty'][0]?.couponBenefit?.cart_discount_fixed,
      );
    }
    return formatCurrencyBRL(0);
  }, [bagDiscounts]);

  const loyaltyDiscountPercentage = useMemo(() => {
    if (bagDiscounts?.discounts['loyalty']?.length > 0) {
      return bagDiscounts?.discounts['loyalty'][0]?.couponBenefit?.cart_discount_percentage;
    }
    return 0;
  }, [bagDiscounts]);

  const productDiscountAmount = useMemo(() => {
    const discountAmount = bagDiscounts?.discounts['product']?.reduce((currDiscount, coupon) => {
      return currDiscount + (coupon?.couponBenefit?.from_value - coupon?.couponBenefit?.by_value);
    }, 0);

    return formatCurrencyBRL(discountAmount);
  }, [bagDiscounts]);

  const loyaltyCardTicketMinimumAmount = useMemo(() => {
    return userState?.loyaltyCard?.ticket_minimum_amount
      ? userState?.loyaltyCard?.ticket_minimum_amount
      : advertiserInfo?.loyaltyCard?.ticket_minimum_amount;
  }, [userState?.loyaltyCard, advertiserInfo?.loyaltyCard]);

  useEffect(() => {
    if (bagDiscounts?.activeCoupon && orderSummaryData?.advertiserPaymentMethod) {
      const selectedPaymentMethodDisabled = isPaymentMethodDisabled(
        bagDiscounts,
        orderSummaryData?.advertiserPaymentMethod,
      );
      if (selectedPaymentMethodDisabled) {
        setOrderSummaryData((prevState) => {
          return {
            ...prevState,
            advertiserPaymentMethod: null,
          };
        });
      }
    }
  }, [bagDiscounts?.activeCoupon]);

  function validatetIfLoyaltyCouponCanBeUsed(showErrorMsg = true) {
    if (
      bagDiscounts?.discounts['loyalty'][0]?.order_minimum_amount &&
      orderSummaryData?.subtotal < bagDiscounts?.discounts['loyalty'][0]?.order_minimum_amount
    ) {
      if (showErrorMsg)
        toast.error(
          `Para utilizar esse cupom o pedido tem que atingir o valor mínimo de: ${formatCurrencyBRL(
            bagDiscounts?.discounts['loyalty'][0]?.order_minimum_amount,
          )}`,
        );
      return false;
    }

    if (
      orderSummaryData?.deliveryType === 'delivery' &&
      !bagDiscounts?.discounts['loyalty'][0]?.is_accept_delivery
    ) {
      if (showErrorMsg) toast.error('Esse cupom só é válido para Retirada');
      return false;
    }

    if (
      orderSummaryData?.deliveryType === 'take_away' &&
      !bagDiscounts?.discounts['loyalty'][0]?.is_accept_physical
    ) {
      if (showErrorMsg) toast.error('Esse cupom só é válido para Delivery');
      return false;
    }

    dispatch(setBagDiscountActiveCoupon('loyalty'));
    return true;
  }

  useEffect(() => {
    if (bagDiscounts?.activeCoupon === 'loyalty') {
      const canBeUsed = validatetIfLoyaltyCouponCanBeUsed(false);
      if (!canBeUsed) {
        dispatch(cleanActiveDiscount());
      }
    }
  }, []);

  return (
    <>
      <Box as="main" flexDir="column" w="100%" px="3rem" pb={40} bg="white">
        <HeaderWithBackButton headerTitle="Resumo do pedido" to={bag} />

        {orderSummaryData?.deliveryType === 'delivery' ? (
          <>
            <Subtitle fontSize="1.4rem" fontWeight="400" color="gray.400" mb={3}>
              Entregar em
            </Subtitle>
            <Button
              onClick={handleOnClickChangeAddress}
              variant="ghost"
              px={0}
              py={3}
              flex="1"
              justifyContent="flex-start"
              w="100%"
            >
              <Box d="flex" alignItems="center" w="100%">
                <Box d="flex" alignItems="center" w="90%">
                  <Icon name="menu-pin" size="2.4rem" mr={2} />
                  <Box d="flex">
                    {orderSummaryData?.deliveryAddress ? (
                      <CustomText fontWeight="700">
                        {formatAddress(orderSummaryData?.deliveryAddress)}
                      </CustomText>
                    ) : (
                      <Text fontWeight="500">Nenhum endereço selecionado</Text>
                    )}
                  </Box>
                </Box>
                <Box d="flex" ml="auto">
                  <Icon name="arrow-back" size="2.4rem" transform="rotate(180deg)" />
                </Box>
              </Box>
            </Button>
          </>
        ) : (
          <Box d="flex" flexDir="column" w="100%">
            <Subtitle fontSize="1.4rem" fontWeight="400" color="gray.400" mb={3}>
              Retirar em
            </Subtitle>
            <Box d="flex" alignItems="center" py={3}>
              <Icon name="menu-pin" size="2.4rem" mr={2} />
              <Text fontWeight="500" fontSize="sm">
                {formatAddress(advertiserInfo?.user?.address[0])}
              </Text>
            </Box>
          </Box>
        )}

        <Divider />
        <HorizontalScroll isInline py={4}>
          {nextAvailableScheduleDay?.schedules?.length > 0 && renderSchedules}
          {!nextAvailableScheduleDay?.schedules && (
            <Box>
              <Subtitle fontSize="1.4rem" fontWeight="400" color="gray.400" mb={6}>
                Horários para agendamento
              </Subtitle>
              <Text fontWeight="500" textAlign="left" fontSize="1.4rem">
                Não existem horários a serem exibidos. Verifique as configurações de agendamento da
                loja!
              </Text>
            </Box>
          )}
        </HorizontalScroll>
        {nextAvailableScheduleDay?.schedules?.length > 0 && isAcceptScheduled && (
          <Box d="flex" mb={8} justifyContent="flex-end">
            <Text
              as="button"
              onClick={handleOnClickSeeMoreOrderSchedules}
              color="green.600"
              fontSize="1.4rem"
              lineHeight="1.25"
            >
              Ver horários
            </Text>
          </Box>
        )}
        <Divider mb={8} />
        <Subtitle fontSize="1.4rem" fontWeight="400" color="gray.400" mb={3}>
          Formas de pagamento
        </Subtitle>
        <Button
          variant="ghost"
          px={0}
          flex="1"
          justifyContent="flex-start"
          w="100%"
          onClick={handleOnClickChosePaymentMethod}
          py={3}
        >
          <Box d="flex" alignItems="center" w="100%" justifyContent="space-between">
            {orderSummaryData?.advertiserPaymentMethod ? (
              orderSummaryData?.advertiserPaymentMethod?.paymentMethod?.slug === 'cash' ? (
                <ListItem
                  key={orderSummaryData?.advertiserPaymentMethod?.paymentMethod?.slug}
                  py={2}
                  borderTop="0"
                  outline={0}
                  flexDir="row"
                >
                  <Image h="1.4rem" mr={4} src={'/images/cash.svg'} />
                  <Text fontWeight="700" textAlign="left">
                    Dinheiro,{' '}
                    <Text as="span">troco para {formatCurrencyBRL(orderSummaryData?.change)}</Text>
                  </Text>
                </ListItem>
              ) : (
                <ListItem
                  key={orderSummaryData?.advertiserPaymentMethod?.paymentMethod?.slug}
                  py={2}
                  borderTop="0"
                  outline={0}
                  flexDir="row"
                  alignItems="center"
                >
                  <Image
                    h="2rem"
                    mr={8}
                    src={orderSummaryData?.advertiserPaymentMethod?.paymentMethod?.image_url}
                  />
                  <Text fontWeight="700" textAlign="left">
                    {orderSummaryData?.advertiserPaymentMethod?.paymentMethod?.name}
                  </Text>
                </ListItem>
              )
            ) : (
              <Text fontWeight="500" textAlign="left">
                Escolher forma de pagamento
              </Text>
            )}

            <Box>
              <Icon name="arrow-back" size="2.4rem" transform="rotate(180deg)" />
            </Box>
          </Box>
        </Button>

        <Divider mb={8} />

        <Subtitle mb={6}>Sacola</Subtitle>

        <Stack isInline alignItems="center" justifyContent="space-between" mb={8}>
          <Button variant="ghost" py={0} px={0} fontSize="sm" onClick={handleOnClickOrderItem}>
            {Array.isArray(orderSummaryData?.products) && (
              <Text fontWeight="500">
                {orderSummaryData?.products?.length}x{' '}
                {orderSummaryData?.products?.length > 1 ? 'Produtos' : 'Produto'}
              </Text>
            )}
            <Icon name="arrow-back" size="1.4rem" transform="rotate(180deg)" />
          </Button>
          <Text as="span" fontSize="sm">
            {formatCurrencyBRL(orderSummaryData?.subtotal)}
          </Text>
        </Stack>

        <Stack as="section" spacing={3} mb={8}>
          <BagPriceDescription
            descriptionTitle="Subtotal"
            descriptionPrice={orderSummaryData?.subtotal}
            color="gray.400"
          />
          {orderSummaryData?.deliveryType === 'delivery' && (
            <BagPriceDescription
              descriptionTitle="Taxa de delivery"
              loading={delivery?.loading}
              descriptionPrice={delivery?.canFinishOrder ? delivery?.description : '--'}
              color={delivery?.canFinishOrder ? 'gray.400' : 'red.400'}
            />
          )}

          {bagDiscounts?.activeCoupon && priceWithoutDiscount - priceWithDiscount > 0 && (
            <BagPriceDescription
              descriptionTitle="Descontos"
              descriptionPrice={formatCurrencyBRL(
                Number((priceWithoutDiscount - priceWithDiscount) * -1),
              )}
              color="red.300"
            />
          )}
          <BagPriceDescription
            my={6}
            descriptionTitle="Total"
            descriptionPrice={
              orderSummaryData?.deliveryType === 'delivery'
                ? priceWithDiscount + delivery?.value
                : priceWithDiscount
            }
            color="gray.700"
            fontWeight="700"
          />
          <Divider />
        </Stack>

        <Stack spacing={4} mb={4}>
          <Subtitle mb={6}>Benefícios do pedido</Subtitle>
          <Box d="flex" alignItems="flex-start" justifyContent="center" flexDir="column" mb={6}>
            {bagDiscounts?.discounts['loyalty']?.length > 0 && (
              <SelectOptionButton
                mt={3}
                p={3}
                w="100%"
                border="1px solid"
                borderColor={bagDiscounts?.activeCoupon === 'loyalty' ? 'green.500' : 'gray.500'}
                isSelected={bagDiscounts?.activeCoupon === 'loyalty'}
                onClick={() => {
                  if (bagDiscounts?.activeCoupon !== 'loyalty') {
                    validatetIfLoyaltyCouponCanBeUsed();
                  } else {
                    dispatch(cleanActiveDiscount());
                  }
                }}
              >
                <Text fontWeight="700" fontSize="md" ml={3}>
                  {`${
                    bagDiscounts?.discounts['loyalty'][0]?.type === 'fixed_cart'
                      ? loyaltyDiscountAmount
                      : `${loyaltyDiscountPercentage}%`
                  } de desconto`}
                </Text>
                <Text textAlign="left" fontSize="sm" ml={3}>
                  do cartão fidelidade
                </Text>
              </SelectOptionButton>
            )}
            {bagDiscounts?.discounts['cart']?.length > 0 && (
              <SelectOptionButton
                mt={3}
                p={3}
                w="100%"
                border="1px solid"
                borderColor={bagDiscounts?.activeCoupon === 'cart' ? 'green.500' : 'gray.500'}
                isSelected={bagDiscounts?.activeCoupon === 'cart'}
                onClick={() => {
                  if (bagDiscounts?.activeCoupon !== 'cart') {
                    dispatch(setBagDiscountActiveCoupon('cart'));
                  } else {
                    dispatch(cleanActiveDiscount());
                  }
                }}
              >
                <Text fontWeight="700" fontSize="md" ml={3}>
                  {`${cartDiscountPercentage}% de desconto`}
                </Text>
                <Text textAlign="left" fontSize="sm" ml={3}>
                  na compra
                </Text>
              </SelectOptionButton>
            )}
            {bagDiscounts?.discounts['product']?.length > 0 && (
              <SelectOptionButton
                mt={3}
                p={3}
                w="100%"
                border="1px solid"
                borderColor={bagDiscounts?.activeCoupon === 'product' ? 'green.500' : 'gray.500'}
                isSelected={bagDiscounts?.activeCoupon === 'product'}
                onClick={() => {
                  if (bagDiscounts?.activeCoupon !== 'product') {
                    dispatch(setBagDiscountActiveCoupon('product'));
                  } else {
                    dispatch(cleanActiveDiscount());
                  }
                }}
              >
                <Text fontWeight="700" fontSize="md" ml={3}>
                  {`${productDiscountAmount} de desconto`}
                </Text>
                <Text textAlign="left" fontSize="sm" ml={3}>
                  {`de produto${bag.length > 1 ? 's' : ''}`}
                </Text>
              </SelectOptionButton>
            )}
            <BenefitCardCodeCoupon />
          </Box>
          <Divider />
        </Stack>

        {advertiserInfo?.loyaltyCard?.is_active &&
          orderSummaryData?.total >= loyaltyCardTicketMinimumAmount && (
            <Stack spacing={4} mb={4}>
              <Box d="flex" alignItems="center">
                <Icon name="heart-card" size="4.4rem" mr={4} />
                <Text wordBreak="break-all" fontSize="sm">
                  Válido para o Cartão Fidelidade
                </Text>
              </Box>
              <Divider />
            </Stack>
          )}

        <Stack spacing={4} mb={4}>
          <Subtitle mb={6}>Seus dados</Subtitle>
          <Box d="flex" alignItems="flex-start" justifyContent="center" flexDir="column" mb={6}>
            <Box d="flex" alignItems="center" mt={4}>
              <Box as={FiUser} size="2.2rem" mr={4} />
              <Text fontSize="sm">{user?.clientInfo?.name}</Text>
            </Box>
            <Box d="flex" alignItems="center" mt={4}>
              <Box as={FiPhone} size="2.2rem" mr={4} />
              <Text fontSize="sm">{phoneMask(user?.clientInfo?.phone)}</Text>
            </Box>
          </Box>
        </Stack>

        <BottomBar>
          {orderSummaryData &&
          !orderSummaryData?.deliveryAddress &&
          orderSummaryData?.deliveryType === 'delivery' ? (
            <Box d="flex" w="100%" py={4}>
              <Button
                flex="1"
                bg="green.300"
                onClick={handleOnClickChangeAddress}
                isDisabled={loading}
              >
                Escolher o endereço de entrega
              </Button>
            </Box>
          ) : orderSummaryData && !orderSummaryData?.advertiserPaymentMethod ? (
            <Box d="flex" w="100%" py={4}>
              <Button
                isDisabled={delivery?.loading}
                flex="1"
                bg="green.300"
                onClick={handleOnClickChosePaymentMethod}
              >
                Escolher forma de pagamento
              </Button>
            </Box>
          ) : (
            <Box d="flex" w="100%" py={4}>
              <Button
                flex="1"
                bg="green.300"
                onClick={handleOnClickConfirmOrder}
                isLoading={createOrderLoading}
                isDisabled={delivery?.loading || !delivery?.canFinishOrder}
              >
                Confirmar pedido
              </Button>
            </Box>
          )}
        </BottomBar>
      </Box>
    </>
  );
};

export default OrderReview;
