import { Box, Flex, FlexProps } from '@chakra-ui/core';
import React from 'react';

export interface ButtonToggleProps extends FlexProps {
  isActive?: boolean;
}

const CircleToggle: React.FC<ButtonToggleProps> = ({
  isActive,
  rounded = '50%',
  w = '2rem',
  h = '2rem',
  border = '1px solid',
  borderColor = 'gray.500',
  ...rest
}) => {
  return (
    <Flex
      justify="center"
      align="center"
      rounded={rounded}
      w={w}
      h={h}
      border={border}
      borderColor={borderColor}
      {...rest}
    >
      <Box
        w="1.2rem"
        height="1.2rem"
        bg={isActive ? 'black' : 'transparent'}
        rounded="50%"
        transition="all 0.2s ease-in-out"
      />
    </Flex>
  );
};

export default CircleToggle;
