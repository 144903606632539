import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';

import { OrderStatus } from '~/models/Order';
import { options, path, url } from '~/services/ws';
import { RootState } from '~/store';
import { Creators as OrderActions } from '~/store/ducks/order';

export interface UseSocketIo {
  token: string;
  enabled: boolean;
}

export const useSocketIo = ({ enabled, token }: UseSocketIo) => {
  const ws = useRef<SocketIOClient.Socket>(null);
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const orders = globalState?.order?.orders;

  const currentOrders = useMemo(() => {
    return orders?.map((order) => order.id);
  }, [orders]);

  useEffect(() => {
    if (!enabled) return;

    const socket = io(url, {
      ...options,
      auth: {
        token: token,
      },
      path,
    });

    socket.on('connect', () => {
      console.log('WS WebMenu - Connected to socket server.');

      currentOrders?.forEach((value) => {
        socket.emit('join-webMenuOrderStatusReconnect', { orderId: value });
        console.log('WS WebMenu - join-webMenuOrderStatusReconnect', { orderId: value });
      });

      socket.on('message', ({ message }: { message: string }) => {
        console.log('WS WebMenu - message', message);
      });

      socket.on('updated_status', (order_status: OrderStatus) => {
        dispatch(OrderActions.updateOrderStatus(order_status));
        console.log('WS WebMenu - updated_status', order_status);
      });
    });

    socket.on('connect_error', (err: Error) => {
      console.log(`WS WebMenu - Error connecting to socket server, reason: ${err}.`);
    });

    ws.current = socket;
  }, [token, enabled, dispatch]);

  return ws;
};
