import { Heading } from '@chakra-ui/core';
import styled from '@emotion/styled';

export const LineClampedText = styled(Heading)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  white-space: pre-line;
`;
