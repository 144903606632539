import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Token from '~/models/Token';
import User from '~/models/User';
import { advertiserMenu } from '~/routes/routeMap';
import { apiGlobal, apiWebMenu } from '~/services/api';

import { RootState } from '..';
import AddressActions from '../ducks/addresses';
import { Creators as AddressesCreators } from '../ducks/addresses';
import { AuthTypes, Creators as AuthCreators, OnCreateSessionUser } from '../ducks/auth';
import { Creators as BagCreators } from '../ducks/bag';
import { Creators as OrderCreators } from '../ducks/order';

const { loginFailure, loginSuccess } = AuthCreators;
const { cleanBag, addDiscountInBag } = BagCreators;
const { clearCurrentAddress, fetchMyAddresses } = AddressesCreators;

export function setToken({ payload }: any) {
  if (!payload) return;

  if (payload && payload?.auth?.token) {
    const { token } = payload.auth.token;
    apiGlobal.defaults.headers.common.Authorization = `Bearer ${token}`;
    apiWebMenu.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}

export function* createSessionUser({ name, phone, redirect }: OnCreateSessionUser) {
  const globalState: RootState = yield select((state: RootState) => state);
  const { currentAddress } = globalState.addresses;
  const facebookCampaignId = globalState.kuppiBoost?.facebook_campaign_id;
  const advertiserInfo = globalState.advertiser.advertiserInfo;
  let checkoutData = null;

  try {
    if (globalState.bag.sizeBag > 0) {
      checkoutData = {
        amount: Number(globalState.bag.priceWithoutDiscount.toFixed(2)),
      };
    }

    const response: AxiosResponse<{ token: Token; user: User; isNew: boolean }> = yield call(
      apiWebMenu.post,
      `/anunciante/${advertiserInfo.slug}/session`,
      {
        name,
        phone,
        facebook_campaign_id: facebookCampaignId,
        checkout_data: checkoutData,
      },
    );

    const { token, user, isNew } = response.data;
    const userRoles: string[] = [];

    user.roles.forEach((roles) => {
      userRoles.push(roles.slug);
    });

    const userIsClient = userRoles.includes('client');

    if (!userIsClient) {
      toast.error('O usuário não possui permissões para acesso.');
      yield put(loginFailure());
      return;
    }

    if (user.clientInfo.loyaltyCardCoupon !== null) {
      yield put(addDiscountInBag(user.clientInfo.loyaltyCardCoupon, true));
    }

    yield put(loginSuccess(token, user, isNew));
    apiGlobal.defaults.headers.common.Authorization = `Bearer ${token.token}`;
    apiWebMenu.defaults.headers.common.Authorization = `Bearer ${token.token}`;

    if (currentAddress && !currentAddress.id) {
      yield put(AddressActions.saveAddress(currentAddress));
    }

    if (!redirect) {
      yield put(push(advertiserMenu));
    } else {
      yield put(
        push(redirect?.path, {
          orderSummaryData: { ...redirect?.data, clientId: user?.clientInfo.id },
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 400) {
      yield put(loginFailure());
      yield put(push(advertiserMenu));
      toast.error('Erro ao completar o cadastro. Atualize a página e tente novamente');
    } else {
      toast.error(
        err.response?.data?.error?.message ||
          'Erro ao completar o cadastro. Atualize a página e tente novamente',
      );
      yield put(loginFailure());
    }
  }
}

export function* loginSuccessSaga() {
  try {
    const globalState: RootState = yield select((state: RootState) => state);

    if (!globalState.auth.isNew) {
      yield put(fetchMyAddresses());
      yield put(OrderCreators.getMyOrders(1, 10));
    }
  } catch (err) {}
}

export function* logOut() {
  yield put(cleanBag());
  yield put(clearCurrentAddress());
  Sentry.configureScope((scope) => scope.setUser(null));
}

export default all([
  takeLatest(AuthTypes.LOGIN_SUCCESS, loginSuccessSaga),
  takeLatest(AuthTypes.LOG_OUT, logOut),
  takeLatest(AuthTypes.CREATE_SESSION_USER, createSessionUser),
  takeLatest('persist/REHYDRATE', setToken),
]);
