import addDays from 'date-fns/addDays';
import isToday from 'date-fns/isToday';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { OrderSchedule } from '~/models/OrderSchedule';
import DeliveryType from '~/models/types/DeliveryType';
import WeekDay from '~/models/types/WeekDay';
import { RootState } from '~/store';
import { formatDate } from '~/utils/formatDate';
import { weekdays } from '~/utils/getCurrentDay';

import { makeId, makeSchedulesForHoraries } from './makeSchedules';

export interface GetOrderSchedulesParams {
  deliveryType: DeliveryType;
  price: string;
}

interface ScheduleDay {
  id: string;
  shortName: string;
  shortMonthName: string;
  date: Date;
  schedules: OrderSchedule[];
}
interface ScheduleContextProps {
  selectedSchedule: OrderSchedule;
  hasSelectedScheduleOnce: boolean;
  selectSchedule: (id: string, scheduleDayShortName: string) => void;
  scheduleDays: ScheduleDay[];
  nextAvailableScheduleDay: ScheduleDay;
  resetSelectedSchedule: () => void;
}

const ScheduleContext = createContext<ScheduleContextProps>({} as ScheduleContextProps);

function ScheduleProvider({ children }: { children: React.ReactNode }) {
  const schedulesAvailability = useSelector(
    (state: RootState) => state.advertiser?.advertiserInfo?.schedulingAvailability,
  );

  const schedulingConfig = useSelector(
    (state: RootState) => state.advertiser?.advertiserInfo?.schedulingConfig,
  );
  const ordersConfig = useSelector(
    (state: RootState) => state.advertiser?.advertiserInfo?.ordersConfig,
  );

  const maxAvailableScheduleDays = schedulingConfig?.max_available_schedule_days;
  const intervalBetweenSchedulesInMinutes = schedulingConfig?.interval_between_scheduling;
  const minSchedulingTime = schedulingConfig?.min_scheduling_time;
  const isAcceptScheduled = ordersConfig?.is_accepting_scheduled_order;
  const isAcceptImmediate = ordersConfig?.is_accepting_immediate_order;
  const maxPossibleScheduleDays = 721;
  const eachDayFromNow = Array.from(Array(maxPossibleScheduleDays).keys()).map((_, idx) =>
    addDays(new Date(), idx),
  );

  const availabilities = useMemo(
    () =>
      weekdays.map((day) => (schedulesAvailability ? schedulesAvailability[day as WeekDay] : null)),
    [schedulesAvailability],
  );

  const scheduleDays = useMemo(
    () =>
      eachDayFromNow
        .map((date, idx) => {
          const currentAvailability = availabilities[date.getDay()];
          const lastHorary = currentAvailability ? [...currentAvailability?.horaries].pop() : null;
          if (lastHorary && currentAvailability?.is_active) {
            const schedules = makeSchedulesForHoraries({
              date,
              lastHorary,
              minSchedulingTime,
              intervalBetweenSchedulesInMinutes,
              isAcceptScheduled,
              isAcceptImmediate,
            });
            const scheduleDay: ScheduleDay = {
              id: formatDate(date, 'yyyy-MM-dd-cccccc'),
              date,
              schedules,
              shortName: formatDate(date, 'cccccc'),
              shortMonthName: formatDate(date, 'MMM'),
            };
            return scheduleDay;
          } else if (isToday(date) && !currentAvailability?.is_active && isAcceptImmediate) {
            const now = new Date();
            const scheduleDay: ScheduleDay = {
              id: formatDate(date, 'yyyy-MM-dd-cccccc'),
              date,
              schedules: [
                {
                  id: makeId(now, null, 'immediate'),
                  timeFrom: now,
                  timeTo: null,
                  type: 'immediate',
                },
              ],
              shortName: formatDate(date, 'cccccc'),
              shortMonthName: formatDate(date, 'MMM'),
            };
            return scheduleDay;
          } else {
            const scheduleDay: ScheduleDay = {
              id: formatDate(date, 'yyyy-MM-dd-cccccc'),
              date,
              schedules: [],
              shortName: formatDate(date, 'cccccc'),
              shortMonthName: formatDate(date, 'MMM'),
            };
            return scheduleDay;
          }
        })
        // Remove days with no availables horaries
        .filter((item) => {
          if (item.schedules.length) {
            return true;
          } else {
            return false;
          }
        })
        // Get the specific number of availables days defined by user
        .slice(0, maxAvailableScheduleDays),
    [
      availabilities,
      intervalBetweenSchedulesInMinutes,
      isAcceptScheduled,
      isAcceptImmediate,
      minSchedulingTime,
    ],
  );

  const nextAvailableScheduleDay = useMemo(
    () =>
      scheduleDays?.find((scheduleDay) => {
        if (isToday(scheduleDay.date) && scheduleDay.schedules.length) {
          // Force only return the schedule immediate, if the user accepts immediate orders
          if (scheduleDay.schedules[0].type === 'immediate' && isAcceptImmediate) {
            return true;
          }
          // Force only return the schedule scheduled, if the user accepts scheduled orders
          else if (scheduleDay.schedules[0].type === 'scheduled' && isAcceptScheduled) {
            return true;
          }
        } else {
          return scheduleDay.schedules.length;
        }
      }),
    [scheduleDays],
  );

  const [selectedSchedule, setSelectedSchedule] = useState<OrderSchedule | null>(null);
  const [hasSelectedScheduleOnce, setHasSelectedScheduleOnce] = useState<boolean>(false);

  useEffect(() => {
    if (
      selectedSchedule &&
      selectedSchedule.type === 'immediate' &&
      ordersConfig?.is_accepting_immediate_order
    ) {
      return;
    } else if (
      selectedSchedule &&
      selectedSchedule.type === 'scheduled' &&
      ordersConfig?.is_accepting_scheduled_order
    ) {
      return;
    }
    const firstSchedule = nextAvailableScheduleDay?.schedules[0];
    if (firstSchedule) {
      setSelectedSchedule(firstSchedule);
    }
  }, [nextAvailableScheduleDay, selectedSchedule, ordersConfig]);

  const selectSchedule = useCallback(
    (id: string, scheduleDayId: string) => {
      const scheduleDay = scheduleDays.find((scheduleDay) => scheduleDay.id === scheduleDayId);
      const foundSchedule = scheduleDay.schedules.find((schedule) => schedule.id === id);
      if (foundSchedule) {
        setSelectedSchedule(foundSchedule);
        setHasSelectedScheduleOnce(true);
      }
    },
    [scheduleDays],
  );

  const resetSelectedSchedule = useCallback(() => {
    const foundSchedule = nextAvailableScheduleDay?.schedules[0];
    if (foundSchedule) {
      setSelectedSchedule(foundSchedule);
      setHasSelectedScheduleOnce(false);
    }
  }, [nextAvailableScheduleDay]);

  return (
    <ScheduleContext.Provider
      value={{
        selectedSchedule,
        selectSchedule,
        scheduleDays,
        nextAvailableScheduleDay,
        resetSelectedSchedule,
        hasSelectedScheduleOnce,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
}

export { ScheduleContext, ScheduleProvider };
