import produce from 'immer';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import OrderSummary from '~/models/OrderSummary';

/* ============== ACTION TYPES ============== */

enum TypesNames {
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  CREATE_SESSION_USER = 'CREATE_SESSION_USER',
  LOG_OUT = 'LOG_OUT',
  SET_LOADING = 'SET_LOADING',
}

export interface OnCreateSessionUser extends Action<TypesNames.CREATE_SESSION_USER> {
  name: string;
  phone: string;
  redirect?: {
    path: string;
    data?: OrderSummary;
  };
}

export interface OnLoginSuccess extends Action<TypesNames.LOGIN_SUCCESS> {
  token: {
    type: string;
    token: string;
    refreshToken?: any;
  };
  user: object;
  isNew: boolean;
}

export interface OnLoginFailure extends Action<TypesNames.LOGIN_FAILURE> {}

export interface OnLogOut extends Action<TypesNames.LOG_OUT> {}
export interface OnSetLoading extends Action<TypesNames.SET_LOADING> {
  loading: boolean;
}

/* ============== ACTION CREATORS AND TYPES ============== */
export const { Types, Creators } = createActions<
  {
    [TypesNames.LOGIN_SUCCESS]: string;
    [TypesNames.LOGIN_FAILURE]: string;
    [TypesNames.LOG_OUT]: string;
    [TypesNames.CREATE_SESSION_USER]: string;
    [TypesNames.SET_LOADING]: string;
  },
  {
    loginSuccess: (
      token: {
        type: string;
        token: string;
        refreshToken?: any;
      },
      user: object,
      isNew: boolean,
    ) => OnLoginSuccess;
    loginFailure: () => OnLoginFailure;
    logOut: () => OnLogOut;
    createSessionUser: (
      name: string,
      phone: string,
      redirect?: {
        path: string;
        data?: OrderSummary;
      },
    ) => OnCreateSessionUser;
    setLoading: (loading: boolean) => OnSetLoading;
  }
>({
  loginSuccess: ['token', 'user', 'isNew'],
  loginFailure: null,
  logOut: null,
  createSessionUser: ['name', 'phone', 'redirect'],
  setLoading: ['loading'],
});

export const AuthTypes = Types;
export default Creators;

/* ============== INITIAL STATE ============== */
export interface AuthStateType {
  token: {
    type: string;
    token: string;
    refreshToken?: any;
  };
  isLoggedin: boolean;
  loading: boolean;
  isNew: boolean;
}

export const INITIAL_STATE: AuthStateType = {
  token: null,
  isLoggedin: false,
  loading: false,
  isNew: false,
};

/* ============== REDUCERS ============== */
export const setLoadingReducer = (state = INITIAL_STATE, { loading }: OnSetLoading) =>
  produce(state, (draft) => {
    draft.loading = loading;
  });

export const loginSuccessReducer = (state = INITIAL_STATE, { token, isNew }: OnLoginSuccess) =>
  produce(state, (draft) => {
    draft.token = token;
    draft.isLoggedin = true;
    draft.loading = false;
    draft.isNew = isNew;
  });

export const loginFailureReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.token = null;
    draft.isLoggedin = false;
    draft.loading = false;
    draft.isNew = false;
  });

export const logOutReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.token = null;
    draft.isLoggedin = false;
    draft.loading = false;
    draft.isNew = false;
  });

export const createSessionUserReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

export const reducer = createReducer<typeof INITIAL_STATE, any>(INITIAL_STATE, {
  [Types.SET_LOADING]: setLoadingReducer,
  [Types.LOGIN_FAILURE]: loginFailureReducer,
  [Types.LOGIN_SUCCESS]: loginSuccessReducer,
  [Types.CREATE_SESSION_USER]: createSessionUserReducer,
  [Types.LOG_OUT]: logOutReducer,
});
