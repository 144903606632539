import { AnyAction, Reducer } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';

export default function persistReducers(
  reducers: Reducer<any, AnyAction>,
  persistConfig: PersistConfig<any>,
) {
  const persistedReducer = persistReducer(persistConfig, reducers);

  return persistedReducer;
}
