import { Box, BoxProps } from '@chakra-ui/core';
import React from 'react';

export type TopRoundedBoxProps = BoxProps;

const TopRoundedBox: React.FC<TopRoundedBoxProps> = ({
  children,
  bg = 'white',
  minH = '49vh',
  pb = 10,
  boxShadow = '',
  borderTopRightRadius = '2.6rem',
  borderTopLeftRadius = '2.6rem',
  ...rest
}) => {
  return (
    <Box
      bg={bg}
      minH={minH}
      pb={pb}
      boxShadow={boxShadow}
      borderTopRightRadius={borderTopRightRadius}
      borderTopLeftRadius={borderTopLeftRadius}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default TopRoundedBox;
