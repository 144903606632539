import {
  Badge,
  Box,
  Heading,
  Icon,
  IconButton,
  Image,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import { push } from 'connected-react-router';
import debounce from 'lodash/debounce';
import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { AiOutlineShop } from 'react-icons/ai';
import { FiSmile } from 'react-icons/fi';
import { RiEBike2Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  amplitudeSetUserId,
  amplitudeSetUserProperties,
  amplitudeTrackEvent,
} from '~/components/Amplitude';
import ListItem from '~/components/ListItem';
import Modal from '~/components/Modal';
import NavBar from '~/components/NavBar';
import ProductCard from '~/components/ProductCard';
import ProductItem from '~/components/ProductItem';
import useDynamicDeliveryFee from '~/hooks/useDynamicDelivery';
import usePersistedState from '~/hooks/usePersistedState';
import usePWA from '~/hooks/usePWA';
import useSlug from '~/hooks/useSlug';
import useWindowConstraints from '~/hooks/useWindowConstraints';
import Coupon from '~/models/Coupon';
import UserLoyaltyCard from '~/models/UserLoyaltyCard';
import {
  advertiserDetails,
  advertiserMenuSearch,
  itemDetailsWithId,
  myLoyaltyCards,
} from '~/routes/routeMap';
import { RootState } from '~/store';
import AdvertiserActions from '~/store/ducks/advertiser';
import BagActions from '~/store/ducks/bag';
import KuppiBoostActions from '~/store/ducks/kuppiBoost';
import { ScrollSmoothLeft, ScrollSmoothTop } from '~/styles/animations/ScrollSmooth';
import { formatAddress } from '~/utils/address';
import { formatCurrencyBRL } from '~/utils/currency';
import RealTimeDimensions from '~/utils/realTimeDimensions';
import { formatMinutes } from '~/utils/time';

import { sendAccessedWebMenu } from '../../services/mixPanel';
import Page404 from '../Page404';
import CategoryButton from './CategoryButton';
import LoyaltyCardModal from './LoyaltyCardModal';
import { AdvertiserHeading, CustomCategoryBox, CustonImage, HorizontalScroll } from './styles';
import currentPosition from './utils/currentCategoryPosition';
import currentWeekDay from './utils/currentWeekDay';

const { getData, getBestOffers, sendCampaignClick } = AdvertiserActions;
const { addDiscountInBag, cleanBag, checkProductAvailabilityRequest } = BagActions;
const { setKuppiBoostData } = KuppiBoostActions;
const { setModalAlreadyBeenShown } = AdvertiserActions;

const AdvertiserMenu: React.FC = () => {
  const [isPageViewTriggered, setIsPageViewTriggered] = useState(false);
  const { scrollY } = useWindowConstraints();
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const slug = useSlug();

  const { bagDiscounts, checkProductAvailabilityLoader } = globalState.bag;
  const currentAddress = globalState?.addresses?.currentAddress;
  const loyaltyCard = globalState?.advertiser?.loyaltyCard;
  const userLoyaltyCard = globalState?.user?.loyaltyCard;
  const rescuedLoyaltyCards = globalState?.user?.rescuedLoyaltyCards;
  const modalLoyaltyCardAlreadyBeenShown =
    globalState?.advertiser?.modalLoyaltyCardAlreadyBeenShown;
  const userData = globalState?.user.user;
  const advertiserData = globalState?.advertiser;
  const advertiserInfoId = advertiserData?.advertiserInfo?.id;
  const imageTimestamp = advertiserData.imageTimestamp;

  const query = new URLSearchParams(useLocation().search);
  const facebookCampaignId = query.get('utm_campaign');
  useEffect(() => {
    if (slug) {
      dispatch(getData(slug));
      dispatch(getBestOffers(slug));
    }
    if (facebookCampaignId) {
      dispatch(setKuppiBoostData(facebookCampaignId.toString()));
    }
  }, [dispatch, slug, checkProductAvailabilityLoader]);

  useEffect(() => {
    if (advertiserInfoId && facebookCampaignId) {
      const campaignId = facebookCampaignId?.replace('mc-', '');
      const clickAlreadyCounted = localStorage.getItem(`click_already_counted:${campaignId}`);
      if (facebookCampaignId?.includes('mc-') && !!!clickAlreadyCounted) {
        dispatch(sendCampaignClick(campaignId));
      }
    }
  }, [advertiserInfoId, facebookCampaignId]);

  // Amplitude Event
  useEffect(() => {
    if (advertiserData?.advertiserInfo) {
      var userProperties = {
        advertiser_info_id: advertiserData?.advertiserInfo?.id?.toString(),
        advertiser_name: advertiserData?.advertiserInfo?.name?.toString(),
        advertiser_category: advertiserData?.advertiserInfo?.advertiserCategory?.name?.toString(),
        user_id: userData ? userData?.id?.toString() : null,
      };
      // only triggers pageview_homepage first time page loads
      if (!isPageViewTriggered) {
        amplitudeTrackEvent('pageview_homepage', userProperties);
        setIsPageViewTriggered(true);
      }
      amplitudeSetUserId(userData);
      amplitudeSetUserProperties(userProperties);
    }
  }, [userData, advertiserData]);

  const loading = globalState?.advertiser?.loading;
  const bestOffersLoading = globalState?.advertiser?.bestOffersLoading;
  const lastAdvertiser = globalState?.advertiser?.lastAdvertiser;
  const advertiserInfo = globalState?.advertiser?.advertiserInfo;
  const advertiserBestOffers = globalState?.advertiser?.advertiserBestOffers;
  const advertiserIsOpen = globalState?.advertiser?.advertiserIsOpen;
  const bestOfferTag = globalState?.advertiser?.bestOfferTag;

  const isLoggedin = globalState?.auth?.isLoggedin;

  const itemsInBag = globalState?.bag?.itemsInBag;
  const { initializeHeadTags, isStandalone } = usePWA();
  const [hasAskedLogin, setHasAskedLogin] = usePersistedState('@kuppi/hasAskedLogin', false);

  useEffect(() => {
    const advertiserSlugInUrl = slug;
    initializeHeadTags(advertiserSlugInUrl).then(() => {});
    // If lastAdvertiser is not null in redux and be different that url advertiser, this means that the user change the advertiser url in the same tab
    if (lastAdvertiser !== null && lastAdvertiser !== advertiserSlugInUrl) {
      // Clean the bag to avoid make orders with products for another advertiser
      dispatch(cleanBag());
    }
  }, [slug, dispatch, initializeHeadTags]);

  const shouldShowBestOffers = useMemo(() => {
    const isNotLoadingAndHasBestOffers =
      !bestOffersLoading && advertiserBestOffers && advertiserBestOffers.length > 0;
    const isAtLeastOneOfferAvailable = advertiserBestOffers?.some(
      (offer: any) => offer.isAvailable,
    );
    return isNotLoadingAndHasBestOffers && isAtLeastOneOfferAvailable;
  }, [bestOffersLoading, advertiserBestOffers]);

  const [categories, setCategories] = useState<any[]>([]);

  const [windowScrollY, setWindowScrollY] = useState(0);
  const [width] = RealTimeDimensions();
  const isMobile = width > 0 && width < 800 ? true : false;

  useEffect(() => {
    setCategories(advertiserInfo?.productCategory);

    if (advertiserInfo) {
      // MixPanel Event
      sendAccessedWebMenu('Funnel 1 - Access', {
        id: userData ? userData.id.toString() : null,
        name: advertiserInfo?.name,
        email: advertiserInfo?.user.email,
        webMenuId: advertiserInfo?.id.toString(),
        facebookCampaignId: facebookCampaignId,
      });
    }
  }, [advertiserInfo]);

  const [categoriesRefs, setCategoriesRefs] = useState<React.MutableRefObject<HTMLDivElement>[]>(
    [],
  );

  const [categoriesHorizontalRefs, setCategoriesHorizontalRefs] = useState<
    React.MutableRefObject<HTMLDivElement>[]
  >([]);

  const [visibleCategoryIndex, setVisibleCategoryIndex] = useState<number | null>(null);

  const horizontalBoxUseRef = useRef();

  useEffect(() => {
    setCategoriesRefs((prevRefs) =>
      Array(categories?.length)
        .fill(undefined)
        .map((_, index) => prevRefs[index] || createRef()),
    );

    setCategoriesHorizontalRefs((prevRefs) =>
      Array(categories?.length)
        .fill(undefined)
        .map((_, index) => prevRefs[index] || createRef()),
    );
  }, [categories]);

  const handleHorizontalScrollCategory = useCallback(
    (category_index: number | null) => {
      setVisibleCategoryIndex(category_index);
      if (category_index === null) {
        ScrollSmoothLeft({
          ref: categoriesHorizontalRefs[0],
          includedBox: horizontalBoxUseRef,
        });
      } else {
        ScrollSmoothLeft({
          ref: categoriesHorizontalRefs[category_index],
          includedBox: horizontalBoxUseRef,
        });
      }
    },
    [categoriesHorizontalRefs],
  );

  useEffect(
    debounce(() => {
      categoriesRefs.forEach((category, index, array) => {
        if (!category?.current) return;

        const nextCategory = array[index + 1];
        const currentCategoryPosition = currentPosition(category);
        const firstCategory = currentPosition(array[0]);
        const nextCategoryPosition = nextCategory && currentPosition(nextCategory);
        const windowHeight = document.body.scrollHeight - window.innerHeight;

        if (scrollY < firstCategory) {
          handleHorizontalScrollCategory(null);
        } else if (windowHeight.toFixed(0) === scrollY.toFixed(0)) {
          handleHorizontalScrollCategory(array.length - 1);
        } else if (scrollY >= currentCategoryPosition && scrollY < nextCategoryPosition) {
          handleHorizontalScrollCategory(index);
        } else if (index === array.length - 1 && scrollY >= currentCategoryPosition) {
          handleHorizontalScrollCategory(index);
        }
      });
    }, 500),
    [scrollY, categoriesRefs, handleHorizontalScrollCategory],
  );

  const handleScrollIntoCategory = useCallback(
    (category_index: number) => {
      ScrollSmoothTop({ ref: categoriesRefs[category_index] });
    },
    [categoriesRefs],
  );

  const {
    isOpen: isOpenLoyaltyCard,
    onOpen: onOpenLoyaltyCard,
    onClose: onCloseLoyaltyCard,
  } = useDisclosure();

  useEffect(() => {
    if (!modalLoyaltyCardAlreadyBeenShown) {
      setTimeout(() => {
        onOpenLoyaltyCard();
      }, 3000);
      dispatch(setModalAlreadyBeenShown());
    }
  }, [dispatch, onOpenLoyaltyCard, modalLoyaltyCardAlreadyBeenShown]);

  const handleOnClickItem = (product: any) => {
    dispatch(push(itemDetailsWithId.replace(':id_produto', `${product?.id}`), { product }));
    // Removed validation, until the bug resolution will done
    //dispatch(checkProductAvailabilityRequest(product));
  };

  const [visibilityPercentModal, setvisibilityPercentModal] = useState<boolean>(false);
  const [currentBestOfferCoupon, setCurrentBestOfferCoupon] = useState<Coupon>(null);

  const handleOnClickBestOffer = (coupon: Coupon) => {
    const type = coupon?.type;
    const mode = coupon?.mode;
    const today = currentWeekDay(new Date().getDay());

    if (mode === 'normal' && !coupon.couponHorary[today].is_active) {
      return toast.error('Este cupom não está disponível hoje.');
    }

    if (type !== 'percentage_cart') {
      dispatch(
        push({
          pathname: itemDetailsWithId.replace(':id_produto', `${coupon.id}`),
          state: { coupon: coupon },
        }),
      );
    } else if (type === 'percentage_cart') {
      if (itemsInBag.length === 0) {
        dispatch(addDiscountInBag(coupon));
      } else {
        setvisibilityPercentModal(true);
        setCurrentBestOfferCoupon(coupon);
      }
    }
  };

  useEffect(() => {
    if (!bagDiscounts?.activeCoupon && advertiserBestOffers) {
      const percentDiscount = advertiserBestOffers?.filter(
        (bestOffer) => bestOffer.type === 'percentage_cart',
      );
      if (percentDiscount?.length > 0) {
        dispatch(addDiscountInBag(advertiserBestOffers[0], true));
      }
    }
  }, [advertiserBestOffers]);

  const handletriggerYesModal = () => {
    dispatch(addDiscountInBag(currentBestOfferCoupon));
  };

  const handletriggerNoModal = () => {
    setvisibilityPercentModal(false);
  };

  const delivery = useDynamicDeliveryFee({
    advertiserAddress: advertiserInfo?.user.address[0],
    userAddress: currentAddress,
    deliveryFee: advertiserInfo?.delivery_fee,
    deliveryDynamicFeeMaxDist: advertiserInfo?.delivery_dynamic_fee_max_dist,
    deliveryDynamicFee: advertiserInfo?.delivery_dynamic_fee,
    deliveryDynamicFeeExceededAmount: advertiserInfo?.delivery_dynamic_fee_exceeded_amount,
    deliveryDynamicFeeFreeDist: advertiserInfo?.delivery_dynamic_fee_free_dist,
    deliveryType: advertiserInfo?.is_using_delivery ? 'delivery' : 'take_away',
  });

  const deliveryTimeAvg = useMemo(() => {
    if (!advertiserInfo || !advertiserInfo?.delivery_time_avg) {
      return '';
    }
    const inMinutes = advertiserInfo?.delivery_time_avg;

    return formatMinutes(inMinutes);
  }, [advertiserInfo]);

  const takeAwayTimeAvg = useMemo(() => {
    if (!advertiserInfo || !advertiserInfo?.take_away_time_avg) {
      return '';
    }
    const inMinutes = advertiserInfo?.take_away_time_avg;

    return formatMinutes(inMinutes);
  }, [advertiserInfo]);

  function returnPercentage(loyaltyCard: UserLoyaltyCard) {
    if (loyaltyCard?.qty_purchases === loyaltyCard?.amount_of_purchases) {
      return '100%';
    }

    if (loyaltyCard?.qty_purchases) {
      const persent = (100 / loyaltyCard?.amount_of_purchases).toFixed(2);
      return `${(Number(persent) * Number(loyaltyCard?.qty_purchases)).toFixed(2)}%`;
    }

    return '0%';
  }

  const loyaltyCardUsedPercentage = useMemo(() => {
    if (!loyaltyCard) {
      return '0%';
    }

    if (userLoyaltyCard) {
      return returnPercentage(userLoyaltyCard);
    }

    if (rescuedLoyaltyCards?.length > 0) {
      return returnPercentage(rescuedLoyaltyCards[0]);
    }

    return '0%';
  }, [loyaltyCard, userLoyaltyCard, rescuedLoyaltyCards]);

  const loyaltyCardUsedText = useMemo(() => {
    if (userLoyaltyCard) {
      return `${userLoyaltyCard?.qty_purchases}/${userLoyaltyCard?.amount_of_purchases}`;
    }

    if (rescuedLoyaltyCards?.length > 0) {
      return `${rescuedLoyaltyCards[0]?.qty_purchases}/${rescuedLoyaltyCards[0]?.amount_of_purchases}`;
    }

    return `0/${loyaltyCard?.amount_of_purchases}`;
  }, [loyaltyCard, userLoyaltyCard, rescuedLoyaltyCards]);

  const { isOpen: isLoginOpen, onOpen: onLoginOpen, onClose: onLoginClose } = useDisclosure();
  useEffect(() => {
    if (isStandalone && !hasAskedLogin && !isLoggedin) {
      setHasAskedLogin(true);
      onLoginOpen();
    }
  }, [isStandalone, onLoginOpen, hasAskedLogin, setHasAskedLogin, dispatch, isLoggedin]);

  useEffect(() => {
    setWindowScrollY(window.scrollY);
  }, [window.scrollY]);

  if (!loading && !advertiserInfo) {
    return <Page404 />;
  }

  if (!loading && !advertiserInfo?.is_done_onboarding) {
    return <Page404 />;
  }

  return (
    <>
      {/* Page title */}
      {/* @ts-ignore */}
      <Helmet>
        <title>{advertiserInfo?.name ? advertiserInfo?.name : 'Kuppi'} | Cardápio Web</title>
      </Helmet>

      {/* Page Content */}
      <Box d="flex" alignItems="flex-start" justifyContent="flex-start" pb={100}>
        {advertiserInfo?.cover?.url ? (
          <Skeleton isLoaded={!loading}>
            <Image
              src={`${advertiserInfo?.cover?.url}?nocache=${imageTimestamp}`}
              position="absolute"
              top="0"
              left="0"
              h="12rem"
              w="100%"
              objectFit="cover"
            />
          </Skeleton>
        ) : (
          <Skeleton isLoaded={!loading} h="12rem" w="100%" position="absolute" top="0" left="0">
            <CustonImage
              src={`${advertiserInfo?.user?.avatar?.url}?nocache=${imageTimestamp}`}
              position="absolute"
              top="0"
              left="0"
              h="12rem"
              w="100%"
              objectFit="cover"
            />
          </Skeleton>
        )}
      </Box>

      <Box
        as="main"
        flexDir="column"
        w="100%"
        position="relative"
        pb={isMobile ? 70 : 90}
        zIndex={1}
      >
        {/* Header */}
        <Stack
          as="header"
          spacing={2}
          bg="#EFEFEF"
          borderRadius="2rem 2rem 0 0"
          mt="1rem"
          py={8}
          px="3rem"
          position="relative"
        >
          {/* Advertiser Name and Logo */}
          <Box d="flex" alignItems="center">
            <Link
              to={{
                pathname: advertiserDetails,
              }}
              style={{ display: 'flex', flex: '1' }}
            >
              <Skeleton isLoaded={!loading} w="96%">
                <Box d="flex" flexDir="column">
                  <Box d="flex" alignItems="center" mb={4}>
                    <AdvertiserHeading as="h1" fontSize="lg" fontWeight="700">
                      {advertiserInfo?.name}
                    </AdvertiserHeading>
                    <Icon name="arrow-back" size="2.4rem" transform="rotate(180deg)" ml={4} />
                  </Box>

                  {/* BADGE DESCONTO E ABERTO/FECHADO */}
                  <Skeleton isLoaded={!loading} w="60%">
                    <Box d="flex">
                      {Boolean(bestOfferTag) && (
                        <Badge
                          variant="subtle"
                          border="1px solid"
                          borderColor="green.100"
                          variantColor="green"
                          px={2}
                          fontSize="1.2rem"
                          fontWeight="400"
                        >
                          ATÉ {bestOfferTag}% OFF
                        </Badge>
                      )}
                      <Badge
                        variant="subtle"
                        border="1px solid"
                        borderColor={advertiserIsOpen ? 'gray.300' : '#FF6C6C'}
                        bg={advertiserIsOpen ? 'transparent' : '#FF6C6C'}
                        color={advertiserIsOpen ? 'gray.400' : '#EFEFEF'}
                        px={2}
                        ml={2}
                        fontSize="1.2rem"
                        fontWeight="400"
                      >
                        {advertiserIsOpen ? 'ABERTO' : 'FECHADO'}
                      </Badge>
                    </Box>
                  </Skeleton>
                </Box>
              </Skeleton>
            </Link>
            <Skeleton isLoaded={!loading} borderRadius="50%" w="7.5rem" h="7.5rem">
              <Image
                src={
                  advertiserInfo?.user?.avatar?.url &&
                  `${advertiserInfo?.user?.avatar?.url}?nocache=${imageTimestamp}`
                }
                backgroundColor="white"
                h={['5.4rem', '7.5rem']}
                w={['5.4rem', '7.5rem']}
                borderRadius="50%"
                objectFit="cover"
              />
            </Skeleton>
          </Box>

          {/* Delivery and Take Away Description */}
          <Skeleton isLoaded={!loading}>
            {advertiserInfo?.is_using_delivery ? (
              <Box d="flex" alignItems="center">
                <Box as={RiEBike2Line} color="gray.500" size="1.6rem" mr={2} />
                {delivery.prettyDescription}
              </Box>
            ) : (
              <Box d="flex" alignItems="center">
                <Box as={AiOutlineShop} color="gray.500" size="2rem" mr={2} />
                <Text as="span" fontSize="sm" fontWeight="400" color="gray.400">
                  Retirada em{' '}
                  {`${formatAddress(advertiserInfo?.user?.address[0]).substring(0, 24)}...`}
                </Text>
              </Box>
            )}
          </Skeleton>

          {/* Delivery Time Average */}
          {deliveryTimeAvg && advertiserInfo.is_using_delivery && (
            <Skeleton isLoaded={!loading}>
              <Box d="flex" alignItems="center">
                <Icon name="clock" color="gray.500" size="1.6rem" mr={2} />
                <Text as="span" fontSize="sm" fontWeight="400" color="gray.400">
                  Tempo de entrega{' '}
                  <Text as="span" color="gray.700">
                    {deliveryTimeAvg}
                  </Text>
                </Text>
              </Box>
            </Skeleton>
          )}

          {/* Take Away Time Average */}
          {takeAwayTimeAvg && !advertiserInfo.is_using_delivery && (
            <Skeleton isLoaded={!loading}>
              <Box d="flex" alignItems="center">
                <Icon name="clock" color="gray.500" size="1.6rem" mr={2} />
                <Text as="span" fontSize="sm" fontWeight="400" color="gray.400">
                  Tempo de retirada{' '}
                  <Text as="span" color="gray.700">
                    {takeAwayTimeAvg}
                  </Text>
                </Text>
              </Box>
            </Skeleton>
          )}

          {/* Minimum Order Amount */}
          {advertiserInfo?.order_minimum_amount && (
            <Skeleton isLoaded={!loading}>
              <Box d="flex" justifyContent="space-between" alignItems="center">
                {/* Minimum Order Amount */}
                <Text fontSize="sm" d="flex" alignItems="center">
                  <Icon name="money" size="2rem" color="gray.500" />
                  <Text as="span" ml={2} color="gray.400">
                    Pedido mínimo
                  </Text>
                  <Text as="span" ml={2} fontSize="sm" color="gray.700">
                    {formatCurrencyBRL(advertiserInfo?.order_minimum_amount)}
                  </Text>
                </Text>
              </Box>
            </Skeleton>
          )}

          {/* Loyalty Card */}
          {loyaltyCard?.is_active && isLoggedin && (
            <Skeleton isLoaded={!loading}>
              <Box
                d="flex"
                justifyContent="space-between"
                alignItems="center"
                cursor="pointer"
                onClick={() => dispatch(push(myLoyaltyCards))}
              >
                <Text fontSize="sm" d="flex" alignItems="center">
                  <Icon as={FiSmile} size="2rem" color="gray.500" />
                  <Text as="span" ml={2} mt={0.5} color="gray.400">
                    Cartão fidelidade{' '}
                    <Text as="span" color="gray.700">
                      {loyaltyCardUsedText}
                    </Text>
                  </Text>
                </Text>
              </Box>
              <Box h="3px" w="100%" mt="1rem" bg="white">
                <Box
                  h="3px"
                  w={loyaltyCardUsedPercentage}
                  mt="1rem"
                  bg="green.400"
                  transition="all 0.2s ease-in-out"
                />
              </Box>
            </Skeleton>
          )}
        </Stack>

        {/* Categories Tabs and Product Search */}
        <CustomCategoryBox stick={windowScrollY > 300} isMobile={isMobile}>
          {/* Categories Tabs */}
          <HorizontalScroll
            isInline
            borderBottom="1px solid"
            borderColor="gray.100"
            position="relative"
            top={0}
            bg="white"
            ref={horizontalBoxUseRef}
          >
            {!categories &&
              Array.from(Array(3).keys()).map((item) => (
                <Skeleton key={item} h="40px" w="18rem" m={4} />
              ))}
            {categories &&
              categories?.map((category, i) => (
                <Box as="div" ref={categoriesHorizontalRefs[i]} key={category.id} p="0" m="0">
                  <CategoryButton
                    onClick={() => handleScrollIntoCategory(i)}
                    currentCategory={visibleCategoryIndex === i}
                  >
                    {category.name}
                  </CategoryButton>
                </Box>
              ))}
          </HorizontalScroll>
          {/* Product Search */}
          {!loading && categories && categories.length > 0 && (
            <Box ml={3} mr={1} d="flex" alignItems="flex-end">
              <Link
                to={{
                  pathname: advertiserMenuSearch,
                }}
                style={{ display: 'flex', flex: '1' }}
              >
                <IconButton
                  aria-label="Pesquisar..."
                  icon="search"
                  variant="ghost"
                  size="lg"
                  color="gray.500"
                  bg="green.200"
                  boxShadow="md"
                  w={'48px'}
                  h={'48px'}
                />
              </Link>
            </Box>
          )}
        </CustomCategoryBox>

        {/* Best Offers */}
        {shouldShowBestOffers && categories && categories.length > 0 && (
          <Box as="section" id="better-discounts" bg="white" py={8}>
            <Heading as="h1" fontSize="lg" fontWeight="700" mb={4} px="3rem">
              Melhores descontos
            </Heading>
            <HorizontalScroll isInline py={4} px={0} spacing={4}>
              {bestOffersLoading && !advertiserBestOffers
                ? Array.from(Array(5).keys()).map((item, i) =>
                    i === 0 ? (
                      <Skeleton
                        key={item}
                        d="flex"
                        flex="0 0 auto"
                        h="20rem"
                        w="17rem"
                        mx={8}
                        borderRadius="1rem"
                      />
                    ) : (
                      <Skeleton
                        key={item}
                        d="flex"
                        flex="0 0 auto"
                        h="20rem"
                        w="17rem"
                        mr={8}
                        borderRadius="1rem"
                      />
                    ),
                  )
                : advertiserBestOffers?.map((item: any) =>
                    item.mode === 'normal' ? (
                      <ProductCard
                        key={item.id}
                        onClick={() => handleOnClickBestOffer(item)}
                        item={item}
                      />
                    ) : (
                      item.mode === 'flash' &&
                      item.usage_limit &&
                      item.usage_limit - item.used_coupons > 0 && (
                        <ProductCard
                          key={item.id}
                          onClick={() => handleOnClickBestOffer(item)}
                          item={item}
                        />
                      )
                    ),
                  )}
            </HorizontalScroll>
          </Box>
        )}

        {/* Categories Skeleton */}
        {loading &&
          !categories &&
          Array.from(Array(2).keys()).map((item, i) => (
            <Box key={item} my={12} px="2rem">
              <Skeleton w="100%" h="4rem" mb={6}></Skeleton>
              <Skeleton w="100%" h="10rem" mb={3}></Skeleton>
              <Skeleton w="100%" h="10rem" mb={3}></Skeleton>
              <Skeleton w="100%" h="10rem" mb={3}></Skeleton>
            </Box>
          ))}

        {/* Empty Categories */}
        {!loading && categories && categories.length === 0 && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            color="gray.400"
            flexDirection="column"
          >
            <Heading as="h1" fontSize="lg" fontWeight="700" px="3rem">
              Oops! Catálogo vazio :(
            </Heading>
            <Text
              as="span"
              fontSize="sm"
              fontWeight="400"
              color="gray.400"
              mt={5}
              pl={10}
              pr={10}
              width="100%"
              textAlign="center"
            >
              O catálogo não foi criado ou não existem categorias ativas.
            </Text>
          </Box>
        )}

        {/* Categories */}
        {categories &&
          categories.map((category, i) => (
            <Box key={category.id} as="section" bg="white" id="categories" pb={16}>
              <Heading
                bg="gray.50"
                as="h1"
                fontSize="lg"
                fontWeight="700"
                py={6}
                px="3rem"
                ref={categoriesRefs[i]}
              >
                {category.name}
              </Heading>
              <Stack spacing={0}>
                {category.products.map((product: any) => (
                  <ListItem key={product.id} onClick={() => handleOnClickItem(product)}>
                    <ProductItem product={product} cursor="pointer" />
                  </ListItem>
                ))}
              </Stack>
            </Box>
          ))}
      </Box>

      {/* Confirm Modal */}
      <Modal
        visibility={visibilityPercentModal}
        triggerYes={handletriggerYesModal}
        triggerNo={handletriggerNoModal}
      />

      {/* Loyalty Card Drawer */}
      {loyaltyCard?.is_active && !isStandalone ? (
        <LoyaltyCardModal
          isVisible={isOpenLoyaltyCard}
          onClose={onCloseLoyaltyCard}
          advertiserLoyaltyCard={loyaltyCard}
          userLoyaltyCard={userLoyaltyCard}
        />
      ) : null}

      {/* Navigation bar (Catalog, Bag, Orders, Profile) */}
      <NavBar />
    </>
  );
};

export default AdvertiserMenu;
