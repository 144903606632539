import { LatLngLiteral } from 'leaflet';

import Address from '~/models/Address';
import { AddressComponent } from '~/models/Autocomplete';

type AddressComponentPredicate = (addressComponent: AddressComponent) => boolean;

export enum AddressFields {
  STREET = 'street',
  NUMBER = 'number',
  NEIGHBORHOOD = 'neighborhood',
  CITY = 'city',
  STATE = 'state',
  ZIPCODE = 'zipcode',
}

export const formatAddress = (address: Address) =>
  !!address
    ? `${address.street}, ${address.number}${address.complement ? ' - ' + address.complement : ''}${
        address.neighborhood ? ', ' + address.neighborhood : ''
      } - ${address.city} - ${address.state} ${address.zipcode ? address.zipcode : ''}`
    : '';

const hasAtLeastOneOf = (array: string[], ...values: string[]) => {
  return array.some((value) => values.includes(value));
};

const getAddressComponentValue = (
  addressComponent: AddressComponent,
  fieldValue: 'short_name' | 'long_name',
) => (!!addressComponent ? addressComponent[fieldValue] : '');

const getFromAddressComponents = (
  addressComponents: AddressComponent[],
  condition: AddressComponentPredicate,
  sort?: (prev: AddressComponent, next: AddressComponent) => number,
  fieldValue: 'short_name' | 'long_name' = 'long_name',
) => {
  let foundComponents = addressComponents.filter(condition);
  if (sort) {
    foundComponents = foundComponents.sort(sort);
  }
  const componentValue =
    foundComponents.length > 1
      ? foundComponents
          .map((component) => getAddressComponentValue(component, fieldValue))
          .join(' ')
      : getAddressComponentValue(foundComponents.pop(), fieldValue);
  return componentValue;
};

export const getFieldFromAddressComponents = (
  field: AddressFields,
  addressComponents: AddressComponent[],
) => {
  switch (field) {
    case AddressFields.STREET:
      return getFromAddressComponents(
        addressComponents,
        (component) =>
          hasAtLeastOneOf(
            component.types,
            'street_address',
            'route',
            'sublocality_level_2',
            'sublocality_level_3',
          ),
        (prev, next) => {
          if (hasAtLeastOneOf(prev.types, 'sublocality_level_2')) return -1;
          if (hasAtLeastOneOf(next.types, 'sublocality_level_3')) return 1;
        },
      );
    case AddressFields.NUMBER:
      const streetNumber = getFromAddressComponents(addressComponents, (component) =>
        hasAtLeastOneOf(component.types, 'street_number'),
      );
      return streetNumber.split(' ')[0];
    case AddressFields.NEIGHBORHOOD:
      return getFromAddressComponents(addressComponents, (component) =>
        hasAtLeastOneOf(component.types, 'sublocality_level_1', 'administrative_area_level_4'),
      );
    case AddressFields.CITY:
      return getFromAddressComponents(addressComponents, (component) =>
        hasAtLeastOneOf(component.types, 'administrative_area_level_2'),
      );
    case AddressFields.STATE:
      return getFromAddressComponents(
        addressComponents,
        (component) => hasAtLeastOneOf(component.types, 'administrative_area_level_1'),
        undefined,
        'short_name',
      );
    case AddressFields.ZIPCODE:
      return getFromAddressComponents(addressComponents, (component) =>
        hasAtLeastOneOf(component.types, 'postal_code'),
      );
  }
};

const brasiliaTransformFunctions: Array<(value: string) => string> = [
  // Substituir "quadra" por "q"
  (value: string) => value.toLowerCase().replaceAll('quadra', 'q'),
];

export const buildSearchTermAutocomplete = (search: string, address: Address) => {
  const city = address.city.toLowerCase();
  const state = address.state.toLowerCase();
  if (city === 'brasília' && state === 'df') {
    let searchTerm = search;
    for (const transform of brasiliaTransformFunctions) {
      searchTerm = transform(searchTerm);
    }
    return searchTerm;
  }
  return search.toLowerCase();
};

export const doesAdvertiserDeliversInRangeArea = (
  userLatLng: LatLngLiteral,
  advertiserLatLng: LatLngLiteral,
  advertiserDeliveryRangeArea: number,
) => {
  const from = new google.maps.LatLng(userLatLng.lat, userLatLng.lng);
  const to = new google.maps.LatLng(advertiserLatLng.lat, advertiserLatLng.lng);
  const distanceToAdvertiserInMeters = google.maps.geometry.spherical.computeDistanceBetween(
    from,
    to,
  );
  const advertiserDeliversInCurrentAddress =
    distanceToAdvertiserInMeters <= advertiserDeliveryRangeArea * 1000;
  return advertiserDeliversInCurrentAddress;
};
