import * as yup from 'yup';

export default yup.object().shape({
  number: yup.string().trim().max(10, 'O número deve possuir até 10 caracteres.'),
  street: yup
    .string()
    .trim()
    .required('Por favor informe sua rua.')
    .max(100, 'A rua deve possuir até 100 caracteres.'),
  complement: yup.string().trim().max(100, 'O complemento deve possuir até 100 caracteres.'),
  neighborhood: yup.number().required('Por favor informe sua localidade.'),
});
