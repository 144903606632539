import { Badge, Box, Icon, Image, Skeleton, Stack, Text } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import { format, isWithinInterval, sub } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '~/components/Button';
import Order from '~/models/Order';
import { pixPendingPayment, reOrder, viewOrder } from '~/routes/routeMap';
import { RootState } from '~/store';
import capitalize from '~/utils/capitalize';

import { CustonHeading, CustonImage, CustonText } from './style';

const locale = { locale: ptBR };

export interface OrderItemProps {
  loading: boolean;
  item?: Order;
  orderList?: string;
}

const OrderItem: React.FC<OrderItemProps> = ({ item, loading, orderList }) => {
  const globalState = useSelector((state: RootState) => state);
  const advertiserInfo = globalState?.advertiser.advertiserInfo;
  const isPaymentPending = item?.orderStatus?.status === 'pending_payment';
  const imageTimestamp = globalState?.advertiser.imageTimestamp;
  const isPaymentExpired =
    item?.advertiserOrderPayment?.pix_expiration_at &&
    !isWithinInterval(new Date(), {
      start: sub(new Date(item?.advertiserOrderPayment?.pix_expiration_at), {
        minutes: 5,
      }),
      end: new Date(item?.advertiserOrderPayment?.pix_expiration_at),
    });

  const dispatch = useDispatch();

  const handleOnClickSeeDetails = () => {
    dispatch(
      push(isPaymentPending && !isPaymentExpired ? pixPendingPayment : viewOrder, {
        order: item,
        goBackToMyOrders: true,
        orderList,
      }),
    );
  };

  const handleOnClickReOrder = () => {
    dispatch(push(reOrder.replace(':id_pedido', `${item?.id}`)));
  };

  const isCanceled = useMemo(() => {
    if (item?.orderStatus?.status === 'canceled') {
      return true;
    }
    return false;
  }, [item]);

  const firstImage = useMemo(() => {
    if (item?.orderProduct.length > 0) {
      return `${item?.orderProduct[0]?.product?.file?.url}?nocache=${imageTimestamp}`;
    }
    return null;
  }, [item]);

  return (
    <Box>
      <Stack isInline spacing={8} cursor="pointer" onClick={handleOnClickSeeDetails}>
        <Skeleton isLoaded={!loading} width="8rem" height="8rem" borderRadius="1rem">
          {firstImage ? (
            <Image
              src={firstImage}
              flex="1"
              width="8rem"
              height="8rem"
              borderRadius="1rem"
              objectFit="cover"
              maxWidth="initial"
            />
          ) : (
            <CustonImage
              src={`${advertiserInfo?.user?.avatar?.url}?nocache=${imageTimestamp}`}
              flex="1"
              width="8rem"
              height="8rem"
              borderRadius="1rem"
              objectFit="cover"
              maxWidth="initial"
            />
          )}
        </Skeleton>

        <Box d="flex" flexDir="column" flex="3">
          <Skeleton isLoaded={!loading} maxWidth="15rem" height="1.8rem" mb={2}>
            <CustonHeading as="h4" fontSize="1.4rem" fontWeight="700" mb={2}>
              Pedido #{item?.id}
            </CustonHeading>
          </Skeleton>

          <Skeleton isLoaded={!loading} maxWidth="100%" height="1.8rem" mb={4}>
            <CustonText mb={2} fontSize="md" fontWeight="400" color="gray.400">
              {item?.created_at &&
                capitalize(
                  format(new Date(item?.created_at), "EEEE', 'd' de 'MMMM' de 'yyyy", locale),
                )}
            </CustonText>
          </Skeleton>

          <Skeleton isLoaded={!loading} maxWidth="12rem" height="1.8rem" mb={2}>
            <Stack direction="row" spacing={2}>
              {item?.order_type === 'scheduled' && (
                <Badge
                  variant="subtle"
                  border="1px solid"
                  borderColor="blue.400"
                  bg="blue.400"
                  color="blue.50"
                  px={2}
                  py={1}
                  fontSize="1rem"
                  fontWeight="700"
                >
                  AGENDADO
                </Badge>
              )}
              <Badge
                variant="subtle"
                border="1px solid"
                borderColor={item?.delivery_type === 'delivery' ? 'green.300' : 'yellow.100'}
                bg={item?.delivery_type === 'delivery' ? 'green.200' : 'yellow.100'}
                color={item?.delivery_type === 'delivery' ? 'gray.700' : 'yellow.700'}
                px={2}
                py={1}
                fontSize="1rem"
                fontWeight="700"
              >
                {item?.delivery_type === 'delivery' ? 'DELIVERY' : 'RETIRADA'}
              </Badge>
              {isCanceled && (
                <Badge
                  variant="subtle"
                  border="1px solid"
                  borderColor="gray.300"
                  bg="transparent"
                  color="gray.400"
                  px={2}
                  py={1}
                  fontSize="1rem"
                  fontWeight="700"
                >
                  CANCELADO
                </Badge>
              )}
              {isPaymentPending && (
                <Badge
                  variant="subtle"
                  border="1px solid"
                  borderColor="yellow.300"
                  bg="yellow.200"
                  color="black.100"
                  px={2}
                  py={1}
                  fontSize="1rem"
                  fontWeight="700"
                >
                  PAGAMENTO PENDENTE
                </Badge>
              )}
            </Stack>

            {orderList === 'current' && (
              <Text
                as="span"
                color="gray.400"
                fontSize="sm"
                py={2}
                px={0}
                d="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Text as="span">Ver detalhes</Text>
                <Icon name="arrow-back" size="1.4rem" transform="rotate(180deg)" />
              </Text>
            )}
          </Skeleton>
        </Box>
      </Stack>
      {orderList === 'previous' && (
        <Box w="100%" mt={5} d="flex" flexDir="row">
          <Button width="100%" onClick={handleOnClickReOrder} bg={'green.200'}>
            Repetir pedido
          </Button>
          <Button ml={5} width="100%" onClick={handleOnClickSeeDetails} bg={'green.200'}>
            Ver detalhes
          </Button>
        </Box>
      )}
    </Box>
  );
};

const arePropsEqual = (prevProps: OrderItemProps, nextProps: OrderItemProps) =>
  prevProps.loading === nextProps.loading && prevProps.item?.id === nextProps.item?.id;

export default React.memo(OrderItem, arePropsEqual);
