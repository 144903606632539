import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import UserLoyaltyCard from '~/models/UserLoyaltyCard';
import { apiGlobal, apiWebMenu } from '~/services/api';
import AuthActions from '~/store/ducks/auth';

import { RootState } from '..';
import UserActions, {
  OnFetchUserLoyaltyCard,
  OnFetchUserRescuedLoyaltyCards,
  OnUpdatePersonalInfo,
  UserTypes,
} from '../ducks/user';

const { successPersonalInfo, failurePersonalInfo, setUserLoyaltyCard, setUserRescuedLoyaltyCards } =
  UserActions;

const getState = (state: RootState) => state;

export function* updatePersonalInfo({ data }: OnUpdatePersonalInfo) {
  try {
    const { user: userState } = yield select(getState);
    const { user, userInfo } = userState;

    const response: AxiosResponse = yield call(
      apiGlobal.put,
      `/user/${user?.id}/client-info/${userInfo?.id}/personal-info/`,
      data,
    );

    yield put(successPersonalInfo(response.data));

    toast.success('Dados atualizadas com sucesso!');
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(AuthActions.logOut());
      toast.error('Sessão inválida, efetue o login novamente');
    } else {
      Sentry.captureException(err);
      toast.error('Falha na atualização de dados. Contate o suporte.');
      yield put(failurePersonalInfo());
    }
  }
}

export function* fetchUserLoyaltyCardRequest({ advertiserLoyaltyCardId }: OnFetchUserLoyaltyCard) {
  yield delay(1500);

  try {
    const { advertiser, user } = yield select(getState);
    const userInfo = user?.userInfo;

    const response: AxiosResponse = yield call(
      apiWebMenu.get,
      `/anunciante/${advertiser?.advertiserInfo?.slug}/client-info/${userInfo?.id}/loyalty-card/${advertiserLoyaltyCardId}/active`,
    );
    const loyaltyCard: UserLoyaltyCard = response.data;
    yield put(setUserLoyaltyCard(loyaltyCard));
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(AuthActions.logOut());
      toast.error('Sessão inválida, efetue o login novamente');
    } else {
      Sentry.captureException(err);
      yield put(setUserLoyaltyCard(null));
    }
  }
}

export function* fetchUserRescuedLoyaltyCardRequest({
  advertiserLoyaltyCardId,
}: OnFetchUserRescuedLoyaltyCards) {
  yield delay(1500);

  try {
    const { advertiser, user } = yield select(getState);
    const userInfo = user?.userInfo;

    const response: AxiosResponse = yield call(
      apiWebMenu.get,
      `/anunciante/${advertiser?.advertiserInfo?.slug}/client-info/${userInfo?.id}/loyalty-card/${advertiserLoyaltyCardId}/completed`,
    );

    yield put(setUserRescuedLoyaltyCards(response.data));
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(AuthActions.logOut());
      toast.error('Sessão inválida, efetue o login novamente');
    } else {
      Sentry.captureException(err);
      yield put(setUserRescuedLoyaltyCards([]));
    }
  }
}

export default all([
  takeLatest(UserTypes.UPDATE_PERSONAL_INFO, updatePersonalInfo),
  takeLatest(UserTypes.FETCH_USER_LOYALTY_CARD, fetchUserLoyaltyCardRequest),
  takeLatest(UserTypes.FETCH_USER_RESCUED_LOYALTY_CARDS, fetchUserRescuedLoyaltyCardRequest),
]);
