import { Box, BoxProps } from '@chakra-ui/core';
import React from 'react';

export type CardProps = BoxProps;

const Card: React.FC<CardProps> = ({
  children,
  border = '1px solid',
  borderColor = 'gray.200',
  rounded = '1rem',
  px = 4,
  py = 8,
  d = 'flex',
  alignItems = 'center',
  boxShadow = '0px 3px 6px #0000000D',

  ...rest
}) => {
  return (
    <Box
      rounded={rounded}
      d={d}
      boxShadow={boxShadow}
      alignItems={alignItems}
      px={px}
      py={py}
      border={border}
      borderColor={borderColor}
      transition="all 0.2s ease-in-out"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Card;
