import { routerMiddleware, RouterState } from 'connected-react-router';
import { persistStore } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import history from '~/routes/history';

import createComposedStore from './createComposedStore';
import rootReducer from './ducks';
import { AddressesStateType } from './ducks/addresses';
import { AdvertiserStateType } from './ducks/advertiser';
import { AuthStateType } from './ducks/auth';
import { BagStateType } from './ducks/bag';
import { CookiesStateType } from './ducks/cookies';
import { KuppiBoostStateType } from './ducks/kuppiBoost';
import { OrderStateType } from './ducks/order';
import { UserStateType } from './ducks/user';
import persistReducers from './persistReducers';
import rootSaga from './sagas';
const sagaMiddleware = createSagaMiddleware();
const middlewares = [routerMiddleware(history), sagaMiddleware];

const persistConfig = {
  key: 'kuppi_web_menu',
  storage: localStorage,
  whitelist: ['auth', 'cookies'],
};
const store = createComposedStore(
  persistReducers(rootReducer(history), persistConfig),
  middlewares,
);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export type RootState = {
  router: RouterState<object>;
  cookies: CookiesStateType;
  auth: AuthStateType;
  user: UserStateType;
  addresses: AddressesStateType;
  advertiser: AdvertiserStateType;
  bag: BagStateType;
  order: OrderStateType;
  kuppiBoost: KuppiBoostStateType;
};

export { store, persistor };
