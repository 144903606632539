import { Box, Image, Text } from '@chakra-ui/core';
import styled from '@emotion/styled';

export const CustomText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const CardBox = styled(Box)`
  & + div {
    margin-left: 1.6rem;
  }

  :first-of-type {
    margin-left: 3rem;
  }

  :last-of-type > div {
    margin-right: 3rem;
  }
`;

export const CustonImage = styled(Image)`
  filter: blur(4px);
  -webkit-filter: blur(4px);
`;
