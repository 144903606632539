import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/core';
import React from 'react';

import Button from '../Button';

interface AlertDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onClickYes?: () => void;
  onClickNo?: () => void;
  heading: string;
  message: string;
}

const AlertDialog: React.FC<AlertDialogProps> = ({
  isOpen,
  onClose,
  onClickYes,
  onClickNo,
  heading,
  message,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent p={8} borderRadius="2rem">
        <ModalBody>
          <Stack spacing={8} textAlign="center" py={6}>
            <Heading as="h4" fontWeight="700" fontSize="lg">
              {heading}
            </Heading>
            <Text fontSize="md" fontWeight="400" textAlign="center">
              {message}
            </Text>
          </Stack>{' '}
        </ModalBody>

        <ModalFooter justifyContent="flex-start">
          <Button
            variant="ghost"
            flex="1"
            border="1px solid"
            borderColor="gray.700"
            fontWeight="bold"
            mr={4}
            onClick={onClickYes ? onClickYes : onClose}
          >
            Sim
          </Button>
          <Button flex="1" onClick={onClickNo ? onClickNo : onClose} fontWeight="bold">
            Não
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AlertDialog;
