import { useCallback, useEffect, useState } from 'react';

import { FacebookLoginStatusResponse, FacebookSDKInitParams } from '~/models/FacebookSDK';

export interface UseFacebookSDKParams extends FacebookSDKInitParams {}

function useFacebookSDK(params: UseFacebookSDKParams) {
  const [isSdkLoaded, setIsSdkLoaded] = useState(false);

  const initFacebookSDK = useCallback(async () => {
    const { appId, xfbml, version, ...rest } = params;

    window.fbAsyncInit = function () {
      FB.init({
        appId,
        xfbml,
        version,
        ...rest,
      });

      FB.getLoginStatus((response) => {
        if (response) {
          setIsSdkLoaded(true);
        }
      });
    };

    (function (document, tag, id) {
      let documentScript = document.getElementsByTagName(tag)[0];
      if (document.getElementById(id)) return;
      let fbSdkScript = document.createElement(tag) as HTMLScriptElement;
      fbSdkScript.id = id;
      fbSdkScript.src = 'https://connect.facebook.net/pt_BR/sdk.js';
      fbSdkScript.crossOrigin = 'anonymous';
      fbSdkScript.defer = true;
      documentScript.parentNode.insertBefore(fbSdkScript, documentScript);
    })(document, 'script', 'fb-sdk');
  }, [params]);

  const login = useCallback(
    async (options?: { auth_type?: string; scope?: string }) => {
      if (!isSdkLoaded) return;
      let auth_type = options?.auth_type;
      let scope = options?.scope;

      const { authResponse } = await new Promise<FacebookLoginStatusResponse>((resolve, reject) => {
        FB.login(
          (response) => {
            if (response.authResponse) {
              resolve(response);
            } else {
              reject('O login pelo Facebook foi cancelado ou não foi autorizado.');
            }
          },
          { auth_type, scope },
        );
      });

      const { name, email } = await new Promise<{ name: string; email: string }>(
        (resolve, reject) => FB.api('/me?fields=email,name', resolve),
      );
      return { name, email, authResponse };
    },
    [isSdkLoaded],
  );

  useEffect(() => {
    initFacebookSDK();
  }, [initFacebookSDK]);

  return { isSdkLoaded, login };
}

export default useFacebookSDK;
