import produce from 'immer';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

/* ============== ACTION TYPES ============== */

enum TypesNames {
  SET_KUPPI_BOOST_DATA = 'SET_KUPPI_BOOST_DATA',
  CLEAN_KUPPI_BOOST = 'CLEAN_KUPPI_BOOST',
}

export interface OnSetKuppiBoostData extends Action<TypesNames.SET_KUPPI_BOOST_DATA> {
  facebook_campaign_id: string;
}

export interface OnCleanKuppiBoost extends Action<TypesNames.CLEAN_KUPPI_BOOST> {}

/* ============== ACTION CREATORS AND TYPES ============== */

export const { Types, Creators } = createActions<
  {
    [TypesNames.SET_KUPPI_BOOST_DATA]: string;
    [TypesNames.CLEAN_KUPPI_BOOST]: string;
  },
  {
    setKuppiBoostData: (facebook_campaign_id: string) => OnSetKuppiBoostData;
    cleanKuppiBoost: () => OnCleanKuppiBoost;
  }
>({
  setKuppiBoostData: ['facebook_campaign_id'],
  cleanKuppiBoost: null,
});

export const KuppiBoostTypes = Types;
export default Creators;

/* ============== INITIAL STATE ============== */

export interface KuppiBoostStateType {
  facebook_campaign_id?: string;
}

export const INITIAL_STATE: KuppiBoostStateType = {
  facebook_campaign_id: null,
};

/* ============== REDUCERS ============== */

export const cleanKuppiBoostReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.facebook_campaign_id = null;
  });

export const setKuppiBoostDataReducer = (
  state = INITIAL_STATE,
  { facebook_campaign_id }: OnSetKuppiBoostData,
) =>
  produce(state, (draft) => {
    draft.facebook_campaign_id = facebook_campaign_id;
  });

export const reducer = createReducer<typeof INITIAL_STATE, any>(INITIAL_STATE, {
  [Types.CLEAN_KUPPI_BOOST]: cleanKuppiBoostReducer,
  [Types.SET_KUPPI_BOOST_DATA]: setKuppiBoostDataReducer,
});
