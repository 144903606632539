import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import isArray from 'lodash/isArray';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Address from '~/models/Address';
import AdvertiserDeliveryTakeawayConfig from '~/models/AdvertiserDeliveryTakeawayConfig';
import { advertiserMenu } from '~/routes/routeMap';
import { apiGlobal, apiWebMenu } from '~/services/api';
import AuthActions from '~/store/ducks/auth';
import { getDistanceBetweenAddresses } from '~/utils/location';
import { onlyNumbers } from '~/utils/masks';

import { RootState } from '..';
import AddressActions, {
  AddressesTypes,
  OnDeleteAddress,
  OnFetchAddressByAddressFields,
  OnFetchAddressByLatLng,
  OnFetchAddressByZipcode,
  OnSaveAddress,
  OnSetCurrentAddress,
} from '../ducks/addresses';

export function* fetchAddressByLatLng({ latitude, longitude }: OnFetchAddressByLatLng) {
  try {
    const response: AxiosResponse = yield call(apiGlobal.post, '/get-address', {
      latitude,
      longitude,
    });

    const address = response.data;
    yield put(AddressActions.setFetchedAddress(address));
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(AuthActions.logOut());
      toast.error('Sessão inválida, efetue o login novamente');
    } else {
      yield put(AddressActions.setFetchedAddress(null));
      toast.error('Falha obter seu endereço. Tente novamente.');
    }
  }
}

export function* fetchAddressByAddressFields({
  fields,
  notRedirect,
}: OnFetchAddressByAddressFields) {
  try {
    const formattedAddres = `${fields.street}, ${fields.number} ${fields.neighborhood} ${fields.city} ${fields.state} ${fields.zipcode}`;
    const response: AxiosResponse = yield call(apiGlobal.post, '/get-address', {
      address: formattedAddres,
    });
    const { advertiserInfo } = yield select((state: RootState) => state.advertiser);
    const address = response.data;

    const { latitude, longitude } = address;
    if (latitude && longitude) {
      const advertiserAddress = advertiserInfo.user.address[0];
      const distanceToAdvertiserInMeters = getDistanceBetweenAddresses(address, advertiserAddress);

      const advertiserDeliversInCurrentAddress =
        distanceToAdvertiserInMeters <= advertiserInfo.delivery_range_area * 1000;
      yield put(
        AddressActions.setAdvertiserDeliverInCurrentAddress(advertiserDeliversInCurrentAddress),
      );
      yield put(AddressActions.setFetchedAddress(address));
      if (advertiserDeliversInCurrentAddress) {
        const addressToSave = { ...fields, latitude, longitude, created_at: new Date() };
        yield put(AddressActions.setFetchedAddress(null));
        yield put(AddressActions.saveAddress(addressToSave));
        yield put(AddressActions.setCurrentAddress(addressToSave));

        if (!notRedirect) {
          yield put(push(advertiserMenu));
          toast.success('Endereço de entrega adicionado com sucesso!');
        }
      } else {
        yield put(AddressActions.setFetchedAddress(null));
      }
    }
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(AuthActions.logOut());
      toast.error('Sessão inválida, efetue o login novamente');
    } else {
      yield put(AddressActions.setFetchedAddress(null));
      toast.error('Falha obter seu endereço. Tente novamente.');
    }
  }
}

export function* fetchAddressByZipcode({ zipcode }: OnFetchAddressByZipcode) {
  try {
    const response: AxiosResponse = yield call(apiWebMenu.get, `/query-zipcode?zipcode=${zipcode}`);
    const address = response.data;
    yield put(AddressActions.setFetchedAddress(address));
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(AuthActions.logOut());
      toast.error('Sessão inválida, efetue o login novamente');
    } else {
      toast.error('Não conseguimos encontrar seu endereço. Verifique seu CEP.');
      yield put(AddressActions.setFetchedAddress(null));
    }
  }
}

export function* saveAddress({ address }: OnSaveAddress) {
  try {
    const { user } = yield select((state: RootState) => state.user);
    const deliveryTakeawayConfig: AdvertiserDeliveryTakeawayConfig = yield select(
      (state: RootState) => state.advertiser.advertiserInfo.deliveryTakeawayConfig,
    );

    if (user && user?.id && address) {
      const response: AxiosResponse = yield call(
        apiWebMenu.post,
        `/user/${user?.id}/address`,
        {
          ...address,
          zipcode: address.zipcode ? onlyNumbers(address.zipcode) : null,
          dne_district_id: address.dne_district_id ?? null,
        },
        {
          params: {
            is_using_neighborhood_area: deliveryTakeawayConfig.is_using_neighborhood_area,
          },
        },
      );
      const { addresses, createdAddress } = response.data;
      yield put(AddressActions.setMyAddresses(addresses));
      yield put(AddressActions.setCurrentAddress(createdAddress));
      yield put(AddressActions.setAddressDefault(createdAddress));
    } else {
      yield put(AddressActions.setMyAddresses([]));
    }
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(AuthActions.logOut());
      toast.error('Sessão inválida, efetue o login novamente');
    } else {
      Sentry.captureException(err);
      const errors = err.response?.data;
      if (isArray(errors)) {
        const [error] = errors;
        toast.error(`Falha ao salvar seu endereço: ${error?.message}`);
      } else {
        toast.error(`Falha ao salvar seu endereço: ${err?.message}`);
      }
      yield put(AddressActions.setMyAddresses([]));
    }
  }
}

export function* fetchMyAddresses() {
  try {
    const globalState: RootState = yield select((state: RootState) => state);
    const { advertiserInfo } = globalState.advertiser;
    const { user } = globalState.user;
    const deliveryTakeawayConfig = advertiserInfo.deliveryTakeawayConfig;
    const neighborhoodAreaConfigs = advertiserInfo.neighborhoodAreaConfigs;

    if (user && user?.id) {
      const response: AxiosResponse = yield call(apiWebMenu.get, `/user/${user?.id}/address`, {
        params: {
          is_using_neighborhood_area: deliveryTakeawayConfig.is_using_neighborhood_area,
        },
      });
      const addresses = response.data;

      const defaultAddress = addresses.find((addr: Address) => {
        return addr.is_default ? true : false;
      });

      if (defaultAddress) {
        const advertiserAddress = advertiserInfo.user.address[0];
        let advertiserDeliversInCurrentAddress: boolean;
        if (
          deliveryTakeawayConfig.is_using_neighborhood_area &&
          !deliveryTakeawayConfig.is_using_range_area
        ) {
          const neighborhoodAreaConfig = neighborhoodAreaConfigs.find(
            (n) =>
              n.district?.name?.toLowerCase() === defaultAddress?.neighborhood?.toLowerCase() &&
              n.district?.uf?.toLowerCase() === defaultAddress?.state?.toLowerCase(),
          );
          advertiserDeliversInCurrentAddress = !!neighborhoodAreaConfig;
        } else {
          const distanceToAdvertiserInMeters = getDistanceBetweenAddresses(
            defaultAddress,
            advertiserAddress,
          );

          advertiserDeliversInCurrentAddress =
            distanceToAdvertiserInMeters <= advertiserInfo.delivery_range_area * 1000;
        }

        yield put(
          AddressActions.setAdvertiserDeliverInCurrentAddress(advertiserDeliversInCurrentAddress),
        );
        if (advertiserDeliversInCurrentAddress) {
          yield put(AddressActions.setCurrentAddress(defaultAddress));
        }
      }

      yield put(AddressActions.setMyAddresses(addresses));
    } else {
      yield put(AddressActions.setMyAddresses([]));
    }
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(AuthActions.logOut());
      toast.error('Sessão inválida, efetue o login novamente');
    } else {
      Sentry.captureException(err);
      toast.error(`Falha ao buscar seus endereços: ${err?.message}`);
      yield put(AddressActions.setMyAddresses([]));
    }
  }
}

export function* setAddressDefault({ address }: OnSetCurrentAddress) {
  try {
    const { user } = yield select((state: RootState) => state.user);

    if (user && user?.id) {
      yield call(apiWebMenu.put, `/user/${user?.id}/address/${address.id}`, {
        is_default: true,
      });
    }
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(AuthActions.logOut());
      toast.error('Sessão inválida, efetue o login novamente');
    }
  }
}

export function* deleteAddress({ address }: OnDeleteAddress) {
  try {
    yield call(apiWebMenu.delete, `/user/${address?.user_id}/address/${address?.id}`);

    const globalState: RootState = yield select((state: RootState) => state);
    const { addresses: allAddresses, currentAddress } = globalState.addresses;

    const updatedAddresses = allAddresses.filter((addr) => {
      return addr.id === address?.id ? false : true;
    });

    if (currentAddress?.id === address?.id) {
      yield put(AddressActions.clearCurrentAddress());
    }

    yield put(AddressActions.setMyAddresses(updatedAddresses));
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(AuthActions.logOut());
      toast.error('Sessão inválida, efetue o login novamente');
    }
  }
}

export default all([
  takeLatest(AddressesTypes.FETCH_ADDRESS_BY_LATLNG, fetchAddressByLatLng),
  takeLatest(AddressesTypes.FETCH_MY_ADDRESSES, fetchMyAddresses),
  takeLatest(AddressesTypes.DELETE_ADDRESS, deleteAddress),
  takeLatest(AddressesTypes.SAVE_ADDRESS, saveAddress),
  takeLatest(AddressesTypes.FETCH_ADDRESS_BY_ADDRESS_FIELDS, fetchAddressByAddressFields),
  takeLatest(AddressesTypes.FETCH_ADDRESS_BY_ZIPCODE, fetchAddressByZipcode),
  takeLatest(AddressesTypes.SET_ADDRESS_DEFAULT, setAddressDefault),
]);
