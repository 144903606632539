import {
  Heading,
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import React, { useEffect } from 'react';

// import { useSelector } from 'react-redux';
import Button from '~/components/Button';
// import { RootState } from '~/store';

export interface ModalProps {
  heading?: React.ReactNode;
  message?: React.ReactNode;
  visibility?: boolean;
  triggerYes: () => void;
  triggerNo: () => void;
}

const Modal: React.FC<ModalProps> = ({ heading, message, visibility, triggerNo, triggerYes }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (visibility) {
      onOpen();
    } else {
      onClose();
    }
  }, [visibility, onClose, onOpen]);

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent p={8} borderRadius="2rem">
        <ModalBody>
          <Stack spacing={8} textAlign="center" py={6}>
            <Heading as="h4" fontWeight="700" fontSize="lg">
              {heading
                ? heading
                : 'Cupons de desconto na carteira não podem ser usados junto de outros tipos de cupom.'}
            </Heading>
            <Text fontSize="md" fontWeight="400" textAlign="center">
              {message ? message : 'Deseja trocar de cupom?'}
            </Text>
          </Stack>{' '}
        </ModalBody>

        <ModalFooter justifyContent="flex-start">
          <Button
            variant="ghost"
            flex="1"
            border="1px solid"
            borderColor="gray.700"
            fontWeight="bold"
            mr={4}
            onClick={() => {
              onClose();
              triggerYes();
            }}
          >
            Sim
          </Button>
          <Button
            flex="1"
            fontWeight="bold"
            onClick={() => {
              onClose();
              triggerNo();
            }}
          >
            Não
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
