import produce from 'immer';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import AdvertiserInfo from '~/models/AdvertiserInfo';
import Coupon from '~/models/Coupon';
import LoyaltyCard from '~/models/LoyaltyCard';

/* ============== ACTION TYPES ============== */

enum TypesNames {
  GET_DATA = 'GET_DATA',
  SET_DATA = 'SET_DATA',
  ADVERTISER_NOT_FOUND = 'ADVERTISER_NOT_FOUND',
  FORMAT_DATA = 'FORMAT_DATA',
  FORMAT_BEST_OFFERS = 'FORMAT_BEST_OFFERS',
  SET_ADVERTISER_LOADING = 'SET_ADVERTISER_LOADING',
  GET_BEST_OFFERS = 'GET_BEST_OFFERS',
  SET_BEST_OFFERS = 'SET_BEST_OFFERS',
  SET_BEST_OFFERS_LOADING = 'SET_BEST_OFFERS_LOADING',
  SET_NEXT_ADVERTISER_IS_OPEN_DATE = 'SET_NEXT_ADVERTISER_IS_OPEN_DATE',
  SET_MODAL_ALREADY_BEEN_SHOWN = 'SET_MODAL_ALREADY_BEEN_SHOWN',
  SEND_CAMPAIGN_CLICK = 'SEND_CAMPAIGN_CLICK',
}

export interface OnGetData extends Action<TypesNames.GET_DATA> {
  slug: string;
}

export interface OnSetData extends Action<TypesNames.SET_DATA> {
  advertiserInfo: any;
  advertiserIsOpen: boolean;
  lastRequest: string;
  lastAdvertiser: string;
  loyaltyCard: LoyaltyCard;
}

export interface OnFormatData extends Action<TypesNames.FORMAT_DATA> {
  advertiserInfo: AdvertiserInfo;
}

export interface OnFormatBestOffers extends Action<TypesNames.FORMAT_BEST_OFFERS> {
  bestOffers: Coupon[];
}

export interface OnAdvertiserNotFound extends Action<TypesNames.ADVERTISER_NOT_FOUND> {}

export interface OnSetBestOffersLoading extends Action<TypesNames.SET_BEST_OFFERS_LOADING> {
  loading: boolean;
}

export interface OnGetBestOffers extends Action<TypesNames.GET_BEST_OFFERS> {
  slug: string;
}

export interface OnSetBestOffers extends Action<TypesNames.SET_BEST_OFFERS> {
  advertiserBestOffers: any;
  bestOfferTag: number;
}

export interface OnSetNextAdvertiserIsOpenDate
  extends Action<TypesNames.SET_NEXT_ADVERTISER_IS_OPEN_DATE> {
  date?: Date;
}

export interface OnSetLoading extends Action<TypesNames.SET_ADVERTISER_LOADING> {
  loading: boolean;
}

export interface OnSetModalAlreadyBeenShown
  extends Action<TypesNames.SET_MODAL_ALREADY_BEEN_SHOWN> {}

export interface OnSendCampaignClick extends Action<TypesNames.SEND_CAMPAIGN_CLICK> {
  facebookCampaignId: string;
}

/* ============== ACTION CREATORS AND TYPES ============== */
export const { Types, Creators } = createActions<
  {
    [TypesNames.GET_DATA]: string;
    [TypesNames.SET_DATA]: string;
    [TypesNames.FORMAT_DATA]: string;
    [TypesNames.FORMAT_BEST_OFFERS]: string;
    [TypesNames.ADVERTISER_NOT_FOUND]: string;
    [TypesNames.SET_ADVERTISER_LOADING]: string;
    [TypesNames.SET_BEST_OFFERS_LOADING]: string;
    [TypesNames.GET_BEST_OFFERS]: string;
    [TypesNames.SET_BEST_OFFERS]: string;
    [TypesNames.SET_NEXT_ADVERTISER_IS_OPEN_DATE]: string;
    [TypesNames.SET_MODAL_ALREADY_BEEN_SHOWN]: string;
    [TypesNames.SEND_CAMPAIGN_CLICK]: string;
  },
  {
    getData: (slug: string) => OnGetData;
    setData: (
      advertiserInfo: any,
      advertiserIsOpen: boolean,
      lastRequest: string,
      lastAdvertiser: string,
      loyaltyCard: LoyaltyCard,
    ) => OnSetData;
    advertiserNotFound: () => OnAdvertiserNotFound;
    formatData: (advertiserInfo: any) => OnFormatData;
    formatBestOffers: (bestOffers: any) => OnFormatBestOffers;
    setAdvertiserLoading: (loading: boolean) => OnSetLoading;
    setBestOffersLoading: (loading: boolean) => OnSetBestOffersLoading;
    getBestOffers: (slug: string) => OnGetBestOffers;
    setBestOffers: (advertiserBestOffers: any, bestOfferTag: number) => OnSetBestOffers;
    setNextAdvertiserIsOpenDate: (date?: Date) => OnSetNextAdvertiserIsOpenDate;
    setModalAlreadyBeenShown: () => OnSetModalAlreadyBeenShown;
    sendCampaignClick: (facebookCampaignId: string) => OnSendCampaignClick;
  }
>({
  getData: ['slug'],
  setData: ['advertiserInfo', 'advertiserIsOpen', 'lastRequest', 'lastAdvertiser', 'loyaltyCard'],
  advertiserNotFound: null,
  formatData: ['advertiserInfo'],
  formatBestOffers: ['bestOffers'],
  setAdvertiserLoading: ['loading'],
  setBestOffersLoading: ['loading'],
  getBestOffers: ['slug'],
  setBestOffers: ['advertiserBestOffers', 'bestOfferTag'],
  setNextAdvertiserIsOpenDate: ['date'],
  setModalAlreadyBeenShown: null,
  sendCampaignClick: ['facebookCampaignId'],
});

export const AdvertiserTypes = Types;
export default Creators;

/* ============== INITIAL STATE ============== */
export interface AdvertiserStateType {
  advertiserInfo?: AdvertiserInfo;
  advertiserBestOffers?: Coupon[];
  bestOfferTag?: number;
  lastAdvertiser?: string;
  lastRequest?: string;
  advertiserIsOpen?: boolean;
  nextAdvertiserIsOpenDate?: Date;
  loading: boolean;
  bestOffersLoading: boolean;
  loyaltyCard: LoyaltyCard;
  modalLoyaltyCardAlreadyBeenShown: boolean;
  imageTimestamp: number;
}

export const INITIAL_STATE: AdvertiserStateType = {
  advertiserInfo: null,
  advertiserBestOffers: null,
  bestOfferTag: null,
  lastRequest: null,
  lastAdvertiser: null,
  advertiserIsOpen: false,
  nextAdvertiserIsOpenDate: null,
  loading: false,
  bestOffersLoading: false,
  loyaltyCard: null,
  modalLoyaltyCardAlreadyBeenShown: false,
  imageTimestamp: new Date().getTime(),
};

/* ============== REDUCERS ============== */

export const setDataReducer = (
  state = INITIAL_STATE,
  { advertiserInfo, advertiserIsOpen, lastRequest, lastAdvertiser, loyaltyCard }: OnSetData,
) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.advertiserIsOpen = advertiserIsOpen;
    draft.advertiserInfo = advertiserInfo;
    draft.lastRequest = lastRequest;
    draft.lastAdvertiser = lastAdvertiser;
    draft.loyaltyCard = loyaltyCard;
    draft.imageTimestamp = advertiserInfo.images_timestamp
      ? advertiserInfo.images_timestamp
      : new Date().getTime();
  });

export const setAdvertiserLoadingReducer = (state = INITIAL_STATE, { loading }: OnSetLoading) =>
  produce(state, (draft) => {
    draft.loading = loading;
  });

export const advertiserNotFoundReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.advertiserInfo = null;
    draft.advertiserBestOffers = null;
    draft.bestOfferTag = null;
    draft.lastRequest = null;
    draft.lastAdvertiser = null;
    draft.advertiserIsOpen = false;
    draft.loading = false;
    draft.bestOffersLoading = false;
    draft.imageTimestamp = new Date().getTime();
  });

export const setBestOffersLoadingReducer = (
  state = INITIAL_STATE,
  { loading }: OnSetBestOffersLoading,
) =>
  produce(state, (draft) => {
    draft.bestOffersLoading = loading;
  });

export const setBestOffersReducer = (
  state = INITIAL_STATE,
  { advertiserBestOffers, bestOfferTag }: OnSetBestOffers,
) =>
  produce(state, (draft) => {
    draft.advertiserBestOffers = advertiserBestOffers;
    draft.bestOfferTag = bestOfferTag;
    draft.bestOffersLoading = false;
  });

export const setNextAdvertiserIsOpenDateReducer = (
  state = INITIAL_STATE,
  { date }: OnSetNextAdvertiserIsOpenDate,
) =>
  produce(state, (draft) => {
    draft.nextAdvertiserIsOpenDate = date;
  });

export const setModalAlreadyBeenShownReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.modalLoyaltyCardAlreadyBeenShown = true;
  });

export const reducer = createReducer<typeof INITIAL_STATE, any>(INITIAL_STATE, {
  [Types.SET_DATA]: setDataReducer,
  [Types.ADVERTISER_NOT_FOUND]: advertiserNotFoundReducer,
  [Types.SET_ADVERTISER_LOADING]: setAdvertiserLoadingReducer,
  [Types.SET_BEST_OFFERS_LOADING]: setBestOffersLoadingReducer,
  [Types.SET_BEST_OFFERS]: setBestOffersReducer,
  [Types.SET_NEXT_ADVERTISER_IS_OPEN_DATE]: setNextAdvertiserIsOpenDateReducer,
  [Types.SET_MODAL_ALREADY_BEEN_SHOWN]: setModalAlreadyBeenShownReducer,
});
