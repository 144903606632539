import { Box, Spinner } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import useSlug from '~/hooks/useSlug';
import Coupon from '~/models/Coupon';
import Product from '~/models/Product';
import ProductComplementCategory from '~/models/ProductComplementCategory';
import ProductComplementOption from '~/models/ProductComplementOption';
import SelectedComplement from '~/models/SelectedComplement';
import { advertiserMenu, bag } from '~/routes/routeMap';
import { RootState } from '~/store';
import { Creators as BagCreators } from '~/store/ducks/bag';
import { Creators as OrderCreators } from '~/store/ducks/order';

const { addToBag, cleanBag } = BagCreators;
const { getMyOrder, setMyOrder } = OrderCreators;

const ReOrder: React.FC = () => {
  const { id_pedido: id } = useParams<any>();
  const { order } = useSelector((state: RootState) => state.order);
  const { advertiserInfo } = useSelector((state: RootState) => state.advertiser);
  const slug = useSlug();

  const dispatch = useDispatch();

  useEffect(() => {
    if (slug && id) {
      dispatch(getMyOrder(id, slug));
    }
  }, [slug]);

  useEffect(() => {
    if (order && Number(order?.id) === Number(id)) {
      dispatch(cleanBag());

      const allAdvertiserProducts = advertiserInfo?.productCategory?.flatMap(
        (category) => category.products,
      );

      const orderProducts = order?.orderProduct;
      const orderCoupons = order?.orderCoupon?.map(
        (orderCoupon) => orderCoupon.userCoupon?.coupon as Coupon,
      );

      const orderProductsIDs = orderProducts.map((product: any) => product.product_id);
      const selectedProducts = allAdvertiserProducts.filter((product) =>
        orderProductsIDs.includes(product.id),
      );

      if (selectedProducts.length === 0) {
        toast.error(
          'Todos os produtos deste pedido não estão disponíveis. O pedido não pode ser refeito.',
        );
        dispatch(push(advertiserMenu));
        return;
      } else if (orderProducts.length > selectedProducts.length) {
        toast.info('Alguns produtos não estão disponíveis. Revise a sacola antes de prosseguir.', {
          autoClose: false,
        });
      }

      orderProducts.forEach((orderProduct: any) => {
        const product = selectedProducts.find(
          (selectedProduct) => selectedProduct?.id === orderProduct?.product?.id,
        );

        if (!product || !product?.isAvailable) return;

        const { orderProductComplement } = orderProduct as any;

        // Order Complements
        const orderComplements = orderProductComplement.map((orderProductComplement: any) => ({
          id: orderProductComplement.product_complement_option_id,
          quantity: orderProductComplement.quantity,
          name: orderProductComplement.productComplementOption.name,
          amount: orderProductComplement.productComplementOption.amount,
          category: orderProductComplement.productComplementOption.product_complement_category_id,
        }));

        const { productComplementCategories } = product;

        const productComplements = productComplementCategories.map(
          (category: ProductComplementCategory) => {
            const options = category.productComplementOptions.map(
              (option: ProductComplementOption) => {
                const orderComplement = orderComplements.find(
                  (orderComplement: any) => orderComplement.id === option.id,
                );

                if (option.id === orderComplement?.id) {
                  return {
                    id: option.id,
                    name: option.name,
                    description: option.description,
                    amount: option.amount,
                    is_selected: category.max_qty === 1,
                    quantity: orderComplement.quantity,
                  };
                }

                return {
                  id: option.id,
                  name: option.name,
                  description: option.description,
                  amount: option.amount,
                  is_selected: false,
                  quantity: 0,
                };
              },
            );

            return {
              id: category.id,
              min_qty: category.min_qty,
              max_qty: category.max_qty,
              name: category.name,
              is_required: category.is_required,
              is_base_amount_more_expensive: category.is_base_amount_more_expensive,
              options: options,
            };
          },
        );

        const productWithSelectedComplements: Product = {
          ...product,
        };

        const complementsTotalPrice = calculateComplementsTotalPrice(productComplements);

        const coupon = orderCoupons.find(
          (orderCoupon: any) => orderCoupon.id === product.coupon?.id,
        );
        if (coupon) {
          productWithSelectedComplements.coupon = coupon;
        }

        const basePrice =
          coupon && product.isCouponAvailable ? coupon.couponBenefit.by_value : product.amount;

        productWithSelectedComplements.selectedComplements = productComplements;
        productWithSelectedComplements.productQuantity = orderProduct.quantity;
        productWithSelectedComplements.obs = orderProduct.obs;
        productWithSelectedComplements.priceNoDiscountWithComplements =
          product?.amount + complementsTotalPrice;
        productWithSelectedComplements.priceWithComplements = basePrice + complementsTotalPrice;

        const isFullFiled = checkAllRequiredComplements(productComplements);

        if (isFullFiled) {
          dispatch(
            addToBag({
              bagId: orderProduct.id,
              ...productWithSelectedComplements,
            }),
          );
        } else {
          toast.error('Pedido não pode ser refeito.');
        }
      });
      dispatch(setMyOrder(null));

      dispatch(push(bag));
    }
  }, [order]);

  const checkAllRequiredComplements = useCallback((complements: SelectedComplement[]) => {
    // Verificar se todos os complementos obrigatórios foram selecionados
    const isRequiredComplementsFullfiled = complements
      ?.filter((c) => c.is_required)
      .every((c) => {
        let optionsSum = 0;
        c.options?.forEach((o) => {
          optionsSum += o.quantity;
        });
        return c.options.some((o) => o.is_selected || optionsSum >= c.min_qty);
      });

    return isRequiredComplementsFullfiled;
  }, []);

  const calculateComplementsTotalPrice = (selectedComplements: SelectedComplement[]) => {
    // Calcular preço dos complementos
    let complementsTotalPrice = 0;
    selectedComplements?.forEach((c) => {
      if (c.is_base_amount_more_expensive) {
        let arrAmountOptions = c.options?.map((o) => {
          return o.quantity > 0 && o.amount;
        });
        let moreExpensiveOption = Math.max.apply(null, arrAmountOptions);

        c.options.forEach((o) => {
          complementsTotalPrice += moreExpensiveOption * o.quantity;
        });
      } else {
        c.options.forEach((o) => {
          // TO-DO: Remove is_selected prop, to simplify logic of complement addition and calc
          if (o.is_selected && o.amount) {
            complementsTotalPrice += o.amount;
          } else if (o.quantity && o.amount) {
            complementsTotalPrice += o.amount * o.quantity;
          }
        });
      }
    });

    return complementsTotalPrice;
  };

  return (
    <Box h="100%" w="100%" d="flex" alignItems="center" justifyContent="center">
      <Spinner size="xl" color="#01F43A" />
    </Box>
  );
};

export default ReOrder;
