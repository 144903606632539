import { InputProps as ChakraInputProps } from '@chakra-ui/core';
import React from 'react';

import { CustonInput } from './styles';

interface CustonInputProps {
  register?: any;
}

export type InputProps = ChakraInputProps & CustonInputProps;

const Input: React.FC<InputProps> = ({
  w = '100%',
  h = '50px',
  background = '#EFEFEF 0% 0% no-repeat padding-box',
  borderRadius = '10px',
  p = '20px',
  paddingLeft,
  border = '2px solid',
  borderColor = '#EFEFEF',
  _focus = {
    borderColor: 'green.400',
    boxShadow: '0px 0px 12px #349B2C40',
  },
  register,
  ...rest
}) => {
  return (
    <CustonInput
      p={p}
      w={w}
      h={h}
      border={border}
      borderColor={borderColor}
      _focus={_focus}
      background={background}
      borderRadius={borderRadius}
      ref={register}
      {...rest}
    />
  );
};

export default Input;
