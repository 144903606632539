import {
  Box,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import BottomBar from '~/components/BottomBar';
import Button from '~/components/Button';
import Input from '~/components/Form/Input';
import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import { CreateAddressDTO } from '~/models/Address';
import confirmAddressSchema from '~/models/schemas/confirmAddressSchema';
import { zipcodeMask } from '~/utils/masks';

interface ConfirmAddressFields {
  number?: string;
  complement?: string;
  neighborhood?: string;
  zipcode?: string;
}
interface ConfirmCurrentLocationProps {
  foundAddress: CreateAddressDTO;
  loading: boolean;
  onCloseBottomBar: () => void;
  onSubmitCurrentLocation: (data: ConfirmAddressFields) => Promise<void> | void;
}

const ConfirmCurrentLocation: React.FC<ConfirmCurrentLocationProps> = ({
  foundAddress,
  onCloseBottomBar,
  onSubmitCurrentLocation,
  loading = false,
}) => {
  const { register, errors, handleSubmit, setValue } = useForm<ConfirmAddressFields>({
    resolver: useYupValidationResolver(confirmAddressSchema()),
  });
  const [hiddenFields, setHiddenFields] = useState<Array<string>>([]);
  const isFieldHidden = useCallback(
    (field: string) => {
      return hiddenFields.includes(field);
    },
    [hiddenFields],
  );

  useEffect(() => {
    setValue('zipcode', foundAddress?.zipcode);
    setValue('neighborhood', foundAddress?.neighborhood);
    setValue('number', foundAddress?.number);

    if (foundAddress?.zipcode?.trim() === '') {
      setHiddenFields((prev) => [...prev, 'zipcode']);
    } else {
      setHiddenFields((prev) => prev.filter((field) => field !== 'zipcode'));
    }
  }, [foundAddress, setValue]);

  return (
    <BottomBar isOverlayVisible onClose={onCloseBottomBar} py={8}>
      <Stack
        as="form"
        direction="column"
        spacing={8}
        onSubmit={handleSubmit(onSubmitCurrentLocation)}
      >
        <Heading as="h4" fontSize="md" fontWeight="500" color="gray.600" textAlign="center">
          Confirme seu endereço
        </Heading>
        <Box p={4} w="100%" d="flex" flexDir="column" alignItems="center">
          <Text fontSize="md" fontWeight="700" mb={2} color="gray.600">
            {foundAddress?.street}
            {foundAddress?.number ? `, ${foundAddress?.number}` : ''}
            {foundAddress?.neighborhood ? ` – ${foundAddress?.neighborhood}` : ''}
          </Text>
          <Text fontSize="md" color="gray.400" mb={2}>
            {foundAddress?.city}, {foundAddress?.state}
          </Text>
        </Box>

        <Box d="flex" mb={4}>
          <FormControl mr={4} isInvalid={!!errors?.number} w="100%">
            <FormLabel fontSize="1.5rem" fontWeight="bold" htmlFor="number">
              Número
            </FormLabel>
            <InputGroup w="100%">
              <Input register={register} placeholder="Número" name="number" id="number" />
              {!!errors?.number && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors?.number?.message}
                    bg="red.400"
                    zIndex={1500}
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
          <FormControl isInvalid={!!errors?.neighborhood} w="100%">
            <FormLabel fontSize="1.5rem" fontWeight="bold" htmlFor="neighborhood">
              Bairro
            </FormLabel>
            <InputGroup>
              <Input
                register={register}
                placeholder="Bairro"
                name="neighborhood"
                id="neighborhood"
              />
              {!!errors?.neighborhood && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors?.neighborhood?.message}
                    bg="red.400"
                    zIndex={1500}
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
        </Box>
        <Box d="flex">
          <FormControl
            isInvalid={!!errors?.complement}
            mr={!isFieldHidden('zipcode') && 4}
            w="100%"
          >
            <FormLabel fontSize="1.5rem" fontWeight="bold" htmlFor="complement">
              Complemento
            </FormLabel>
            <InputGroup>
              <Input
                register={register}
                placeholder="Complemento"
                name="complement"
                id="complement"
              />
              {!!errors?.complement && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors?.complement?.message}
                    bg="red.400"
                    zIndex={1500}
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
          <FormControl
            isInvalid={!!errors?.zipcode}
            d={isFieldHidden('zipcode') && 'none'}
            w="100%"
          >
            <FormLabel fontSize="1.5rem" fontWeight="bold" htmlFor="zipcode">
              CEP
            </FormLabel>
            <InputGroup>
              <Input
                register={register}
                placeholder="CEP"
                name="zipcode"
                id="zipcode"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValue('zipcode', zipcodeMask(e.target.value, true))
                }
              />
              {!!errors.zipcode && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors?.zipcode?.message}
                    bg="red.400"
                    zIndex={1500}
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
        </Box>
        <Button type="submit" isLoading={loading} isDisabled={loading}>
          Buscar endereço
        </Button>
      </Stack>
    </BottomBar>
  );
};

export default ConfirmCurrentLocation;
