import produce from 'immer';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import BagDiscounts, { ActiveCouponType } from '~/models/BagDiscounts';
import Coupon from '~/models/Coupon';
import Product from '~/models/Product';
import DeliveryType from '~/models/types/DeliveryType';
import { recalcBagAmounts, validatePaymentMethodAvailableForDiscount } from '~/utils/bag';

/* ============== ACTION TYPES ============== */

enum TypesNames {
  ADD_TO_BAG = 'ADD_TO_BAG',
  ADD_DISCOUNT_IN_BAG = 'ADD_DISCOUNT_IN_BAG',
  EDDITING_TO_BAG = 'EDDITING_TO_BAG',
  REMOVING_FROM_THE_BAG = 'REMOVING_FROM_THE_BAG',
  CLEAN_BAG = 'CLEAN_BAG',
  CLEAN_ACTIVE_DISCOUNT = 'CLEAN_ACTIVE_DISCOUNT',
  SET_ORDER_TYPE = 'SET_ORDER_TYPE',
  CHECK_PRODUCT_AVAILABILITY_REQUEST = 'CHECK_PRODUCT_AVAILABILITY_REQUEST',
  CHECK_PRODUCT_AVAILABILITY_SUCCESS = 'CHECK_PRODUCT_AVAILABILITY_SUCCESS',
  CHECK_PRODUCT_AVAILABILITY_FAILURE = 'CHECK_PRODUCT_AVAILABILITY_FAILURE',
  SET_BAG_DISCOUNT_ACTIVE_COUPON = 'SET_BAG_DISCOUNT_ACTIVE_COUPON',
  REMOVE_CODE_COUPON_DISCOUNT = 'REMOVE_CODE_COUPON_DISCOUNT',
  VALIDATE_CODE_COUPON_REQUEST = 'VALIDATE_CODE_COUPON_REQUEST',
  VALIDATE_CODE_COUPON_SUCCESS = 'VALIDATE_CODE_COUPON_SUCCESS',
  VALIDATE_CODE_COUPON_FAILURE = 'VALIDATE_CODE_COUPON_FAILURE',
}

export interface OnAddToBag extends Action<TypesNames.ADD_TO_BAG> {
  product: Product;
  oldProductQuantity: number;
}

export interface OnAddDiscountInBag extends Action<TypesNames.ADD_DISCOUNT_IN_BAG> {
  coupon: Coupon;
  notApplyDiscount: boolean;
}

export interface OnEdditingToBag extends Action<TypesNames.EDDITING_TO_BAG> {
  product: Product;
}

export interface OnRemovingFromTheBag extends Action<TypesNames.REMOVING_FROM_THE_BAG> {
  productBagId: number;
}

export interface OnCleanBag extends Action<TypesNames.CLEAN_BAG> {}

export interface OnCleanActiveDiscount extends Action<TypesNames.CLEAN_ACTIVE_DISCOUNT> {}

export interface OnSetOrderType extends Action<TypesNames.SET_ORDER_TYPE> {
  deliveryType: DeliveryType;
}

export interface OnCheckProductAvailabilityResquest
  extends Action<TypesNames.CHECK_PRODUCT_AVAILABILITY_REQUEST> {
  product: Product;
}

export interface OnCheckProductAvailabilitySuccess
  extends Action<TypesNames.CHECK_PRODUCT_AVAILABILITY_SUCCESS> {}

export interface OnCheckProductAvailabilityFailure
  extends Action<TypesNames.CHECK_PRODUCT_AVAILABILITY_FAILURE> {}

export interface OnSetBagDiscountActiveCoupon
  extends Action<TypesNames.SET_BAG_DISCOUNT_ACTIVE_COUPON> {
  activeCoupon: ActiveCouponType;
}

export interface OnRemoveCodeCouponDiscount
  extends Action<TypesNames.REMOVE_CODE_COUPON_DISCOUNT> {}

export interface OnValidateCodeCouponRequest
  extends Action<TypesNames.VALIDATE_CODE_COUPON_REQUEST> {
  code: string;
}

export interface OnValidateCodeCouponSuccess
  extends Action<TypesNames.VALIDATE_CODE_COUPON_SUCCESS> {
  codeCoupon?: Coupon;
  errorMsg?: string;
}

export interface OnValidateCodeCouponFailure
  extends Action<TypesNames.VALIDATE_CODE_COUPON_FAILURE> {}

/* ============== ACTION CREATORS AND TYPES ============== */

export const { Types, Creators } = createActions<
  {
    [TypesNames.ADD_TO_BAG]: string;
    [TypesNames.EDDITING_TO_BAG]: string;
    [TypesNames.REMOVING_FROM_THE_BAG]: string;
    [TypesNames.ADD_DISCOUNT_IN_BAG]: string;
    [TypesNames.CLEAN_BAG]: string;
    [TypesNames.CLEAN_ACTIVE_DISCOUNT]: string;
    [TypesNames.SET_ORDER_TYPE]: string;
    [TypesNames.CHECK_PRODUCT_AVAILABILITY_REQUEST]: string;
    [TypesNames.CHECK_PRODUCT_AVAILABILITY_SUCCESS]: string;
    [TypesNames.CHECK_PRODUCT_AVAILABILITY_FAILURE]: string;
    [TypesNames.SET_BAG_DISCOUNT_ACTIVE_COUPON]: string;
    [TypesNames.REMOVE_CODE_COUPON_DISCOUNT]: string;
    [TypesNames.VALIDATE_CODE_COUPON_REQUEST]: string;
    [TypesNames.VALIDATE_CODE_COUPON_SUCCESS]: string;
    [TypesNames.VALIDATE_CODE_COUPON_FAILURE]: string;
  },
  {
    addToBag: (product: Product) => OnAddToBag;
    edditingToBag: (product: Product, oldProductQuantity: number) => OnEdditingToBag;
    removingFromTheBag: (productBagId: number) => OnRemovingFromTheBag;
    addDiscountInBag: (coupon: Coupon, notApplyDiscount?: boolean) => OnAddDiscountInBag;
    cleanBag: () => OnCleanBag;
    cleanActiveDiscount: () => OnCleanActiveDiscount;
    setOrderType: (deliveryType: DeliveryType) => OnSetOrderType;
    checkProductAvailabilityRequest: (product: object) => OnCheckProductAvailabilityResquest;
    checkProductAvailabilitySuccess: () => OnCheckProductAvailabilitySuccess;
    checkProductAvailabilityFailure: () => OnCheckProductAvailabilityFailure;
    setBagDiscountActiveCoupon: (activeCoupon: ActiveCouponType) => OnSetBagDiscountActiveCoupon;
    removeCodeCouponDiscount: () => OnRemoveCodeCouponDiscount;
    validateCodeCouponRequest: (
      code: string,
      orderSummaryDataLocation: {},
    ) => OnValidateCodeCouponRequest;
    validateCodeCouponSuccess: (
      codeCoupon?: Coupon,
      errorMsg?: string,
    ) => OnValidateCodeCouponSuccess;
    validateCodeCouponFailure: () => OnValidateCodeCouponFailure;
  }
>({
  addToBag: ['product'],
  edditingToBag: ['product', 'oldProductQuantity'],
  removingFromTheBag: ['productBagId'],
  addDiscountInBag: ['coupon', 'notApplyDiscount'],
  cleanBag: null,
  cleanActiveDiscount: null,
  setOrderType: ['deliveryType'],
  checkProductAvailabilityRequest: ['product'],
  checkProductAvailabilitySuccess: null,
  checkProductAvailabilityFailure: null,
  setBagDiscountActiveCoupon: ['activeCoupon', 'deliveryFee'],
  removeCodeCouponDiscount: null,
  validateCodeCouponRequest: ['code', 'orderSummaryDataLocation'],
  validateCodeCouponSuccess: ['codeCoupon', 'errorMsg'],
  validateCodeCouponFailure: null,
});

export const BagTypes = Types;
export default Creators;

/* ============== INITIAL STATE ============== */

export interface BagStateType {
  sizeBag?: number;
  itemsInBag?: Product[];
  bagDiscounts?: BagDiscounts;
  priceWithDiscount?: number;
  priceWithoutDiscount?: number;
  checkProductAvailabilityLoader?: boolean;
  deliveryType: DeliveryType;
  validateCodeCouponLoading: boolean;
  codeCouponValidationStatus?: string;
  codeCouponValidated: boolean;
}

export const INITIAL_STATE: BagStateType = {
  sizeBag: 0,
  itemsInBag: [],
  bagDiscounts: {
    activeCoupon: null,
    paymentMethods: {
      isAcceptMoney: true,
      isAcceptCard: true,
      isAcceptPix: true,
    },
    discounts: {
      code: [],
      loyalty: [],
      cart: [],
      product: [],
    },
  },
  priceWithDiscount: null,
  priceWithoutDiscount: null,
  deliveryType: null,
  checkProductAvailabilityLoader: null,
  validateCodeCouponLoading: false,
  codeCouponValidationStatus: '',
  codeCouponValidated: false,
};

/* ============== REDUCERS ============== */

export const addToBagReducer = (state = INITIAL_STATE, { product }: OnAddToBag) =>
  produce(state, (draft) => {
    draft.itemsInBag.push(product);

    let productDiscount = [...state.bagDiscounts.discounts['product']];
    if (product.coupon && product.isCouponAvailable) {
      for (let i = 1; i <= product.productQuantity; i++) {
        productDiscount.push(product.coupon);
        draft.bagDiscounts.activeCoupon = 'product';
        draft.bagDiscounts.discounts['product'] = [...productDiscount];
      }
    }

    const calculedData = recalcBagAmounts(
      draft.itemsInBag,
      draft.bagDiscounts.discounts[draft.bagDiscounts.activeCoupon],
    );
    draft.sizeBag = calculedData.sizeBag;
    draft.priceWithoutDiscount = calculedData.priceWithoutDiscount;
    draft.priceWithDiscount = calculedData.priceWithDiscount;

    const availablePaymentMethods = validatePaymentMethodAvailableForDiscount(draft.bagDiscounts);
    draft.bagDiscounts.paymentMethods = availablePaymentMethods;
  });

export const edditingToBagReducer = (
  state = INITIAL_STATE,
  { product, oldProductQuantity }: OnAddToBag,
) =>
  produce(state, (draft) => {
    const itemsWithoutEdditedItem: Product[] = draft.itemsInBag.filter(
      (item) => !(item.bagId === product.bagId),
    );
    itemsWithoutEdditedItem.push(product);
    draft.itemsInBag = itemsWithoutEdditedItem;

    let productDiscount = [...state.bagDiscounts.discounts['product']];
    if (product.coupon && product.isCouponAvailable) {
      for (let i = 1; i <= oldProductQuantity; i++) {
        const removeCouponIdx = productDiscount.findIndex(
          (coupon) => coupon.id === product.coupon.id,
        );
        productDiscount.splice(removeCouponIdx, 1);
      }
      for (let i = 1; i <= product.productQuantity; i++) {
        productDiscount.push(product.coupon);
        draft.bagDiscounts.activeCoupon = 'product';
        draft.bagDiscounts.discounts['product'] = [...productDiscount];
      }
    }

    const calculedData = recalcBagAmounts(
      draft.itemsInBag,
      draft.bagDiscounts.discounts[draft.bagDiscounts.activeCoupon],
    );
    draft.sizeBag = calculedData.sizeBag;
    draft.priceWithoutDiscount = calculedData.priceWithoutDiscount;
    draft.priceWithDiscount = calculedData.priceWithDiscount;

    const availablePaymentMethods = validatePaymentMethodAvailableForDiscount(draft.bagDiscounts);
    draft.bagDiscounts.paymentMethods = availablePaymentMethods;
  });

export const removingFromTheBagReducer = (
  state = INITIAL_STATE,
  { productBagId }: OnRemovingFromTheBag,
) =>
  produce(state, (draft) => {
    let removeCouponId: number;
    let removeCouponQty: number;
    const itemsWithoutEdditedItem: any = draft.itemsInBag.filter((item) => {
      if (item.bagId === productBagId) {
        removeCouponId = item.coupon?.id;
        removeCouponQty = item.productQuantity;
        return false;
      }
      return true;
    });
    draft.itemsInBag = itemsWithoutEdditedItem;

    let productDiscount = [...state.bagDiscounts.discounts['product']];
    if (removeCouponId) {
      for (let i = 1; i <= removeCouponQty; i++) {
        const removeCouponIdx = productDiscount.findIndex((coupon) => coupon.id === removeCouponId);
        productDiscount.splice(removeCouponIdx, 1);
        draft.bagDiscounts.discounts['product'] = [...productDiscount];
      }

      if (productDiscount.length === 0) {
        draft.bagDiscounts.activeCoupon = null;
      }
    }

    const calculedData = recalcBagAmounts(
      draft.itemsInBag,
      draft.bagDiscounts.discounts[draft.bagDiscounts.activeCoupon],
    );
    draft.sizeBag = calculedData.sizeBag;
    draft.priceWithoutDiscount = calculedData.priceWithoutDiscount;
    draft.priceWithDiscount = calculedData.priceWithDiscount;

    const availablePaymentMethods = validatePaymentMethodAvailableForDiscount(draft.bagDiscounts);
    draft.bagDiscounts.paymentMethods = availablePaymentMethods;
  });

export const addDiscountInBagReducer = (
  state = INITIAL_STATE,
  { coupon, notApplyDiscount }: OnAddDiscountInBag,
) =>
  produce(state, (draft) => {
    let discountType: ActiveCouponType;
    switch (coupon.mode) {
      case 'code':
        discountType = 'code';
        draft.bagDiscounts.discounts[discountType] = [coupon];
        break;
      case 'normal':
      case 'flash':
        if (coupon.type === 'from_value_to_value') {
          discountType = 'product';
          draft.bagDiscounts.discounts[discountType].push(coupon);
        } else if (['percentage_cart', 'fixed_cart'].includes(coupon.type)) {
          discountType = coupon.user_loyalty_card_id === null ? 'cart' : 'loyalty';
          draft.bagDiscounts.discounts[discountType] = [coupon];
        }
        break;
      default:
        break;
    }

    if (!notApplyDiscount) {
      draft.bagDiscounts.activeCoupon = discountType;

      const calculedData = recalcBagAmounts(
        draft.itemsInBag,
        draft.bagDiscounts.discounts[discountType],
      );
      draft.sizeBag = calculedData.sizeBag;
      draft.priceWithoutDiscount = calculedData.priceWithoutDiscount;
      draft.priceWithDiscount = calculedData.priceWithDiscount;

      const availablePaymentMethods = validatePaymentMethodAvailableForDiscount(draft.bagDiscounts);
      draft.bagDiscounts.paymentMethods = availablePaymentMethods;
    }
  });

export const cleanBagReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.sizeBag = 0;
    draft.itemsInBag = [];
    draft.bagDiscounts = {
      activeCoupon: null,
      paymentMethods: {
        isAcceptMoney: true,
        isAcceptCard: true,
        isAcceptPix: true,
      },
      discounts: {
        code: [],
        loyalty: [],
        cart: [],
        product: [],
      },
    };
    draft.priceWithoutDiscount = null;
    draft.priceWithDiscount = null;
    draft.deliveryType = null;
    draft.validateCodeCouponLoading = false;
    draft.codeCouponValidationStatus = null;
    draft.codeCouponValidated = false;
  });

export const cleanActiveDiscountReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.bagDiscounts = {
      ...draft.bagDiscounts,
      activeCoupon: null,
      paymentMethods: {
        isAcceptMoney: true,
        isAcceptCard: true,
        isAcceptPix: true,
      },
    };

    const calculedData = recalcBagAmounts(
      draft.itemsInBag,
      draft.bagDiscounts.discounts[draft.bagDiscounts.activeCoupon],
    );
    draft.sizeBag = calculedData.sizeBag;
    draft.priceWithoutDiscount = calculedData.priceWithoutDiscount;
    draft.priceWithDiscount = calculedData.priceWithDiscount;
  });

export const setOrderTypeReducer = (state = INITIAL_STATE, { deliveryType }: OnSetOrderType) =>
  produce(state, (draft) => {
    draft.deliveryType = deliveryType;
  });

export const checkProductAvailabilityRequest = (
  state = INITIAL_STATE,
  { product }: OnCheckProductAvailabilityResquest,
) =>
  produce(state, (draft) => {
    draft.checkProductAvailabilityLoader = true;
  });

export const checkProductAvailabilitySuccess = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.checkProductAvailabilityLoader = false;
  });

export const checkProductAvailabilityFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.checkProductAvailabilityLoader = false;
  });

export const setBagDiscountActiveCouponReducer = (
  state = INITIAL_STATE,
  { activeCoupon }: OnSetBagDiscountActiveCoupon,
) =>
  produce(state, (draft) => {
    draft.bagDiscounts.activeCoupon = activeCoupon;
    draft.codeCouponValidationStatus = null;

    const calculedData = recalcBagAmounts(
      draft.itemsInBag,
      draft.bagDiscounts.discounts[activeCoupon],
    );
    draft.sizeBag = calculedData.sizeBag;
    draft.priceWithoutDiscount = calculedData.priceWithoutDiscount;
    draft.priceWithDiscount = calculedData.priceWithDiscount;

    const availablePaymentMethods = validatePaymentMethodAvailableForDiscount(draft.bagDiscounts);
    draft.bagDiscounts.paymentMethods = availablePaymentMethods;
  });

export const removeCodeCouponDiscountReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.bagDiscounts.discounts['code'] = [];
    draft.validateCodeCouponLoading = false;
    draft.codeCouponValidationStatus = null;
    draft.codeCouponValidated = false;

    const calculedData = recalcBagAmounts(
      draft.itemsInBag,
      draft.bagDiscounts.discounts[draft.bagDiscounts.activeCoupon],
    );
    draft.sizeBag = calculedData.sizeBag;
    draft.priceWithoutDiscount = calculedData.priceWithoutDiscount;
    draft.priceWithDiscount = calculedData.priceWithDiscount;
  });

export const validateCodeCouponRequestReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.validateCodeCouponLoading = true;
    draft.codeCouponValidationStatus = null;
    draft.codeCouponValidated = false;
  });

export const validateCodeCouponSuccessReducer = (
  state = INITIAL_STATE,
  { codeCoupon, errorMsg }: OnValidateCodeCouponSuccess,
) =>
  produce(state, (draft) => {
    draft.validateCodeCouponLoading = false;
    draft.codeCouponValidationStatus = errorMsg;

    if (codeCoupon) {
      draft.codeCouponValidated = true;
      let codeDiscount = [...state.bagDiscounts.discounts['code']];
      codeDiscount.push(codeCoupon);
      draft.bagDiscounts.activeCoupon = 'code';
      draft.bagDiscounts.discounts['code'] = [...codeDiscount];

      const calculedData = recalcBagAmounts(draft.itemsInBag, codeDiscount);
      draft.sizeBag = calculedData.sizeBag;
      draft.priceWithoutDiscount = calculedData.priceWithoutDiscount;
      draft.priceWithDiscount = calculedData.priceWithDiscount;

      const availablePaymentMethods = validatePaymentMethodAvailableForDiscount(draft.bagDiscounts);
      draft.bagDiscounts.paymentMethods = availablePaymentMethods;
    }
  });

export const validateCodeCouponFailureReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.validateCodeCouponLoading = false;
    draft.codeCouponValidated = false;
  });

export const reducer = createReducer<typeof INITIAL_STATE, any>(INITIAL_STATE, {
  [Types.ADD_TO_BAG]: addToBagReducer,
  [Types.EDDITING_TO_BAG]: edditingToBagReducer,
  [Types.REMOVING_FROM_THE_BAG]: removingFromTheBagReducer,
  [Types.ADD_DISCOUNT_IN_BAG]: addDiscountInBagReducer,
  [Types.SET_ORDER_TYPE]: setOrderTypeReducer,
  [Types.CLEAN_BAG]: cleanBagReducer,
  [Types.CLEAN_ACTIVE_DISCOUNT]: cleanActiveDiscountReducer,
  [Types.CHECK_PRODUCT_AVAILABILITY_REQUEST]: checkProductAvailabilityRequest,
  [Types.CHECK_PRODUCT_AVAILABILITY_SUCCESS]: checkProductAvailabilitySuccess,
  [Types.CHECK_PRODUCT_AVAILABILITY_FAILURE]: checkProductAvailabilityFailure,
  [Types.SET_BAG_DISCOUNT_ACTIVE_COUPON]: setBagDiscountActiveCouponReducer,
  [Types.REMOVE_CODE_COUPON_DISCOUNT]: removeCodeCouponDiscountReducer,
  [Types.VALIDATE_CODE_COUPON_REQUEST]: validateCodeCouponRequestReducer,
  [Types.VALIDATE_CODE_COUPON_SUCCESS]: validateCodeCouponSuccessReducer,
  [Types.VALIDATE_CODE_COUPON_FAILURE]: validateCodeCouponFailureReducer,
});
