export const formatMinutes = (timeInMinutes: number) => {
  const hours = new Date(timeInMinutes * 1000).toISOString().substr(14, 2);
  const minutes = new Date(timeInMinutes * 1000).toISOString().substr(17, 2);
  if (timeInMinutes >= 60) {
    // At least one hour
    return `${hours}h${minutes}m`;
  } else {
    return `${minutes}min`;
  }
};
