import AdvertiserPaymentMethod from '~/models/AdvertiserPaymentMethod';
import BagDiscounts, { AvailablePaymentMethodsType } from '~/models/BagDiscounts';
import Coupon from '~/models/Coupon';
import Product from '~/models/Product';
import SelectedComplement from '~/models/SelectedComplement';

type RecalcBagAmountsReturn = {
  sizeBag: number;
  priceWithDiscount: number;
  priceWithoutDiscount: number;
};

export function recalcBagAmounts(itemsInBag: Product[], coupons: Coupon[]): RecalcBagAmountsReturn {
  let priceWithoutDiscount = 0;
  let sizeBag = 0;

  itemsInBag.forEach((item) => {
    sizeBag += item.productQuantity;

    let complementsTotalPrice = 0;
    item.selectedComplements.forEach((c: SelectedComplement) => {
      if (c.is_base_amount_more_expensive) {
        let arrAmountOptions = c.options.map((o) => {
          return o.quantity > 0 && o.amount;
        });
        let moreExpensiveOption = Math.max.apply(null, arrAmountOptions);

        c.options.forEach((o) => {
          complementsTotalPrice += moreExpensiveOption * o.quantity;
        });
      } else {
        c.options.forEach((o) => {
          if (o.is_selected && o.amount) {
            complementsTotalPrice += o.amount;
          } else if (o.quantity && o.amount) {
            complementsTotalPrice += o.amount * o.quantity;
          }
        });
      }
    });

    priceWithoutDiscount += (item.amount + complementsTotalPrice) * item.productQuantity;
  });

  let discountAmount: number = 0;
  if (coupons?.length > 0) {
    discountAmount = coupons?.reduce((currDiscount, coupon) => {
      switch (coupon.type) {
        case 'percentage_cart':
          const discountAmountFromPercentage =
            (priceWithoutDiscount * coupon.couponBenefit.cart_discount_percentage) / 100;
          return currDiscount + discountAmountFromPercentage;
        case 'fixed_cart':
          return currDiscount + coupon.couponBenefit.cart_discount_fixed;
        case 'from_value_to_value':
          return currDiscount + (coupon.couponBenefit.from_value - coupon.couponBenefit.by_value);
        default:
          break;
      }
    }, 0);
  }

  return {
    sizeBag: sizeBag,
    priceWithoutDiscount: Number(priceWithoutDiscount.toFixed(2)),
    priceWithDiscount:
      discountAmount > priceWithoutDiscount
        ? 0
        : Number((priceWithoutDiscount - discountAmount).toFixed(2)),
  };
}

export function validatePaymentMethodAvailableForDiscount(
  bagDiscounts: BagDiscounts,
): AvailablePaymentMethodsType {
  let isAcceptMoney = true;
  let isAcceptCard = true;
  let isAcceptPix = true;

  bagDiscounts?.discounts[bagDiscounts.activeCoupon]?.forEach((coupon) => {
    if (isAcceptMoney && !coupon.is_accept_money) {
      isAcceptMoney = false;
    }
    if (isAcceptCard && !coupon.is_accept_credit_card) {
      isAcceptCard = false;
    }
    if (isAcceptPix && !coupon.is_accept_pix) {
      isAcceptPix = false;
    }
  });

  return {
    isAcceptMoney: isAcceptMoney,
    isAcceptCard: isAcceptCard,
    isAcceptPix: isAcceptPix,
  };
}

export function isPaymentMethodDisabled(
  bagDiscounts: BagDiscounts,
  paymentMethod: AdvertiserPaymentMethod,
) {
  return (
    (!bagDiscounts?.paymentMethods?.isAcceptMoney &&
      paymentMethod.paymentMethod.function === 'money') ||
    (!bagDiscounts?.paymentMethods?.isAcceptCard &&
      ['credit', 'debit', 'voucher'].includes(paymentMethod.paymentMethod.function)) ||
    (!bagDiscounts?.paymentMethods?.isAcceptPix &&
      paymentMethod.paymentMethod.function === 'money_transfer')
  );
}
