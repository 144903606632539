import { Box } from '@chakra-ui/core';
import React from 'react';
import { FiCheck } from 'react-icons/fi';

import Button, { ButtonProps } from '../Button';

export interface SelectOptionButtonProps extends ButtonProps {
  isSelected?: boolean;
  circleSize?: number;
}

const SelectOptionButton: React.FC<SelectOptionButtonProps> = ({
  children,
  isSelected,
  circleSize = 3.5,
  alignItems = 'flex-start',
  p = 2,
  ...rest
}) => {
  return (
    <Button variant="ghost" justifyContent="flex-start" alignItems={alignItems} p={p} {...rest}>
      <Box
        w={`${circleSize}rem`}
        h={`${circleSize}rem`}
        d="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="50%"
        bg={isSelected ? 'green.400' : 'transparent'}
        border="1px solid"
        borderColor={isSelected ? 'green.500' : 'gray.500'}
        mr={4}
        transition="background .2s ease, border-color .2s ease"
      >
        <Box as={FiCheck} opacity={isSelected ? 1 : 0} transition="opacity .2s ease-out" />
      </Box>
      <Box d="flex" flexDir="column" alignItems="flex-start" flex="1">
        {children}
      </Box>
    </Button>
  );
};

export default SelectOptionButton;
