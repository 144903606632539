import { Box, Text, useDisclosure } from '@chakra-ui/core';
import { Icon } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import useSlug from '~/hooks/useSlug';
import { advertiserMenu, bag, myOrders } from '~/routes/routeMap';
import { RootState } from '~/store';
import AuthActions from '~/store/ducks/auth';
import { onlyNumbers } from '~/utils/masks';
import RealTimeDimensions from '~/utils/realTimeDimensions';

import BottomBar from '../BottomBar';
import LoginForm from '../LoginForm';
import SideMenu from '../SideMenu';
import ImgAndText from './ImgAndText';
import { FooterBarBox, FooterBarMenuTitle } from './styles';

const NavBar: React.FC = () => {
  // Navigation bar (Catalog, Bag, Orders, Profile)
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const slug = useSlug();

  const isLoggedin = globalState?.auth?.isLoggedin;
  const itemsInBag = globalState?.bag?.itemsInBag;
  const orders = globalState?.order?.orders;
  const [width] = RealTimeDimensions();
  const isWeb = width > 800 ? true : false;

  const { isOpen: isOpenMenu, onOpen: onOpenMenu, onClose: onCloseMenu } = useDisclosure();
  const { isOpen: isLoginOpen, onOpen: onLoginOpen, onClose: onLoginClose } = useDisclosure();

  const hasAtLeastOneOngoingOrder = useMemo(
    () =>
      orders.some((order) =>
        order.orderStatus?.status?.match(
          /new|accepted|preparing|delivering|ready_take_away|pending_payment/,
        ),
      ),
    [orders],
  );

  const handleOnSubmitLogin = useCallback(
    (data: { name: string; phone: string }) => {
      dispatch(AuthActions.createSessionUser(data.name, onlyNumbers(`55${data.phone}`)));
      onLoginClose();
    },
    [onLoginClose, dispatch],
  );

  const location = useLocation();

  function handleNavClick(onExecute: Function, bagClick: boolean = false) {
    if (!isLoggedin && !bagClick) {
      onLoginOpen();
      return;
    }
    onExecute();
  }

  function handleBagBehavior() {
    if (itemsInBag.length > 0) {
      handleNavClick(() => dispatch(push(bag)), true);
      return;
    }
    toast.info('Você ainda não adicionou itens na sacola.');
  }

  return (
    <FooterBarBox boxShadow={'0 20px 40px 10px #767676'} isWeb={isWeb}>
      {/* Catalog */}

      <Box
        onClick={() => dispatch(push(advertiserMenu))}
        cursor="pointer"
        w={'100%'}
        h={'100%'}
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        display={'flex'}
        mr={'5%'}
      >
        <ImgAndText
          src={require(`../../assets/images/mobile_footer_bar/${
            location.pathname === '/' && !isOpenMenu ? `cardapio2` : `cardapio`
          }.png`)}
          text="Cardápio"
          key="imgAndText02"
          isCurrPage={location.pathname === '/' && !isOpenMenu}
          isWeb={isWeb}
        />
      </Box>

      {/* Bag */}
      <Box
        onClick={handleBagBehavior}
        cursor="pointer"
        w={'100%'}
        h={'100%'}
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        display={'flex'}
        mr={'5%'}
      >
        <Icon name="shopping-bag" size="2.4rem" w={25} h={25} />
        {itemsInBag.length > 0 && (
          <Text
            as="span"
            position="absolute"
            top="15%"
            left="37.5%"
            color="white"
            backgroundColor="red.500"
            borderRadius="50%"
            border="1px solid"
            borderColor="red.500"
            fontWeight="700"
            w="1.4rem"
            h="1.4rem"
            textAlign="center"
            py={1}
            lineHeight="7px"
            fontSize={itemsInBag.length > 9 ? '0.6rem' : '0.8rem'}
          >
            {itemsInBag.length > 9 ? '9+' : itemsInBag.length}
          </Text>
        )}
        <FooterBarMenuTitle paddingTop={2}>Sacola</FooterBarMenuTitle>
      </Box>

      {/* Orders */}
      <Box
        onClick={() => handleNavClick(() => dispatch(push(myOrders)))}
        w={'100%'}
        h={'100%'}
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        display={'flex'}
        mr={'5%'}
      >
        <ImgAndText
          src={require(`../../assets/images/mobile_footer_bar/${
            location.pathname === '/meus-pedidos' && !isOpenMenu ? `pedidos2` : `pedidos`
          }.png`)}
          text="Pedidos"
          key="imgAndText04"
          isCurrPage={location.pathname === '/meus-pedidos' && !isOpenMenu}
          isWeb={isWeb}
        />
        {hasAtLeastOneOngoingOrder && (
          <Text
            as="span"
            position="absolute"
            top="15%"
            right="34.3%"
            color="white"
            backgroundColor="red.500"
            borderRadius="50%"
            border="1px solid"
            borderColor="red.500"
            fontWeight="700"
            w="1.4rem"
            h="1.4rem"
            textAlign="center"
            py={1}
            lineHeight="7px"
            fontSize={orders.length > 9 ? '0.6rem' : '0.8rem'}
          >
            {orders.length > 9 ? '9+' : orders.length}
          </Text>
        )}
      </Box>

      {/* Profile */}
      <Box
        onClick={() => handleNavClick(onOpenMenu)}
        cursor="pointer"
        w={'100%'}
        h={'100%'}
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        display={'flex'}
      >
        <ImgAndText
          src={require(`../../assets/images/mobile_footer_bar/${
            isOpenMenu ? `profile2` : `profile`
          }.png`)}
          text="Perfil"
          key="imgAndText01"
          isCurrPage={isOpenMenu}
          isWeb={isWeb}
        />
      </Box>

      {/* Side Menu */}
      {isLoggedin && <SideMenu isOpen={isOpenMenu} onClose={onCloseMenu} advertiserSlug={slug} />}

      {isLoginOpen && (
        <BottomBar isOverlayVisible onClose={onLoginClose} zIndex={999}>
          <LoginForm isVisible canContinue onFormSubmit={handleOnSubmitLogin} />
        </BottomBar>
      )}
    </FooterBarBox>
  );
};

export default NavBar;
