import axios from 'axios';

import getEnvFromSubdomain from '~/utils/getEnvFromSubdomain';

const reactAppEnv = getEnvFromSubdomain();
const reactAppEnvGlobal = process.env.REACT_APP_ENV_GLOBAL_API;
const reactAppEnvWebMenu = process.env.REACT_APP_ENV_WEBMENU_API;

const baseUrlsWebMenu: Record<string, string> = {
  production: 'https://api.kuppi.com.br/web-menu',
  development: 'https://api.kuppi.com.br/web-menu',
  qa: 'https://api.kuppi.com.br/web-menu',
  local: 'http://localhost:3333/web-menu',
};

const baseUrlsGlobal: Record<string, string> = {
  production: 'https://api.kuppi.com.br/global',
  development: 'https://api.kuppi.com.br/global',
  qa: 'https://api.kuppi.com.br/global',
  local: 'http://localhost:3334/global',
};

export const baseUrlGlobal = baseUrlsGlobal[reactAppEnvGlobal || reactAppEnv];
export const baseUrlWebMenu = baseUrlsWebMenu[reactAppEnvWebMenu || reactAppEnv];

// Global API
export const apiGlobal = axios.create({
  baseURL: baseUrlGlobal,
  headers: {
    'Content-Type': 'application/json',
  },
});

// WebMenu API
export const apiWebMenu = axios.create({
  baseURL: baseUrlWebMenu,
  headers: {
    'Content-Type': 'application/json',
  },
});
