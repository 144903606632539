import {
  Box,
  Divider,
  Heading,
  Icon,
  Image,
  Link as ChakraLink,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import { Badge } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import addMinutes from 'date-fns/addMinutes';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RiWhatsappFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import copyPaste from '~/assets/images/icon_copy_paste/copypaste.png';
import pixcode from '~/assets/images/icon_copy_paste/pixcode.png';
import pixLogo from '~/assets/images/logo_pix/logo-pix.png';
import Button from '~/components/Button';
import Card from '~/components/Card';
import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import ListItem from '~/components/ListItem';
import useOrderSchedules from '~/hooks/useOrderSchedules';
import usePWA from '~/hooks/usePWA';
import Order from '~/models/Order';
import { myLoyaltyCards, myOrders, orderDetails, reOrder } from '~/routes/routeMap';
import { RootState } from '~/store';
import { Creators as OrderCreators } from '~/store/ducks/order';
import { formatAddress } from '~/utils/address';
import { formatCurrencyBRL } from '~/utils/currency';
import { formatDate } from '~/utils/formatDate';
import RealTimeDimensions from '~/utils/realTimeDimensions';
import { formatPhoneCallAction } from '~/utils/valitadion';

import { BarCompletion, CustonHeading, LoyaltyBar } from './styles';
import Timeline from './Timeline';

interface UseLocationProps {
  order: Order;
  orderList?: string;
  goBackToMyOrders: boolean;
}

const ViewOrder: React.FC = () => {
  const { isOpen, onToggle } = useDisclosure();
  const [width] = RealTimeDimensions();
  const { resetSelectedSchedule } = useOrderSchedules();
  const { promptInstall } = usePWA();
  const [hasPrompted, setHasPrompted] = useState(false);
  const isMobile = width > 0 && width < 800 ? true : false;
  const dispatch = useDispatch();
  const location = useLocation<UseLocationProps>();
  const state = location?.state;
  const order = state?.order;
  const orderList = state?.orderList;
  const goBackToMyOrders = state?.goBackToMyOrders;
  const globalState = useSelector((state: RootState) => state);
  const imageTimestamp = globalState?.advertiser.imageTimestamp;
  const advertiserInfo = globalState.advertiser?.advertiserInfo;
  const takeAwayTimeAvg = advertiserInfo?.take_away_time_avg;
  const deliveryTimeAvg = advertiserInfo?.delivery_time_avg;
  const advertiserPixKey = advertiserInfo?.advertiserPaymentMethod?.filter((paymentMethod) => {
    if (paymentMethod?.payment_method_slug === 'pix_chave') {
      return paymentMethod;
    }
  })[0]?.external_processor_key;
  const pixKeyRegex =
    /^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$/;
  const emailRegex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  const {
    order: { orders, previousOrders, reorderLoading },
  } = useSelector((state: RootState) => state);

  const orderSelected =
    orderList === 'previous'
      ? previousOrders.find((orderFinded) => orderFinded.id === order?.id)
      : orders.find((orderFinded) => orderFinded.id === order?.id);

  const delayedPromptInstall = useCallback(() => {
    const timeout = setTimeout(() => {
      promptInstall();
      setHasPrompted(true);
    }, 1000);
    return timeout;
  }, [promptInstall]);

  useEffect(() => {
    if (goBackToMyOrders || hasPrompted) return;
    const timeout = delayedPromptInstall();
    return () => {
      clearTimeout(timeout);
    };
  }, [delayedPromptInstall, hasPrompted, goBackToMyOrders]);

  useEffect(() => {
    if (!order) {
      dispatch(push(myOrders));
    }
    resetSelectedSchedule();
  }, [order, resetSelectedSchedule, dispatch]);

  const handleOnClickSeeFidelityCard = useCallback(() => {
    dispatch(push(myLoyaltyCards, { isInViewOrder: true }));
  }, [dispatch]);

  const handleOnClickSeeDetails = useCallback(() => {
    dispatch(push(orderDetails, { order }));
  }, [dispatch, order]);

  const handleOnClickReOrder = () => {
    dispatch(push(reOrder.replace(':id_pedido', `${order?.id}`)));
  };

  const barWidth = useCallback((current, max) => {
    const percent = (current / max) * 100;

    return percent;
  }, []);

  const progressbar = useMemo(() => {
    let fill = 0;
    const status = (orderSelected as Order)?.orderStatus;

    if (status?.new_at) {
      fill = 20;
    }
    if (status?.accepted_at) {
      fill = 40;
    }
    if (status?.preparing_at) {
      fill = 60;
    }
    if (status?.delivery_at || status?.ready_take_away_at) {
      fill = 80;
    }
    if (status?.finished_at) {
      fill = 100;
    }
    if (status?.canceled_at) {
      fill = 0;
    }

    return barWidth(fill, 100);
  }, [barWidth, orderSelected]);

  const statusText = useMemo(() => {
    const orderStatus = orderSelected?.orderStatus;
    const status = orderStatus?.status;

    if (!status) return '';
    switch (status) {
      case 'new':
        return 'Aguardando confirmação do estabelecimento';
      case 'accepted':
        return 'O estabelecimento aceitou seu pedido';
      case 'preparing':
        return 'O estabelecimento está preparando seu pedido';
      case 'delivering':
        return 'Seu pedido saiu para a entrega';
      case 'ready_take_away':
        return 'Seu pedido está pronto para retirada';
      case 'finished':
        return 'Pedido entregue';
      case 'pending_payment':
        return 'Pagamento não realizado';
      case 'canceled':
        return 'Pedido cancelado!';
    }
  }, [orderSelected]);

  const formatSelectedSchedule = useMemo(() => {
    if (order) {
      const timeFrom = new Date(order?.scheduling_start_at);
      const timeTo = new Date(order?.scheduling_end_at);
      const type = order?.order_type;
      const timeFromImmediate = addMinutes(
        timeFrom,
        order?.delivery_type === 'delivery' ? deliveryTimeAvg : takeAwayTimeAvg,
      );

      if (isToday(timeFrom) && type === 'immediate') {
        return `Hoje, ${formatDate(timeFrom, 'HH:mm')} - ${formatDate(timeFromImmediate, 'HH:mm')}`;
      } else if (isToday(timeFrom) && timeTo) {
        return `Hoje, ${formatDate(timeFrom, 'HH:mm')} - ${formatDate(timeTo, 'HH:mm')}`;
      } else {
        return `${formatDate(timeFrom, 'cccccc dd/MM, HH:mm')} - ${formatDate(timeTo, 'HH:mm')}`;
      }
    }
  }, [
    order?.scheduling_start_at,
    order?.scheduling_end_at,
    order?.order_type,
    order?.delivery_type,
    deliveryTimeAvg,
    takeAwayTimeAvg,
  ]);

  function copyPixPaymentCode(e: any) {
    var copyText = document.getElementById('pixCode');
    var textArea = document.createElement('textarea');
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();

    toast.success('A chave Pix foi copiada com sucesso!');
  }

  function handleValidatePixkeyType(pixKey: string) {
    if (!pixKey) {
      return '-';
    }
    if (pixKey?.match(emailRegex)) {
      return 'Email';
    }
    if (pixKey?.match(pixKeyRegex)) {
      return 'Chave aleatória';
    }
    if (pixKey?.length === 12 || pixKey.length === 13) {
      return 'Telefone';
    }
    if (pixKey?.length === 14) {
      return 'CPF';
    }
    if (pixKey?.length === 18) {
      return 'CNPJ';
    }
  }

  return (
    <Box as="main" flexDir="column" w="100%" px="3rem" mt="2.5rem" pb={40}>
      <HeaderWithBackButton headerTitle="" mb={16} to={!goBackToMyOrders && '/'} />
      <Box d="flex" flexDir="column" textAlign="center" mt={-50}>
        <Heading as="h4" fontSize="2xl" fontWeight="700" mb={4}>
          Acompanhe seu pedido
        </Heading>
        {order?.order_type === 'immediate' && (
          <>
            <Heading as="h4" fontSize="md" fontWeight="500" textAlign="left">
              Previsão de entrega
            </Heading>
            <Heading as="h3" fontSize="xl" fontWeight="700" textAlign="left">
              {formatSelectedSchedule}
            </Heading>
          </>
        )}

        <Heading as="h3" fontSize="sm" fontWeight="500" mt={8}>
          Pedido #{order?.id} realizado em{' '}
          {order?.created_at && format(new Date(order?.created_at), 'dd/MM/yyyy')} às{' '}
          {order?.created_at && format(new Date(order?.created_at), 'HH:mm')}
        </Heading>
      </Box>

      {/* Chave PIX Payment Information */}
      {order?.paymentMethod?.slug === 'pix_chave' &&
        !order?.advertiserOrderPayment?.paid_at &&
        !order?.orderStatus?.canceled_at && (
          <Card flexDir="column" mb={12} px={0} mt={12} pl={5} pr={5}>
            <Badge
              variant="solid"
              border="1px solid"
              borderColor={'yellow.100'}
              bg={'yellow.100'}
              color={'black'}
              pt={1}
              pb={1}
              pl={2}
              pr={2}
              fontSize="1rem"
              fontWeight="700"
              mb={2}
            >
              Realize o pagamento pela Chave PIX
            </Badge>
            <Image src={pixLogo} m="0px auto" w="100px" />
            <Text fontSize="1.3rem" mt={2} textAlign="center">
              Valor total a ser pago
            </Text>
            <Text as="span" fontSize="3rem" textAlign="center" fontWeight="bold">
              {formatCurrencyBRL(order?.total_amount)}
            </Text>
            <Text as="span" fontSize="1.6rem" textAlign="left" mt={5} mr={'auto'} fontWeight="bold">
              {handleValidatePixkeyType(advertiserPixKey)}
            </Text>
            <ListItem
              maxH="fit-content"
              p={5}
              w="100%"
              as="button"
              border="dashed 2px"
              borderColor="gray.900"
              borderRadius={13}
              d="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDir="row"
              fontWeight="bold"
            >
              <Box ml={2}>
                <Image src={pixcode} minWidth="2rem" onClick={copyPixPaymentCode} />
              </Box>
              <Text
                h="100%"
                fontSize="1.8rem"
                overflow="hidden"
                whiteSpace="nowrap"
                style={{ textOverflow: 'ellipsis' }}
                mr={1.5}
                onClick={copyPixPaymentCode}
                id="pixCode"
              >
                {advertiserPixKey}
              </Text>
              <Box ml={2}>
                <Image src={copyPaste} minWidth="2rem" onClick={copyPixPaymentCode} />
              </Box>
            </ListItem>
            <Text fontSize="1.4rem" mt={8} textAlign="center">
              * Se já tiver pago, envie o comprovante para a loja no whatsapp
            </Text>
          </Card>
        )}

      {/* Not Paid Info or Order Timeline */}
      {['pending_payment'].includes(orderSelected?.orderStatus.status) &&
      orderSelected?.paymentMethod.type === 'online' ? (
        <Card justifyContent={'center'} alignItems={'center'} mt={8} flexDir="column">
          <Box d="flex" flexDir="row" mb={6}>
            <img alt="error_image" style={{ marginRight: 15, width: 22 }} src="/images/error.png" />
            <Heading as="h3" fontSize="lg" fontWeight="600">
              Pagamento não realizado
            </Heading>
          </Box>

          <Divider w="100%" m={0} />

          <Button
            d="flex"
            variant="solid"
            py={4}
            px={10}
            mt={6}
            alignItems="center"
            isDisabled={reorderLoading}
            justifyContent="center"
            isLoading={reorderLoading}
            onClick={() => dispatch(OrderCreators.reorderRequest(order.id))}
          >
            <Text fontSize="sm">Gerar novo pagamento</Text>
          </Button>
        </Card>
      ) : (
        <Card flexDir="column" mb={12} px={0} mt={12}>
          <Heading as="h3" fontSize="md" mx="2rem" mt="1rem" mb="1rem">
            {statusText}
          </Heading>

          <Box
            position="relative"
            d="flex"
            flexDir="row"
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            my="1rem"
            px="1.5rem"
          >
            <LoyaltyBar>
              <BarCompletion progress={progressbar} />
            </LoyaltyBar>

            <Icon
              p="5px"
              background="white"
              zIndex={5}
              size="3rem"
              name="order-accepted"
              color={progressbar >= 20 ? 'black.400' : 'gray.300'}
            />
            <Icon
              p="5px"
              background="white"
              zIndex={5}
              size="3rem"
              name="scheduling-clock"
              color={progressbar >= 40 ? 'black.400' : 'gray.300'}
            />
            <Icon
              p="5px"
              background="white"
              zIndex={5}
              size="3rem"
              name="order-in-progress"
              color={progressbar >= 60 ? 'black.400' : 'gray.300'}
            />
            <Icon
              p="5px"
              background="white"
              zIndex={5}
              size="3rem"
              name="order-delivery"
              color={progressbar >= 80 ? 'black.400' : 'gray.300'}
            />
          </Box>

          <Divider w="100%" m={0} />

          <Button
            d="flex"
            w="100%"
            variant="ghost"
            py={4}
            alignItems="center"
            justifyContent="center"
            background="white"
            onClick={onToggle}
          >
            <Text fontSize="sm">{!isOpen ? 'Ver mais' : 'Ver menos'}</Text>
            <Icon size="1.5rem" name={!isOpen ? 'chevron-down' : 'chevron-up'} m="4px" />
          </Button>

          <Box d="flex" alignItems="center" justifyContent="center" px="20px">
            {isOpen && (
              <Box position="relative" d="flex" flexDir="column" justifyContent="center">
                <Box
                  w="15px"
                  h="78%"
                  position="absolute"
                  d="flex"
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="transparent"
                >
                  <Box backgroundColor="gray.200" w="2px" h="100%" />
                </Box>
                <Timeline active={progressbar >= 0}>
                  Aguardando confirmação do estabelecimento
                </Timeline>
                <Timeline active={progressbar >= 30}>O estabelecimento aceitou seu pedido</Timeline>
                <Timeline active={progressbar >= 60}>
                  O estabelecimento está preparando seu pedido
                </Timeline>
                <Timeline active={progressbar >= 80}>
                  {order?.delivery_type === 'delivery'
                    ? 'Seu pedido saiu para a entrega'
                    : 'Seu pedido está pronto para retirada'}
                </Timeline>
                <Timeline active={progressbar >= 100}>Pedido entregue</Timeline>
              </Box>
            )}
          </Box>
        </Card>
      )}

      {/* Advertiser and Order details */}
      <Card justifyContent="center" px={0} py={0} flexDir="column" mt={10}>
        <Box d="flex" alignItems="center" w="100%" p={5} flexDir={isMobile ? 'column' : 'row'}>
          <Box
            d="flex"
            alignItems="center"
            justifyContent="flex-start"
            w="100%"
            p={5}
            flexDir="row"
          >
            <Image
              src={`${advertiserInfo?.user?.avatar?.url}?nocache=${imageTimestamp}`}
              backgroundColor="white"
              h="6.5rem"
              w="6.5rem"
              borderRadius="50%"
              objectFit="cover"
              border="1px solid #c3c3c3"
              mr={6}
            />
            <CustonHeading as="h2" fontSize="lg">
              {advertiserInfo?.name}
            </CustonHeading>
          </Box>
          <Box
            d="flex"
            alignItems="center"
            justifyContent={isMobile ? 'flex-center' : 'flex-end'}
            w="100%"
            p={5}
            flexDir="row"
          >
            {advertiserInfo?.advertiserPhones?.length > 0 &&
              advertiserInfo?.advertiserPhones?.map((phone, idx) => {
                if (phone?.type === 'whatsapp') {
                  return (
                    <Box
                      key={`advertiserPhone-${idx}`}
                      d="flex"
                      alignItems="center"
                      py={5}
                      justifyContent="space-around"
                    >
                      <ChakraLink
                        flex="1"
                        href={formatPhoneCallAction(phone?.number)}
                        d="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        fontSize="md"
                        px={4}
                        _hover={{
                          textDecor: 'none',
                        }}
                        w="auto"
                      >
                        <Text as="span" fontSize="md" w="auto">
                          LIGAR
                        </Text>
                        <Icon name="phone" ml={3} size="1.4rem" />
                      </ChakraLink>
                      <ChakraLink
                        flex="1"
                        href={`whatsapp://send?text=Ol%C3%A1%2C%20gostaria%20de%20falar%20sobre%20meu%20pedido%3A%20%23${order?.id}&phone=${phone?.number}`}
                        d="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        fontSize="md"
                        px={4}
                        ml={8}
                        _hover={{
                          textDecor: 'none',
                        }}
                        w="auto"
                      >
                        <Text as="span" fontSize="md" w="auto">
                          WHATSAPP
                        </Text>
                        <Box as={RiWhatsappFill} ml={3} size="2rem" />
                      </ChakraLink>
                    </Box>
                  );
                }
              })}
          </Box>
        </Box>
        <Divider w="100%" m={0} />

        <Stack spacing={4} px={6} py={6} w="100%">
          <Text fontSize="md" color="gray.400">
            {order?.delivery_type === 'delivery' ? 'Endereço de entrega' : 'Endereço de retirada'}
          </Text>
          <Text fontSize="md" fontWeight="700">
            {formatAddress(order?.address)}
          </Text>
        </Stack>
        <Divider w="100%" m={0} />

        <Stack spacing={2} px={6} py={6} w="100%">
          <Text fontSize="md" color="gray.400">
            Forma de Pagamento
          </Text>
          {order?.paymentMethod?.slug === 'cash' ? (
            <ListItem
              key={order?.paymentMethod?.slug}
              py={2}
              borderTop="0"
              as="button"
              outline={0}
              flexDir="row"
              alignItems="center"
            >
              <Image h="2rem" mr={4} src={'/images/cash.svg'} />
              <Text fontSize="md" fontWeight="700" textAlign="left">
                Dinheiro, Troco {formatCurrencyBRL(order?.change)}
              </Text>
            </ListItem>
          ) : (
            <ListItem
              key={order?.paymentMethod?.slug}
              py={2}
              borderTop="0"
              as="button"
              outline={0}
              flexDir="row"
              alignItems="center"
            >
              <Image h="2rem" mr={8} src={order?.paymentMethod?.image_url} />
              <Text fontSize="md" fontWeight="700" textAlign="left">
                {order?.paymentMethod?.name}
              </Text>
            </ListItem>
          )}
        </Stack>
        <Divider w="100%" m={0} />

        <Stack isInline align="center" spacing={2} px={6} py={6} w="100%">
          <Box d="flex" alignItems="center" w="100%">
            <Text fontSize="md" fontWeight="700">
              Total {formatCurrencyBRL(order?.total_amount)}
            </Text>
          </Box>
          <Button variant="ghost" py={8} onClick={handleOnClickSeeDetails}>
            <span>Ver detalhes</span>
            <Icon name="arrow-back" size="1.4rem" transform="rotate(180deg)" />
          </Button>
        </Stack>
      </Card>

      {/* Discount and Loyalty Card */}
      {order?.is_valid_to_loyalty_card && !order?.orderStatus?.canceled_at && (
        <Card justifyContent="center" mt={10} px={0} py={0} flexDir="column">
          <Stack spacing={4} px={4} py={6}>
            <Box d="flex" alignItems="center">
              <Icon name="heart-card" size="2.2rem" mr={4} />
              <Text fontSize="sm">Pedido válido para o Cartão Fidelidade</Text>
            </Box>
          </Stack>
          <Divider w="100%" m={0} />
          <Button width="100%" variant="ghost" py={8} onClick={handleOnClickSeeFidelityCard}>
            <span>Ver meus cartões fidelidade</span>
            <Icon name="arrow-back" size="1.4rem" transform="rotate(180deg)" />
          </Button>
        </Card>
      )}

      {/* Reorder button, only for "concluded" orders - finished or canceled */}
      {order?.orderStatus?.status?.match(/canceled|finished/) && (
        <Box w="100%" mt={12} d="flex" flexDir="row">
          <Button width="100%" onClick={handleOnClickReOrder} bg={'green.200'}>
            Repetir pedido
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ViewOrder;
