import {
  Box,
  Grid,
  Heading,
  Icon,
  Image,
  List,
  ListItem,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/core';
import { push } from 'connected-react-router';
import { format } from 'date-fns';
import addHours from 'date-fns/addHours';
import { nanoid } from 'nanoid';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import TopRoundedBox from '~/components/TopRoundedBox';
import UserLoyaltyCard from '~/models/UserLoyaltyCard';
import { myLoyaltyCards } from '~/routes/routeMap';
import { RootState } from '~/store';
import { formatCurrencyBRL } from '~/utils/currency';

const LoyaltyCardDetail: React.FC = () => {
  const dispatch = useDispatch();

  const { lastAdvertiser } = useSelector((state: RootState) => state.advertiser);

  const userState = useSelector((state: RootState) => state.user);
  const loadingLoyaltyCard = userState?.loadingLoyaltyCard;
  const { state } = useLocation<{ userLoyaltyCard: UserLoyaltyCard }>();
  const userLoyaltyCard = state?.userLoyaltyCard;

  useEffect(() => {
    if (!userLoyaltyCard) {
      dispatch(push(myLoyaltyCards));
    }
  }, [dispatch, userLoyaltyCard, lastAdvertiser]);

  const loyaltyUseDescription = useMemo<string>(() => {
    if (userLoyaltyCard?.is_accept_delivery && userLoyaltyCard?.is_accept_physical)
      return 'Para compras no Delivery ou Retirada';
    if (userLoyaltyCard?.is_accept_delivery && !userLoyaltyCard?.is_accept_physical)
      return 'Para compras no Delivery';
    if (userLoyaltyCard?.is_accept_physical && !userLoyaltyCard?.is_accept_delivery)
      return 'Para compras de Retirada';
  }, [userLoyaltyCard]);

  const loyaltyCardDescription = useMemo<string>(() => {
    if (userLoyaltyCard?.type === 'percentage_cart')
      return `Vale ${userLoyaltyCard?.cart_discount_percentage}% de desconto`;
    if (userLoyaltyCard?.type === 'fixed_cart')
      return `Vale ${formatCurrencyBRL(userLoyaltyCard?.cart_discount_fixed)} de desconto`;
  }, [userLoyaltyCard]);

  const loyaltyCardRule = useMemo<string>(() => {
    if (userLoyaltyCard?.is_ticket_minimum_active) {
      return `A cada compra no estabelecimento com o valor
      mínimo de ${formatCurrencyBRL(userLoyaltyCard?.ticket_minimum_amount)}
      você ganha um selo. Quando completar a cartela, você ganha o cupom com o desconto`;
    } else {
      return `A cada compra no estabelecimento você ganha um selo.
      Quando completar a cartela, você ganha o cupom com o desconto`;
    }
  }, [userLoyaltyCard]);

  const isCardCompleted = useMemo(() => {
    return userLoyaltyCard?.is_rescued;
  }, [userLoyaltyCard]);

  const isCouponUsed = useMemo(() => {
    return userLoyaltyCard?.is_rescued_benefit_used;
  }, [userLoyaltyCard]);

  const userLoyaltyCardValidDate = useMemo<string>(() => {
    if (userLoyaltyCard)
      return `Você deve completar o cartão até ${format(
        addHours(new Date(userLoyaltyCard?.expiry_at), 3),
        'dd/MM/yyyy',
      )}`;
    return null;
  }, [userLoyaltyCard]);

  const userAmountOfLoyaltyCardPurchases = useMemo<number>(() => {
    return Number(userLoyaltyCard?.qty_purchases) || 0;
  }, [userLoyaltyCard]);

  const renderedLoyaltyCardStamps = useMemo<React.ReactNode>(() => {
    if (!userLoyaltyCard) {
      return Array.from(Array(userLoyaltyCard?.amount_of_purchases).keys()).map((i) => (
        <Skeleton key={i} isLoaded={!loadingLoyaltyCard} borderRadius="50%" w="7rem" h="7rem">
          <Icon name="kuppi" color="gray.100" size="7rem" />
        </Skeleton>
      ));
    } else {
      const neededAmountOfPurchases = Array.from(
        Array(userLoyaltyCard?.amount_of_purchases).keys(),
      );
      const userAmountOfPuchases = Array.from(Array(userAmountOfLoyaltyCardPurchases).keys());

      const remainingAmountOfPurchases =
        neededAmountOfPurchases.length - userAmountOfPuchases.length;

      const greenStamps = userAmountOfPuchases.map((i) => (
        <Skeleton
          key={nanoid(4)}
          isLoaded={!loadingLoyaltyCard}
          borderRadius="50%"
          w="7rem"
          h="7rem"
        >
          <Icon name="kuppi" color="green.400" size="7rem" />
        </Skeleton>
      ));

      const grayStamps = Array.from(Array(remainingAmountOfPurchases).keys()).map((i) => (
        <Skeleton
          key={nanoid(4)}
          isLoaded={!loadingLoyaltyCard}
          borderRadius="50%"
          w="7rem"
          h="7rem"
        >
          <Icon name="kuppi" color="gray.100" size="7rem" />
        </Skeleton>
      ));

      return [...greenStamps, ...grayStamps];
    }
  }, [userLoyaltyCard, loadingLoyaltyCard, userAmountOfLoyaltyCardPurchases]);

  return (
    <Box as="main" flexDir="column" w="100%" bg="green.400">
      <HeaderWithBackButton headerTitle="Cartão Fidelidade" px="3rem" />
      <TopRoundedBox as="section">
        <Stack spacing={2} align="center" mb={8} px="3rem">
          <Skeleton isLoaded={!loadingLoyaltyCard} mt={30} minH="3rem" w="90%">
            <Text fontSize="lg" fontWeight="700" textAlign="center">
              {loyaltyCardDescription}
            </Text>
          </Skeleton>
          <Skeleton isLoaded={!loadingLoyaltyCard} minH="3rem" w="90%">
            <Text fontSize="md" textAlign="center">
              {loyaltyUseDescription}
            </Text>
          </Skeleton>
        </Stack>

        {isCardCompleted ? (
          <Box d="flex" flexDir="column" alignItems="center" px="4rem">
            <Skeleton isLoaded={!loadingLoyaltyCard} mb={8}>
              <Image src="/images/fidelity_complete.png" h="14rem" />
            </Skeleton>
            <Skeleton mb={4} isLoaded={!loadingLoyaltyCard}>
              <Heading as="h3" fontSize="xl" mt={5} mb={4} textAlign="center">
                Parabéns!
              </Heading>
              <Text fontSize="md" textAlign="center">
                Você completou esse cartão fidelidade em{' '}
                {format(new Date(userLoyaltyCard?.rescued_at), 'dd/MM/yyyy @ HH:mm').replace(
                  '@',
                  'às',
                )}
              </Text>
            </Skeleton>
            {!isCouponUsed ? (
              <Text fontSize="md" mb={4} textAlign="center">
                Seu cupom está disponível para ser utilizado no resumo do pedido
              </Text>
            ) : (
              <Text fontSize="md" mb={4} textAlign="center">
                Você ja utilizou o cupom de desconto referente a esse cartão fidelidade
              </Text>
            )}
          </Box>
        ) : (
          <Grid templateColumns="repeat(2, 1fr)" gap={6} px="10rem">
            {renderedLoyaltyCardStamps}
          </Grid>
        )}
      </TopRoundedBox>

      {!isCouponUsed && (
        <Box as="section" bg="gray.100" p="3rem" minH="28rem" alignItems="center">
          <Heading as="h4" fontSize="md" mb={4} textAlign="center">
            Regras do Cartão Fidelidade
          </Heading>
          <List styleType="disc" fontSize="md" spacing={4}>
            <ListItem>{loyaltyCardRule}</ListItem>
            {userLoyaltyCardValidDate && <ListItem>{userLoyaltyCardValidDate}</ListItem>}
            {userLoyaltyCard?.is_ticket_minimum_retrieval_active && (
              <ListItem>
                Valor mínimo do pedido para utilizar o desconto deve ser{' '}
                {formatCurrencyBRL(userLoyaltyCard?.ticket_minimum_amount_retrieval)}
              </ListItem>
            )}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default LoyaltyCardDetail;
