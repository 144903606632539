/* Advertiser */
export const advertiserMenu = '/';
export const advertiserDetails = '/detalhes';
export const advertiserMenuSearch = '/busca';
export const clearInfiniteLoading = '/forcar-recarregar';

/* Product Detail, Loyalty Card */
export const itemDetails = '/item';
export const itemDetailsWithId = '/item/:id_produto';
export const myLoyaltyCards = '/meus-cartoes-fidelidade';
export const loyaltyCardDetail = '/cartao-fidelidade';

/* Order */
export const bag = '/sacola';
export const orderReview = '/sacola/resumo';
export const selectPaymentMethod = '/sacola/resumo/forma-de-pagamento';
export const selectOrderSchedule = '/sacola/resumo/horarios-de-agendamento';
export const orderItemDetails = '/sacola/resumo/pedido';
export const viewOrder = '/pedido';
export const reOrder = '/pedido/:id_pedido/refazer';
export const orderDetails = '/pedido/detalhes';
export const pixPendingPayment = '/pedido/pix-pendente';
export const pixPaymentHelp = '/pedido/pix-ajuda';
export const myOrders = '/meus-pedidos';

/* Profile and Account */
export const myProfile = '/perfil-e-conta';
export const changeInfo = '/perfil-e-conta/alterar-informacoes';
export const changeContacts = '/perfil-e-conta/alterar-contatos';

/* Addresses */
export const listAddress = '/endereco';
export const autocompleteAddress = '/endereco/procurar-endereco';
export const confirmAddress = '/endereco/confirmar';
export const confirmAddressNeighborhood = '/endereco/bairro/confirmar';
export const confirmAddressGPS = '/endereco/gps/confirmar';
