import { Image } from '@chakra-ui/core';
import { Text } from '@chakra-ui/core';
import { Box } from '@chakra-ui/core';
import styled from '@emotion/styled';

interface NavBarProps {
  isWeb?: boolean;
}

export const FooterBarBox = styled(Box)<NavBarProps>`
  display: flex;
  position: fixed;
  bottom: 0;
  flex-direction: row;

  width: 100%;
  height: 10%;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  border-radius: 15px 15px 0 0;
  z-index: 2;

  ${(props) =>
    props.isWeb &&
    `
    @media screen and (min-width: 901px) {
      width: 900px;
    }

    height: 10%;
    font-size: 1.2em;
  `}
`;

export const FooterBarImg = styled(Image)<NavBarProps>`
  margin-bottom: 8px;
  width: 20px;
  height: 20px;
`;

export const FooterBarMenuTitle = styled(Text)`
  font-size: 1.2em;
  font-weight: 600;
`;
