import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import BottomBar from '~/components/BottomBar';
import Button from '~/components/Button';
import Form from '~/components/Form';
import Input from '~/components/Form/Input';
import GoToButton from '~/components/GoToButton';
import Select from '~/components/Select';
import UnsupportedAddressModal from '~/components/UnsupportedAddressModal';
import OrderSummary from '~/models/OrderSummary';
import { advertiserMenu, confirmAddressGPS, listAddress } from '~/routes/routeMap';
import { getLatLngByAddressFields } from '~/services/address';
import { RootState } from '~/store';
import AddressActions from '~/store/ducks/addresses';
import { debounce } from '~/utils/debounce';
import { zipcodeMask } from '~/utils/masks';

import { State, states } from '../states';

interface ConfirmAddressInputs {
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  zipcode: string;
}

interface UseLocationProps {
  onConfirmBackTo: string;
  orderSummaryData: OrderSummary;
}

const ConfirmAddress: React.FC = () => {
  const dispatch = useDispatch();
  const { fetchAddressByZipcode, clearFetchedAddress, setCurrentAddress, saveAddress } =
    AddressActions;

  const location = useLocation<UseLocationProps>();
  const state = location?.state;
  const globalState = useSelector((state: RootState) => state);
  const advertiserInfo = globalState?.advertiser?.advertiserInfo;
  const deliveryTakeawayConfig = advertiserInfo?.deliveryTakeawayConfig;
  const neighborhoodAreaConfigs = advertiserInfo?.neighborhoodAreaConfigs;
  const neighborhoods = neighborhoodAreaConfigs?.map((n) => ({
    label: n.district.name,
    value: String(n.district.id),
  }));

  const { fetchedAddress, loading, doesAdvertiserDeliversInCurrentAddress } = globalState.addresses;
  const [onSubmitLoading, setOnSubmitLoading] = useState(false);

  const { register, handleSubmit, setValue, errors } = useForm<ConfirmAddressInputs>({
    reValidateMode: 'onChange',
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ufs, setUfs] = useState<State[]>([]);

  useEffect(() => {
    if (!advertiserInfo) {
      dispatch(push(advertiserMenu));
    }
  }, [advertiserInfo]);

  useEffect(() => {
    dispatch(clearFetchedAddress());
    return () => {
      dispatch(clearFetchedAddress());
    };
  }, [dispatch, clearFetchedAddress]);

  useEffect(() => {
    if (
      fetchedAddress &&
      fetchedAddress.latitude &&
      fetchedAddress.longitude &&
      !doesAdvertiserDeliversInCurrentAddress
    ) {
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doesAdvertiserDeliversInCurrentAddress, fetchedAddress, onOpen]);

  const debounceFetchAddressByZipcode = debounce((zipcode: string) => {
    if (!zipcode.length) {
      dispatch(clearFetchedAddress());
      return;
    }
    if (zipcode.length === 9) {
      dispatch(fetchAddressByZipcode(zipcode));
    }
  }, 500);

  const handleZipcodeKeyUp = (event: React.KeyboardEvent<any>) => {
    event.persist();
    const target = event.currentTarget as HTMLInputElement;
    const zipcode = target.value;
    debounceFetchAddressByZipcode(zipcode);
  };

  const fetchUfsFromIbge = useCallback(() => {
    setUfs(states.sort((prev, next) => prev.nome.localeCompare(next.nome)));
  }, []);

  useEffect(() => {
    fetchUfsFromIbge();
    const input: HTMLInputElement = document.querySelector('input[name="zipcode"]');
    input.focus();
  }, [fetchUfsFromIbge]);

  useEffect(() => {
    if (fetchedAddress) {
      if (
        deliveryTakeawayConfig?.is_using_neighborhood_area &&
        !deliveryTakeawayConfig?.is_using_range_area
      ) {
        const foundNeighborhood = neighborhoodAreaConfigs?.find(
          (n) => n.dne_district_id === fetchedAddress.district_id,
        );
        // Advertiser does not delivery in neighborhood and fetch address returned a district_id
        if (!foundNeighborhood && fetchedAddress.district_id) {
          onOpen();
          return;
        }
        if (fetchedAddress.neighborhood && foundNeighborhood) {
          setValue('neighborhood', String(fetchedAddress.district_id));
        }
      } else {
        fetchedAddress.neighborhood && setValue('neighborhood', fetchedAddress.neighborhood);
      }
      fetchedAddress.street && setValue('street', fetchedAddress.street);
      fetchedAddress.number && setValue('number', fetchedAddress.number);
      fetchedAddress.complement && setValue('complement', fetchedAddress.complement);
      fetchedAddress.city && setValue('city', fetchedAddress.city);
      fetchedAddress.state && setValue('state', fetchedAddress.state);
      fetchedAddress.zipcode && setValue('zipcode', zipcodeMask(fetchedAddress.zipcode, true));
    } else {
      setValue('street', null);
      setValue('number', null);
      setValue('complement', null);
      setValue('neighborhood', null);
      setValue('city', null);
      setValue('state', null);
      setValue('zipcode', null);
    }
  }, [fetchedAddress, setValue, deliveryTakeawayConfig, neighborhoodAreaConfigs, onOpen]);

  const onSubmit = useCallback(
    async (data: ConfirmAddressInputs) => {
      let neighborhood: string;
      let foundNeighborhoodAreaConfig;
      setOnSubmitLoading(true);

      if (
        deliveryTakeawayConfig?.is_using_neighborhood_area &&
        !deliveryTakeawayConfig?.is_using_range_area
      ) {
        foundNeighborhoodAreaConfig = neighborhoodAreaConfigs?.find(
          (n) =>
            n.dne_district_id === Number(data.neighborhood) &&
            n.district.dne_locality_id === fetchedAddress.locality_id,
        );
        neighborhood = foundNeighborhoodAreaConfig
          ? foundNeighborhoodAreaConfig.district.name
          : null;
      } else {
        neighborhood = data.neighborhood;
      }

      const foundLocation = await getLatLngByAddressFields({
        street: data.street,
        number: data.number,
        neighborhood,
        city: data.city,
        state: data.state,
        zipcode: data.zipcode,
      });
      setOnSubmitLoading(false);

      if (
        deliveryTakeawayConfig?.is_using_range_area &&
        !deliveryTakeawayConfig?.is_using_neighborhood_area
      ) {
        dispatch(
          push(confirmAddressGPS, {
            ...state,
            addressDetails: {
              street: foundLocation.street,
              number: data.number.trim() === '' ? 'S/N' : data.number,
              complement: data.complement,
              neighborhood: data.neighborhood,
              city: foundLocation.city,
              state: foundLocation.state,
              zipcode: data.zipcode,
              lat: foundLocation.latitude,
              lng: foundLocation.longitude,
            },
          }),
        );
      } else {
        if (!foundNeighborhoodAreaConfig) {
          onOpen();
          return;
        }
        const addressDetails = {
          street: data.street,
          number: data.number.trim() === '' ? 'S/N' : data.number,
          complement: data.complement,
          neighborhood,
          city: data.city,
          state: data.state,
          zipcode: data.zipcode,
          latitude: foundLocation.latitude,
          longitude: foundLocation.longitude,
        };

        dispatch(setCurrentAddress(addressDetails));
        dispatch(saveAddress(addressDetails));

        if (state?.onConfirmBackTo) {
          dispatch(
            push(state?.onConfirmBackTo, {
              ...state,
              orderSummaryData: { ...state.orderSummaryData, deliveryAddress: addressDetails },
            }),
          );
        } else {
          dispatch(push(advertiserMenu));
        }
      }
    },
    [
      dispatch,
      state,
      deliveryTakeawayConfig,
      saveAddress,
      setCurrentAddress,
      neighborhoodAreaConfigs,
      onOpen,
      fetchedAddress,
    ],
  );

  const onClickSelectAnotherAddress = () => {
    dispatch(push(listAddress));
  };

  return (
    <>
      <Box px="3rem" pt={8} display="flex" alignItems="center" justifyContent="flex-start" w="100%">
        <GoToButton />
        <Text
          as="span"
          fontSize="1.5rem"
          fontWeight="bold"
          display="flex"
          alignItems="center"
          justifyContent="center"
          w="100%"
          pr="1rem"
        >
          Meu Endereço
        </Text>
      </Box>

      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingBottom: '10rem' }}
      >
        <Box pt="4rem" display="flex" alignItems="center" justifyContent="space-between" mb={6}>
          <FormControl mr={6} isInvalid={!!errors.zipcode}>
            <FormLabel fontSize="1.5rem" fontWeight="bold">
              Informe seu CEP
            </FormLabel>
            <InputGroup>
              <Input
                placeholder="00000-000"
                type="text"
                name="zipcode"
                autoComplete="off"
                onKeyUp={handleZipcodeKeyUp}
                register={register({
                  required: 'Por favor informe seu CEP.',
                  pattern: {
                    value: RegExp(/\d{5}-\d{3}/),
                    message: 'O formato do CEP é inválido.',
                  },
                })}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValue('zipcode', zipcodeMask(e.target.value, true))
                }
              />
              {!!errors.zipcode && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors.zipcode?.message}
                    bg="red.400"
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
          <FormControl isInvalid={!!errors.state}>
            <FormLabel fontSize="1.5rem" fontWeight="bold">
              Estado
            </FormLabel>
            <InputGroup>
              <Select
                placeholder="Seu estado"
                name="state"
                isDisabled
                register={register({
                  required: 'Por favor selecione seu estado.',
                  maxLength: 2,
                })}
              >
                {ufs.map((uf) => (
                  <option key={uf.id} value={uf.sigla}>
                    {uf.nome}
                  </option>
                ))}
              </Select>
            </InputGroup>
          </FormControl>
        </Box>

        <FormControl mb={6} isInvalid={!!errors.city}>
          <FormLabel fontSize="1.5rem" fontWeight="bold">
            Sua Cidade
          </FormLabel>
          <InputGroup>
            <Input
              placeholder="Ex: Niteroi"
              type="text"
              name="city"
              autoComplete="off"
              register={register({
                required: 'Por favor informe sua cidade.',
                maxLength: {
                  value: 100,
                  message:
                    'O nome dessa cidade é muito grande. Deve possuir no máximo 100 caracteres.',
                },
              })}
            />
            {!!errors.city && (
              <InputRightElement h="50px" w="50px">
                <Tooltip
                  aria-label="Erro."
                  placement="top"
                  hasArrow
                  label={errors.city?.message}
                  bg="red.400"
                >
                  <IconButton
                    aria-label="Erro."
                    icon="warning"
                    color="red.300"
                    _focus={undefined}
                  />
                </Tooltip>
              </InputRightElement>
            )}
          </InputGroup>
        </FormControl>

        <FormControl mb={6} isInvalid={!!errors.street}>
          <FormLabel fontSize="1.5rem" fontWeight="bold">
            Sua Rua
          </FormLabel>
          <InputGroup>
            <Input
              placeholder="Ex: Rua Antonio Cabral"
              type="text"
              autoComplete="off"
              name="street"
              register={register({
                required: 'Por favor informe sua rua.',
                maxLength: {
                  value: 255,
                  message: 'A rua é muito grande. Deve possuir no máximo 255 caracteres.',
                },
              })}
            />
            {!!errors.street && (
              <InputRightElement h="50px" w="50px">
                <Tooltip
                  aria-label="Erro."
                  placement="top"
                  hasArrow
                  label={errors.street?.message}
                  bg="red.400"
                >
                  <IconButton
                    aria-label="Erro."
                    icon="warning"
                    color="red.300"
                    _focus={undefined}
                  />
                </Tooltip>
              </InputRightElement>
            )}
          </InputGroup>
        </FormControl>

        {deliveryTakeawayConfig?.is_using_range_area &&
        !deliveryTakeawayConfig?.is_using_neighborhood_area ? (
          <FormControl mb={6} isInvalid={!!errors.neighborhood}>
            <FormLabel fontSize="1.5rem" fontWeight="bold">
              Seu Bairro
            </FormLabel>
            <InputGroup>
              <Input
                placeholder="Ex: Icaraí"
                type="text"
                autoComplete="off"
                name="neighborhood"
                register={register({
                  required: 'Por favor informe seu bairro.',
                  maxLength: {
                    value: 100,
                    message: 'O bairro é muito grande. Deve possuir no máximo 100 caracteres.',
                  },
                })}
              />
              {!!errors.neighborhood && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors.neighborhood?.message}
                    bg="red.400"
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
        ) : (
          <FormControl mb={6} isInvalid={!!errors.neighborhood}>
            <Select
              placeholder="Seu bairro"
              name="neighborhood"
              register={register({
                required: 'Por favor selecione seu bairro.',
                maxLength: 100,
              })}
            >
              {neighborhoods?.map((n) => (
                <option key={n.value} value={n.value}>
                  {n.label}
                </option>
              ))}
            </Select>
          </FormControl>
        )}

        <Box pt="1rem" display="flex" alignItems="center" justifyContent="space-between">
          <FormControl mr={6} isInvalid={!!errors.number}>
            <FormLabel fontSize="1.5rem" fontWeight="bold" htmlFor="number">
              Seu número
            </FormLabel>
            <InputGroup>
              <Input
                placeholder="Ex: 345"
                type="text"
                name="number"
                id="number"
                register={register({
                  required: 'Por favor informe seu número.',
                  maxLength: {
                    value: 10,
                    message: 'O número é muito grande. Deve possuir no máximo 10 caracteres.',
                  },
                })}
              />
              {!!errors.number && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors.number?.message}
                    bg="red.400"
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
          <FormControl isInvalid={!!errors.complement}>
            <FormLabel fontSize="1.5rem" fontWeight="bold" htmlFor="complement">
              Complemento
            </FormLabel>
            <InputGroup>
              <Input
                placeholder="Ex: Quadra/Lote/Apt"
                type="text"
                id="complement"
                name="complement"
                register={register({
                  maxLength: {
                    value: 255,
                    message: 'O complemento é muito grande. Deve possuir no máximo 255 caracteres.',
                  },
                })}
              />
              {!!errors.complement && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors.complement?.message}
                    bg="red.400"
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
        </Box>

        <BottomBar zIndex={600}>
          <Box d="flex" w="100%" py={4}>
            <Button
              flex="1"
              type="submit"
              bg="green.300"
              isLoading={loading || onSubmitLoading}
              isDisabled={Object.keys(errors).length > 0}
            >
              Confirmar Endereço
            </Button>
          </Box>
        </BottomBar>

        <UnsupportedAddressModal
          isOpen={isOpen}
          onClose={onClose}
          onClickAction={onClickSelectAnotherAddress}
        />
      </Form>
    </>
  );
};

export default ConfirmAddress;
