import { BoxProps, PseudoBox, Text } from '@chakra-ui/core';
import React from 'react';

export interface SeeMoreScheduleOptionsProps extends BoxProps {}

const SeeMoreScheduleOptions: React.FC<SeeMoreScheduleOptionsProps> = ({
  borderRadius = '0.5rem',
  borderWidth = '1px',
  borderStyle = 'solid',
  borderColor = 'gray.300',
  flexDir = 'column',
  fontSize = '1.3rem',
  minW = '15rem',
  p = 5,
  ...rest
}) => {
  return (
    <PseudoBox
      d="flex"
      as="button"
      minW={minW}
      h="10rem"
      cursor="pointer"
      pos="relative"
      p={p}
      flexDir={flexDir}
      borderWidth={borderWidth}
      borderStyle={borderStyle}
      borderColor={borderColor}
      borderRadius={borderRadius}
      fontSize={fontSize}
      alignItems="center"
      justifyContent="center"
      transition="box-shadow 0.2s ease"
      _hover={{
        boxShadow: '0 0 8px 2px #349B2C40',
      }}
      {...rest}
    >
      <Text as="span" fontWeight="700" color="green.600" mb={2}>
        Ver mais...
      </Text>
    </PseudoBox>
  );
};

export default SeeMoreScheduleOptions;
