import { Box, Icon, Text } from '@chakra-ui/core';
import React, { useState } from 'react';

import LoyaltyCard from '~/models/LoyaltyCard';
import UserLoyaltyCard from '~/models/UserLoyaltyCard';
import { formatCurrencyBRL } from '~/utils/currency';

type LoyaltyCardRulesType = UserLoyaltyCard | LoyaltyCard;

interface LoyaltyCardRulesProps {
  currentLoyaltyCard: LoyaltyCardRulesType;
}

const LoyaltyCardRules: React.FC<LoyaltyCardRulesProps> = ({ currentLoyaltyCard }) => {
  const [isViewingRules, setIsViewingRules] = useState(false);

  return (
    <Box d="flex" flexDir="column" alignItems="center" justifyContent="center" mt="1rem">
      <Box
        w="120px"
        d="flex"
        alignItems="center"
        mb={8}
        onClick={() => setIsViewingRules(!isViewingRules)}
        cursor="pointer"
      >
        <Icon
          name="arrow-back"
          size="2.2rem"
          transform={isViewingRules ? 'rotate(90deg)' : 'rotate(270deg)'}
          mr={4}
          w="auto"
        />
        <Text fontSize="1.6rem" as="span" w="auto">
          Ver Regras
        </Text>
      </Box>
      {isViewingRules && (
        <Box
          d="flex"
          flexDir="column"
          w="100%"
          alignItems="center"
          justifyContent="center"
          px="2rem"
        >
          <Text fontSize="1.2rem" as="span" mb="1.5rem">
            * Os pedidos devem ser efetuados num período de{' '}
            {currentLoyaltyCard?.card_expiry_in_days} dias contados a partir da primeira compra
            válida para o cartão fidelidade ativo
          </Text>
          {currentLoyaltyCard?.is_ticket_minimum_active && (
            <Text fontSize="1.2rem" as="span" mb="1.5rem">
              * Valor mínimo do pedido para contabilizar deve ser{' '}
              {formatCurrencyBRL(currentLoyaltyCard?.ticket_minimum_amount)}
            </Text>
          )}
          {currentLoyaltyCard?.is_ticket_minimum_retrieval_active && (
            <Text fontSize="1.2rem" as="span" mb="1.5rem">
              * Valor mínimo do pedido para utilizar o desconto deve ser{' '}
              {formatCurrencyBRL(currentLoyaltyCard?.ticket_minimum_amount_retrieval)}
            </Text>
          )}
          {currentLoyaltyCard?.is_accept_delivery && !currentLoyaltyCard?.is_accept_physical && (
            <Text fontSize="1.2rem" as="span" mb="1.5rem">
              * O desconto somente poderá ser utilizado nos pedidos por Delivery
            </Text>
          )}
          {currentLoyaltyCard?.is_accept_physical && !currentLoyaltyCard?.is_accept_delivery && (
            <Text fontSize="1.2rem" as="span" mb="1.5rem">
              * O desconto somente poderá ser utilizado nos pedidos para Retirada
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
};

export default LoyaltyCardRules;
