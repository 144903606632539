import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/core';
import React from 'react';

import Button from '../Button';

interface UnsupportedAddressModalProps {
  isOpen: boolean;
  onClose: () => void;
  onClickAction?: () => void;
}

const UnsupportedAddressModal: React.FC<UnsupportedAddressModalProps> = ({
  isOpen,
  onClose,
  onClickAction,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent p={8} borderRadius="2rem">
        <ModalBody mb={3}>
          <Heading as="h4" fontWeight="700" fontSize="lg">
            Infelizmente ainda não fazemos entregas neste endereço.
          </Heading>
        </ModalBody>

        <ModalFooter justifyContent="flex-start">
          <Button flex="1" bg="green.300" mr={4} onClick={onClickAction ? onClickAction : onClose}>
            Selecionar outro endereço
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UnsupportedAddressModal;
