import { Box, Divider, Stack, Tab, TabList, TabPanel, Tabs, Text } from '@chakra-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';

import Button from '~/components/Button';
import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import ListItem from '~/components/ListItem';
import NavBar from '~/components/NavBar';
import OrderItem from '~/components/OrderItem';
import { StyledTabPanels } from '~/components/StyledTabPanels';
import TopRoundedBox from '~/components/TopRoundedBox';
import { advertiserMenu } from '~/routes/routeMap';
import { sendOrdersFollowUp } from '~/services/mixPanel';
import { RootState } from '~/store';
import { Creators as OrderCreators } from '~/store/ducks/order';
import RealTimeDimensions from '~/utils/realTimeDimensions';

import { CustonText } from './styles';

const { getMyOrders } = OrderCreators;

const MyOrders: React.FC = () => {
  const [width] = RealTimeDimensions();
  const isMobile = width > 0 && width < 800 ? true : false;
  const dispatch = useDispatch();

  const globalState = useSelector((state: RootState) => state);
  const { orders, pagination, previousOrders, createOrderLoading } = globalState.order;
  const [query, setQuery] = useState({ page: 1, perPage: 10 });

  useEffect(() => {
    dispatch(getMyOrders(query.page, query.perPage));
    // MixPanel Event
    sendOrdersFollowUp('Orders Follow Up', {
      id: globalState.user?.user.id ? globalState.user?.user.id.toString() : null,
    });
  }, [dispatch, query]);

  const fetchMoreOrders = useCallback(() => {
    if (query.page >= pagination?.lastPage) {
      return;
    }
    setQuery((prevQuery) => ({
      ...prevQuery,
      page: prevQuery.page + 1,
    }));
  }, [pagination, query.page]);

  return (
    <Box as="main" flexDir="column" w="100%" bg="green.400">
      <HeaderWithBackButton to={advertiserMenu} headerTitle="Meus pedidos" px="3rem" />

      <TopRoundedBox as="section" pb={isMobile ? 70 : 90}>
        <Tabs isFitted px={12}>
          <TabList borderBottom="1px solid">
            <Tab
              fontSize="md"
              fontWeight="700"
              py={5}
              _focus={{ outline: '0' }}
              color="gray.400"
              borderBottom="5px solid"
              _selected={{ borderColor: 'green.400', color: 'gray.800' }}
              d="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CustonText>Em Andamento</CustonText>
              {orders?.length > 0 && (
                <Box w="10px" h="10px" background="#FF6C6C" ml="10px" borderRadius="100%" />
              )}
            </Tab>
            <Tab
              fontSize="md"
              fontWeight="700"
              py={5}
              _focus={{ outline: '0' }}
              color="gray.400"
              borderBottom="5px solid"
              _selected={{ borderColor: 'green.400', color: 'gray.800' }}
            >
              <CustonText>Anteriores</CustonText>
            </Tab>
          </TabList>

          <StyledTabPanels py={12}>
            <TabPanel>
              <Stack spacing={0}>
                {orders?.map((item, index) => (
                  <>
                    <ListItem key={`order-${item.id}-${index}`} borderTop="0">
                      <OrderItem orderList="current" loading={false} item={item} />
                    </ListItem>
                    <Divider w="100%" h="10%" mt={5} />
                  </>
                ))}
                {createOrderLoading && (
                  <>
                    <ListItem borderTop="0">
                      <OrderItem loading={createOrderLoading} />
                    </ListItem>
                    <Divider w="100%" h="10%" m={0} />
                    <ListItem borderTop="0">
                      <OrderItem loading={createOrderLoading} />
                    </ListItem>
                    <Divider w="100%" h="10%" m={0} />
                  </>
                )}
                {!createOrderLoading && orders?.length <= 0 && (
                  <ListItem borderTop="0" d="flex" alignItems="center" justifyContent="center">
                    <Text fontSize="sm">Não há pedidos em andamento</Text>
                  </ListItem>
                )}
              </Stack>
            </TabPanel>
            <TabPanel>
              <Stack spacing={0}>
                {previousOrders?.map((item, index) => (
                  <>
                    <ListItem key={index} borderTop="0">
                      <OrderItem orderList="previous" loading={false} item={item} />
                    </ListItem>
                    <Divider w="100%" h="10%" m={0} />
                  </>
                ))}
                {createOrderLoading && (
                  <>
                    <ListItem borderTop="0">
                      <OrderItem loading={createOrderLoading} />
                    </ListItem>
                    <Divider w="100%" h="10%" m={0} />
                    <ListItem borderTop="0">
                      <OrderItem loading={createOrderLoading} />
                    </ListItem>
                    <Divider w="100%" h="10%" m={0} />
                  </>
                )}
                {!createOrderLoading && previousOrders?.length <= 0 && (
                  <ListItem borderTop="0" d="flex" alignItems="center" justifyContent="center">
                    <Text fontSize="sm">Não há pedidos anteriores</Text>
                  </ListItem>
                )}
              </Stack>
              <Box d="flex" justifyContent="center" py={4} mt={12}>
                <Button
                  width="min(100%, 20rem)"
                  onClick={fetchMoreOrders}
                  isDisabled={query.page >= pagination?.lastPage}
                  isLoading={createOrderLoading}
                  rightIcon={IoIosArrowDown}
                  bg={'green.200'}
                >
                  Ver mais
                </Button>
              </Box>
            </TabPanel>
          </StyledTabPanels>
        </Tabs>
        <NavBar />
      </TopRoundedBox>
    </Box>
  );
};

export default MyOrders;
