import { Badge, Box, BoxProps, Icon, Image, Text } from '@chakra-ui/core';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Coupon from '~/models/Coupon';
import currentWeekDay from '~/pages/AdvertiserMenu/utils/currentWeekDay';
import { RootState } from '~/store';
import { mainGradient } from '~/styles/gradients';
import { hasDecimalPlaces } from '~/utils/currency';

import { CardBox, CustomText, CustonImage } from './styles';

interface ProductCardProps extends BoxProps {
  item: Coupon;
}

const ProductCard: React.FC<ProductCardProps> = ({ item, onClick, ...rest }) => {
  const globalState = useSelector((state: RootState) => state);
  const { advertiserInfo } = globalState.advertiser;
  const type = item?.type;
  const mode = item?.mode;
  const remains = item?.mode === 'normal' ? 0 : item?.usage_limit - item?.used_coupons;
  const today = currentWeekDay(new Date().getDay());
  const imageTimestamp = globalState?.advertiser.imageTimestamp;

  const itemName = useMemo(() => {
    const byValue = item?.couponBenefit?.by_value;
    const fromValue = item?.couponBenefit?.from_value;

    if (hasDecimalPlaces(byValue) || hasDecimalPlaces(fromValue)) {
      return item?.display_name;
    } else {
      return item?.short_display_name;
    }
  }, [item]);

  const discountPercentageOff = useMemo(() => {
    if (item) {
      const fromValue = item.couponBenefit?.from_value;
      const byValue = item.couponBenefit?.by_value;
      const percentage = Math.floor(Math.abs((byValue / fromValue) * 100 - 100));
      return percentage;
    }
  }, [item]);

  return (
    <CardBox onClick={onClick} cursor="pointer" {...rest}>
      <Box
        d="flex"
        flexDir="column"
        borderRadius="1rem"
        w="20rem"
        shadow="sm"
        flex="0 0 auto"
        position="relative"
        minH="230px"
        h="100%"
      >
        <Box position="absolute" top="1rem" left="1rem" zIndex={2} d="flex">
          {mode === 'flash' && (
            <Box
              w="30px"
              h="30px"
              bg="white"
              boxShadow="0px 3px 6px #00000029"
              borderRadius="50%"
              p={2}
              mr="5px"
            >
              <Icon name="flash" size="2rem" color="green.400" />
            </Box>
          )}

          {type === 'percentage_cart' && (
            <Box
              w="30px"
              h="30px"
              background={mainGradient}
              boxShadow="0px 3px 6px #00000029"
              borderRadius="50%"
              p={2}
              mr="5px"
            >
              <Text fontSize="2rem" fontWeight="bold" lineHeight="2rem">
                %
              </Text>
            </Box>
          )}
        </Box>
        {item?.product?.file ? (
          <Image
            src={
              item?.product?.file
                ? `${item?.product?.file?.url}?nocache=${imageTimestamp}`
                : '/images/hamburger.jpg'
            }
            borderTopRightRadius="1rem"
            borderTopLeftRadius="1rem"
            h="9rem"
            objectFit="cover"
            mb={2}
          />
        ) : (
          <CustonImage
            src={
              advertiserInfo?.user?.avatar &&
              `${advertiserInfo?.user?.avatar?.url}?nocache=${imageTimestamp}`
            }
            loading="lazy"
            borderTopRightRadius="1rem"
            borderTopLeftRadius="1rem"
            height="9rem"
            objectFit="cover"
            mb={2}
          />
        )}
        <Box px={3} py={3} d="flex" flexDir="column" h="100%" alignItems="flex-start">
          <CustomText as="h3" fontSize="sm" fontWeight="700" lineHeight="1.6" mb={4}>
            {itemName}
          </CustomText>
          <CustomText fontSize="sm" mb={2}>
            {item?.description}
          </CustomText>
          {item?.couponHorary && !item?.couponHorary[today].is_active && (
            <Badge
              variant="subtle"
              variantColor="yellow"
              color="yellow.600"
              px={2}
              py={1}
              mb={4}
              fontSize="1rem"
              fontWeight="700"
            >
              DISPONÍVEL EM DIAS ESPECÍFICOS
            </Badge>
          )}
          {mode === 'flash' && (
            <Box minH="1rem" mt="auto" d="flex" mb={2}>
              <Badge
                mt="auto"
                variant="subtle"
                variantColor="green"
                px={2}
                py={1}
                fontSize="1rem"
                fontWeight="700"
                w="auto"
              >
                {remains > 1 ? `RESTAM ${remains} CUPONS` : `RESTA ${remains} CUPOM`}
              </Badge>
            </Box>
          )}
          {['normal', 'flash'].includes(mode) && type === 'from_value_to_value' && (
            <Box minH="1rem" mt="auto" d="flex" mb={2}>
              <Badge
                variant="subtle"
                variantColor="green"
                px={2}
                py={1}
                mb={4}
                fontSize="1.1rem"
                fontWeight="700"
                w="auto"
              >
                {discountPercentageOff}% OFF
              </Badge>
            </Box>
          )}
        </Box>
      </Box>
    </CardBox>
  );
};

const propsAreEqual = (prevProps: ProductCardProps, nextProps: ProductCardProps) =>
  prevProps.item.id === nextProps.item.id &&
  prevProps.item.product?.id === nextProps.item.product?.id;

export default React.memo(ProductCard, propsAreEqual);
