export function onlyNumbers(value: string) {
  if (value) {
    return value.replace(/\D/g, '');
  }
  return '';
}

export function zipcodeMask(value: string, isSimplified: boolean = false) {
  if (value !== null && value !== undefined) {
    const cep = onlyNumbers(value);

    if (isSimplified) {
      return cep.replace(/(\d{5})(\d)/, '$1-$2').replace(/(-\d{3})\d+?$/, '$1');
    }
    // 00.000-000
    return cep
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1');
  }
  return '';
}

export function phoneMask(value: string) {
  if (value !== null && value !== undefined) {
    let phone = onlyNumbers(value);

    if (phone.length >= 12) {
      phone = phone.substring(2, phone.length);
    }
    // 00 0000-0000
    if (phone.length <= 10) {
      return phone
        .replace(/(\d{2})(\d)/, '$1 $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    }
    return phone
      .replace(/(\d{2})(\d)/, '$1 $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }
  return '';
}

export function cellPhoneMask(value: string) {
  if (value !== null && value !== undefined) {
    const phone = onlyNumbers(value);

    // 00 0000-0000
    if (phone.length <= 10) {
      return phone
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    }
    return phone
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }
  return '';
}

export function numberMask(value: string) {
  if (value !== null && value !== undefined) {
    const amount = onlyNumbers(value);

    // 999.99
    return amount.replace(/(\d{1,3})(\d{2})/, '$1.$2');
  }
  return '0';
}

export function cpfMask(value: string) {
  if (value !== null && value !== undefined) {
    const cpf = onlyNumbers(value);

    // 000.000.000-00
    return cpf
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }
  return '';
}
