import { Badge, Box, BoxProps, Heading, IconButton, Stack, Text } from '@chakra-ui/core';
import React, { createRef, useCallback, useEffect, useState } from 'react';

import CircleToggle from '~/components/CircleToggle';
import ListItem from '~/components/ListItem';
import SelectedComplement from '~/models/SelectedComplement';
import { ScrollSmoothTop } from '~/styles/animations/ScrollSmooth';
import { formatCurrencyBRL } from '~/utils/currency';
import numToWords from '~/utils/numToWords';

export interface ComplementsMenuProps extends BoxProps {
  complements?: any[];
  onChangeComplementOptions?: (complements: SelectedComplement[]) => void;
}

const ComplementsMenu: React.FC<ComplementsMenuProps> = ({
  complements,
  onChangeComplementOptions,
  ...rest
}) => {
  const [complementOptions, setComplementOptions] = useState<SelectedComplement[]>(
    complements?.map((complement) => ({
      id: complement.id,
      name: complement.name,
      min_qty: complement.min_qty,
      max_qty: complement.max_qty,
      is_required: complement.is_required,
      is_base_amount_more_expensive: complement.is_base_amount_more_expensive,
      options: complement.options
        ? complement.options.map((option: any) => ({
            id: option.id,
            name: option.name,
            description: option.description,
            amount: option.amount,
            is_selected: option.is_selected,
            quantity: option.quantity,
          }))
        : complement.productComplementOptions.map((option: any) => ({
            id: option.id,
            name: option.name,
            description: option.description,
            amount: option.amount,
            is_selected: false,
            quantity: 0,
          })),
    })),
  );

  const [complementRefs, setComplementRefs] = useState<React.MutableRefObject<HTMLDivElement>[]>(
    [],
  );

  useEffect(() => {
    setComplementRefs((prevRefs) =>
      Array(complementOptions?.length)
        .fill(undefined)
        .map((_, index) => prevRefs[index] || createRef()),
    );
  }, [complementOptions]);

  useEffect(() => {
    setComplementOptions(() => {
      return complements?.map((complement) => ({
        id: complement.id,
        name: complement.name,
        min_qty: complement.min_qty,
        max_qty: complement.max_qty,
        is_required: complement.is_required,
        is_base_amount_more_expensive: complement.is_base_amount_more_expensive,
        options: complement.options
          ? complement.options.map((option: any) => ({
              id: option.id,
              name: option.name,
              description: option.description,
              amount: option.amount,
              is_selected: option.is_selected,
              quantity: option.quantity,
            }))
          : complement.productComplementOptions?.map((option: any) => ({
              id: option.id,
              name: option.name,
              description: option.description,
              amount: option.amount,
              is_selected: false,
              quantity: 0,
            })),
      }));
    });
  }, [complements]);

  useEffect(() => {
    onChangeComplementOptions && onChangeComplementOptions(complementOptions);
  }, [onChangeComplementOptions, complementOptions]);

  function handleScrollIntoComplement(complement_index: number, optionsSum: number) {
    // Scroll to the next complement with a delay
    setTimeout(() => {
      if (
        complementOptions[complement_index].is_required &&
        complementOptions[complement_index].max_qty === optionsSum &&
        complement_index >= 0 &&
        complement_index + 1 <= complementOptions.length - 1
      ) {
        ScrollSmoothTop({ ref: complementRefs[complement_index + 1], complementsScroll: true });
      }
    }, 400);
  }

  const markItemAsSelected = (
    complement_id: number,
    option_id: number,
    complementRefPosition?: number,
  ) => {
    setComplementOptions((prevState) => {
      const complement = prevState.find((c) => c.id === complement_id);
      const option = complement.options.find((o) => o.id === option_id);

      complement.options?.forEach((o) => {
        if (o.id === option.id) {
          o.is_selected = true;
          o.quantity = 1;
        } else {
          o.is_selected = false;
          o.quantity = 0;
        }
      });
      const newState = prevState.map((prevComplement) => {
        if (prevComplement.id === complement_id) {
          return complement;
        }
        return prevComplement;
      });
      return newState;
    });
    handleScrollIntoComplement(complementRefPosition, 1);
  };

  const onChangeQuantity = useCallback(
    (
      complement_id: number,
      option_id: number,
      shouldIncrease: boolean,
      complementRefPosition?: number,
    ) => {
      const complement = complementOptions.find((c) => c.id === complement_id);
      const option = complement.options.find((o) => o.id === option_id);
      let optionsSum = 0;
      complement.options?.forEach((o) => {
        optionsSum += o.quantity;
      });
      if (shouldIncrease && optionsSum < complement.max_qty) {
        option.quantity += 1;
        optionsSum += 1;
      } else if (!shouldIncrease && optionsSum !== 0) {
        option.quantity -= 1;
        optionsSum -= 1;
      }

      const newState = complementOptions?.map((prevComplement) => {
        if (prevComplement.id === complement_id) {
          return complement;
        }
        return prevComplement;
      });

      setComplementOptions(newState);
      handleScrollIntoComplement(complementRefPosition, optionsSum);
    },
    [complementOptions],
  );

  const getCurrentQuantity = useCallback(
    (complement_id: number) => {
      const complement = complementOptions.find((c) => c.id === complement_id);
      let optionsSum = 0;
      complement.options?.forEach((o) => {
        optionsSum += o.quantity;
      });
      return Number(optionsSum);
    },
    [complementOptions],
  );

  return (
    <Box {...rest}>
      {complementOptions.map((complement, idx) => (
        <Stack spacing={0} key={complement.id} ref={complementRefs[idx]}>
          <ListItem justifyContent="space-between" flexDir="row" alignItems="center">
            <Heading as="h4" fontSize="md" fontWeight="700" d="flex" flexDir="column">
              {complement.name}
              {complement.is_required && complement.max_qty > 1 && (
                <>
                  <Text as="span" fontSize="sm" fontWeight="300" mt="5px">
                    {getCurrentQuantity(complement.id)} de {complement.max_qty} opções
                  </Text>

                  <Text as="span" fontSize="sm" fontWeight="300" mt="5px" color="#FF6C6C">
                    {getCurrentQuantity(complement.id) < complement.min_qty &&
                      `Mínimo ${
                        complement.min_qty === 1
                          ? 'uma'
                          : complement.min_qty === 2
                          ? 'duas'
                          : numToWords(complement.min_qty)
                      } ${complement.min_qty > 1 ? 'opções' : 'opção'}`}
                  </Text>
                </>
              )}
              {!complement.is_required && complement.max_qty > 1 && (
                <Text as="span" fontSize="sm" fontWeight="300" mt="5px">
                  Até {complement.max_qty} opções
                </Text>
              )}
              {complement.is_required && complement.max_qty === 1 && (
                <Text as="span" fontSize="sm" fontWeight="300" mt="5px">
                  Selecione uma opção
                </Text>
              )}
              {!complement.is_required && complement.max_qty === 1 && (
                <Text as="span" fontSize="sm" fontWeight="300" mt="5px">
                  Até uma opção
                </Text>
              )}
            </Heading>
            {complement.is_required && (
              <Badge
                variant="subtle"
                bg="black"
                color="white"
                px={2}
                py={1}
                fontSize="1.1rem"
                fontWeight="700"
              >
                OBRIGATÓRIO
              </Badge>
            )}
          </ListItem>
          {complement.options?.map((option) =>
            complement.max_qty > 1 ? (
              <ListItem
                d="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDir="row"
                key={option.id}
              >
                <Box d="flex" flexDir="column">
                  <Text as="span" fontSize="md" mb={2}>
                    {option.name}
                  </Text>

                  {!!option.amount && (
                    <Text as="span" fontSize="sm" color="gray.300">
                      + {formatCurrencyBRL(option.amount)}
                    </Text>
                  )}

                  {option.description && (
                    <Text as="span" color="gray.500" fontSize="sm" mb={2} pr={4}>
                      {option.description}
                    </Text>
                  )}
                </Box>

                <Stack spacing={4} isInline alignItems="center">
                  {option.quantity > 0 && (
                    <>
                      <IconButton
                        aria-label="Remover item"
                        icon="minus"
                        stroke="gray.800"
                        size="md"
                        background="transparent"
                        mr="20px"
                        onClick={() => onChangeQuantity(complement.id, option.id, false, idx)}
                      />
                      <Text fontSize="md">{option.quantity}</Text>
                    </>
                  )}
                  <IconButton
                    aria-label="Adicionar item"
                    icon="plus-square"
                    stroke="gray.800"
                    size="md"
                    background="transparent"
                    ml="20px"
                    onClick={() => onChangeQuantity(complement.id, option.id, true, idx)}
                  />
                </Stack>
              </ListItem>
            ) : (
              <ListItem
                key={option.id}
                d="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDir="row"
                cursor="pointer"
                onClick={() => markItemAsSelected(complement.id, option.id, idx)}
              >
                <Box d="flex" flexDir="column">
                  <Text as="span" fontSize="md" mb={2}>
                    {option.name}
                  </Text>
                  {!!option.amount && (
                    <Text as="span" fontSize="sm" color="gray.300">
                      + {formatCurrencyBRL(option.amount)}
                    </Text>
                  )}
                  {option.description && (
                    <Text as="span" color="gray.500" fontSize="sm" mb={2} pr={4}>
                      {option.description}
                    </Text>
                  )}
                </Box>
                <Box d="flex" alignItems="center" justifyContent="center">
                  <CircleToggle isActive={option.is_selected} />
                </Box>
              </ListItem>
            ),
          )}
        </Stack>
      ))}
    </Box>
  );
};

export default ComplementsMenu;
