import { ButtonProps } from '@chakra-ui/core';
import React from 'react';

import Button from '~/components/Button';

const MenuButton: React.FC<ButtonProps> = ({ children, ...rest }: ButtonProps) => {
  return (
    <Button
      my="0.3em"
      w="100%"
      h="35px"
      background="transparent"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p="0"
      {...rest}
    >
      {children}
    </Button>
  );
};

export default MenuButton;
