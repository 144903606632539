import {
  Box,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  InputGroup,
  InputRightElement,
  Stack,
  StackProps,
  Text,
  Tooltip,
} from '@chakra-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import BottomBar from '~/components/BottomBar';
import Button from '~/components/Button';
import Input from '~/components/Form/Input';
import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import { AddressComponent } from '~/models/Autocomplete';
import confirmAddressSchema from '~/models/schemas/confirmAddressSchema';
import { AddressFields, getFieldFromAddressComponents } from '~/utils/address';
import { zipcodeMask } from '~/utils/masks';

export interface ConfirmPredictionProps extends StackProps {
  onCloseBottomBar: () => void;
  loading: boolean;
  onSubmitSelectedPrediction: (data: ConfirmAddressFields) => Promise<void>;
  selectedPrediction?: {
    mainText: string;
    secondaryText: string;
  };
  selectedAddressComponents?: AddressComponent[];
}

interface ConfirmAddressFields {
  number?: string;
  complement?: string;
  neighborhood?: string;
  zipcode?: string;
}

const ConfirmPrediction: React.FC<ConfirmPredictionProps> = ({
  onCloseBottomBar,
  selectedPrediction,
  loading,
  selectedAddressComponents,
  onSubmitSelectedPrediction,
  children,
  ...rest
}) => {
  const [shouldValidateZipcode, setShouldValidateZipcode] = useState<boolean>(true);
  const { register, errors, handleSubmit, setValue } = useForm<ConfirmAddressFields>({
    resolver: useYupValidationResolver(confirmAddressSchema(shouldValidateZipcode)),
  });
  const [hiddenFields, setHiddenFields] = useState<Array<string>>([]);

  const isFieldHidden = useCallback(
    (field: string) => {
      return hiddenFields.includes(field);
    },
    [hiddenFields],
  );

  const toggleField = useCallback((value: string, field: string, hiddenFieldsList: string[]) => {
    if (value.trim() !== '') {
      hiddenFieldsList = hiddenFieldsList.concat([field]);
    } else {
      hiddenFieldsList = hiddenFieldsList.filter((item) => item !== field);
    }
    return hiddenFieldsList;
  }, []);

  useEffect(() => {
    const number = getFieldFromAddressComponents(AddressFields.NUMBER, selectedAddressComponents);
    setValue('number', number);
    const zipcode = getFieldFromAddressComponents(AddressFields.ZIPCODE, selectedAddressComponents);
    setValue('zipcode', zipcode);
    // Google returned zipcode, hide zipcode input and dont validate using RegEx
    if (zipcode.trim() !== '') {
      setShouldValidateZipcode(false);
    }

    const neighborhood = getFieldFromAddressComponents(
      AddressFields.NEIGHBORHOOD,
      selectedAddressComponents,
    );
    setValue('neighborhood', neighborhood);

    setHiddenFields((prevHiddenFields) => {
      prevHiddenFields = toggleField(neighborhood, 'neighborhood', prevHiddenFields);
      prevHiddenFields = toggleField(zipcode, 'zipcode', prevHiddenFields);
      return prevHiddenFields;
    });
    // Hide zipcode field if it is returned from google maps
  }, [selectedAddressComponents, toggleField, setValue]);

  return (
    <BottomBar isOverlayVisible onClose={onCloseBottomBar} py={8}>
      <Stack
        as="form"
        direction="column"
        spacing={8}
        onSubmit={handleSubmit(onSubmitSelectedPrediction)}
        {...rest}
      >
        <Heading as="h4" fontSize="md" fontWeight="500" color="gray.600" textAlign="center">
          Confirme seu endereço
        </Heading>
        <Box p={4} w="100%" d="flex" flexDir="column" alignItems="center">
          <Text fontSize="md" fontWeight="700" mb={2} color="gray.600">
            {selectedPrediction?.mainText}
          </Text>
          <Text fontSize="md" color="gray.400" mb={2}>
            {selectedPrediction?.secondaryText}
          </Text>
        </Box>

        <Box d="flex" mb={4}>
          <FormControl mr={4} isInvalid={!!errors.number} w="100%">
            <FormLabel fontSize="1.5rem" fontWeight="bold" htmlFor="number">
              Número
            </FormLabel>
            <InputGroup w="100%">
              <Input register={register} placeholder="Número" name="number" id="number" />
              {!!errors.number && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors.number?.message}
                    bg="red.400"
                    zIndex={1500}
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
          <FormControl isInvalid={!!errors.complement} w="100%">
            <FormLabel fontSize="1.5rem" fontWeight="bold" htmlFor="complement">
              Complemento
            </FormLabel>
            <InputGroup>
              <Input
                register={register}
                placeholder="Complemento"
                name="complement"
                id="complement"
              />
              {!!errors.complement && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors.complement?.message}
                    bg="red.400"
                    zIndex={1500}
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
        </Box>
        <Box d="flex">
          <FormControl
            isInvalid={!!errors.neighborhood}
            d={isFieldHidden('neighborhood') && 'none'}
            mr={!isFieldHidden('zipcode') && 4}
            w="100%"
          >
            <FormLabel fontSize="1.5rem" fontWeight="bold" htmlFor="neighborhood">
              Bairro
            </FormLabel>
            <InputGroup>
              <Input
                register={register}
                placeholder="Bairro"
                name="neighborhood"
                id="neighborhood"
              />
              {!!errors.neighborhood && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors.neighborhood?.message}
                    bg="red.400"
                    zIndex={1500}
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
          <FormControl isInvalid={!!errors.zipcode} d={isFieldHidden('zipcode') && 'none'} w="100%">
            <FormLabel fontSize="1.5rem" fontWeight="bold" htmlFor="zipcode">
              CEP
            </FormLabel>
            <InputGroup>
              <Input
                register={register}
                placeholder="CEP"
                name="zipcode"
                id="zipcode"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValue('zipcode', zipcodeMask(e.target.value, true))
                }
              />
              {!!errors.zipcode && (
                <InputRightElement h="50px" w="50px">
                  <Tooltip
                    aria-label="Erro."
                    placement="top"
                    hasArrow
                    label={errors.zipcode?.message}
                    bg="red.400"
                    zIndex={1500}
                  >
                    <IconButton
                      aria-label="Erro."
                      icon="warning"
                      color="red.300"
                      _focus={undefined}
                    />
                  </Tooltip>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
        </Box>
        <Button type="submit" isLoading={loading} isDisabled={loading}>
          Buscar endereço
        </Button>
      </Stack>
    </BottomBar>
  );
};

export default ConfirmPrediction;
