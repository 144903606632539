import mixpanel from 'mixpanel-browser';

import getEnvFromSubdomain from '~/utils/getEnvFromSubdomain';

const reactAppEnv = getEnvFromSubdomain();
// Deactivated 05/05/2022
const mixpanelActive = false;
const token = '7f79940b43400c5ffe4f76065ea699df';
const configs = {
  debug: reactAppEnv === 'development',
  ignore_dnt: true,
};

function initMixPanel(infoId: string) {
  // Only init mixpanel when in production
  if (reactAppEnv === 'production' && mixpanelActive) {
    mixpanel.init(token, configs);
    mixpanel.identify(infoId);
    // Only call mix panel alias with user id when not null
    if (infoId) {
      mixpanel.alias(infoId);
    }
  }
}

// Only sends data when in production. If is in other environment, log the request
function mixPanelTrack(eventName: String, trackedObject: Object) {
  if (reactAppEnv === 'production' && mixpanelActive) {
    mixpanel.track(`Web Menu Event - ${eventName}`, trackedObject);
  } else if (configs.debug) {
    console.log(`Web Menu Event - ${eventName}`, trackedObject);
  }
}

export function sendAccessedWebMenu(eventName: String, info: Record<string, string>) {
  initMixPanel(info.id);
  mixPanelTrack(eventName, {
    $distinct_id: info.id ? info.id : null,
    $name: info.name,
    $email: info.email,
    webMenuId: info.webMenuId,
    facebook_campaign_id: info.facebookCampaignId,
  });
}

export function sendOrderCreationEvent(eventName: String, info: Record<string, string>) {
  initMixPanel(info.id);
  mixPanelTrack(eventName, {
    id: info.id,
    $distinct_id: info.id ? info.id : null,
    webMenuId: info.webMenuId,
    orderType: info.orderType,
    deliveryType: info.deliveryType,
    slug: info.slug,
  });
}

export function sendPaymentMethodEvent(eventName: String, info: Record<string, string>) {
  initMixPanel(info.id);
  mixPanelTrack(eventName, {
    id: info.id,
    $distinct_id: info.id ? info.id : null,
    webMenuId: info.webMenuId,
    orderType: info.orderType,
    deliveryType: info.deliveryType,
    paymentMethod: info.paymentMethod,
  });
}

export function sendOrderConcludedEvent(eventName: String, info: Record<string, string>) {
  initMixPanel(info.id);
  mixPanelTrack(eventName, {
    id: info.id,
    $distinct_id: info.id ? info.id : null,
    webMenuId: info.webMenuId,
    orderType: info.orderType,
    deliveryType: info.deliveryType,
    paymentMethod: info.paymentMethod,
    amount: info.amount,
  });
}

export function sendOrdersFollowUp(eventName: String, info: Record<string, string>) {
  initMixPanel(info.id);
  mixPanelTrack(eventName, {
    id: info.id,
    $distinct_id: info.id ? info.id : null,
  });
}

export function sendWebAppRequest(eventName: String, info: Record<string, string>) {
  initMixPanel(info.id);
  mixPanelTrack(eventName, {
    advertiserId: info.advertiserId,
    segment: info.segment,
    loyaltyCardElegible: info.loyaltyCardElegible,
  });
}
