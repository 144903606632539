import { Box, Image, ImageProps as ChakraImageProps, Skeleton } from '@chakra-ui/core';
import React, { useCallback, useRef, useState } from 'react';

import useIntersectionObserver from '~/hooks/useIntersectionObserver';
import { debounce } from '~/utils/debounce';

export interface ImageProps extends Omit<ChakraImageProps, 'placeholder'> {
  src?: string;
  alt?: string;
}

const LazyImage: React.FC<ImageProps> = ({
  src,
  alt,
  w = '100%',
  h = 'auto',
  pos = 'absolute',
  borderRadius,
  opacity = 0,
  ...rest
}) => {
  const [isInView, setIsInView] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const containerRef = useRef();

  const debouncedHandleOnViewportEnter = debounce(() => {
    if (!isInView) {
      setIsInView(true);
    }
  }, 200);

  const handleOnImageLoad = useCallback(() => {
    if (!isLoaded) {
      setIsLoaded(true);
    }
  }, [isLoaded]);

  useIntersectionObserver({
    ref: containerRef,
    onViewportEnter: debouncedHandleOnViewportEnter,
  });

  return (
    <Box ref={containerRef} pos="relative" w={w} h={h}>
      {!isLoaded && <Skeleton w={w} h={h} borderRadius={borderRadius} />}
      {isInView && (
        <Image
          onLoad={handleOnImageLoad}
          src={src}
          alt={alt}
          w={w}
          h={h}
          pos={pos}
          opacity={isLoaded ? 1 : opacity}
          transition="opacity 0.3s"
          borderRadius={borderRadius}
          {...rest}
        />
      )}
    </Box>
  );
};

const arePropsEqual = (prevProps: ImageProps, nextProps: ImageProps) =>
  prevProps.src === nextProps.src;

export default React.memo(LazyImage, arePropsEqual);
