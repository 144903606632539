import {
  Box,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Image,
  InputGroup,
  InputRightElement,
  Tooltip,
} from '@chakra-ui/core';
import { push } from 'connected-react-router';
import addHours from 'date-fns/addHours';
import format from 'date-fns/format';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import BottomBar from '~/components/BottomBar';
import Button from '~/components/Button';
import Form from '~/components/Form';
import Input from '~/components/Form/Input';
import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import Select from '~/components/Select';
import TopRoundedBox from '~/components/TopRoundedBox';
import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import updateUserSchema from '~/models/schemas/updateUserSchema';
import { advertiserMenu, myProfile } from '~/routes/routeMap';
import { RootState } from '~/store';
import Creators from '~/store/ducks/user';
import { cpfMask, onlyNumbers } from '~/utils/masks';

import { FileInput } from './styles';

const { updatePersonalInfo } = Creators;

interface PersonalInfoInputs {
  name: string;
  cpf: string;
  birth_at: string;
  gender: string;
}

const ChangeInfo: React.FC = () => {
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const { user, avatar, loading } = globalState.user;
  const imageTimestamp = globalState?.advertiser.imageTimestamp;

  const { register, handleSubmit, setValue, errors } = useForm<PersonalInfoInputs>({
    defaultValues: {
      name: user.clientInfo?.name,
      birth_at: user.clientInfo?.birth_at
        ? format(addHours(new Date(user.clientInfo?.birth_at), 3), 'yyyy-MM-dd')
        : undefined,
      gender: user.clientInfo?.gender,
      cpf: cpfMask(user.clientInfo?.cpf),
    },
    resolver: useYupValidationResolver(updateUserSchema),
  });

  useEffect(() => {
    if (!user) {
      dispatch(push(advertiserMenu));
    }
  }, [user, dispatch]);

  const [fileData, setFileData] = useState<any>();
  const [
    cleanAvatar,
    // setCleanAvatar
  ] = useState<boolean>(false);

  const onSubmit = ({ name, cpf, birth_at, gender }: PersonalInfoInputs) => {
    const avatar = fileData ? fileData : null;

    const data: any = {
      name,
      cpf: onlyNumbers(cpf),
      birth_at,
      gender,
      avatar,
      cleanAvatar,
    };
    dispatch(updatePersonalInfo(data));
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];

    if (file) {
      const { name } = file;
      const size = (file.size / 1000000).toFixed(1);
      const type = file.type.split('/')[0];
      const subtype = file.type.split('/')[1];

      const subtypes = ['png', 'jpg', 'jpeg'];

      if (Number(size) > 1) {
        toast.error(
          'Erro ao adicionar a nova foto. O arquivo é grande demais (tamanho máximo de 5mb).',
        );
      } else if (type !== 'image') {
        toast.error('Erro ao adicionar a nova foto. O arquivo não é uma imagem.');
      } else if (!subtypes.includes(subtype)) {
        toast.error(
          'Erro ao adicionar a nova foto. O arquivo não é de um formato aceito, a imagem precisa ser do formato de .png, .jpg ou .jpeg. ',
        );
      } else {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = function () {
          setFileData({ base64Content: fileReader.result, name, type, sub_type: subtype });
        };
      }
    }
  };

  return (
    <Box as="main" flexDir="column" w="100%" bg="green.400">
      <HeaderWithBackButton
        headerTitle="Informações Pessoais"
        px="3rem"
        position="relative"
        to={myProfile}
      >
        <Box pb="2rem" position="relative">
          <Image
            zIndex={1}
            src={
              fileData
                ? fileData.base64Content
                : avatar
                ? `${avatar?.url}?nocache=${imageTimestamp}`
                : '/images/profile/profile@2x.png'
            }
            w="10rem"
            h="10rem"
            borderRadius="100%"
            objectFit="cover"
          />
          <Box
            zIndex={1}
            w="3rem"
            h="3rem"
            position="absolute"
            borderRadius="100%"
            background="white"
            right="0"
            bottom="20px"
            d="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon name="profile-photo" size="2rem" />
          </Box>
          <FileInput
            type="file"
            name="avatar"
            accept="image/*"
            ref={register}
            onChange={onChange}
          />
        </Box>
      </HeaderWithBackButton>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <TopRoundedBox as="section">
          <Box pt="4rem" px="3rem">
            <FormControl mb={6} w="100%" isInvalid={!!errors.name} isDisabled={loading}>
              <FormLabel htmlFor="name" fontWeight="700" fontSize="sm">
                Nome
              </FormLabel>
              <InputGroup>
                <Input type="text" id="name" name="name" register={register} mb="1rem" />
                {!!errors.name && (
                  <InputRightElement h="50px" w="50px">
                    <Tooltip
                      aria-label="Erro."
                      placement="top"
                      hasArrow
                      label={errors.name?.message}
                      bg="red.400"
                      zIndex={1500}
                    >
                      <IconButton
                        aria-label="Erro."
                        icon="warning"
                        color="red.300"
                        _focus={undefined}
                      />
                    </Tooltip>
                  </InputRightElement>
                )}
              </InputGroup>
            </FormControl>
            <FormControl mb={6} w="100%" isInvalid={!!errors.cpf} isDisabled={loading}>
              <FormLabel htmlFor="cpf" fontWeight="700" fontSize="sm">
                CPF
              </FormLabel>
              <InputGroup>
                <Input
                  type="text"
                  name="cpf"
                  id="cpf"
                  mb="1rem"
                  register={register}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setValue('cpf', cpfMask(e.target.value))
                  }
                />
                {!!errors.cpf && (
                  <InputRightElement h="50px" w="50px">
                    <Tooltip
                      aria-label="Erro."
                      placement="top"
                      hasArrow
                      label={errors.cpf?.message}
                      bg="red.400"
                      zIndex={1500}
                    >
                      <IconButton
                        aria-label="Erro."
                        icon="warning"
                        color="red.300"
                        _focus={undefined}
                      />
                    </Tooltip>
                  </InputRightElement>
                )}
              </InputGroup>
            </FormControl>
            <FormControl mb={6} w="100%" isInvalid={!!errors.birth_at} isDisabled={loading}>
              <FormLabel htmlFor="birth_at" fontWeight="700" fontSize="sm">
                Data de Nascimento
              </FormLabel>
              <InputGroup>
                <Input type="date" name="birth_at" id="birth_at" register={register} mb="1rem" />
                {!!errors.birth_at && (
                  <InputRightElement h="50px" w="50px">
                    <Tooltip
                      aria-label="Erro."
                      placement="top"
                      hasArrow
                      label={errors.birth_at?.message}
                      bg="red.400"
                      zIndex={1500}
                    >
                      <IconButton
                        aria-label="Erro."
                        icon="warning"
                        color="red.300"
                        _focus={undefined}
                      />
                    </Tooltip>
                  </InputRightElement>
                )}
              </InputGroup>
            </FormControl>
            <FormControl w="100%" isInvalid={!!errors.gender} isDisabled={loading}>
              <FormLabel htmlFor="birth_at" fontWeight="700" fontSize="sm">
                Sexo
              </FormLabel>
              <Select mb="1rem" name="gender" register={register}>
                <option value=""></option>
                <option value="male">Masculino</option>
                <option value="female">Feminino</option>
                <option value="other">Outros</option>
              </Select>
            </FormControl>
          </Box>
        </TopRoundedBox>

        <BottomBar zIndex={600} w="100%">
          <Box d="flex" w="100%" py={4}>
            <Button
              flex="1"
              type="submit"
              bg="green.300"
              isLoading={loading}
              isDisabled={!!Object.keys(errors).length}
            >
              Salvar alterações
            </Button>
          </Box>
        </BottomBar>
      </Form>
    </Box>
  );
};

export default ChangeInfo;
