import getEnvFromSubdomain from '~/utils/getEnvFromSubdomain';

const reactAppEnv = getEnvFromSubdomain();
const reactAppEnvGlobal = process.env.REACT_APP_ENV_GLOBAL_API;
const reactAppEnvWebMenu = process.env.REACT_APP_ENV_WEBMENU_API;

const baseUrls: Record<string, string> = {
  production: 'https://ms.kuppi.com.br',
  development: 'https://dev-ms.kuppi.com.br',
  qa: 'https://qa-ms.kuppi.com.br',
};

const baseUrlsWebSocket: Record<string, string> = {
  ...baseUrls,
  local: 'http://0.0.0.0:3336',
};

export const url = baseUrlsWebSocket[reactAppEnvWebMenu || reactAppEnvGlobal || reactAppEnv];

export const path = '/lambda/socket.io';

export const options = {
  secure: false,
  reconnection: true,
  rejectUnauthorized: false,
  auth: {
    token: '',
  },
  transports: ['websocket'],
};
