import { isValid } from 'cpf';
import { isDate, parse } from 'date-fns';
import * as yup from 'yup';

function parseDateString(value: Date, originalValue: string) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'yyyy-MM-dd', new Date());
  return parsedDate;
}

export const cpfRegex = /\d{3}.\d{3}.\d{3}-\d{2}/;

export default yup.object().shape({
  name: yup
    .string()
    .max(60, 'Seu nome deve ter no máximo 60 caracteres.')
    .required('Por favor informe seu nome.'),
  birth_at: yup
    .date()
    .transform(parseDateString)
    .typeError('Data de nascimento inválida.')
    .max(new Date(), 'Data de nascimento não pode ser no futuro.'),
  gender: yup.string().oneOf(['male', 'female', 'other'], 'Sexo informado inválido.'),
  cpf: yup
    .string()
    .matches(cpfRegex, 'O formato do CPF é inválido.')
    .test({
      test: (value: string) => isValid(value),
      message: 'O CPF informado é inválido.',
      name: 'is-cpf',
    }),
});
