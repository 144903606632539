import { Box, Heading, Stack, Text } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import addMinutes from 'date-fns/addMinutes';
import isToday from 'date-fns/isToday';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import BottomBar from '~/components/BottomBar';
import Button from '~/components/Button';
import CircleToggle from '~/components/CircleToggle';
import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import ListItem from '~/components/ListItem';
import useOrderSchedules from '~/hooks/useOrderSchedules';
import { OrderSchedule } from '~/models/OrderSchedule';
import OrderSummary from '~/models/OrderSummary';
import { HorizontalScroll } from '~/pages/AdvertiserMenu/styles';
import { orderReview } from '~/routes/routeMap';
import { RootState } from '~/store';
import { formatDate } from '~/utils/formatDate';

interface UseLocationProps {
  orderSummaryData: OrderSummary;
}

const SelectOrderSchedule: React.FC = () => {
  const location = useLocation<UseLocationProps>();
  const { state } = location;
  const orderSummaryData = state?.orderSummaryData;
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const deliveryTimeAvg = globalState.advertiser?.advertiserInfo?.delivery_time_avg;
  const takeAwayTimeAvg = globalState.advertiser?.advertiserInfo?.take_away_time_avg;
  const { scheduleDays, selectSchedule, selectedSchedule } = useOrderSchedules();
  const [selectedScheduleDay, setSelectedScheduleDay] = useState(
    scheduleDays.find((day) => day.schedules.length > 0) || { id: null, schedules: [] },
  );
  const handleOnClickConfirmSchedule = useCallback(() => {
    dispatch(push(orderReview, { orderSummaryData }));
  }, [dispatch, orderSummaryData]);

  const immediateSchedules = useMemo(
    () => selectedScheduleDay?.schedules.filter((schedule) => schedule.type === 'immediate'),
    [selectedScheduleDay?.schedules],
  );

  const scheduledSchedules = useMemo(
    () => selectedScheduleDay?.schedules.filter((schedule) => schedule.type === 'scheduled'),
    [selectedScheduleDay?.schedules],
  );

  const formatSchedule = useCallback(
    (schedule: OrderSchedule) => {
      if (!schedule) return '';
      const timeFrom = schedule.timeFrom;
      const timeTo = schedule.timeTo;
      const type = schedule.type;
      const timeFromImmediate = addMinutes(
        timeFrom,
        orderSummaryData.deliveryType === 'delivery' ? deliveryTimeAvg : takeAwayTimeAvg,
      );

      if (isToday(timeFrom) && type === 'immediate') {
        return `hoje, ${formatDate(timeFrom, 'HH:mm')} - ${formatDate(timeFromImmediate, 'HH:mm')}`;
      } else if (isToday(timeFrom) && timeTo) {
        return `hoje, ${formatDate(timeFrom, 'HH:mm')} - ${formatDate(timeTo, 'HH:mm')}`;
      } else {
        return `${formatDate(timeFrom, 'cccccc, HH:mm')} - ${formatDate(timeTo, 'HH:mm')}`;
      }
    },
    [orderSummaryData.deliveryType, deliveryTimeAvg, takeAwayTimeAvg],
  );

  return (
    <Box as="main" flexDir="column" w="100%" px="3rem" pt="2.5rem" pb={40} bg="white">
      <HeaderWithBackButton headerTitle="Selecione uma opção" mb={8} />
      <HorizontalScroll isInline mb={4} spacing={4} py={4}>
        {scheduleDays?.length > 0 &&
          scheduleDays?.map((scheduleDay) => (
            <Box
              key={scheduleDay.id}
              d="flex"
              as="button"
              onClick={() => setSelectedScheduleDay(scheduleDay)}
              flexDir="column"
              p={4}
              alignItems="center"
              borderRadius="0.5rem"
              border="2px solid"
              borderColor={selectedScheduleDay?.id === scheduleDay.id ? 'green.400' : 'gray.200'}
              boxShadow={
                selectedScheduleDay?.id === scheduleDay.id ? '0 0 8px 2px #349B2C40' : 'initial'
              }
            >
              <Text as="span" fontSize="1.4rem" color="gray.400">
                {`${scheduleDay.shortMonthName}/${scheduleDay.date
                  .getFullYear()
                  .toString()
                  .substr(2, 2)}`}
              </Text>
              <Text as="span" fontSize="1.3rem" color="gray.400">
                {isToday(scheduleDay.date) ? 'hoje' : scheduleDay.shortName.substr(0, 3)}
              </Text>
              <Text as="span" fontSize="1.8rem" fontWeight="700">
                {scheduleDay.date.getDate()}
              </Text>
            </Box>
          ))}
      </HorizontalScroll>
      {immediateSchedules.length > 0 && (
        <Stack mb={6}>
          <Heading fontSize="1.7rem" fontWeight="500" mb={6}>
            Peça agora
          </Heading>

          {immediateSchedules.map((schedule) => (
            <ListItem
              key={schedule?.id}
              py={4}
              as="button"
              outline={0}
              flexDir="row"
              onClick={() => selectSchedule(schedule.id, selectedScheduleDay?.id)}
            >
              <Text fontSize="1.4rem" color="gray.500">
                {formatSchedule(schedule)}
              </Text>
              <CircleToggle ml="auto" isActive={selectedSchedule?.id === schedule?.id} />
            </ListItem>
          ))}
        </Stack>
      )}
      {scheduledSchedules.length > 0 && (
        <Stack>
          <Heading fontSize="1.7rem" fontWeight="500">
            Agendar
          </Heading>

          {scheduledSchedules.map((schedule) => (
            <ListItem
              key={schedule?.id}
              py={4}
              as="button"
              outline={0}
              flexDir="row"
              onClick={() => selectSchedule(schedule.id, selectedScheduleDay?.id)}
            >
              <Text fontSize="1.4rem" color="gray.500">
                {formatSchedule(schedule)}
              </Text>
              <CircleToggle ml="auto" isActive={selectedSchedule?.id === schedule?.id} />
            </ListItem>
          ))}
        </Stack>
      )}
      <BottomBar>
        <Stack spacing={16} alignItems="center" py={3} px={4}>
          <Button w="100%" onClick={handleOnClickConfirmSchedule}>
            Continuar
          </Button>
        </Stack>
      </BottomBar>
    </Box>
  );
};

export default SelectOrderSchedule;
