import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from '@chakra-ui/core';
import React from 'react';

export type ButtonProps = ChakraButtonProps;

const Button: React.FC<ButtonProps> = ({
  children,
  variantColor = 'green',
  fontSize = 'sm',
  fontWeight = '700',
  color = 'gray.700',
  py = 6,
  px = 8,
  _focus = {
    boxShadow: 'none',
  },
  _active = {},
  ...rest
}) => {
  return (
    <ChakraButton
      variantColor={variantColor}
      py={py}
      h="auto"
      px={px}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      _focus={_focus}
      _active={_active}
      _hover={_active}
      {...rest}
    >
      {children}
    </ChakraButton>
  );
};

export default Button;
