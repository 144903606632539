import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';

import AppContainer from './contexts';
import Routes from './routes';

function App() {
  return (
    // @ts-ignore
    <HelmetProvider>
      <AppContainer>
        {/* @ts-ignore */}
        <ErrorBoundary showDialog>
          <Routes />
        </ErrorBoundary>
      </AppContainer>
    </HelmetProvider>
  );
}

export default App;
