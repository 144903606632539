export const formatCurrencyBRL = (value: number, options?: Intl.NumberFormatOptions) => {
  const maximumFractionDigits = options?.maximumFractionDigits || 2;
  const formatter = new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    style: 'currency',
    maximumFractionDigits,
    ...options,
  });
  return formatter.format(value);
};

export const hasDecimalPlaces = (value: number) => (value ? value % 1 !== 0 : false);
