import * as yup from 'yup';

// export const mediumPasswordRegex = /(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))/;
// export const strongPasswordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}/;
// export const strongPasswordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}/;
export const phoneRegex = /\(\d{2}\) ([9]\d{4}|\d{4})-\d{4}/;

export default yup.object().shape({
  name: yup
    .string()
    .max(60, 'Seu nome deve ter no máximo 60 caracteres.')
    .required('Por favor informe seu nome.'),
  phone: yup
    .string()
    .required('Por favor informe seu número de telefone.')
    .matches(phoneRegex, 'O número de telefone informado é inválido.'),
});
