import * as yup from 'yup';

export default function confirmAddressSchema(sholdValidateZipcode: boolean = true) {
  return yup.object().shape({
    number: yup.string().trim().max(10, 'O número deve possuir até 10 caracteres.'),
    complement: yup.string().trim().max(60, 'O complemento deve possuir até 60 caracteres.'),
    neighborhood: yup
      .string()
      .trim()
      .required('Por favor informe seu bairro.')
      .max(60, 'O bairro deve possuir até 60 caracteres.'),
    zipcode: sholdValidateZipcode
      ? yup
          .string()
          .trim()
          .required('Por favor informe seu CEP.')
          .matches(RegExp(/\d{5}-\d{3}/), 'Informe um CEP válido!')
      : yup.string().trim().max(9).required('Por favor informe seu CEP.'),
  });
}
