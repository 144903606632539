import { SelectProps as ChakraSelectProps } from '@chakra-ui/core';
import React from 'react';

import { CustonSelect } from './styles';

export interface SelectProps extends ChakraSelectProps {
  register?: any;
}

const Select: React.FC<SelectProps> = ({
  w = '100%',
  h = '50px',
  background = '#EFEFEF 0% 0% no-repeat padding-box',
  borderRadius = '10px',
  border = '2px solid',
  borderColor = '#EFEFEF',
  _focus = {
    borderColor: 'green.400',
    boxShadow: '0px 0px 12px #349B2C40',
  },
  register,
  ...rest
}) => {
  return (
    <CustonSelect
      // p={p}
      iconSize={10}
      w={w}
      h={h}
      border={border}
      borderColor={borderColor}
      _focus={_focus}
      background={background}
      borderRadius={borderRadius}
      ref={register}
      {...rest}
    />
  );
};

export default Select;
