import { Badge, Box, Stack, StackProps, Text } from '@chakra-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Product from '~/models/Product';
import { RootState } from '~/store';
import { formatCurrencyBRL } from '~/utils/currency';

import LazyImage from '../LazyImage';
import { LineClampedText } from './styles';

export interface ProductItemProps extends StackProps {
  product: Product;
}

const ProductItem: React.FC<ProductItemProps> = ({ product, ...rest }: ProductItemProps): any => {
  const imageTimestamp = useSelector((state: RootState) => state.advertiser.imageTimestamp);
  const [isMinValue, setIsMinValue] = useState<number>();

  useEffect(() => {
    if (product.amount === 0) {
      const requiredComplements = product.productComplementCategories.filter((complement) => {
        return complement.is_required ? true : false;
      });

      let minProductPrice = product.amount;

      requiredComplements.forEach((complement) => {
        const optionPrices = complement.productComplementOptions.map(
          (option) => option.amount && option.amount,
        );
        const lessOptionPrice = Math.min.apply(null, optionPrices);
        minProductPrice += lessOptionPrice * complement.min_qty;
      });

      setIsMinValue(minProductPrice);
    }
  }, [product]);

  const discountPercentageOff = useMemo(() => {
    if (product.coupon && product.isCouponAvailable) {
      const fromValue = product.coupon?.couponBenefit?.from_value;
      const byValue = product.coupon?.couponBenefit?.by_value;
      const percentage = Math.floor(Math.abs((byValue / fromValue) * 100 - 100));
      return percentage;
    }
  }, [product.coupon, product.isCouponAvailable]);

  return (
    <Stack
      {...rest}
      isInline
      px="3rem"
      spacing={4}
      d="flex"
      w="100%"
      minH={product.file ? '10rem' : 'auto'}
      justifyContent="space-between"
    >
      <Box d="flex" flexDir="column" alignItems="flex-start" w={product.file?.url ? '75%' : '100%'}>
        <LineClampedText as="h4" mb={2} fontWeight="700" fontSize="sm" title={product.description}>
          {product.name}
        </LineClampedText>
        <LineClampedText as="p" fontWeight="400" mb={4} fontSize="sm" title={product.description}>
          {product.description}
        </LineClampedText>
        {!product.isAvailable && (
          <Badge
            variant="subtle"
            variantColor="yellow"
            color="yellow.600"
            px={2}
            py={1}
            mb={4}
            fontSize="1.1rem"
            fontWeight="700"
          >
            DISPONÍVEL EM DIAS ESPECÍFICOS
          </Badge>
        )}
        <Box mt="auto">
          {product.coupon && product.isCouponAvailable ? (
            <Box d="flex" flexDir="column" alignItems="flex-start">
              <Badge
                variant="subtle"
                variantColor="green"
                px={2}
                py={1}
                mb={4}
                fontSize="1.1rem"
                fontWeight="700"
              >
                {discountPercentageOff}% OFF
              </Badge>
              <Box d="flex">
                <Text
                  as="span"
                  mr={4}
                  fontSize="sm"
                  color="gray.400"
                  textDecor="line-through"
                  fontWeight="400"
                >
                  {formatCurrencyBRL(product.coupon?.couponBenefit?.from_value)}
                </Text>
                <Text as="span" mr={4} fontSize="sm" fontWeight="700">
                  {formatCurrencyBRL(product.coupon?.couponBenefit?.by_value)}
                </Text>
              </Box>
            </Box>
          ) : isMinValue ? (
            <Text as="span" mr={4} fontSize="sm" fontWeight="700">
              A partir de {formatCurrencyBRL(isMinValue)}
            </Text>
          ) : (
            <Text as="span" mr={4} fontSize="sm" fontWeight="700">
              {formatCurrencyBRL(product.amount)}
            </Text>
          )}
        </Box>
      </Box>
      {product.file?.url && (
        <LazyImage
          src={`${product.file?.url}?nocache=${imageTimestamp}`}
          flex="1"
          w="10rem"
          h="10rem"
          borderRadius="1rem"
          objectFit="cover"
        />
      )}
    </Stack>
  );
};

const arePropsEqual = (prevProps: ProductItemProps, nextProps: ProductItemProps) =>
  prevProps.product.id === nextProps.product.id &&
  prevProps.product.isAvailable === nextProps.product.isAvailable;

export default React.memo(ProductItem, arePropsEqual);
