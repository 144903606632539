import { PersistConfig } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';

export const advertiserPersistConfig: PersistConfig<any> = {
  key: '@kuppi/advertiser',
  storage: storageSession,
  blacklist: ['loading', 'bestOffersLoading', 'lastRequest'],
};

export const bagPersistConfig: PersistConfig<any> = {
  key: '@kuppi/bag',
  storage: storageSession,
};

export const ordersPersistConfig: PersistConfig<any> = {
  key: '@kuppi/orders',
  storage: storageSession,
  blacklist: ['createOrderLoading'],
};

export const userPersistConfig: PersistConfig<any> = {
  key: '@kuppi/user',
  storage: localStorage,
  blacklist: ['rescuedLoyaltyCards', 'loadingLoyaltyCard', 'loyaltyCard', 'loading'],
};
