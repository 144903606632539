import { Text } from '@chakra-ui/core';
import styled from '@emotion/styled';

export const CustonText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;

  @media screen and (max-width: 412px) {
    max-width: 16ch;
  }

  @media screen and (max-width: 380px) {
    max-width: 12ch;
  }
`;

export const CustonTextComplement = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;

  @media screen and (max-width: 412px) {
    max-width: 21ch;
  }

  @media screen and (max-width: 380px) {
    max-width: 17ch;
  }
`;
