import { Box, Heading, HeadingProps, Input, Text } from '@chakra-ui/core';
import styled from '@emotion/styled';
import React from 'react';

export const CustomBox = styled(Box)`
  width: 100px;
  height: 50px;
  border-radius: 10px;
  background-color: #2cfe89;
  align-itens: center;
  justify-content: center;
  display: flex;
`;

export const CustomInput = styled(Input)`
  width: 60%;
  height: 50px;
  font-weight: 700;
  border-radius: 10px;
  margin-right: 10px;
`;

export const CustomText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60ch;

  @media screen and (max-width: 475px) {
    max-width: 30ch;
  }

  @media screen and (max-width: 400px) {
    max-width: 28ch;
  }

  @media screen and (max-width: 340px) {
    max-width: 26ch;
  }
`;

export const Subtitle: React.FC<HeadingProps> = ({
  as = 'h3',
  fontSize = '1.6rem',
  fontWeight = '700',
  color = 'gray.700',
  children,
  ...rest
}) => (
  <Heading as={as} fontSize={fontSize} fontWeight={fontWeight} color={color} {...rest}>
    {children}
  </Heading>
);
