import {
  Box,
  Icon,
  Image,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import AutocompleteItem from '~/components/AutocompleteItem';
import Button from '~/components/Button';
import Input from '~/components/Form/Input';
import useConstant from '~/hooks/useConstant';
import { AddressComponent, GetAddressList, Prediction } from '~/models/Autocomplete';
import OrderSummary from '~/models/OrderSummary';
import { advertiserMenu, confirmAddress, confirmAddressGPS, listAddress } from '~/routes/routeMap';
import { getAddressDetails, getAddressList, getLatLngByAddressFields } from '~/services/address';
import { RootState } from '~/store';
import {
  AddressFields,
  buildSearchTermAutocomplete,
  getFieldFromAddressComponents,
} from '~/utils/address';
import { debounce } from '~/utils/debounce';

import ConfirmPrediction from '../ConfirmPrediction';

export interface ConfirmAddressFields {
  number?: string;
  complement?: string;
  neighborhood?: string;
  zipcode?: string;
}

interface UseLocationProps {
  onConfirmBackTo: string;
  orderSummaryData: OrderSummary;
}

const AutocompleteAddress: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation<UseLocationProps>();
  const state = location?.state;
  const advertiserInfo = useSelector((state: RootState) => state.advertiser.advertiserInfo);

  const [autocompletedAddress, setAutocompletedAddress] = useState<GetAddressList>();
  const [selectedPrediction, setSelectedPrediction] = useState<Prediction>(null);
  const [selectedAddressLocation, setSelectedAddressLocation] =
    useState<google.maps.LatLngLiteral>(null);
  const [selectedAddressComponents, setSelectedAddressComponents] = useState<AddressComponent[]>(
    [],
  );
  const [loading, setLoading] = useState(false);
  const [loadingSelection, setLoadingSelection] = useState(false);

  useEffect(() => {
    if (!advertiserInfo) {
      dispatch(push(advertiserMenu));
    }
  }, [advertiserInfo]);

  const onChange = async (value: string) => {
    setLoading(true);
    if (value === '') {
      setLoading(false);
      setAutocompletedAddress(null);
    } else {
      const addressList = await getAddressList(value);
      setAutocompletedAddress(addressList);
    }
    setLoading(false);
  };

  const debouncedOnChange = useConstant(() => debounce(onChange));

  const onSubmitSelectedPrediction = useCallback(
    async (data: ConfirmAddressFields) => {
      try {
        setLoadingSelection(true);
        const street = getFieldFromAddressComponents(
          AddressFields.STREET,
          selectedAddressComponents,
        );

        const city = getFieldFromAddressComponents(AddressFields.CITY, selectedAddressComponents);
        const uf = getFieldFromAddressComponents(AddressFields.STATE, selectedAddressComponents);

        if (selectedPrediction.types.includes('point_of_interest')) {
          const zipcode = getFieldFromAddressComponents(
            AddressFields.ZIPCODE,
            selectedAddressComponents,
          );
          const neighborhood = getFieldFromAddressComponents(
            AddressFields.NEIGHBORHOOD,
            selectedAddressComponents,
          );
          const number = getFieldFromAddressComponents(
            AddressFields.NUMBER,
            selectedAddressComponents,
          );

          dispatch(
            push(confirmAddressGPS, {
              ...state,
              addressDetails: {
                street: selectedPrediction.structured_formatting.main_text,
                number:
                  data?.number?.trim() !== ''
                    ? data?.number
                    : number?.trim() !== ''
                    ? number
                    : 'S/N',
                complement: data?.complement,
                neighborhood: neighborhood.trim() !== '' ? neighborhood : 'N/A',
                city,
                state: uf,
                zipcode: zipcode.trim() !== '' ? zipcode : 'N/A',
                lat: selectedAddressLocation.lat,
                lng: selectedAddressLocation.lng,
              },
            }),
          );
          return;
        }

        const foundLocation = await getLatLngByAddressFields({
          street,
          number: data?.number,
          neighborhood: data.neighborhood,
          city,
          state: uf,
          zipcode: data.zipcode,
        });

        dispatch(
          push(confirmAddressGPS, {
            ...state,
            addressDetails: {
              street,
              number: data?.number?.trim() === '' ? 'S/N' : data?.number,
              neighborhood: data.neighborhood,
              complement: data.complement,
              city,
              state: uf,
              zipcode: data.zipcode,
              lat: foundLocation.latitude,
              lng: foundLocation.longitude,
            },
          }),
        );
      } catch (err: any) {
        const message = err.response?.data?.error?.message;
        if (message) {
          toast.error(message);
        } else {
          toast.error('Ocorreu um erro ao buscar seu endereço.');
        }
      } finally {
        setLoadingSelection(false);
      }
    },
    [dispatch, state, selectedAddressComponents, selectedAddressLocation, selectedPrediction],
  );

  const handleOnSelectPrediction = useCallback(async (item: Prediction) => {
    try {
      setLoadingSelection(true);
      const details = await getAddressDetails(item.place_id);
      const addressComponents = details.result.address_components;
      const addressLocation = details.result.geometry.location;
      setSelectedPrediction(item);
      setSelectedAddressComponents(addressComponents);
      setSelectedAddressLocation(addressLocation);
    } catch (err) {
      toast.error('Ocorreu um erro ao selecionar este endereço.');
    } finally {
      setLoadingSelection(false);
    }
  }, []);

  const onCloseBottomBar = useCallback(() => {
    setSelectedPrediction(null);
    setSelectedAddressComponents([]);
    setSelectedAddressLocation(null);
  }, []);

  return (
    <Box
      px="3rem"
      pt="2rem"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexDir="column"
    >
      <Box w="100%" my="10px">
        <InputGroup>
          <Input
            p="20px 20px 20px 50px"
            placeholder="Digite seu endereço com número"
            name="address-kuppi-search"
            autoFocus
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const advertiserAddress = advertiserInfo?.user?.address[0];
              debouncedOnChange(buildSearchTermAutocomplete(e.target.value, advertiserAddress));
            }}
          />
          <InputLeftElement h="50px" w="50px">
            <Button
              fontSize="lg"
              display="flex"
              alignItems="center"
              justifyContent="center"
              background="transparent"
              h="50px"
              w="50px"
              onClick={() => dispatch(push(listAddress, { ...state }))}
            >
              <Icon size="2.5rem" as={MdKeyboardArrowLeft} />
            </Button>
          </InputLeftElement>
        </InputGroup>
        <Box w="100%" d="flex" justifyContent="flex-end" mt="1rem">
          <Image src="/images/powered-by-google.png" w="109.3px" h="15px" />
        </Box>
      </Box>

      {loading ? (
        <Box py="5rem">
          <Spinner w="3.5rem" h="3.5rem" color="green.600" />
        </Box>
      ) : (
        <>
          <Stack spacing={3} w="100%" mt=".5rem">
            {autocompletedAddress?.predictions?.map((addrs, index) => (
              <AutocompleteItem
                key={index}
                item={addrs}
                onClick={() => handleOnSelectPrediction(addrs)}
              />
            ))}
          </Stack>

          <Box w="100%">
            {autocompletedAddress && (
              <Box
                mt="1.5rem"
                d="flex"
                flexDir="column"
                alignItems="center"
                justifyContent="center"
                w="100%"
              >
                <Text fontSize="15px" as="span">
                  Não achou seu endereço?
                </Text>
                <Button
                  mt="1.5rem"
                  w="100%"
                  variant="outline"
                  borderColor="gray.400"
                  fontWeight="semibold"
                  onClick={() => dispatch(push(confirmAddress, { ...state }))}
                >
                  Buscar pelo CEP
                </Button>
              </Box>
            )}
          </Box>
        </>
      )}

      {selectedPrediction && (
        <ConfirmPrediction
          onSubmitSelectedPrediction={onSubmitSelectedPrediction}
          onCloseBottomBar={onCloseBottomBar}
          loading={loadingSelection}
          selectedPrediction={{
            mainText: selectedPrediction?.structured_formatting?.main_text,
            secondaryText: selectedPrediction.structured_formatting?.secondary_text,
          }}
          selectedAddressComponents={selectedAddressComponents}
        />
      )}
    </Box>
  );
};

export default AutocompleteAddress;
