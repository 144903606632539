import {
  Badge,
  Box,
  Divider,
  Heading,
  Icon,
  Image,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import { push } from 'connected-react-router';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import { ptBR } from 'date-fns/locale';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

import {
  amplitudeSetUserId,
  amplitudeSetUserProperties,
  amplitudeTrackEvent,
} from '~/components/Amplitude';
import BottomBar from '~/components/BottomBar';
import Button from '~/components/Button';
import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import InfoBox from '~/components/InfoBox';
import ListItem from '~/components/ListItem';
import LoginForm from '~/components/LoginForm';
import NumberCounter from '~/components/NumberCounter';
import SelectOptionButton from '~/components/SelectOptionButton';
import useDynamicDeliveryFee, { DeliveryTypes } from '~/hooks/useDynamicDelivery';
import useOrderSchedules from '~/hooks/useOrderSchedules';
import Address from '~/models/Address';
import AdvertiserInfo from '~/models/AdvertiserInfo';
import BagDiscounts from '~/models/BagDiscounts';
import OrderSummary from '~/models/OrderSummary';
import Product from '~/models/Product';
import DeliveryType from '~/models/types/DeliveryType';
import { advertiserMenu, itemDetailsWithId, orderReview } from '~/routes/routeMap';
import { RootState } from '~/store';
import AuthActions from '~/store/ducks/auth';
import BagActions from '~/store/ducks/bag';
import { ScrollSmoothTop } from '~/styles/animations/ScrollSmooth';
import { formatAddress } from '~/utils/address';
import { formatCurrencyBRL } from '~/utils/currency';
import { onlyNumbers } from '~/utils/masks';
import { formatMinutes } from '~/utils/time';

import BagPriceDescription from './BagPriceDescription';

const { edditingToBag, removingFromTheBag, cleanBag, setOrderType } = BagActions;
const { createSessionUser } = AuthActions;

interface RegisterInputs {
  name: string;
  phone: string;
}

const Bag: React.FC = () => {
  const [isEditingItem, setIsEditingItem] = useState<boolean>(false);
  const [itemInEditing, setItemInEditing] = useState<Product>();
  const [itemInEditingCount, setItemInEditingCount] = useState<number>(null);

  const dispatch = useDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const globalState = useSelector((state: RootState) => state);
  const {
    imageTimestamp,
    advertiserInfo,
    advertiserIsOpen,
    nextAdvertiserIsOpenDate: nextAdvertiserIsOpen,
  } = globalState?.advertiser;
  const selectOrderTypeRef = useRef<HTMLDivElement>(null);
  const advertiserAddress = advertiserInfo?.user.address[0];
  const nextAdvertiserIsOpenDate = new Date(nextAdvertiserIsOpen);

  const { currentAddress } = globalState?.addresses;
  const { user } = globalState?.user;
  const userState = globalState?.user;

  const { itemsInBag, priceWithDiscount, priceWithoutDiscount, deliveryType, bagDiscounts } =
    globalState?.bag;
  const { resetSelectedSchedule } = useOrderSchedules();

  useEffect(() => {
    if (advertiserIsOpen) {
      if (advertiserInfo.is_using_delivery && !advertiserInfo.is_using_take_away) {
        handleOnChangeOrderType('delivery');
      } else if (advertiserInfo.is_using_take_away && !advertiserInfo.is_using_delivery) {
        handleOnChangeOrderType('take_away');
      }
    }
  }, [advertiserIsOpen, advertiserInfo]);

  useEffect(() => {
    if (itemsInBag.length === 0) {
      dispatch(push(advertiserMenu));
    }
  }, [itemsInBag, bagDiscounts.activeCoupon, dispatch]);

  const [totalDiscount, setTotalDiscount] = useState<number>(null);
  const [totalBag, setTotalBag] = useState<number>(null);

  useEffect(() => {
    const allDiscount = priceWithoutDiscount - priceWithDiscount;
    setTotalDiscount(allDiscount);
    setTotalBag(priceWithoutDiscount - allDiscount);
  }, [itemsInBag, bagDiscounts.activeCoupon, priceWithDiscount, priceWithoutDiscount]);

  const delivery = useDynamicDeliveryFee({
    advertiserAddress: advertiserInfo?.user.address[0],
    userAddress: currentAddress,
    deliveryFee: advertiserInfo?.delivery_fee,
    deliveryDynamicFeeMaxDist: advertiserInfo?.delivery_dynamic_fee_max_dist,
    deliveryDynamicFee: advertiserInfo?.delivery_dynamic_fee,
    deliveryDynamicFeeExceededAmount: advertiserInfo?.delivery_dynamic_fee_exceeded_amount,
    deliveryDynamicFeeFreeDist: advertiserInfo?.delivery_dynamic_fee_free_dist,
    deliveryType,
  });

  const isTotalBagLessThanOrderMinAmount = useMemo(
    () => totalBag < advertiserInfo?.order_minimum_amount,
    [totalBag, advertiserInfo],
  );

  const canContinueWithOrder = useMemo(
    () =>
      itemsInBag.length > 0 && advertiserIsOpen && totalBag >= advertiserInfo?.order_minimum_amount,
    [itemsInBag.length, advertiserIsOpen, totalBag, advertiserInfo],
  );

  const loyaltyCardTicketMinimumAmount = useMemo(() => {
    return userState?.loyaltyCard?.ticket_minimum_amount
      ? userState?.loyaltyCard?.ticket_minimum_amount
      : advertiserInfo?.loyaltyCard?.ticket_minimum_amount;
  }, [userState?.loyaltyCard, advertiserInfo?.loyaltyCard]);

  const createOrderSummaryData = (
    itemsInBag: Product[],
    userInfo: any,
    deliveryType: DeliveryType,
    currentAddress: Address,
    bagDiscounts: BagDiscounts,
    advertiserInfo: AdvertiserInfo,
    totalBag: number,
    priceWithoutDiscount: number,
    delivery: {
      feeType: DeliveryTypes;
      value: number;
      description: string;
      prettyDescription: string | JSX.Element;
    },
    totalDiscount: number,
  ): OrderSummary => {
    return {
      products: itemsInBag,
      clientId: userInfo?.id,
      deliveryType,
      deliveryAddress: currentAddress,
      bagDiscounts,
      isValidToLoyaltyCard:
        advertiserInfo?.loyaltyCard &&
        advertiserInfo?.loyaltyCard?.is_active &&
        Number(totalBag.toFixed(2)) >= loyaltyCardTicketMinimumAmount
          ? true
          : false,
      subtotal: Number(priceWithoutDiscount.toFixed(2)),
      deliveryFee: delivery.value,
      totalDiscount: Number(totalDiscount.toFixed(2)),
      total: Number((totalBag + delivery.value).toFixed(2)),
      orderType: 'immediate',
    };
  };

  const handleScrollIntoSelectOrderType = useCallback(() => {
    ScrollSmoothTop({ ref: selectOrderTypeRef });
    selectOrderTypeRef.current?.classList.add('shake');
    setTimeout(() => {
      selectOrderTypeRef.current?.classList.remove('shake');
    }, 1000);
  }, []);

  const onSubmitWithUserData = useCallback(
    (userData: RegisterInputs) => {
      const orderSummaryData: OrderSummary = createOrderSummaryData(
        itemsInBag,
        user?.clientInfo,
        deliveryType,
        currentAddress,
        bagDiscounts,
        advertiserInfo,
        totalBag,
        priceWithoutDiscount,
        delivery,
        totalDiscount,
      );
      dispatch(
        createSessionUser(userData.name, onlyNumbers(`55${userData.phone}`), {
          data: orderSummaryData,
          path: orderReview,
        }),
      );
    },
    [
      dispatch,
      user,
      deliveryType,
      currentAddress,
      bagDiscounts,
      advertiserInfo,
      priceWithoutDiscount,
      totalBag,
      totalDiscount,
      itemsInBag,
      delivery,
    ],
  );

  useEffect(() => {
    if (user) {
      // Amplitude Event
      // sets user properties, then sends an event to register these new props
      amplitudeSetUserId(user);
      var userProperties = {
        advertiser_info_id: advertiserInfo?.id?.toString(),
        advertiser_name: advertiserInfo?.name?.toString(),
        advertiser_category: advertiserInfo?.advertiserCategory?.name?.toString(),
        user_id: user ? user?.id?.toString() : null,
      };
      amplitudeSetUserProperties(userProperties);
      amplitudeTrackEvent('user_login', userProperties);
    }
  }, [user]);

  const handleOnClickContinue = useCallback(() => {
    if (!deliveryType) {
      handleScrollIntoSelectOrderType();
      return;
    }
    if (!isOpen) {
      onOpen();
      return;
    }
  }, [handleScrollIntoSelectOrderType, deliveryType, onOpen, isOpen]);

  const handleOnEditingItem = useCallback(
    (index: number) => {
      setIsEditingItem((prevState) => !prevState);
      setItemInEditing(itemsInBag[index]);
      setItemInEditingCount(itemsInBag[index].productQuantity);
    },
    [itemsInBag],
  );

  const handleCleanEditingItem = useCallback(() => {
    setIsEditingItem(false);
    setItemInEditing(null);
    setItemInEditingCount(null);
    onClose();
  }, [onClose]);

  const currentCountProduct = (count: number) => {
    setItemInEditingCount(count);
  };

  const handleEditingItemQuantity = useCallback(
    (item: Product) => {
      const currentItem = { ...item };
      const oldProductQuantity = item.productQuantity;
      currentItem.productQuantity = itemInEditingCount;
      dispatch(edditingToBag(currentItem, oldProductQuantity));
      setIsEditingItem((prevState) => !prevState);
    },
    [dispatch, itemInEditingCount],
  );

  const handleRemovingItemQuantity = useCallback(
    (product_id: number) => {
      dispatch(removingFromTheBag(product_id));
      setIsEditingItem((prevState) => !prevState);
    },
    [dispatch],
  );

  const handleOnChangeOrderType = (type: DeliveryType) => {
    dispatch(setOrderType(type));
    // Amplitude Event
    amplitudeTrackEvent('selected_order_type', {
      advertiser_info_id: advertiserInfo?.id?.toString(),
      advertiser_name: advertiserInfo?.name?.toString(),
      advertiser_category: advertiserInfo?.advertiserCategory?.name?.toString(),
      user_id: user ? user?.id?.toString() : null,
      order_type: type,
    });
  };

  const clearBag = useCallback(() => {
    resetSelectedSchedule();
    dispatch(cleanBag());
  }, [resetSelectedSchedule, dispatch]);

  if (itemsInBag.length === 0 && !bagDiscounts.activeCoupon) {
    return (
      <Box h="100%" w="100%" d="flex" alignItems="center" justifyContent="center">
        <Spinner size="xl" color="#01F43A" />
      </Box>
    );
  }

  return (
    <Box as="main" flexDir="column" w="100%" px="3rem" pb={40} bg="white">
      <HeaderWithBackButton
        justifyContent="center"
        alignItems="center"
        headerTitle={
          <Box d="flex" w="100%" ml="auto" alignItems="center" justifyContent="space-between">
            <Box />
            <Text>Sua Sacola</Text>
            <Button p="0" m="0" background="transparent" onClick={clearBag}>
              <Text fontWeight="300" fontSize="1em" color="red.300">
                Limpar
              </Text>
            </Button>
          </Box>
        }
        to={advertiserMenu}
      />
      <Box d="flex" alignItems="center" mb={3}>
        <Image
          src={
            advertiserInfo?.user?.avatar?.url
              ? `${advertiserInfo?.user?.avatar?.url}?nocache=${imageTimestamp}`
              : '/images/advertiser_logo.png'
          }
          w="6rem"
          h="6rem"
          mr={2}
          backgroundColor="white"
          borderRadius="50%"
          objectFit="cover"
          border="1px solid #EFEFEF"
          onClick={() => dispatch(push(advertiserMenu))}
        />
        <Stack spacing={1} onClick={() => dispatch(push(advertiserMenu))}>
          <Heading as="h2" fontSize="lg">
            {advertiserInfo?.name}
          </Heading>
          <Text fontSize="sm" cursor="pointer" color="#0e681b" as="u">
            Adicionar mais itens
          </Text>
        </Stack>
      </Box>

      <Stack spacing={0}>
        {itemsInBag.map((item, index) => (
          <ListItem key={`${item.id}-${index}`} onClick={() => handleOnEditingItem(index)}>
            <Box d="flex" alignItems="flex-start" justifyContent="space-between" w="100%" mb="2px">
              <Box d="flex" alignItems="flex-start" flexDir="column">
                <Text fontSize="sm" fontWeight="bold">
                  {item.productQuantity}x {item.name}
                </Text>
              </Box>
              <Box d="flex" alignItems="flex-start" justifyContent="center">
                <Box d="flex" flexDir="column" alignItems="flex-end">
                  {bagDiscounts.activeCoupon === 'product' && item.coupon && (
                    <Text
                      as="span"
                      textAlign="center"
                      textDecoration="line-through"
                      fontSize="sm"
                      color="gray.400"
                      fontWeight="500"
                    >
                      {formatCurrencyBRL(
                        item.coupon.couponBenefit.from_value * item.productQuantity,
                      )}
                    </Text>
                  )}
                  <Text as="span" textAlign="center" fontSize="sm" fontWeight="700">
                    {bagDiscounts.activeCoupon === 'product' && item.coupon
                      ? formatCurrencyBRL(item.coupon.couponBenefit.by_value * item.productQuantity)
                      : formatCurrencyBRL(item.amount * item.productQuantity)}
                  </Text>
                </Box>
                <Box size="xs" w="auto" h="auto" m={0} pl={2} pr={0} py={0}>
                  <Icon as={FiMoreVertical} size="2rem" />
                </Box>
              </Box>
            </Box>

            <Box d="flex" alignItems="flex-start" flexDir="column">
              {item.selectedComplements.map((complement: any) => (
                <React.Fragment key={complement.id}>
                  {complement.options.map((option: any) => (
                    <Box w="100%" key={option.id} d="flex" justifyContent="space-between">
                      {option.quantity > 0 && !option.is_selected ? (
                        <>
                          <Box d="flex" justifyContent="center" alignItems="center" mb="1px">
                            <Box
                              fontSize="1rem"
                              fontWeight="bold"
                              background="#c3c3c3"
                              h="14px"
                              w="15px"
                              d="flex"
                              justifyContent="center"
                              alignItems="center"
                              lineHeight="14px"
                              borderRadius="2px"
                            >
                              {option.quantity}
                            </Box>
                            <Text key={option.id} fontSize="sm" ml="5px">
                              {option.name}
                            </Text>
                          </Box>
                        </>
                      ) : (
                        option.quantity > 0 &&
                        option.is_selected && (
                          <>
                            <Box d="flex" justifyContent="center" alignItems="center">
                              <Box
                                fontSize="1rem"
                                fontWeight="bold"
                                background="#c3c3c3"
                                h="14px"
                                w="15px"
                                d="flex"
                                justifyContent="center"
                                alignItems="center"
                                lineHeight="14px"
                                borderRadius="2px"
                              >
                                {option.quantity}
                              </Box>
                              <Text key={option.id} fontSize="sm" ml="5px">
                                {option.name}
                              </Text>
                            </Box>
                          </>
                        )
                      )}
                    </Box>
                  ))}
                </React.Fragment>
              ))}
            </Box>
            {item.obs && (
              <Text fontSize="sm" mt="3px">
                Obervação: {item.obs}.
              </Text>
            )}
          </ListItem>
        ))}

        <ListItem />
      </Stack>

      <Stack as="footer" spacing={3} mb={8}>
        <BagPriceDescription
          descriptionTitle="Subtotal"
          descriptionPrice={priceWithoutDiscount}
          color="gray.400"
        />
        {deliveryType === 'delivery' && (
          <BagPriceDescription
            descriptionTitle="Taxa de delivery"
            descriptionPrice={delivery.description}
            color="gray.400"
          />
        )}

        {bagDiscounts?.activeCoupon && priceWithoutDiscount - priceWithDiscount > 0 && (
          <BagPriceDescription
            descriptionTitle="Descontos"
            descriptionPrice={formatCurrencyBRL(
              Number((priceWithoutDiscount - priceWithDiscount) * -1),
            )}
            color="red.300"
          />
        )}
        <BagPriceDescription
          my={6}
          descriptionTitle="Total"
          descriptionPrice={
            deliveryType === 'delivery' ? priceWithDiscount + delivery.value : priceWithDiscount
          }
          color="gray.700"
          fontWeight="700"
        />
        <Divider />
      </Stack>

      {advertiserIsOpen && (
        <Stack spacing={3}>
          <Box d="flex" justifyContent="space-between" py={2}>
            <Heading fontSize="md">Selecione uma opção</Heading>
            <Badge
              ref={selectOrderTypeRef}
              variant="subtle"
              bg="black"
              color="white"
              px={2}
              py={1}
              fontSize="1.1rem"
              fontWeight="700"
            >
              OBRIGATÓRIO
            </Badge>
          </Box>
          {advertiserInfo.is_using_delivery && (
            <SelectOptionButton
              isSelected={deliveryType === 'delivery'}
              onClick={() => handleOnChangeOrderType('delivery')}
            >
              <Box d="flex" alignItems="center" mb={2}>
                <Text as="span" fontWeight="700" mr={2}>
                  Delivery
                </Text>
                {!!advertiserInfo?.delivery_time_avg && (
                  <Badge
                    variant="subtle"
                    bg="gray.500"
                    color="white"
                    px={2}
                    py={1}
                    fontSize="1rem"
                    fontWeight="700"
                  >
                    {formatMinutes(advertiserInfo?.delivery_time_avg)}
                  </Badge>
                )}
              </Box>
              <Text
                fontWeight="500"
                fontSize="sm"
                color="gray.400"
                whiteSpace="normal"
                textAlign="left"
              >
                Quero receber em casa
              </Text>
            </SelectOptionButton>
          )}
          {advertiserInfo.is_using_take_away && (
            <SelectOptionButton
              isSelected={deliveryType === 'take_away'}
              onClick={() => handleOnChangeOrderType('take_away')}
            >
              <Box d="flex" alignItems="center" mb={2}>
                <Text as="span" fontWeight="700" mr={2}>
                  Retirada
                </Text>
                {!!advertiserInfo?.take_away_time_avg && (
                  <Badge
                    variant="subtle"
                    bg="gray.500"
                    color="white"
                    px={2}
                    py={1}
                    fontSize="1rem"
                    fontWeight="700"
                  >
                    {formatMinutes(advertiserInfo?.take_away_time_avg)}
                  </Badge>
                )}
              </Box>

              <Text
                fontWeight="500"
                fontSize="sm"
                color="gray.400"
                whiteSpace="normal"
                textAlign="left"
              >
                Retirar em {formatAddress(advertiserAddress)}
              </Text>
            </SelectOptionButton>
          )}
        </Stack>
      )}

      <Stack spacing={3} mt={8}>
        {isTotalBagLessThanOrderMinAmount && (
          <InfoBox
            content={`O pedido mínimo para esta loja é de ${formatCurrencyBRL(
              advertiserInfo?.order_minimum_amount,
            )}, não inclusa a taxa de entrega.`}
            mb={6}
          />
        )}
        {!advertiserIsOpen && nextAdvertiserIsOpenDate && (
          <>
            {isToday(nextAdvertiserIsOpenDate) ? (
              <InfoBox
                variant="danger"
                mb={6}
                content={`Este estabelecimento está fechado no momento. Abrirá ${format(
                  nextAdvertiserIsOpenDate,
                  "'hoje às' HH:mm",
                  {
                    locale: ptBR,
                  },
                )}.`}
              />
            ) : (
              <InfoBox
                variant="danger"
                mb={6}
                content={`Este estabelecimento está fechado no momento. Abrirá ${format(
                  nextAdvertiserIsOpenDate,
                  "EEEE 'às' HH:mm",
                  {
                    locale: ptBR,
                  },
                )}.`}
              />
            )}
          </>
        )}
      </Stack>

      <BottomBar isOverlayVisible={isEditingItem || isOpen} onClose={handleCleanEditingItem}>
        {isEditingItem ? (
          <Stack spacing={8} textAlign="center" py={6}>
            <Heading as="h4" fontWeight="700" fontSize="md">
              {itemInEditing?.name}
            </Heading>
            {itemInEditing?.selectedComplements?.length > 0 && (
              <Text fontSize="sm">
                {itemInEditing?.selectedComplements?.map((complement: any) => (
                  <React.Fragment key={complement.id}>
                    {complement.options.map((option: any, index: number) => (
                      <React.Fragment key={option.id}>
                        {option.quantity > 0 && !option.is_selected ? (
                          <>
                            {option.quantity}x {option.name}
                            {index !== complement.length - 1 && ', '}
                          </>
                        ) : (
                          option.quantity > 0 &&
                          option.is_selected && (
                            <>
                              {option.quantity}x {option.name}
                              {index !== complement.length - 1 && ', '}
                            </>
                          )
                        )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Text>
            )}
            <Button
              variant="ghost"
              border="1px solid"
              borderColor="gray.400"
              mt={4}
              onClick={() =>
                dispatch(
                  push(itemDetailsWithId.replace(':id_produto', `${itemInEditing.id}`), {
                    product: itemInEditing,
                    mode: 'edditing',
                  }),
                )
              }
            >
              Revisar detalhes do item
            </Button>
            <Stack isInline align="center" spacing={4}>
              <NumberCounter
                onChangeCount={currentCountProduct}
                canReduceUpTo={0}
                flex="2"
                initialCount={itemInEditing?.productQuantity}
              />
              <Button
                variantColor="green"
                d="flex"
                border="1px solid"
                borderColor="green.500"
                flex="3"
                alignItems="center"
                justifyContent="space-between"
                onClick={
                  itemInEditingCount === 0
                    ? () => handleRemovingItemQuantity(itemInEditing.bagId)
                    : () => handleEditingItemQuantity(itemInEditing)
                }
              >
                <span>{itemInEditingCount === 0 ? 'Excluir' : 'Atualizar'}</span>{' '}
                <span>
                  {bagDiscounts.activeCoupon === 'product' && itemInEditing.coupon
                    ? formatCurrencyBRL(
                        itemInEditing.coupon.couponBenefit.by_value * itemInEditingCount,
                      )
                    : formatCurrencyBRL(itemInEditing.amount * itemInEditingCount)}
                </span>
              </Button>
            </Stack>
          </Stack>
        ) : (
          <LoginForm
            isVisible={isOpen}
            canContinue={canContinueWithOrder}
            onClickContinue={handleOnClickContinue}
            onFormSubmit={onSubmitWithUserData}
            helperText={
              <Text fontSize="sm" fontWeight="400" color="gray.400" py={2}>
                Dessa forma você poderá acompanhar o status do seu pedido.
              </Text>
            }
          />
        )}
      </BottomBar>
    </Box>
  );
};

export default Bag;
