import 'react-toastify/dist/ReactToastify.css';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';

import history from '~/routes/history';

import App from './App';
import * as serviceWorker from './serviceWorker';

smoothscroll.polyfill();

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const ENV_API =
  process.env.REACT_APP_ENV_API === 'development' || process.env.REACT_APP_ENV_API === 'local'
    ? null
    : process.env.REACT_APP_ENV_API;

const NODE_ENV = process.env.NODE_ENV !== 'development';
const APP_VERSION = process.env.REACT_APP_VERSION;

if (NODE_ENV && ENV_API) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV_API,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0.8,
    release: APP_VERSION,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration) => {
    const swUpdateEvent = new CustomEvent('onswupdate', {
      detail: {
        registration,
      },
    });
    document.dispatchEvent(swUpdateEvent);
  },
});
