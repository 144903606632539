import { Dispatch, SetStateAction, useEffect, useState } from 'react';

function usePersistedState<S>(
  key: string,
  initialState?: S | (() => S),
): [S, Dispatch<SetStateAction<S>>] {
  const [state, setState] = useState(() => {
    const storageValue = localStorage.getItem(key);

    if (storageValue) {
      return JSON.parse(storageValue);
    }
    return initialState;
  });

  useEffect(() => {
    if (!state) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(state));
    }
  }, [key, state]);

  return [state, setState];
}

export default usePersistedState;
