import { Box, BoxProps, Text } from '@chakra-ui/core';
import React, { useMemo } from 'react';

// import { Container } from './styles';

export interface InfoBoxProps extends BoxProps {
  content?: string;
  variant?: 'default' | 'danger' | 'info';
}

const InfoBox: React.FC<InfoBoxProps> = ({
  content,
  variant,
  w = '100%',
  p = '1.4rem 2rem',
  backgroundColor = 'gray.200',
  borderRadius = '1rem',
  ...rest
}) => {
  const getBackgroundColor = useMemo(() => {
    switch (variant) {
      case 'danger':
        return '#FF6C6C';
      default:
        return backgroundColor;
    }
  }, [backgroundColor, variant]);

  const getBorderColor = useMemo(() => {
    switch (variant) {
      case 'danger':
        return '#FF6C6C';
      default:
        return backgroundColor;
    }
  }, [backgroundColor, variant]);

  const getTextColor = useMemo(() => {
    switch (variant) {
      case 'danger':
        return '#EFEFEF';
      case 'info':
        return 'gray.500';
      default:
        return 'gray.800';
    }
  }, [variant]);

  return (
    <Box
      w={w}
      p={p}
      backgroundColor={getBackgroundColor}
      borderColor={getBorderColor}
      borderWidth="2px"
      borderRadius={borderRadius}
      {...rest}
    >
      <Text as="p" fontSize="1.4rem" color={getTextColor} textAlign="center">
        {content}
      </Text>
    </Box>
  );
};

export default InfoBox;
