import { Box, Heading, Icon, Image, Text } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '~/components/Button';
import { myProfile } from '~/routes/routeMap';
import { RootState } from '~/store';
import { Creators as AuthActions } from '~/store/ducks/auth';

import MenuButton from '../MenuButton';
import { CustomText } from './styles';

export interface MenuOption {
  option: React.ReactNode;
  icon: string;
  to?: string;
}

export interface LoggedInContentProps {
  menuOptions: MenuOption[];
  onClose?: VoidFunction;
}

const { logOut } = AuthActions;

const LoggedInContent = React.memo(function LoggedInContent({
  menuOptions,
  onClose,
}: LoggedInContentProps) {
  const globalState = useSelector((state: RootState) => state);
  const { avatar, userInfo, user }: any = globalState.user;
  const imageTimestamp = globalState?.advertiser.imageTimestamp;
  const dispatch = useDispatch();

  const handleOnClickMenuOption = (to: string) => {
    dispatch(push(to));
  };

  const { advertiserInfo } = useSelector((state: RootState) => state.advertiser);

  return (
    <>
      <Button
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        mt="2rem"
        flexDir="row"
        mx="2rem"
        px="0"
        py="0"
        background="transparent"
        onClick={() => handleOnClickMenuOption(myProfile)}
      >
        <Image
          w="47px"
          h="47px"
          src={avatar ? avatar?.url : '/images/profile/profile@2x.png'}
          borderRadius="full"
        />
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDir="column"
          ml="2rem"
        >
          <CustomText as="span" fontSize="1.4rem" fontWeight="bold">
            {userInfo?.name ? userInfo?.name : user?.email}
          </CustomText>
          <Box fontSize="1.4rem" h="1.5rem" p="0">
            Ver Perfil <Icon name="chevron-right"></Icon>
          </Box>
        </Box>
      </Button>
      <Box
        d="flex"
        alignItems="center"
        justifyContent="flex-start"
        mt="3rem"
        flexDir="column"
        px="2em"
        h="80vh"
      >
        {menuOptions.map((option) => (
          <MenuButton key={option.to} onClick={() => handleOnClickMenuOption(option.to)}>
            <Icon size="2.2rem" name={option.icon} />
            <Box w="100%" d="flex" alignItems="flex-start" px="20px">
              <Text as="span" fontSize="1.4rem" fontWeight="bold">
                {option.option}
              </Text>
            </Box>
            <Icon size="2rem" name="chevron-right"></Icon>
          </MenuButton>
        ))}

        <MenuButton
          onClick={() => {
            onClose();
            dispatch(logOut());
          }}
        >
          <Icon size="2.0rem" name="exit" />
          <Box w="100%" d="flex" alignItems="flex-start" px="20px">
            <Text as="span" fontSize="1.4rem" fontWeight="bold">
              Sair
            </Text>
          </Box>
        </MenuButton>

        {/* <Box my="4rem" d="flex" flexDir="column" w="100%">
          <Text fontSize="sm" textAlign="center" mb={4}>
            <strong>Facilite os seus próximos pedidos</strong>, adicione este aplicativo web ao seu
            dispositivo.
          </Text>
          <Button variant="outline" mb={4}>
            Salvar na sua tela de início
          </Button>
          <Text fontSize="1.2rem" color="gray.400">
            *Não se preocupe, esse app não vai ocupar espaço no seu celular
          </Text>
        </Box> */}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDir="column"
          mt="auto"
          mb="1rem"
        >
          <Box display="flex" alignItems="center" justifyContent="space-around" my="3.5rem">
            <Image
              src={
                advertiserInfo?.user?.avatar?.url &&
                `${advertiserInfo?.user?.avatar?.url}?nocache=${imageTimestamp}`
              }
              backgroundColor="white"
              boxShadow="0px 2px 6px rgba(0, 0, 0, 0.15)"
              h="5rem"
              w="5rem"
              borderRadius="50%"
              objectFit="cover"
              mr={6}
            />
            <Heading as="h3" fontSize="md" fontWeight="700">
              {advertiserInfo?.name}
            </Heading>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" flexDir="column">
            <Text as="span" fontSize="1.5rem" textAlign="center">
              Este estabelecimento é um
              <br /> parceiro da{' '}
              <Text as="span" fontSize="1.5rem" fontWeight="bold">
                Kuppi. <br />O delivery do comércio local.
              </Text>
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default LoggedInContent;
