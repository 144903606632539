import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { advertiserMenu } from '~/routes/routeMap';
import { apiWebMenu } from '~/services/api';
import AuthActions from '~/store/ducks/auth';
import { formatCurrencyBRL } from '~/utils/currency';

import { RootState } from '..';
import { BagTypes, Creators as bagCreators, OnAddDiscountInBag } from '../ducks/bag';

const {
  checkProductAvailabilitySuccess,
  checkProductAvailabilityFailure,
  validateCodeCouponSuccess,
  validateCodeCouponFailure,
} = bagCreators;

const getState = (state: RootState) => state;

export function* addDiscountInBag({ coupon, notApplyDiscount }: OnAddDiscountInBag) {
  if (!notApplyDiscount) {
    if (coupon?.type === 'fixed_cart') {
      toast.success(
        `Desconto de ${formatCurrencyBRL(
          coupon.couponBenefit.cart_discount_fixed,
        )} adicionado a sacola`,
      );
    } else if (coupon?.type === 'percentage_cart') {
      toast.success(
        `Desconto da sacola atualizado para ${coupon.couponBenefit.cart_discount_percentage}%`,
      );
    }
  }
}

export function* checkProductAvailabilityRequest({ product }: any) {
  const globalState: RootState = yield select((state: RootState) => state);
  const advertiserInfo = globalState.advertiser.advertiserInfo;

  try {
    yield call(apiWebMenu.post, `/anunciante/${advertiserInfo.slug}/check-product-availability`, {
      product: product,
    });

    yield put(checkProductAvailabilitySuccess());
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(AuthActions.logOut());
      toast.error('Sessão inválida, efetue o login novamente');
    } else {
      Sentry.captureException(err);
      yield put(checkProductAvailabilityFailure());
      const message =
        err.response?.data?.error?.message ||
        'Erro ao buscar os dados do produto, você será redirecionado para o catálogo.';
      toast.error(message, { autoClose: false });
      yield put(push(advertiserMenu));
    }
  }
}

export function* validateCodeCouponRequest({ code, orderSummaryDataLocation }: any) {
  const { advertiser, user, bag } = yield select(getState);
  const advertiserInfo = advertiser?.advertiserInfo;
  const userInfo = user?.userInfo;

  const orderData = orderSummaryDataLocation;

  try {
    const response: AxiosResponse = yield call(
      apiWebMenu.post,
      `/anunciante/${advertiserInfo.slug}/client-info/${userInfo.id}/coupons/${code}/validate`,
      {
        order_amount: bag.priceWithoutDiscount,
        delivery_type: orderData.deliveryType,
        payment_method_function: orderData.advertiserPaymentMethod?.paymentMethod.function,
      },
    );

    yield put(validateCodeCouponSuccess(response.data.coupon, response.data.error_message));
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(AuthActions.logOut());
      toast.error('Sessão inválida, efetue o login novamente');
    } else {
      Sentry.captureException(err);
      yield put(validateCodeCouponFailure());
      toast.error('Falha ao recuperar o status do cupom. Contate o suporte.');
    }
  }
}

export default all([
  takeLatest(BagTypes.ADD_DISCOUNT_IN_BAG, addDiscountInBag),
  takeLatest(BagTypes.CHECK_PRODUCT_AVAILABILITY_REQUEST, checkProductAvailabilityRequest),
  takeLatest(BagTypes.VALIDATE_CODE_COUPON_REQUEST, validateCodeCouponRequest),
]);
