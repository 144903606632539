import { useCallback } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

interface UseYupValidationResolverOptions {
  shouldToast?: boolean;
  toastOnly?: string[];
}

interface ValidationErrorMessage {
  [key: string]: {
    message: string;
    type: string;
  };
}

const useYupValidationResolver = (
  validationSchema: yup.AnySchema,
  options?: UseYupValidationResolverOptions,
) =>
  useCallback(
    async (data) => {
      const shouldToast = options?.shouldToast;
      const toastOnly = options?.toastOnly;
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {} as ValidationErrorMessage,
        };
      } catch (errors) {
        if (errors instanceof yup.ValidationError) {
          const fieldErrors = {
            values: {},
            errors: errors.inner.reduce(
              (allErrors, currentError) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {},
            ) as ValidationErrorMessage,
          };
          if (shouldToast) {
            Object.entries(fieldErrors.errors).forEach(([key, value]) => {
              if (toastOnly?.includes(key)) {
                toast.error(value.message, {
                  toastId: key,
                  position: 'top-center',
                });
              }
            });
          }
          return fieldErrors;
        }
      }
    },
    [validationSchema, options],
  );

export default useYupValidationResolver;
