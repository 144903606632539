import { Text } from '@chakra-ui/core';
import styled from '@emotion/styled';

export const CustomText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 24ch;

  @media screen and (max-width: 475px) {
    max-width: 22ch;
  }

  @media screen and (max-width: 400px) {
    max-width: 18ch;
  }

  @media screen and (max-width: 340px) {
    max-width: 15ch;
  }
`;
