import { Box, Button, Text } from '@chakra-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { UseLocationProps } from '~/pages/OrderReview';
import { CustomInput } from '~/pages/OrderReview/styles';
import { RootState } from '~/store';
import { Creators as BagActions } from '~/store/ducks/bag';
import { formatCurrencyBRL } from '~/utils/currency';

import SelectOptionButton from '../SelectOptionButton';

const {
  removeCodeCouponDiscount,
  setBagDiscountActiveCoupon,
  validateCodeCouponRequest,
  cleanActiveDiscount,
} = BagActions;

const BenefitCardCodeCoupon = () => {
  const [couponDiscountInputValue, setCouponDiscountInputValue] = useState('');
  const dispatch = useDispatch();
  const location = useLocation<UseLocationProps>();
  const { state } = location;
  const orderSummaryDataLocation = state?.orderSummaryData;
  const globalState = useSelector((state: RootState) => state);
  const {
    bagDiscounts,
    validateCodeCouponLoading,
    codeCouponValidationStatus,
    codeCouponValidated,
  } = globalState?.bag;

  const codeDiscountData = useMemo(() => {
    if (bagDiscounts?.discounts['code']?.length > 0) {
      return {
        code: bagDiscounts?.discounts['code'][0]?.code,
        type: bagDiscounts?.discounts['code'][0]?.type,
        percentage: bagDiscounts?.discounts['code'][0]?.couponBenefit?.cart_discount_percentage,
        amount: formatCurrencyBRL(
          bagDiscounts?.discounts['code'][0]?.couponBenefit?.cart_discount_fixed,
        ),
      };
    }
  }, [bagDiscounts]);

  useEffect(() => {
    if (codeCouponValidated || bagDiscounts?.activeCoupon !== 'code') {
      setCouponDiscountInputValue('');
    }
  }, [codeCouponValidated, bagDiscounts?.activeCoupon]);

  return (
    <Box
      d="flex"
      flexDir="column"
      w="100%"
      mt={3}
      p={1}
      border="1px solid"
      borderColor={bagDiscounts?.activeCoupon === 'code' ? 'green.500' : 'gray.500'}
      borderRadius="8px"
    >
      <SelectOptionButton
        isSelected={bagDiscounts?.activeCoupon === 'code'}
        onClick={() => {
          if (bagDiscounts?.activeCoupon !== 'code') {
            dispatch(setBagDiscountActiveCoupon('code'));
          } else {
            dispatch(cleanActiveDiscount());
          }
        }}
      >
        {!codeCouponValidated && (
          <Text fontWeight="700" textAlign="left" fontSize="md" as="h5" mt={3} ml={3}>
            Inserir cupom de desconto
          </Text>
        )}
        {codeCouponValidated && (
          <Box
            flexDir="column"
            d="flex"
            justifyContent="left"
            alignItems="left"
            width="auto"
            ml={3}
          >
            <Text fontWeight="700" textAlign="left" fontSize="md" as="h5" mt={3}>
              {`${
                codeDiscountData?.type === 'percentage_cart'
                  ? `${codeDiscountData?.percentage}%`
                  : codeDiscountData?.amount
              } de desconto`}
            </Text>
          </Box>
        )}
      </SelectOptionButton>
      {codeCouponValidated && (
        <Box d="flex" justifyContent="center" alignItems="center" mt={2} mb={3} w="100%">
          <Text
            fontWeight="700"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
            fontSize="md"
            as="h5"
          >
            {codeDiscountData?.code}
          </Text>
          <Button
            ml={5}
            height="30px"
            fontWeight="bold"
            fontSize="1.3rem"
            bg="green.300"
            onClick={() => {
              dispatch(removeCodeCouponDiscount());
            }}
          >
            Trocar Cupom
          </Button>
        </Box>
      )}
      {bagDiscounts?.activeCoupon === 'code' && !codeCouponValidated && (
        <Box mt="5" w="100%" pl={3} pr={3} pb={3}>
          <Text textAlign="left" fontSize="sm" wordBreak="break-all">
            Informe o código para validar seu desconto
          </Text>
          <Box d="flex" mt="10px">
            <CustomInput
              placeholder="EX: SAMUUKA10"
              maxLength={20}
              value={couponDiscountInputValue}
              onChange={(event: any) =>
                setCouponDiscountInputValue(event.target.value.toString().toUpperCase())
              }
            />
            <Button
              width="40%"
              height="48px"
              fontWeight="bold"
              flex="1"
              bg="green.300"
              onClick={() =>
                dispatch(
                  validateCodeCouponRequest(couponDiscountInputValue, orderSummaryDataLocation),
                )
              }
              isLoading={validateCodeCouponLoading}
              isDisabled={validateCodeCouponLoading || couponDiscountInputValue.trim() === ''}
            >
              Aplicar
            </Button>
          </Box>
          {codeCouponValidationStatus && (
            <Text
              textAlign="left"
              fontSize="md"
              color={'#FD4141'}
              marginTop="10px"
              wordBreak="break-all"
            >
              {codeCouponValidationStatus}
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
};

export default BenefitCardCodeCoupon;
