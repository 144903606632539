import {
  Box,
  Divider,
  Heading,
  Icon,
  Image,
  Link as ChakraLink,
  Stack,
  Text,
} from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { RiWhatsappFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import ListItem from '~/components/ListItem';
import TopRoundedBox from '~/components/TopRoundedBox';
import AdvertiserPaymentMethod from '~/models/AdvertiserPaymentMethod';
import { advertiserMenu } from '~/routes/routeMap';
import { RootState } from '~/store';
import { phoneMask } from '~/utils/masks';
import { formatPhoneCallAction } from '~/utils/valitadion';

const AdvertiserDetails: React.FC = () => {
  const globalState = useSelector((state: RootState) => state);
  const advertiserInfo = globalState ? globalState?.advertiser?.advertiserInfo : null;
  const paymentMethodsOnline: AdvertiserPaymentMethod[] =
    advertiserInfo?.advertiserPaymentMethodOnline;
  const paymentMethodsOffline: AdvertiserPaymentMethod[] =
    advertiserInfo?.advertiserPaymentMethodOffline;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!advertiserInfo) {
      dispatch(push(advertiserMenu));
    }
  }, [advertiserInfo]);

  const weekDays: any = {
    sunday: 'Domingo',
    monday: 'Segunda',
    tuesday: 'Terça',
    wednesday: 'Quarta',
    thursday: 'Quinta',
    friday: 'Sexta',
    saturday: 'Sábado',
  };

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{advertiserInfo?.name ? advertiserInfo?.name : 'Kuppi'} | Cardápio Web</title>
      </Helmet>

      <Box as="main" flexDir="column" w="100%" h="100%" bg="green.400">
        <HeaderWithBackButton headerTitle="Informações" px="3rem" />

        <TopRoundedBox as="section" px="3rem" pt="3rem">
          {/* Advertiser Info */}
          <Heading as="h2" fontSize="lg" fontWeight="700" lineHeight="1.6">
            {advertiserInfo?.name}
          </Heading>
          <Heading as="h3" fontSize="md" fontWeight="400" mb={20}>
            {advertiserInfo?.advertiserCategory?.name}
          </Heading>

          {/* Store Horaries */}
          <Box d="flex" flexDirection="column" mb={20}>
            <Heading as="h4" fontWeight="700" fontSize="md" mb={6}>
              Horários de funcionamento
            </Heading>

            <Stack spacing={2}>
              {advertiserInfo?.advertiserAvailability &&
                Object.values(advertiserInfo?.advertiserAvailability).map(
                  (item: any, index: number) =>
                    item?.is_active && (
                      <Box d="flex" key={index}>
                        <Text as="span" fontSize="md" d="flex">
                          <Text as="span" fontSize="md" d="flex" mr="5px">
                            {weekDays[Object.keys(advertiserInfo?.advertiserAvailability)[index]]}{' '}
                          </Text>
                          <Text
                            as="span"
                            flex={3}
                            fontSize="md"
                            d="flex"
                            alignItems="flex-start"
                            justifyContent="flex-start"
                          >
                            {item?.horaries.map(
                              (horary: any, i: any, arr: any) =>
                                `${horary?.start_at} às ${horary?.end_at}${
                                  i < arr?.length - 1 ? '; ' : ''
                                }`,
                            )}
                          </Text>
                        </Text>
                      </Box>
                    ),
                )}
            </Stack>
          </Box>

          {/* Payment Methods Online */}
          {paymentMethodsOnline?.length > 0 && (
            <Box d="flex" flexDirection="column">
              <Heading as="h4" fontWeight="700" fontSize="md" mb={6}>
                Pagamento online
              </Heading>
              {paymentMethodsOnline?.map((method: any) => (
                <React.Fragment key={method?.id}>
                  <ListItem d="flex" flexDir="row" justifyContent="space-between">
                    <Image h="2rem" mr={8} src={method?.paymentMethod?.image_url} />
                    <Text fontSize="md">{method?.paymentMethod?.name}</Text>
                    <Box flex="1" />
                  </ListItem>
                </React.Fragment>
              ))}
              {/* Bottom border */}
              <ListItem />
            </Box>
          )}
          {/* Payment Methods Offline */}
          {paymentMethodsOffline?.length > 0 && (
            <Box d="flex" flexDirection="column" mb={8}>
              <Heading as="h4" fontWeight="700" fontSize="md" mb={6}>
                Pagamento na entrega
              </Heading>
              {paymentMethodsOffline?.map((method: any) => (
                <React.Fragment key={method?.id}>
                  <ListItem d="flex" flexDir="row" justifyContent="space-between">
                    <Image h="2rem" mr={8} src={method?.paymentMethod?.image_url} />
                    <Text fontSize="md">{method?.paymentMethod?.name}</Text>
                    <Box flex="1" />
                  </ListItem>
                </React.Fragment>
              ))}
              {/* Bottom border */}
              <ListItem />
            </Box>
          )}
        </TopRoundedBox>
      </Box>
      {/* Address and Phone */}
      <Box as="footer" bg="blackAlpha.100" flexDir="column" w="100%" px="3rem" py="3rem" pb={16}>
        <Heading as="h4" fontWeight="700" fontSize="md" mb={6}>
          {advertiserInfo?.user?.address[0]?.is_visible_on_web_menu
            ? 'Endereço e Telefones'
            : 'Telefones'}
        </Heading>

        <Stack spacing={2}>
          {advertiserInfo?.user?.address[0]?.is_visible_on_web_menu && (
            <>
              <Box d="flex" alignItems="flex-start" py={2} justifyContent="space-between">
                <Text flex="2" fontSize="md" mr={4}>
                  {advertiserInfo?.user?.address[0]?.street},{' '}
                  {advertiserInfo?.user?.address[0]?.number}{' '}
                  {advertiserInfo?.user?.address[0]?.complement} -{' '}
                  {advertiserInfo?.user?.address[0]?.city} {advertiserInfo?.user?.address[0]?.state}
                </Text>
                <ChakraLink
                  flex="1"
                  href={`https://maps.google.com/?q=${advertiserInfo?.user?.address[0]?.latitude},${advertiserInfo?.user?.address[0]?.longitude}`}
                  rel="noopener noreferer"
                  target="_blank"
                  d="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  fontSize="md"
                  px={4}
                  _hover={{
                    textDecor: 'none',
                  }}
                  w="auto"
                >
                  <Text as="span" fontSize="md" w="auto">
                    VER MAPA
                  </Text>
                  <Icon name="menu-pin" ml={3} size="1.6rem" />
                </ChakraLink>
              </Box>
              <Divider />
            </>
          )}

          {advertiserInfo?.advertiserPhones?.length > 0 &&
            advertiserInfo?.advertiserPhones?.map((phone, idx) => (
              <>
                <Box
                  key={`advertiserPhone-${idx}`}
                  d="flex"
                  alignItems="center"
                  py={5}
                  justifyContent="space-between"
                >
                  <Text fontSize="md" mr={4}>
                    {phoneMask(phone?.number)}
                  </Text>
                  <Box flexDir="column">
                    <ChakraLink
                      flex="1"
                      href={formatPhoneCallAction(phone?.number)}
                      d="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      fontSize="md"
                      px={4}
                      _hover={{
                        textDecor: 'none',
                      }}
                      w="auto"
                    >
                      <Text as="span" fontSize="md" w="auto">
                        LIGAR
                      </Text>
                      <Icon name="phone" ml={3} size="1.4rem" />
                    </ChakraLink>
                    {phone?.type === 'whatsapp' && (
                      <ChakraLink
                        flex="1"
                        href={`whatsapp://send?text=Olá&phone=${phone?.number}`}
                        d="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        fontSize="md"
                        px={4}
                        mt={6}
                        _hover={{
                          textDecor: 'none',
                        }}
                        w="auto"
                      >
                        <Text as="span" fontSize="md" w="auto">
                          WHATSAPP
                        </Text>
                        <Box as={RiWhatsappFill} ml={3} size="2rem" />
                      </ChakraLink>
                    )}
                  </Box>
                </Box>
                <Divider />
              </>
            ))}
        </Stack>
      </Box>
    </>
  );
};

export default AdvertiserDetails;
