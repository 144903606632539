import { Box } from '@chakra-ui/core';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const fadeUp = keyframes`
  0% {
    transform: translate3d(-50%, 100%, 1.0);
    opacity: 0.6;
  }

  100% {
    transform: translate3d(-50%, 0, 1.0);
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
`;

export const Container = styled(Box)`
  animation: ${fadeUp} 400ms ease;
`;

export const Overlay = styled(Box)`
  animation: ${fadeIn} 200ms ease;
`;
