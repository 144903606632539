import { Box, Image, ListItem, Progress, Stack, Text } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import BottomBar from '~/components/BottomBar';
import Button from '~/components/Button';
import Card from '~/components/Card';
import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import Order from '~/models/Order';
import { RootState } from '~/store';
import Creators from '~/store/ducks/order';
import { formatCurrencyBRL } from '~/utils/currency';

import copyPaste from '../../assets/images/icon_copy_paste/copypaste.png';
import pixLogo from '../../assets/images/logo_pix/logo-pix.png';

interface UseLocationProps {
  order: Order;
  orderList?: string;
  goBackToMyOrders: boolean;
}

const PixPendingPayment: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation<UseLocationProps>();
  const state = location?.state;
  const goBackToMyOrders = state?.goBackToMyOrders;
  const currentDate = new Date().getTime();
  const globalState = useSelector((state: RootState) => state);
  const advertiserInfo = globalState.advertiser?.advertiserInfo;
  const orders = globalState?.order?.orders;

  const currOrder = useMemo(() => {
    return orders?.filter((o) => o.id === state?.order?.id);
  }, [orders]);
  const order = currOrder[0];

  // Force pix expiration date to 5 minutes, because in the backend the expiration date is 4 minutes and 10 seconds
  const pixExpirationDate = new Date(order?.advertiserOrderPayment?.pix_expiration_at).getTime();
  const [timePercent, setTimePercent] = useState(pixExpirationDate - currentDate <= 0 ? 0 : 100);
  const [countdown, setCountdown] = useState(startCountdown(currentDate, pixExpirationDate));

  function handleCountdown(createDate: number, expirationDate: number) {
    const timeRemain = expirationDate - createDate;
    const mins = new Date(timeRemain).getMinutes();
    const secs = new Date(timeRemain).getSeconds();
    setTimePercent(timeRemain / 3000);
    setCountdown(String(mins + ':' + (secs < 10 ? '0' + secs : secs)));
  }

  function startCountdown(createDate: number, expirationDate: number) {
    const timeRemain = expirationDate - createDate;
    const mins = new Date(timeRemain).getMinutes();
    const secs = new Date(timeRemain).getSeconds();
    return String(mins + ':' + (secs < 10 ? '0' + secs : secs));
  }

  useEffect(() => {
    if (!order || !order?.advertiserOrderPayment) {
      dispatch(push('/meus-pedidos'));
    } else if (
      order &&
      order?.advertiserOrderPayment &&
      (timePercent === 0 || order?.orderStatus?.status === 'new')
    ) {
      dispatch(push('/pedido', state));
    }
  }, [order?.orderStatus]);

  useEffect(() => {
    dispatch(Creators.createOrderFailure());
    const interval = setInterval(() => {
      handleCountdown(currentDate, pixExpirationDate);
      startCountdown(currentDate, pixExpirationDate);
    }, 1000);
    if (currentDate >= pixExpirationDate) {
      clearInterval(interval);
      setTimePercent(0);
      dispatch(push('/pedido', state));
    }

    return () => clearInterval(interval);
  });

  function copyPixPaymentCode(e: any) {
    var copyText = document.getElementById('pixCode');
    var textArea = document.createElement('textarea');
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();

    toast.success('O código do Pix foi copiado com sucesso!');
  }

  const handleOnClickSeeHelpPage = () => {
    dispatch(push('/pedido/pix-ajuda', state));
  };

  const handleOnClickSeeDetails = useCallback(() => {
    dispatch(push(`/pedido/detalhes`, { order }));
  }, [dispatch, order]);

  return (
    <>
      {order?.orderStatus?.status === 'pending_payment' && (
        <Box as="main" flexDir="column" w="100%" px="3rem" mt="2.5rem" pb={40}>
          <HeaderWithBackButton
            justifyContent="center"
            alignItems="center"
            headerTitle={
              <Box d="flex" w="100%" ml="auto" alignItems="center" justifyContent="space-between">
                <Box />
                <Text>Pagamento</Text>
                <Button p="0" m="0" background="transparent" onClick={handleOnClickSeeHelpPage}>
                  <Text fontWeight="400" fontSize="1.25rem" color="teal.500">
                    Ajuda?
                  </Text>
                </Button>
              </Box>
            }
            mb={4}
            to={!goBackToMyOrders ? '/' : '/meus-pedidos'}
            state={state}
          />

          <Image src={pixLogo} m="0px auto" />
          <Text fontSize="1.7rem" fontWeight="bold" textAlign="center">
            Pedido Aguardando Pagamento
          </Text>

          <Box d="flex" alignItems="center" flexDir="column" w="100%" mt={8}>
            <Text fontSize="1.3rem" textAlign="center">
              Copie o código abaixo para pagar via Pix em qualquer aplicativo habilitado
            </Text>

            <ListItem
              maxH="fit-content"
              p={5}
              mt={10}
              w="100%"
              as="button"
              border="dashed 2px"
              borderColor="gray.900"
              borderRadius={13}
              d="flex"
              justifyContent="center"
              flexDir="row"
              fontWeight="bold"
            >
              <Text
                h="100%"
                fontSize="md"
                overflow="hidden"
                whiteSpace="nowrap"
                style={{ textOverflow: 'ellipsis' }}
                textTransform="uppercase"
                mr={1.5}
                onClick={copyPixPaymentCode}
                id="pixCode"
              >
                {order?.advertiserOrderPayment?.pix_qr_code}
              </Text>

              <Box ml={2}>
                <Image src={copyPaste} minWidth="2rem" onClick={copyPixPaymentCode} />
              </Box>
            </ListItem>
          </Box>

          <Box d="flex" alignItems="center" flexDir="column" w="100%" mt={5}>
            <Text fontSize="1.3rem" fontWeight="bold" textAlign="center">
              O código Pix tem vencimento de 5 minutos.
            </Text>

            <Text fontSize="1.1rem" textAlign="center" mt={5}>
              O tempo para você pagar acaba em:
            </Text>

            <Text as="span" fontSize="4rem" textAlign="center" mt={5} fontWeight="bold">
              {countdown}
            </Text>

            <Progress color={'green'} h={3} w="100%" value={timePercent} />
          </Box>

          <Card justifyContent="center" px={0} py={4} mt={25} flexDir="column">
            <Text fontSize="1.5rem" px={6} fontWeight="bold" alignSelf="flex-start">
              Detalhes do pedido
            </Text>
            <Box d="flex" alignItems="center" justifyContent="flex-start" w="100%" p={5}>
              <Box>
                <Image
                  src={advertiserInfo?.user?.avatar?.url}
                  backgroundColor="white"
                  h="5.5rem"
                  w="5.5rem"
                  borderRadius="50%"
                  objectFit="cover"
                  border="1px solid"
                  borderColor="gray.900"
                  mr={6}
                />
              </Box>
              <Box>
                <Text fontWeight="bold" fontSize="1.3rem">
                  {advertiserInfo?.name}
                </Text>
                <Text
                  fontWeight="bold"
                  mt={1}
                  fontSize="1rem"
                  color="red.500"
                  onClick={handleOnClickSeeDetails}
                  cursor="pointer"
                >
                  Ver mais
                </Text>
              </Box>
              <Box ml="auto">
                <Text fontSize="1.2rem" color="gray.400">
                  Pedido #{order?.id}
                </Text>
              </Box>
            </Box>

            <Box d="flex" alignItems="center" justifyContent="flex-start" w="100%" p={5}>
              <Box>
                <Text fontSize="1.4rem" color="gray.400">
                  Pagamento
                </Text>
              </Box>
              <Box ml="auto" d="flex" w="auto" flexDirection="row">
                <Image h="1.8rem" mr={2} src={order?.paymentMethod?.image_url} />
                <Text fontSize="1.4rem" color="gray.400">
                  Pix
                </Text>
              </Box>
            </Box>

            <Box d="flex" alignItems="center" justifyContent="flex-start" w="100%" p={5}>
              <Box>
                <Text fontWeight="bold" fontSize="1.5rem">
                  Total
                </Text>
              </Box>
              <Box ml="auto">
                <Text fontWeight="bold" fontSize="1.5rem">
                  {formatCurrencyBRL(order?.total_amount)}
                </Text>
              </Box>
            </Box>
          </Card>

          <BottomBar>
            <Stack spacing={16} alignItems="center" py={3} px={4}>
              <Button id="btnCopyCode" w="100%" isDisabled={null} onClick={copyPixPaymentCode}>
                Copiar código
              </Button>
            </Stack>
          </BottomBar>
        </Box>
      )}
    </>
  );
};

export default PixPendingPayment;
