import { useCallback, useEffect } from 'react';

import { apiWebMenu } from '~/services/api';

import usePersistedState from './usePersistedState';

export default function useSlug(): string | null {
  const [slug, setSlug] = usePersistedState('@kuppi/slug', null);

  const fetchSlugFromDomain = useCallback(
    async (hostname: string) => {
      try {
        const response = await apiWebMenu.get('/domain', { params: { domain: hostname } });
        setSlug(response.data.slug);
      } catch (err) {
        setSlug(null);
      }
    },
    [setSlug],
  );

  useEffect(() => {
    const hostname = window.location.hostname;
    const regex = new RegExp(/^([a-z]|-|\.|[0-9])+\.kuppi\.(local|app)$/);
    // Is subdomain
    if (regex.test(hostname)) {
      const [subdomain] = hostname.split('.');

      if (subdomain && !subdomain.match(/^www+/)) {
        setSlug(subdomain);
      }
    }
    // Proprietary domain, fetch slug from API
    else {
      fetchSlugFromDomain(hostname);
    }
  }, [setSlug, fetchSlugFromDomain]);

  return slug;
}
