// @ts-nocheck
import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';
import { addHours, format, isWithinInterval } from 'date-fns';
import { toast } from 'react-toastify';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import AdvertiserInfo from '~/models/AdvertiserInfo';
import Coupon from '~/models/Coupon';
import Product from '~/models/Product';
import { apiGlobal, apiWebMenu } from '~/services/api';
import { isAvailableAt, nextAvailableAt } from '~/utils/availability';
import getCurrentDay from '~/utils/getCurrentDay';

import { Creators as AddressCreators } from '../ducks/addresses';
import {
  AdvertiserTypes,
  Creators as AdvertiserCreators,
  OnFormatBestOffers,
  OnFormatData,
  OnGetBestOffers,
  OnGetData,
  OnSendCampaignClick,
} from '../ducks/advertiser';
import { Creators as AuthCreators } from '../ducks/auth';
import { Creators as BagCreators } from '../ducks/bag';
import { Creators as UserCreators } from '../ducks/user';

const {
  setData,
  setNextAdvertiserIsOpenDate,
  setBestOffers,
  advertiserNotFound,
  formatData: formatDataAction,
  formatBestOffers: formatBestOffersAction,
  setAdvertiserLoading,
  setBestOffersLoading,
} = AdvertiserCreators;
const { setAdvertiserDeliverInCurrentAddress, setCurrentAddress } = AddressCreators;
const { cleanBag } = BagCreators;
const { logOut } = AuthCreators;
const { setHasUpdatedTheAddress } = UserCreators;

export function* formatBestOffers({ bestOffers }: OnFormatBestOffers) {
  try {
    const currentDay = getCurrentDay();
    const now = new Date();

    const bestBestOffer = Object.assign({}, bestOffers[0]);

    bestOffers = bestOffers
      .map((offer) => {
        if (offer.is_active) {
          let isCouponAvailable = false;

          if (offer.mode === 'flash') {
            isCouponAvailable = offer.used_coupons < offer.usage_limit;

            if (isCouponAvailable && offer.couponHorary) {
              isCouponAvailable = offer.couponHorary[currentDay]?.horaries.some(
                ({ start_at, end_at }) => isAvailableAt(start_at, end_at, now),
              );
            }
          } else if (offer.mode === 'normal' && offer.couponHorary) {
            isCouponAvailable = offer.couponHorary[currentDay]?.horaries.some(
              ({ start_at, end_at }) => isAvailableAt(start_at, end_at, now),
            );
          }

          if (!isCouponAvailable) {
            const couponNextAvailableAt = nextAvailableAt(now, offer.couponHorary);
            offer.nextAvailableAt = couponNextAvailableAt;
          }
          offer.isAvailable = isCouponAvailable;

          if (offer.product) {
            const isProductAvailable = offer.product.availability[currentDay]?.horaries.some(
              ({ start_at, end_at }) => isAvailableAt(start_at, end_at, now),
            );

            if (!isProductAvailable) {
              const productNextAvailableAt = nextAvailableAt(now, offer.product.availability);
              offer.product.nextAvailableAt = productNextAvailableAt;
            }

            offer.product.isAvailable = isProductAvailable;
            offer.product.isCouponAvailable = isCouponAvailable;
          }

          return offer;
        }

        return;
      })
      .filter((offer) => offer);

    // Generate best offer tag value - displayed at advertiser web menu home page
    if (bestBestOffer.product) {
      const firstProduct = bestBestOffer.product;
      if (firstProduct?.coupon?.type === 'percentage_cart') {
        yield put(
          setBestOffers(
            bestOffers,
            Math.floor(firstProduct?.coupon?.couponBenefit?.cart_discount_percentage),
          ),
        );
      } else {
        const percentage =
          ((firstProduct?.coupon?.couponBenefit.from_value -
            firstProduct?.coupon?.couponBenefit.by_value) /
            firstProduct?.coupon?.couponBenefit.from_value) *
          100;
        yield put(setBestOffers(bestOffers, percentage ? Math.floor(percentage) : 0));
      }
    } else {
      const firstCoupon = bestBestOffer as Coupon;

      if (firstCoupon?.type === 'percentage_cart') {
        yield put(
          setBestOffers(
            bestOffers,
            Math.floor(firstCoupon?.couponBenefit?.cart_discount_percentage),
          ),
        );
      } else {
        const percentage =
          ((firstCoupon?.couponBenefit?.from_value - firstCoupon?.couponBenefit?.by_value) /
            firstCoupon?.couponBenefit?.from_value) *
          100;
        yield put(setBestOffers(bestOffers, percentage ? Math.floor(percentage) : 0));
      }
    }
  } catch (err) {
    Sentry.captureException(err);
  }
}

export function* formatData({ advertiserInfo }: OnFormatData) {
  const currentDay = getCurrentDay();
  const now = new Date();
  const timeZoneOffsetInHour = now.getTimezoneOffset() / 60;
  let isOpen = false;

  const isBlocked = advertiserInfo.is_overdue_payment || !advertiserInfo.user.is_active;

  if (isBlocked) {
    yield put(setData(null, false, String(now), advertiserInfo.slug, null));
    return;
  }

  if (
    advertiserInfo.advertiserAvailability[currentDay]?.is_active &&
    advertiserInfo.is_accepting_orders
  ) {
    isOpen = advertiserInfo.advertiserAvailability[currentDay].horaries.some(
      (horary: { start_at: string; end_at: string }) => {
        if (horary.start_at === '00:00' && horary.end_at === '00:00') {
          return true;
        }
        return isWithinInterval(now, {
          start: addHours(
            new Date(`${format(new Date(), 'yyyy-MM-dd')}T${horary.start_at}:00Z`),
            timeZoneOffsetInHour,
          ),
          end: addHours(
            new Date(`${format(new Date(), 'yyyy-MM-dd')}T${horary.end_at}:00Z`),
            timeZoneOffsetInHour,
          ),
        });
      },
    );
  }
  let advertiserNextOpenAt: Date;

  if (!isOpen) {
    advertiserNextOpenAt = nextAvailableAt(now, advertiserInfo.advertiserAvailability);
    yield put(setNextAdvertiserIsOpenDate(advertiserNextOpenAt));
  }

  const availableCategories = advertiserInfo?.productCategory?.map((category) => {
    const products = category?.products?.map((product) => {
      const isProductAvailable = product.availability[currentDay]?.horaries.some(
        ({ start_at, end_at }) => isAvailableAt(start_at, end_at, now),
      );

      if (!isProductAvailable) {
        const productNextAvailableAt = nextAvailableAt(now, product.availability);
        product.nextAvailableAt = productNextAvailableAt;
      }

      let isCouponAvailable = false;
      if (product.coupon && product.coupon?.is_active) {
        if (product.coupon?.mode === 'flash') {
          isCouponAvailable = product.coupon?.used_coupons < product.coupon?.usage_limit;

          if (isCouponAvailable && product.coupon?.couponHorary) {
            isCouponAvailable = product.coupon?.couponHorary[currentDay]?.horaries.some(
              ({ start_at, end_at }) => isAvailableAt(start_at, end_at, now),
            );
          }
        } else if (product.coupon?.mode === 'normal' && product.coupon?.couponHorary) {
          isCouponAvailable = product.coupon?.couponHorary[currentDay]?.horaries.some(
            ({ start_at, end_at }) => isAvailableAt(start_at, end_at, now),
          );
        }
      }

      product.isAvailable = isProductAvailable;
      product.isCouponAvailable = isCouponAvailable;

      return product;
    });

    // Filter only the unavailable products
    const unavailableProducts = products?.filter((product) => product.isAvailable !== true);
    // Filter only the available products
    const availableProducts = products?.filter((product) => product.isAvailable === true);

    // Mount the products at category, forcing unavailable after the availables (avoiding sorting erro at safari and chrome)
    category.products = [...availableProducts, ...unavailableProducts];
    return category;
  });

  advertiserInfo.productCategory = availableCategories;

  // Treatment to separate online from offline payment methods
  advertiserInfo.advertiserPaymentMethod.map((pm) => {
    if (pm.is_active) {
      if (pm.paymentMethod.type === 'offline') {
        advertiserInfo.advertiserPaymentMethodOffline.push(pm);
      } else if (pm.paymentMethod.type === 'online') {
        advertiserInfo.advertiserPaymentMethodOnline.push(pm);
      }
    }
    return true;
  });

  // Treatment to get the cluster slug related to the advertiser
  advertiserInfo.advertiser_cluster_events = {
    add_to_cart: advertiserInfo.advertiserCategory.advertiserBoostCluster.add_to_cart_event_name,
  };

  // Treatment to sort neighborhoods by name
  const sortedNeighborhoods = advertiserInfo.neighborhoodAreaConfigs?.sort((nA, nB) => {
    // Sort by Neighborhood
    if (nA.district.name < nB.district.name) return -1;
    if (nA.district.name > nB.district.name) return 1;
    return 0;
  });
  advertiserInfo.neighborhoodAreaConfigs = sortedNeighborhoods;

  // Treatment do return only different advertiser contact phones
  advertiserInfo.advertiserPhones = [];
  const phoneWhatsappAlreadyExists = advertiserInfo.advertiserPhones.find((phone) => {
    return phone.number === advertiserInfo?.user.address[0]?.phone_whatsapp;
  });
  if (!phoneWhatsappAlreadyExists && advertiserInfo?.user.address[0]?.phone_whatsapp) {
    advertiserInfo.advertiserPhones.push({
      type: 'whatsapp',
      number: advertiserInfo?.user.address[0]?.phone_whatsapp,
    });
  }
  const phone1AlreadyExists = advertiserInfo.advertiserPhones.find((phone) => {
    return phone.number === advertiserInfo?.user.address[0]?.phone_1;
  });
  if (!phone1AlreadyExists && advertiserInfo?.user.address[0]?.phone_1) {
    advertiserInfo.advertiserPhones.push({
      type: 'phone',
      number: advertiserInfo?.user.address[0]?.phone_1,
    });
  }
  const phone2AlreadyExists = advertiserInfo.advertiserPhones.find((phone) => {
    return phone.number === advertiserInfo?.user.address[0]?.phone_2;
  });
  if (!phone2AlreadyExists && advertiserInfo?.user.address[0]?.phone_2) {
    advertiserInfo.advertiserPhones.push({
      type: 'phone',
      number: advertiserInfo?.user.address[0]?.phone_2,
    });
  }

  yield put(
    setData(advertiserInfo, isOpen, String(now), advertiserInfo.slug, advertiserInfo.loyaltyCard),
  );
}

export function* getData({ slug }: OnGetData) {
  try {
    const advertiserInfoInStore: AdvertiserInfo = yield select(
      (state) => state.advertiser.advertiserInfo,
    );
    const currentAddressLat = advertiserInfoInStore?.user?.address[0]?.latitude;
    const currentAddressLgn = advertiserInfoInStore?.user?.address[0]?.longitude;

    if (advertiserInfoInStore === null) {
      yield put(setAdvertiserLoading(true));
    }
    const response: AxiosResponse = yield call(apiWebMenu.get, `/anunciante/${slug}`);
    const advertiserInfo = response.data as AdvertiserInfo;

    const newLat = advertiserInfo?.user?.address[0]?.latitude;
    const newLgn = advertiserInfo?.user?.address[0]?.longitude;

    const advertiserAddressHasBeenUpdated =
      currentAddressLat !== newLat && currentAddressLgn !== newLgn;

    if (advertiserAddressHasBeenUpdated) {
      yield put(setHasUpdatedTheAddress(true));
    }

    advertiserInfo.advertiserPaymentMethodOffline = [];
    advertiserInfo.advertiserPaymentMethodOnline = [];
    // Clear user current address if delivery type is changed
    if (
      advertiserInfoInStore?.deliveryTakeawayConfig?.is_using_neighborhood_area !==
      advertiserInfo?.deliveryTakeawayConfig?.is_using_neighborhood_area
    ) {
      yield put(setCurrentAddress(null));
    }
    yield put(formatDataAction(advertiserInfo));
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logOut());
      toast.error('Sessão inválida, efetue o login novamente');
    } else if (err.response?.status === 404) {
      yield put(advertiserNotFound());
      yield put(cleanBag());
      yield put(logOut());
      yield put(setAdvertiserDeliverInCurrentAddress(null));
    } else {
      toast.error('Falha ao coletar os dados. Contate o suporte.');
    }
  } finally {
    yield put(setAdvertiserLoading(false));
  }
}

export function* getBestOffers({ slug }: OnGetBestOffers) {
  try {
    const bestOffersInStore: Array<Product | Coupon> = yield select(
      (state) => state.advertiser.advertiserBestOffers,
    );
    if (bestOffersInStore === null || !bestOffersInStore?.length) {
      yield put(setBestOffersLoading(true));
    }

    const response: AxiosResponse = yield call(apiWebMenu.get, `/anunciante/${slug}/best-offers`);
    yield put(formatBestOffersAction(response.data));
  } catch (err: any) {
    if (err.response?.status === 401) {
      yield put(logOut());
      toast.error('Sessão inválida, efetue o login novamente');
    } else {
      toast.error('Falha ao coletar os dados. Contate o suporte.');
    }
  } finally {
    yield put(setBestOffersLoading(false));
  }
}

export function* sendCampaignClick({ facebookCampaignId }: OnSendCampaignClick) {
  yield delay(5000);

  try {
    const advertiserInfoInStore: AdvertiserInfo = yield select(
      (state) => state.advertiser.advertiserInfo,
    );

    yield call(apiGlobal.post, `/user-info/${advertiserInfoInStore.id}/mass-message/click`, {
      campaign_id: parseInt(facebookCampaignId),
    });

    localStorage.setItem(`click_already_counted:${facebookCampaignId}`, 'true');
  } catch (err: any) {
    toast.error('Falha ao atualizar as métricas!');
  }
}

export default all([
  takeLatest(AdvertiserTypes.GET_DATA, getData),
  takeLatest(AdvertiserTypes.GET_BEST_OFFERS, getBestOffers),
  takeLatest(AdvertiserTypes.FORMAT_DATA, formatData),
  takeLatest(AdvertiserTypes.FORMAT_BEST_OFFERS, formatBestOffers),
  takeLatest(AdvertiserTypes.SEND_CAMPAIGN_CLICK, sendCampaignClick),
]);
