import React from 'react';

import { findHeightPosition, findWidthPosition } from '~/utils/findPosition';

interface ScrollSmoothProps {
  ref: React.MutableRefObject<HTMLDivElement>;
  includedBox?: React.MutableRefObject<HTMLDivElement>;
  complementsScroll?: boolean;
}

export function ScrollSmoothTop({
  ref,
  complementsScroll = false,
  includedBox = null,
}: ScrollSmoothProps): any {
  const { height } = ref.current?.getBoundingClientRect();
  const top = complementsScroll
    ? findHeightPosition(ref.current)
    : findHeightPosition(ref.current) - height + 1;

  if (includedBox) {
    includedBox.current?.scrollTo({
      behavior: 'smooth',
      top,
    });
  } else {
    window.scrollTo({
      behavior: 'smooth',
      top,
    });
  }
}

export function ScrollSmoothLeft({ ref, includedBox = null }: ScrollSmoothProps): any {
  const left = findWidthPosition(ref.current);

  if (includedBox) {
    includedBox.current?.scrollTo({
      behavior: 'smooth',
      left,
    });
  } else {
    window.scrollTo({
      behavior: 'smooth',
      left,
    });
  }
}
