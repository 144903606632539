import { Flex, FlexProps } from '@chakra-ui/core';
import React from 'react';

import { VisibleMobileOnly } from './styles';

interface LayoutProps extends FlexProps {}

const Layout: React.FC<LayoutProps> = ({ children, ...rest }) => {
  return (
    <Flex minW="100%" minH="100vh" justifyContent="center" {...rest}>
      <VisibleMobileOnly
        direction="column"
        bg="white"
        w="min(900px, 100%)"
        boxShadow={['initial', 'initial', 'initial', 'initial', '0 0 12px 4px rgba(0,0,0,0.1)']}
      >
        {children}
      </VisibleMobileOnly>
    </Flex>
  );
};

export default Layout;
