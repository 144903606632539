import { Box, Heading, Stack, Text } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import BottomBar from '~/components/BottomBar';
import Button from '~/components/Button';
import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import ListItem from '~/components/ListItem';
import OrderSummary from '~/models/OrderSummary';
import { advertiserMenu, bag, orderReview } from '~/routes/routeMap';
import { RootState } from '~/store';
import { formatCurrencyBRL } from '~/utils/currency';

interface UseLocationProps {
  orderSummaryData: OrderSummary;
}

const OrderItemDetails: React.FC = () => {
  const dispatch = useDispatch();

  const { state } = useLocation<UseLocationProps>();
  const orderSummaryData = state?.orderSummaryData;

  const globalState = useSelector((state: RootState) => state);
  const { bagDiscounts } = globalState?.bag;

  useEffect(() => {
    if (!orderSummaryData) {
      dispatch(push(advertiserMenu));
    }
  }, [orderSummaryData, dispatch]);

  const { advertiserInfo } = globalState.advertiser;

  const handleOnClickConfirmItems = () => {
    dispatch(push(orderReview, { orderSummaryData }));
  };

  const handleOnClickEditItems = () => {
    dispatch(push(bag));
  };

  return (
    <Box as="main" flexDir="column" w="100%" px="3rem" pb={40} position="relative">
      <HeaderWithBackButton
        headerTitle="Itens do pedido"
        mb={16}
        to={orderReview}
        state={{ orderSummaryData }}
      />
      <Box d="flex" alignItems="center" mb={3}>
        <Heading as="h2" fontSize="lg">
          {advertiserInfo?.name}
        </Heading>
      </Box>

      <Stack spacing={0} as="section">
        {orderSummaryData?.products.map((item) => (
          <ListItem key={item.id}>
            <Box d="flex" alignItems="flex-start" justifyContent="space-between" w="100%">
              <Box d="flex" alignItems="flex-start" flexDir="column">
                <Text fontSize="sm">
                  {item.productQuantity}x {item.name}
                </Text>
              </Box>
              <Box d="flex" alignItems="flex-start" justifyContent="center">
                <Text as="span" textAlign="center" fontSize="sm" fontWeight="700">
                  {bagDiscounts.activeCoupon === 'product' && item.coupon
                    ? formatCurrencyBRL(item.coupon.couponBenefit.by_value * item.productQuantity)
                    : formatCurrencyBRL(item.amount * item.productQuantity)}
                </Text>
              </Box>
            </Box>
            <Box d="flex" alignItems="flex-start" flexDir="column">
              {item.selectedComplements.map((complement: any) => (
                <React.Fragment key={complement.id}>
                  {complement.options.map((option: any) => (
                    <Box w="100%" key={option.id} d="flex" justifyContent="space-between">
                      {option.quantity > 0 && !option.is_selected ? (
                        <>
                          <Text key={option.id} fontSize="sm">
                            {option.quantity}x {option.name}
                          </Text>
                        </>
                      ) : (
                        option.quantity > 0 &&
                        option.is_selected && (
                          <>
                            <Text key={option.id} fontSize="sm">
                              {option.quantity}x {option.name}
                            </Text>
                          </>
                        )
                      )}
                    </Box>
                  ))}
                </React.Fragment>
              ))}
            </Box>
          </ListItem>
        ))}
      </Stack>

      <BottomBar>
        <Box d="flex" flexDir="column" w="100%" py={4}>
          <Button onClick={handleOnClickConfirmItems}>Confirmar itens</Button>
          <Button variant="ghost" onClick={handleOnClickEditItems}>
            Editar itens do pedido
          </Button>
        </Box>
      </BottomBar>
    </Box>
  );
};

export default OrderItemDetails;
