import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import { reducer as addresses } from './addresses';
import { reducer as advertiser } from './advertiser';
import { reducer as auth } from './auth';
import { reducer as bag } from './bag';
import {
  advertiserPersistConfig,
  bagPersistConfig,
  ordersPersistConfig,
  userPersistConfig,
} from './config/persist';
import { reducer as cookies } from './cookies';
import { reducer as kuppiBoost } from './kuppiBoost';
import { reducer as order } from './order';
import { reducer as user } from './user';

const createRootReducer = (history: History<object>) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    user: persistReducer(userPersistConfig, user),
    addresses,
    advertiser: persistReducer(advertiserPersistConfig, advertiser),
    cookies,
    bag: persistReducer(bagPersistConfig, bag),
    order: persistReducer(ordersPersistConfig, order),
    kuppiBoost,
  });

export default createRootReducer;
