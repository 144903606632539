import { Box, Icon } from '@chakra-ui/core';
import React from 'react';

import Button, { ButtonProps } from '~/components/Button';

interface ProfileOptionProps extends ButtonProps {
  iconName: string;
  iconSize?: string;
}

const ProfileOption: React.FC<ProfileOptionProps> = ({ children, iconName, iconSize, onClick }) => {
  return (
    <Button
      d="flex"
      flexDir="row"
      w="100%"
      py="2rem"
      px="0"
      background="transparent"
      alignItems="center"
      justifyContent="flex-start"
      onClick={onClick}
    >
      <Box
        d="flex"
        flexDir="column"
        w="4rem"
        h="100%"
        alignItems="flex-start"
        justifyContent="center"
      >
        <Icon name={iconName} size={iconSize ? iconSize : '2rem'} />
      </Box>
      <Box
        d="flex"
        flexDir="column"
        w="100%"
        h="100%"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {children}
      </Box>
    </Button>
  );
};

export default ProfileOption;
