import produce from 'immer';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import { OrderStatus } from '~/models/Order';
import Order from '~/models/Order';
import OrderSummary from '~/models/OrderSummary';
import { Pagination } from '~/models/PaginatedResult';

/* ============== ACTION TYPES ============== */

enum TypesNames {
  CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST',
  REORDER_REQUEST = 'REORDER_REQUEST',
  CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE',
  CLEAN_ALL = 'CLEAN_ALL',
  UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS',
  GET_MY_ORDER = 'GET_MY_ORDER',
  SET_MY_ORDER = 'SET_MY_ORDER',
  GET_MY_ORDERS = 'GET_MY_ORDERS',
  SET_MY_ORDERS = 'SET_MY_ORDERS',
  SET_PREVIOUS_ORDERS = 'SET_PREVIOUS_ORDERS',
  SET_ORDERS_PAGINATION = 'SET_ORDERS_PAGINATION',
  PIX_KEY_CONFIRM_PAYMENT = 'PIX_KEY_CONFIRM_PAYMENT',
  PIX_KEY_CONFIRM_PAYMENT_SUCCESS_FAILURE = 'PIX_KEY_CONFIRM_PAYMENT_SUCCESS_FAILURE',
  LOG_OUT = 'LOG_OUT',
  SET_FORCE_JOIN_WS_ORDER_STATUS = 'SET_FORCE_JOIN_WS_ORDER_STATUS',
}

export interface OnCreateOrderRequest extends Action<TypesNames.CREATE_ORDER_REQUEST> {
  orderData: OrderSummary;
}

export interface OnReOrderRequest extends Action<TypesNames.REORDER_REQUEST> {
  orderId: number;
}

export interface OnCreateOrderSuccess extends Action<TypesNames.CREATE_ORDER_SUCCESS> {
  orders: Order[];
  previousOrders: Order[];
}

export interface OnCreateOrderFailure extends Action<TypesNames.CREATE_ORDER_FAILURE> {}

export interface OnCleanAll extends Action<TypesNames.CLEAN_ALL> {}

export interface OnUpdateOrderStatus extends Action<TypesNames.UPDATE_ORDER_STATUS> {
  orderStatus: OrderStatus;
}

export interface OnGetMyOrders extends Action<TypesNames.GET_MY_ORDERS> {
  page: number;
  perPage?: number;
}

export interface OnGetMyOrder extends Action<TypesNames.GET_MY_ORDERS> {
  id: number;
  slug: string;
}

export interface OnSetMyOrder extends Action<TypesNames.SET_MY_ORDER> {
  order: Order;
}

export interface OnSetMyOrders extends Action<TypesNames.SET_MY_ORDERS> {
  orders: Order[];
}

export interface OnSetPreviousOrders extends Action<TypesNames.SET_PREVIOUS_ORDERS> {
  orders: Order[];
}

export interface OnSetOrdersPagination extends Action<TypesNames.SET_ORDERS_PAGINATION> {
  pagination: Pagination;
}

export interface OnSetForceJoinWSOrderStatus
  extends Action<TypesNames.SET_FORCE_JOIN_WS_ORDER_STATUS> {}

/* ============== ACTION CREATORS AND TYPES ============== */

export const { Types, Creators } = createActions<
  {
    [TypesNames.CREATE_ORDER_REQUEST]: string;
    [TypesNames.REORDER_REQUEST]: string;
    [TypesNames.CREATE_ORDER_SUCCESS]: string;
    [TypesNames.CREATE_ORDER_FAILURE]: string;
    [TypesNames.CLEAN_ALL]: string;
    [TypesNames.UPDATE_ORDER_STATUS]: string;
    [TypesNames.GET_MY_ORDER]: string;
    [TypesNames.SET_MY_ORDER]: string;
    [TypesNames.GET_MY_ORDERS]: string;
    [TypesNames.SET_MY_ORDERS]: string;
    [TypesNames.SET_PREVIOUS_ORDERS]: string;
    [TypesNames.SET_ORDERS_PAGINATION]: string;
    [TypesNames.PIX_KEY_CONFIRM_PAYMENT]: any;
    [TypesNames.PIX_KEY_CONFIRM_PAYMENT_SUCCESS_FAILURE]: string;
    [TypesNames.LOG_OUT]: string;
    [TypesNames.SET_FORCE_JOIN_WS_ORDER_STATUS]: string;
  },
  {
    createOrderRequest: (orderData: OrderSummary) => OnCreateOrderRequest;
    reorderRequest: (orderId: number) => OnReOrderRequest;
    createOrderSuccess: (orders: Order[], previousOrders: Order[]) => OnCreateOrderSuccess;
    createOrderFailure: () => OnCreateOrderFailure;
    cleanAll: () => OnCleanAll;
    updateOrderStatus: (orderStatus: OrderStatus) => OnUpdateOrderStatus;
    getMyOrder: (id: number, slug: string) => OnGetMyOrder;
    setMyOrder: (order: Order) => OnSetMyOrder;
    getMyOrders: (page: number, perPage?: number) => OnGetMyOrders;
    setMyOrders: (orders: Order[]) => OnSetMyOrders;
    setPreviousOrders: (orders: Order[]) => OnSetPreviousOrders;
    setOrdersPagination: (pagination: Pagination) => OnSetOrdersPagination;
    logOut: () => OnCleanAll;
    setForceJoinWSOrderStatus: () => OnSetForceJoinWSOrderStatus;
  }
>({
  createOrderRequest: ['orderData'],
  reorderRequest: ['orderId'],
  createOrderSuccess: ['orders', 'previousOrders'],
  createOrderFailure: null,
  cleanAll: null,
  updateOrderStatus: ['orderStatus'],
  getMyOrder: ['id', 'slug'],
  setMyOrder: ['order'],
  getMyOrders: ['page', 'perPage'],
  setMyOrders: ['orders'],
  setPreviousOrders: ['orders'],
  setOrdersPagination: ['pagination'],
  logOut: null,
  setForceJoinWSOrderStatus: null,
});

export const OrderTypes = Types;
export default Creators;

/* ============== INITIAL STATE ============== */

export interface OrderStateType {
  orders?: Order[];
  order?: Order;
  previousOrders?: Order[];
  createOrderLoading: boolean;
  reorderLoading: boolean;
  confirmingPixPaymentLoading: boolean;
  pagination?: Pagination;
  forceJoinWSOrderStatus: number;
}

export const INITIAL_STATE: OrderStateType = {
  orders: [],
  order: null,
  previousOrders: [],
  createOrderLoading: false,
  reorderLoading: false,
  confirmingPixPaymentLoading: false,
  pagination: null,
  forceJoinWSOrderStatus: 0,
};

/* ============== REDUCERS ============== */

export const createOrderRequestReducer = (
  state = INITIAL_STATE,
  { orderData }: OnCreateOrderRequest,
) =>
  produce(state, (draft) => {
    draft.createOrderLoading = true;
  });

export const reorderRequestReducer = (state = INITIAL_STATE, { orderId }: OnReOrderRequest) =>
  produce(state, (draft) => {
    draft.reorderLoading = true;
  });

export const createOrderSuccessReducer = (
  state = INITIAL_STATE,
  { orders, previousOrders }: OnCreateOrderSuccess,
) =>
  produce(state, (draft) => {
    draft.orders = orders;
    draft.previousOrders = previousOrders;
    draft.createOrderLoading = false;
    draft.reorderLoading = false;
  });

export const createOrderFailureReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.createOrderLoading = false;
    draft.reorderLoading = false;
  });

export const cleanAllReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.orders = [];
    draft.order = null;
    draft.createOrderLoading = false;
  });

export const updateOrderStatusReducer = (
  state = INITIAL_STATE,
  { orderStatus }: OnUpdateOrderStatus,
) =>
  produce(state, (draft) => {
    const newOrders = draft.orders.map((order) =>
      order.id === orderStatus.order_id ? { ...order, orderStatus } : order,
    );

    draft.orders = newOrders;
  });

export const getMyOrderReducer = (state = INITIAL_STATE, { id, slug }: OnGetMyOrder) =>
  produce(state, (draft) => {
    draft.order = null;
    draft.createOrderLoading = true;
  });

export const getMyOrdersReducer = (state = INITIAL_STATE, { page }: OnGetMyOrders) =>
  produce(state, (draft) => {
    if (page <= 1) {
      draft.orders = [];
      draft.previousOrders = [];
    }
    draft.createOrderLoading = true;
  });

export const setMyOrdersReducer = (state = INITIAL_STATE, { orders }: OnSetMyOrders) =>
  produce(state, (draft) => {
    draft.orders = [...draft.orders, ...orders];
    draft.createOrderLoading = false;
  });

export const setMyOrderReducer = (state = INITIAL_STATE, { order }: OnSetMyOrder) =>
  produce(state, (draft) => {
    draft.order = order;
    draft.createOrderLoading = false;
  });

export const setPreviousOrdersReducer = (state = INITIAL_STATE, { orders }: OnSetPreviousOrders) =>
  produce(state, (draft) => {
    draft.previousOrders = [...draft.previousOrders, ...orders];
    draft.createOrderLoading = false;
  });

export const setOrdersPaginationReducer = (
  state = INITIAL_STATE,
  { pagination }: OnSetOrdersPagination,
) =>
  produce(state, (draft) => {
    draft.pagination = pagination;
  });

export const setForceJoinWSOrderStatusReducer = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.forceJoinWSOrderStatus = new Date().getTime();
  });

export const reducer = createReducer<typeof INITIAL_STATE, any>(INITIAL_STATE, {
  [Types.REORDER_REQUEST]: reorderRequestReducer,
  [Types.CREATE_ORDER_REQUEST]: createOrderRequestReducer,
  [Types.CREATE_ORDER_SUCCESS]: createOrderSuccessReducer,
  [Types.CREATE_ORDER_FAILURE]: createOrderFailureReducer,
  [Types.CLEAN_ALL]: cleanAllReducer,
  [Types.UPDATE_ORDER_STATUS]: updateOrderStatusReducer,
  [Types.GET_MY_ORDER]: getMyOrderReducer,
  [Types.SET_MY_ORDER]: setMyOrderReducer,
  [Types.GET_MY_ORDERS]: getMyOrdersReducer,
  [Types.SET_MY_ORDERS]: setMyOrdersReducer,
  [Types.SET_PREVIOUS_ORDERS]: setPreviousOrdersReducer,
  [Types.SET_ORDERS_PAGINATION]: setOrdersPaginationReducer,
  [Types.LOG_OUT]: cleanAllReducer,
  [Types.SET_FORCE_JOIN_WS_ORDER_STATUS]: setForceJoinWSOrderStatusReducer,
});
