import { BoxProps } from '@chakra-ui/core';
import { goBack, push } from 'connected-react-router';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { GoBackButton } from './styles';

export interface BackButtonProps extends BoxProps {
  to?: string;
  state?: any;
}

const BackButton: React.FC<BackButtonProps> = ({ to, state, onClick }) => {
  const dispatch = useDispatch();

  const handleGoBack = useCallback(() => {
    if (to) {
      dispatch(push(to, { ...state }));
    } else {
      dispatch(goBack());
    }
  }, [dispatch, state, to]);

  return (
    <GoBackButton
      bg="white"
      shadow="0px 0px 7px #00000015"
      aria-label="Voltar"
      icon="arrow-back"
      rounded="50%"
      size="lg"
      onClick={onClick ? onClick : handleGoBack}
    />
  );
};

export default BackButton;
