import { all } from 'redux-saga/effects';

import addresses from './addresses';
import advertiser from './advertiser';
import auth from './auth';
import bag from './bag';
import order from './order';
import user from './user';

export default function* rootSaga() {
  return yield all([auth, addresses, advertiser, user, order, bag]);
}
