import {
  Box,
  Heading,
  Icon,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/core';
import { push } from 'connected-react-router';
import deburr from 'lodash/deburr';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import Button from '~/components/Button';
import Input from '~/components/Form/Input';
import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import ListItem from '~/components/ListItem';
import ProductItem from '~/components/ProductItem';
import TopRoundedBox from '~/components/TopRoundedBox';
import useConstant from '~/hooks/useConstant';
import { advertiserMenu, itemDetailsWithId } from '~/routes/routeMap';
import { RootState } from '~/store';
import { debounce } from '~/utils/debounce';

const AdvertiserMenuSearch: React.FC = () => {
  const globalState = useSelector((state: RootState) => state);
  const advertiserInfo = globalState ? globalState?.advertiser?.advertiserInfo : null;
  const categories = advertiserInfo?.productCategory;
  const [searchString, setSearchString] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!advertiserInfo) {
      dispatch(push(advertiserMenu));
    }
  }, [advertiserInfo]);

  useEffect(() => {
    if (!categories) {
      dispatch(push(advertiserMenu));
    } else {
      setFilteredCategories(categories);
      setLoading(false);
    }
  }, [categories]);

  const handleOnClickItem = (product: any) => {
    dispatch(push(itemDetailsWithId.replace(':id_produto', `${product?.id}`), { product }));
    // Removed validation, until the bug resolution will done
    //dispatch(checkProductAvailabilityRequest(product));
  };

  const onChangeFilterCategories = (searchValue: string) => {
    if (searchValue === '') {
      // Set all categories and products
      setFilteredCategories(categories);
    } else {
      const filtCat: any[] = [];
      const searchValueNormalized = Buffer.from(
        deburr(searchValue).toLowerCase().split(' ').join('_'),
        'ascii',
      ).toString();

      // Search on every category
      categories.filter((category) => {
        // On every product that has the search string
        const filtCatProd = category.products.filter((product) => {
          const productNameNormalized = Buffer.from(
            deburr(product.name).toLowerCase().split(' ').join('_'),
            'ascii',
          ).toString();

          return productNameNormalized.includes(searchValueNormalized);
        });

        // Return only the categories that has products that contains the search string
        if (filtCatProd.length > 0) {
          const t = { ...category };
          t.products = filtCatProd;
          filtCat.push(t);
        }
      });

      // Set filtered categories and products
      setFilteredCategories(filtCat);
    }
    setLoading(false);
  };

  const debouncedOnChangeFilterCategories = useConstant(() =>
    debounce(onChangeFilterCategories, 500),
  );

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{advertiserInfo?.name ? advertiserInfo?.name : 'Kuppi'} | Cardápio Web</title>
      </Helmet>

      <Box as="main" flexDir="column" w="100%" bg="green.400">
        <HeaderWithBackButton headerTitle="Busca de produtos" px="3rem" to={advertiserMenu} />

        <TopRoundedBox as="section">
          <Box w="100%" my="10px" px={10} py={10}>
            <InputGroup>
              <Input
                value={searchString}
                p="20px 20px 20px 20px"
                placeholder="Digite o produto a ser buscado"
                name="product-kuppi-search"
                autoFocus
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLoading(true);
                  setSearchString(e.target.value);
                  debouncedOnChangeFilterCategories(e.target.value);
                }}
              />
              <InputRightElement h="50px" w="50px">
                <Button
                  fontSize="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  background="transparent"
                  h="20px"
                  w="20px"
                  onClick={() => {
                    setSearchString('');
                    onChangeFilterCategories('');
                  }}
                >
                  <Icon size="2.5rem" as={MdClose} color={'gray.300'} />
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>

          {/* Loading Search */}
          {loading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="100%"
              flexDirection="column"
              mt="50px"
            >
              <Spinner w="3.5rem" h="3.5rem" color="green.600" />
            </Box>
          )}

          {/* Empty Search */}
          {!loading && filteredCategories && filteredCategories.length === 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="100%"
              color="gray.400"
              flexDirection="column"
              mt="50px"
            >
              <Heading as="h1" fontSize="lg" fontWeight="700" px="3rem">
                Oops! :(
              </Heading>
              <Text
                as="span"
                fontSize="sm"
                fontWeight="400"
                color="gray.400"
                mt={5}
                pl={10}
                pr={10}
                width="100%"
                textAlign="center"
              >
                Não existem produtos para essa busca.
              </Text>
            </Box>
          )}

          {/* Search Results */}
          {!loading &&
            filteredCategories &&
            filteredCategories.map((category, i) => (
              <Box key={category.id} as="section" bg="white" id="categories" pb={16}>
                <Heading bg="gray.50" as="h1" fontSize="lg" fontWeight="700" py={6} px="3rem">
                  {category.name}
                </Heading>
                <Stack spacing={0}>
                  {category.products.map((product: any) => (
                    <ListItem key={product.id} onClick={() => handleOnClickItem(product)}>
                      <ProductItem product={product} cursor="pointer" />
                    </ListItem>
                  ))}
                </Stack>
              </Box>
            ))}
        </TopRoundedBox>
      </Box>
    </>
  );
};

export default AdvertiserMenuSearch;
