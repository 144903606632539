import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';

import { advertiserMenu } from '~/routes/routeMap';
import { Creators as AuthActions } from '~/store/ducks/auth';
const { setLoading } = AuthActions;

const ClearInfiniteLoading: React.FC = () => {
  const dispatch = useDispatch();

  dispatch(setLoading(false));
  dispatch(push(advertiserMenu));

  return <></>;
};

export default ClearInfiniteLoading;
