import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useSocketIo } from '~/hooks/useSocketIo';
import { RootState } from '~/store';

interface WebSocketContextProps {
  socket?: React.RefObject<SocketIOClient.Socket>;
}

const WebSocketContext = createContext<WebSocketContextProps>({});

export const WebSocketProvider = ({ children }: { children: React.ReactNode }) => {
  const globalState = useSelector((state: RootState) => state);
  const isLoggedin = globalState?.auth?.isLoggedin;
  const token = globalState?.auth?.token?.token;
  const { orders, forceJoinWSOrderStatus: joinWSOrderStatus } = globalState?.order;
  const socket = useSocketIo({ enabled: isLoggedin, token });

  const currentOrders = useMemo(() => {
    return orders?.map((order) => order.id);
  }, [orders]);

  useEffect(() => {
    const ws = socket.current;

    if (!isLoggedin && ws) {
      ws.disconnect();
    }
  }, [isLoggedin, socket]);

  useEffect(() => {
    const ws = socket.current;

    if (!ws) return;

    console.log('WS WebMenu - joinWSOrderStatus', joinWSOrderStatus);

    currentOrders?.forEach((value) => {
      ws.emit('join', { orderId: value });
      console.log('WS WebMenu - joinOrder', { orderId: value });
    });
  }, [joinWSOrderStatus, socket]);

  return <WebSocketContext.Provider value={{ socket }}>{children}</WebSocketContext.Provider>;
};

export const useWebSocket = () => useContext(WebSocketContext);
