import { Box, Heading, Text } from '@chakra-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import AstronautGuy from '~/components/AstronautGuy';

const Page404: React.FC = () => {
  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>404 - Essa página não foi encontrada – Kuppi Web</title>
      </Helmet>

      <Box
        as="main"
        d="flex"
        flexDir="column"
        justifyContent="center"
        h="100vh"
        alignItems="center"
        px={4}
      >
        {/* <Image src="/favicon.png" my={12} /> */}
        <AstronautGuy />
        <Heading>404</Heading>
        <Text fontSize="lg" textAlign="center">
          A página que você está procurando não foi encontrada.
        </Text>
      </Box>
    </>
  );
};

export default Page404;
