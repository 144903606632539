import { Text } from '@chakra-ui/core';
import styled from '@emotion/styled';

export const LineClampedText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 31;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;

  margin-top: 10px;
  margin-bottom: 15px;
  white-space: pre-line;
`;
