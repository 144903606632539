import { IconButton, Stack, StackProps, Text } from '@chakra-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

interface NumberCounterProps extends StackProps {
  initialCount?: number;
  canReduceUpTo?: number;
  onChangeCount?: (count: number) => void;
}

const NumberCounter: React.FC<NumberCounterProps> = ({
  initialCount = 1,
  onChangeCount,
  canReduceUpTo = 1,
  spacing = 4,
  mr = 6,
  px = 6,
  py = 3,
  justifyContent = 'center',
  ...rest
}) => {
  const [count, setCount] = useState(initialCount);

  const increaseCount = useCallback(() => setCount((prevState) => prevState + 1), []);
  const decreaseCount = useCallback(
    () => setCount((prevState) => (prevState > canReduceUpTo ? prevState - 1 : canReduceUpTo)),
    [canReduceUpTo],
  );

  useEffect(() => {
    onChangeCount && onChangeCount(count);
  }, [count, onChangeCount]);

  return (
    <Stack
      isInline
      align="center"
      spacing={spacing}
      mr={mr}
      px={px}
      py={py}
      justifyContent={justifyContent}
      rounded="md"
      border="1px solid"
      borderColor="gray.400"
      {...rest}
    >
      <IconButton
        onClick={decreaseCount}
        aria-label="Retirar um item"
        icon="minus"
        stroke="gray.800"
        size="lg"
        background="transparent"
      />
      <Text fontSize="lg" fontWeight="700">
        {count}
      </Text>
      <IconButton
        onClick={increaseCount}
        aria-label="Adicionar um item"
        icon="plus-square"
        stroke="gray.800"
        size="lg"
        background="transparent"
      />
    </Stack>
  );
};

export default NumberCounter;
