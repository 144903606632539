import { Box, BoxProps } from '@chakra-ui/core';
import React from 'react';

interface AddressItemProps extends BoxProps {
  isSelected?: boolean;
}

const AddressItem: React.FC<AddressItemProps> = ({ children, isSelected, ...rest }) => {
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      w="100%"
      backgroundColor="gray.50"
      border="2px solid"
      borderColor={isSelected ? 'green.400' : 'gray.50'}
      borderRadius="10px"
      px="20px"
      py="26px"
      my="10px"
      transition="all 0.2s ease-in-out"
      boxShadow={isSelected && '0px 0px 12px #349B2C40'}
      cursor="pointer"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default AddressItem;
