import { Badge, Box, Heading, Icon, Image, Spinner, Stack, Text } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import { format } from 'date-fns';
import isToday from 'date-fns/isToday';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { amplitudeTrackEvent } from '~/components/Amplitude';
import BottomBar from '~/components/BottomBar';
import Button from '~/components/Button';
import Textarea from '~/components/Form/Textarea';
import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import InfoBox from '~/components/InfoBox';
import NumberCounter from '~/components/NumberCounter';
import TopRoundedBox from '~/components/TopRoundedBox';
import useSlug from '~/hooks/useSlug';
import Coupon from '~/models/Coupon';
import Product from '~/models/Product';
import SelectedComplement from '~/models/SelectedComplement';
import { advertiserMenu, bag } from '~/routes/routeMap';
import { sendAccessedWebMenu } from '~/services/mixPanel';
import { RootState } from '~/store';
import { Creators as AdvertiserCreators } from '~/store/ducks/advertiser';
import Creators from '~/store/ducks/bag';
import { formatCurrencyBRL } from '~/utils/currency';

import ComplementsMenu from './ComplementsMenu';
import { LineClampedText } from './styles';

interface UseLocationProps {
  product?: Product;
  coupon?: Coupon;
  mode?: string;
}

const { addToBag, edditingToBag } = Creators;
const { getData } = AdvertiserCreators;

const ItemDetails: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation<UseLocationProps>();
  const { state } = location;
  const { id_produto: id } = useParams<any>();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(state?.coupon ? state?.coupon?.product : state?.product);
  const coupon = state?.coupon ? state?.coupon : product?.coupon;
  const benefit = coupon?.couponBenefit;
  const mode = state?.mode;
  const query = new URLSearchParams(useLocation().search);
  const facebook_campaign_id = query.get('utm_campaign');
  const slug = useSlug();

  const globalState = useSelector((state: RootState) => state);
  const { itemsInBag, checkProductAvailabilityLoader } = globalState.bag;
  const imageTimestamp = globalState?.advertiser.imageTimestamp;
  const advertiserInfo = globalState?.advertiser?.advertiserInfo;
  const mixPanelData = {
    id: globalState?.user?.user?.id ? globalState?.user?.user?.id.toString() : null,
    name: globalState?.advertiser?.advertiserInfo?.name,
    email: globalState?.advertiser?.advertiserInfo?.user.email,
    webMenu: globalState?.advertiser?.advertiserInfo?.id.toString(),
    facebookCampaignId: facebook_campaign_id,
  };

  useEffect(() => {
    if (product) {
      window.scrollTo(0, 0);
    }
    // Mix Panel Event
    sendAccessedWebMenu('Funnel 2 - Product View', mixPanelData);

    // Amplitude Event
    amplitudeTrackEvent('pageview_product', {
      advertiser_info_id: globalState?.advertiser?.advertiserInfo?.id,
      advertiser_name: globalState?.advertiser?.advertiserInfo?.name,
      advertiser_category:
        globalState?.advertiser?.advertiserInfo?.advertiserCategory?.name?.toString(),
      user_id: globalState?.user?.user ? globalState?.user?.user?.id : null,
    });
  }, [dispatch, product, id]);

  const [price, setPrice] = useState<number>(
    coupon && product.isCouponAvailable ? coupon.couponBenefit.by_value : product?.amount,
  );

  const [priceNoDiscount, setPriceNoDiscount] = useState<number>(product?.amount);

  const [productCount, setProductCount] = useState<number>(1);
  const [oldProductCount, setOldProductCount] = useState<number>(1);
  const productNextAvailableAt = useMemo(() => {
    return !!product?.nextAvailableAt ? new Date(product.nextAvailableAt) : null;
  }, [product]);
  const [canAddToCart, setCanAddToCart] = useState<boolean>(false);
  const [productToBag, setProductToBag] = useState<any>();
  const [selectedComplements, setSelectedComplements] = useState<SelectedComplement[] | null>(null);

  const [productObs, setProductObs] = useState<string>();

  const [isMinValue, setIsMinValue] = useState<number>();

  const isComplementMoreEspensive = useMemo(() => {
    const complementMoreEspensive = product?.productComplementCategories?.some((complement) => {
      return complement.is_base_amount_more_expensive ? true : false;
    });
    return complementMoreEspensive;
  }, [product]);

  useEffect(() => {
    if (slug && !product) {
      dispatch(getData(slug));
    }
  }, [dispatch, slug, product]);

  useEffect(() => {
    if (product?.amount === 0) {
      const requiredComplements = product?.productComplementCategories?.filter((complement) => {
        return complement.is_required ? true : false;
      });

      let minProductPrice = 0;

      requiredComplements?.forEach((complement) => {
        const optionPrices = complement.productComplementOptions?.map(
          (option) => option.amount && option.amount,
        );
        const lessOptionPrice = Math.min.apply(null, optionPrices);
        minProductPrice += lessOptionPrice * complement.min_qty;
      });

      setIsMinValue(minProductPrice);
    }
  }, [product]);

  useEffect(() => {
    if (state?.product?.obs) {
      setProductObs(state?.product?.obs);
    }
  }, [state, setProductObs]);

  useEffect(() => {
    const productWithSelectedComplements = { ...product };

    if (coupon) {
      productWithSelectedComplements.coupon = coupon;
    }
    productWithSelectedComplements.selectedComplements = selectedComplements;
    productWithSelectedComplements.productQuantity = productCount;
    productWithSelectedComplements.obs = productObs;
    productWithSelectedComplements.priceNoDiscountWithComplements = priceNoDiscount;
    productWithSelectedComplements.priceWithComplements = price;
    setOldProductCount(product?.productQuantity);
    setProductToBag(productWithSelectedComplements);
  }, [product, coupon, productCount, selectedComplements, price, productObs, priceNoDiscount]);

  const handleOnChangeComplementOptions = useCallback(
    (complements: SelectedComplement[]) => {
      // Verificar se todos os complementos obrigatórios foram selecionados
      const isRequiredComplementsFullfiled = complements
        ?.filter((c) => c.is_required)
        .every((c) => {
          let optionsSum = 0;
          c.options?.forEach((o) => {
            optionsSum += o.quantity;
          });
          return c.options.some((o) => o.is_selected || optionsSum >= c.min_qty);
        });

      // Calcular preço dos complementos
      let complementsTotalPrice = 0;
      complements?.forEach((c) => {
        if (c.is_base_amount_more_expensive) {
          let arrAmountOptions = c.options?.map((o) => {
            return o.quantity > 0 && o.amount;
          });
          let moreExpensiveOption = Math.max.apply(null, arrAmountOptions);

          c.options.forEach((o) => {
            complementsTotalPrice += moreExpensiveOption * o.quantity;
          });
        } else {
          c.options.forEach((o) => {
            if (o.is_selected && o.amount) {
              complementsTotalPrice += o.amount;
            } else if (o.quantity && o.amount) {
              complementsTotalPrice += o.amount * o.quantity;
            }
          });
        }
      });

      setCanAddToCart(isRequiredComplementsFullfiled && product?.isAvailable);

      setPrice((_) => {
        const basePrice =
          coupon && product.isCouponAvailable ? coupon.couponBenefit.by_value : product?.amount;
        return basePrice + complementsTotalPrice;
      });

      setPriceNoDiscount((_) => {
        const basePrice = product?.amount;
        return basePrice + complementsTotalPrice;
      });

      if (isRequiredComplementsFullfiled) {
        setSelectedComplements(() => complements);
      }
    },
    [coupon, product, setSelectedComplements],
  );

  const handleOnChangeProductCount = useCallback((count: number) => {
    setProductCount(count);
  }, []);

  const handleAddToBag = useCallback(() => {
    // MixPanel Event
    sendAccessedWebMenu('Funnel 3 - Added item to bag', mixPanelData);
    // Amplitude Event
    amplitudeTrackEvent('add_to_cart', {
      advertiser_info_id: globalState?.advertiser?.advertiserInfo?.id,
      advertiser_name: globalState?.advertiser?.advertiserInfo?.name,
      advertiser_category:
        globalState?.advertiser?.advertiserInfo?.advertiserCategory?.name?.toString(),
      user_id: globalState?.user?.user ? globalState?.user?.user?.id : null,
    });
    // Facebook Pixel - Track add to cart event
    ReactPixel.track(
      globalState?.advertiser?.advertiserInfo?.advertiser_cluster_events?.add_to_cart,
    );

    // Verifica se a bag tem um item apenas para direcionar o cliente para a bag;
    dispatch(addToBag({ bagId: itemsInBag.length, ...productToBag }));
    if (itemsInBag.length === 0) {
      dispatch(push(bag));
    } else {
      // Caso a bag tenha mais de um item, ao adicionar um novo, volta para o menu;
      dispatch(push(advertiserMenu));
    }
  }, [itemsInBag.length, productToBag, dispatch]);

  const handleEdittingToBag = useCallback(() => {
    // MixPanel Event
    sendAccessedWebMenu('Funnel 3.1 - Edited item in bag', mixPanelData);
    dispatch(edditingToBag(productToBag, oldProductCount));
    dispatch(push(bag));
  }, [productToBag, dispatch]);

  useEffect(() => {
    setProduct(handleGetProduct());
    setLoading(false);
  }, [id, advertiserInfo, slug, dispatch, setLoading]);

  const handleGetProduct = useCallback(() => {
    if (!product) {
      const foundProduct = advertiserInfo?.productCategory
        ?.flatMap((category) => category.products)
        .find((prod) => prod.id === id);

      if (!foundProduct && !loading) {
        dispatch(push(advertiserMenu));
      }

      if (mode === 'edditing') {
        return { ...foundProduct, selectedComplements: selectedComplements };
      }

      return foundProduct;
    }

    return product;
  }, [id, product, advertiserInfo, selectedComplements, loading, dispatch]);

  if (!product || loading) {
    return (
      <Box h="100%" w="100%" d="flex" alignItems="center" justifyContent="center">
        <Spinner size="xl" color="#01F43A" />
      </Box>
    );
  }

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{product?.name ? product?.name : 'Kuppi'} | Cardápio Web</title>
      </Helmet>
      <Box as="main" flexDir="column" w="100%" bg="green.400" mb={70}>
        <HeaderWithBackButton headerTitle="Detalhes do Produto" px="3rem" to={advertiserMenu} />

        <TopRoundedBox as="section" pl={10} pr={10} pt={10}>
          {/* HEADER */}
          {product?.file?.url && (
            <Image
              rounded="1rem"
              src={`${product?.file?.url}?nocache=${imageTimestamp}`}
              m="0 auto 4rem"
              maxH="30rem"
              objectFit="cover"
            />
          )}

          {/* CUPONS E VALIDADE */}
          {coupon?.mode === 'flash' && (
            <Stack isInline align="center" justify="space-between" mb={8}>
              <Badge
                variant="subtle"
                variantColor="green"
                px={4}
                py={1}
                fontSize="1.2rem"
                fontWeight="400"
              >
                <Icon name="flash" fill="green.700" mr={2} />
                RESTAM {coupon?.usage_limit - coupon?.used_coupons} CUPONS!
              </Badge>
              <Text fontSize="1.2rem" color="gray.500">
                Válido até {format(new Date(coupon.validity_at), 'dd/MM')} às{' '}
                {format(new Date(coupon.validity_at), 'HH:mm')}
              </Text>
            </Stack>
          )}

          {/* DETALHES DO ITEM */}
          <Stack mb={!isComplementMoreEspensive ? 12 : 5} alignItems="flex-start">
            <LineClampedText
              as="h3"
              fontSize="lg"
              fontWeight="700"
              lineHeight="1.5"
              color={!product.isAvailable ? 'gray.500' : 'gray.700'}
            >
              {product?.name}
            </LineClampedText>
            {!product?.isAvailable && productNextAvailableAt && (
              <>
                {isToday(productNextAvailableAt) ? (
                  <InfoBox
                    p={2}
                    mb={2}
                    variant="info"
                    color="gray.300"
                    content={`Este produto está indisponível no momento, mas estará disponível ${format(
                      productNextAvailableAt,
                      "'hoje às' HH:mm",
                      {
                        locale: ptBR,
                      },
                    )}.`}
                  />
                ) : (
                  <InfoBox
                    p={2}
                    mb={2}
                    variant="info"
                    content={`Este produto está indisponível no momento, mas estará disponível ${format(
                      productNextAvailableAt,
                      "EEEE 'às' HH:mm",
                      {
                        locale: ptBR,
                      },
                    )}.`}
                  />
                )}
              </>
            )}
            <LineClampedText
              as="p"
              fontSize="md"
              color={!product?.isAvailable ? 'gray.500' : 'gray.700'}
            >
              {product?.description}
            </LineClampedText>
            {coupon?.type === 'from_value_to_value' && product.isCouponAvailable ? (
              <Text as="p" fontSize="md" color="gray.700" fontWeight="700">
                De{' '}
                <Text as="span" color="gray.500" textDecor="line-through">
                  {formatCurrencyBRL(benefit?.from_value)}
                </Text>{' '}
                por {formatCurrencyBRL(benefit?.by_value)}
              </Text>
            ) : isMinValue && product?.isCouponAvailable ? (
              <Text as="p" fontSize="md" color="gray.700" fontWeight="700">
                A partir de {formatCurrencyBRL(isMinValue)}
              </Text>
            ) : (
              <Text
                as="p"
                fontSize="md"
                fontWeight="700"
                color={!product?.isAvailable ? 'gray.500' : 'gray.700'}
              >
                {formatCurrencyBRL(product?.amount)}
              </Text>
            )}
          </Stack>
          {isComplementMoreEspensive && (
            <InfoBox
              content="O valor cobrado será referente a opção escolhido de maior valor."
              mb={12}
            />
          )}
          {/* COMPLEMENTOS */}
          {product?.productComplementCategories && product?.isAvailable ? (
            <ComplementsMenu
              mb={4}
              complements={
                mode === 'edditing'
                  ? product?.selectedComplements
                  : product?.productComplementCategories
              }
              onChangeComplementOptions={handleOnChangeComplementOptions}
            />
          ) : null}
          {/* OBSERVACOES */}

          {product?.isAvailable && (
            <Stack spacing={5}>
              <Box d="flex" alignItems="center">
                <Icon name="message" size="2rem" mr="1rem" />
                <Heading as="h4" fontSize="sm" fontWeight="700">
                  Alguma observação?
                </Heading>
              </Box>
              <Textarea
                maxLength={255}
                name="obs"
                value={productObs}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProductObs(e.target.value)}
                placeholder="Digite a observação aqui..."
              />
            </Stack>
          )}
        </TopRoundedBox>
      </Box>
      {/* BARRA INFERIOR */}
      <BottomBar>
        <Stack
          isInline
          align="center"
          spacing={4}
          my="10px"
          justifyContent={['flex-start', 'flex-start', 'space-between']}
        >
          <NumberCounter
            justifyContent="space-between"
            flex="2"
            maxW={['initial', 'initial', '16rem']}
            onChangeCount={handleOnChangeProductCount}
            initialCount={mode === 'edditing' ? product?.productQuantity : 1}
          />
          <Button
            variantColor="green"
            d="flex"
            flex="3"
            maxW={['initial', 'initial', '24rem']}
            alignItems="center"
            justifyContent="space-between"
            isDisabled={!canAddToCart || checkProductAvailabilityLoader}
            onClick={mode === 'edditing' ? handleEdittingToBag : handleAddToBag}
          >
            <span>{mode === 'edditing' ? 'Atualizar' : 'Adicionar'}</span>{' '}
            <span>{formatCurrencyBRL(price * productCount)}</span>
          </Button>
        </Stack>
      </BottomBar>
    </>
  );
};

export default ItemDetails;
