import React, { createContext, useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useSelector } from 'react-redux';

import useFacebookSDK from '~/hooks/useFacebookSDK';
import { FacebookLoginCallbackResponse } from '~/models/FacebookSDK';
import { RootState } from '~/store';

interface FacebookContextProps {
  isSdkLoaded?: boolean;
  login?: (options?: {
    auth_type?: string;
    scope?: string;
  }) => Promise<FacebookLoginCallbackResponse>;
}

const FacebookContext = createContext<FacebookContextProps>({ isSdkLoaded: false });

const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

function FacebookProvider({ children }: { children: React.ReactNode }) {
  const kuppiPixelID = '1609621289422319';
  const [isKuppiPixelLoaded, setIsKuppiPixelLoaded] = useState(false);
  const userPixelIDs = useSelector(
    (state: RootState) => state.advertiser?.advertiserInfo?.ownDomainConfigs?.facebook_pixel_id,
  );
  const [isUserPixelLoaded, setIsUserPixelLoaded] = useState(false);

  const { isSdkLoaded, login } = useFacebookSDK({
    appId: FACEBOOK_APP_ID,
    version: 'v9.0',
  });

  useEffect(() => {
    if (!isUserPixelLoaded && userPixelIDs) {
      userPixelIDs.split('@').forEach((userPixelID) => {
        ReactPixel.init(userPixelID, undefined, { autoConfig: true, debug: false });
      });
      setIsUserPixelLoaded(true);
    }

    if (!isKuppiPixelLoaded && kuppiPixelID) {
      ReactPixel.init(kuppiPixelID, undefined, { autoConfig: true, debug: false });
      setIsKuppiPixelLoaded(true);
    }
  }, [isUserPixelLoaded, userPixelIDs, isKuppiPixelLoaded, kuppiPixelID]);
  return (
    <FacebookContext.Provider value={{ isSdkLoaded, login }}>{children}</FacebookContext.Provider>
  );
}

export { FacebookContext, FacebookProvider };
