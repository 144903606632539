import { Box, Divider, Stack, Text } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import TopRoundedBox from '~/components/TopRoundedBox';
import { advertiserMenu, changeContacts, changeInfo } from '~/routes/routeMap';

import ProfileOption from './ProfileOption';

const MyProfile: React.FC = () => {
  const dispatch = useDispatch();

  const handleOnClickOption = useCallback(
    (to) => {
      dispatch(push(to));
    },
    [dispatch],
  );

  return (
    <Box as="main" flexDir="column" w="100%" bg="green.400">
      <HeaderWithBackButton headerTitle="Meus dados" px="3rem" to={advertiserMenu} />

      <TopRoundedBox as="section">
        <Box pt="3rem" px="3rem">
          <Stack>
            <ProfileOption iconName="profile-infos" onClick={() => handleOnClickOption(changeInfo)}>
              <Text as="span" fontSize="md" fontWeight="bold">
                Informações Pessoais
              </Text>
              <Text as="span" fontSize="sm">
                Nome, data de nascimento, CPF
              </Text>
            </ProfileOption>

            <Divider />

            <ProfileOption
              iconName="profile-email"
              onClick={() => handleOnClickOption(changeContacts)}
            >
              <Text as="span" fontSize="md" fontWeight="bold">
                Dados de Contato
              </Text>
              <Text as="span" fontSize="sm">
                Telefone
              </Text>
            </ProfileOption>

            <Divider />
          </Stack>
        </Box>
      </TopRoundedBox>
    </Box>
  );
};

export default MyProfile;
