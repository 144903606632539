export function findHeightPosition(element: HTMLDivElement) {
  var currentTop = 0;
  if (element && element.offsetParent) {
    do {
      currentTop += element.offsetTop;
    } while ((element = element.offsetParent as any));
    return currentTop;
  }
}

export function findWidthPosition(element: HTMLDivElement) {
  var currentLeft = 0;
  if (element && element.offsetParent) {
    do {
      currentLeft += element.offsetLeft;
    } while ((element = element.offsetParent as any));
    return currentLeft;
  }
}
