import addDays from 'date-fns/addDays';
import addHours from 'date-fns/addHours';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import isWithinInterval from 'date-fns/isWithinInterval';
import { pick } from 'lodash';

import DayOfWeek from '~/models/DayOfWeek';
import WeekDay from '~/models/types/WeekDay';
import { weekdays } from '~/utils/getCurrentDay';

export const isAvailableAt = (start_at: string, end_at: string, now: Date) => {
  const timeZoneOffsetInHour = now.getTimezoneOffset() / 60;

  if (start_at === '00:00' && end_at === '00:00') {
    return true;
  }
  return isWithinInterval(now, {
    start: addHours(
      new Date(`${format(new Date(), 'yyyy-MM-dd')}T${start_at}:00Z`),
      timeZoneOffsetInHour,
    ),
    end: addHours(
      new Date(`${format(new Date(), 'yyyy-MM-dd')}T${end_at}:00Z`),
      timeZoneOffsetInHour,
    ),
  });
};

export const nextAvailableAt = (now: Date, availabilties: Record<WeekDay, DayOfWeek>) => {
  let todayIndex = now.getDay(); // 0 - 6
  let daysToAdd = now.getDay();
  let nextOpenDate = null;
  const availabilities = pick(availabilties, weekdays) as any;

  do {
    if (todayIndex > 6) {
      todayIndex = 0;
    }
    const todayAvailability: DayOfWeek = availabilities[weekdays[todayIndex]];
    if (!todayAvailability.is_active) {
      todayIndex++;
      daysToAdd++;
      continue;
    }
    // eslint-disable-next-line
    nextOpenDate = todayAvailability.horaries.map((horary) => {
      let startAtDate = new Date();
      const hours = Number(horary.start_at.split(':')[0]);
      const minutes = Number(horary.start_at.split(':')[1]);
      startAtDate.setHours(hours);
      startAtDate.setMinutes(minutes);
      startAtDate = addDays(startAtDate, daysToAdd - now.getDay());

      if (isBefore(now, startAtDate)) {
        return startAtDate;
      }
      return null;
    })[0];

    if (nextOpenDate) break;
    todayIndex++;
    daysToAdd++;
  } while (daysToAdd <= now.getDay() + 7);

  return nextOpenDate;
};
