import { Drawer, DrawerBody, DrawerContent, DrawerOverlay, Icon } from '@chakra-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listAddress, myLoyaltyCards, myOrders } from '~/routes/routeMap';
import { RootState } from '~/store';
import UserActions from '~/store/ducks/user';
import { sideMenuGradient } from '~/styles/gradients';

import LoggedInContent, { MenuOption } from './LoggedInContent';
import { CustonButton } from './styles';

interface SideMenuProps {
  isOpen: boolean;
  onClose: VoidFunction;
  advertiserSlug?: string;
}

const SideMenu: React.FC<SideMenuProps> = ({ isOpen, onClose, advertiserSlug }: SideMenuProps) => {
  const { isLoggedin } = useSelector(({ auth }: RootState) => auth);
  const loyaltyCard = useSelector((state: RootState) => state.advertiser.loyaltyCard);
  const userLoyaltyCard = useSelector((state: RootState) => state.user.loyaltyCard);
  const dispatch = useDispatch();
  const [menu, setMenu] = useState<MenuOption[]>([
    {
      icon: 'menu-orders',
      option: 'Meus Pedidos',
      to: myOrders,
    },
    {
      icon: 'menu-pin',
      option: 'Meus Endereços',
      to: listAddress,
    },
  ]);

  useEffect(() => {
    if (loyaltyCard?.id && isLoggedin && !userLoyaltyCard?.id) {
      dispatch(UserActions.fetchUserLoyaltyCard(loyaltyCard?.id));
      dispatch(UserActions.fetchUserRescuedLoyaltyCards(loyaltyCard?.id));
    }
  }, [dispatch, loyaltyCard, isLoggedin, userLoyaltyCard]);

  useEffect(() => {
    if (loyaltyCard?.is_active || !!userLoyaltyCard) {
      setMenu((prevMenu) => {
        const newMenu = prevMenu.filter((i) => i.icon !== 'menu-fidelity');
        newMenu.push({
          icon: 'menu-fidelity',
          option: 'Cartões Fidelidade',
          to: myLoyaltyCards,
        });
        return newMenu;
      });
    }
  }, [loyaltyCard, userLoyaltyCard, advertiserSlug]);

  return (
    <Drawer placement="left" onClose={onClose} isOpen={isOpen} size="md" blockScrollOnMount>
      <DrawerOverlay />
      <DrawerContent overflow="auto">
        <DrawerBody background={sideMenuGradient} p="0">
          <CustonButton onClick={onClose}>
            <Icon name="close" size="1.8rem"></Icon>
          </CustonButton>
          <LoggedInContent menuOptions={menu} onClose={onClose} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default SideMenu;
