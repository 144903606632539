import { Box, Heading, Image, Stack } from '@chakra-ui/core';
import styled from '@emotion/styled';

interface CustomCategoryBoxProps {
  stick?: boolean;
  isMobile?: boolean;
}

export const HorizontalScroll = styled(Stack)`
  -webkit-overflow-scrolling: touch;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CustomCategoryBox = styled(Box)<CustomCategoryBoxProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${(props) =>
    props.stick &&
    `
    position: fixed;
    top: 0;
    z-index: 9;
    background-color: #FFF;

    @media screen and (max-width: 900px) {
      width: 100%;
    }

    @media screen and (min-width: 901px) {
      width: 900px;
    }
  `}
`;

export const AdvertiserHeading = styled(Heading)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 24ch;

  @media screen and (max-width: 475px) {
    max-width: 22ch;
  }

  @media screen and (max-width: 400px) {
    max-width: 18ch;
  }

  @media screen and (max-width: 340px) {
    max-width: 15ch;
  }
`;

export const CustonImage = styled(Image)`
  filter: blur(4px);
  -webkit-filter: blur(4px);
`;
