import amplitude from 'amplitude-js';

import User from '~/models/User';
import getEnvFromSubdomain from '~/utils/getEnvFromSubdomain';

//Code to import config info about your app
const API_KEY = 'c7371b9a4ad5711c154449f5d8c10346';
const amplitudeActive = true;
const acceptedEnv = 'production';
const reactAppEnv = getEnvFromSubdomain();

if (reactAppEnv === acceptedEnv && amplitudeActive) {
  //If you only have one environment, just put the single API key
  amplitude.getInstance().init(API_KEY, null, {
    // optional configuration options
    includeUtm: true,
    includeGclid: true,
    includeReferrer: true,
  });
}

export function amplitudeTrackEvent(event: string, userProperties: Object) {
  if (reactAppEnv === acceptedEnv && amplitudeActive) {
    //If you only have one environment, just put the single API key
    amplitude.getInstance().logEvent(event, userProperties);
  } else {
    console.log(`Amplitude Event - Send ${event}: `, userProperties);
  }
}

export function amplitudeSetUserId(userData: User) {
  let userId = null;
  if (userData) {
    userId = userData.id.toString();
  }
  if (reactAppEnv === acceptedEnv && amplitudeActive) {
    amplitude.getInstance().setUserId(userId);
  } else {
    console.log(`Amplitude Event - Set User ID: `, userId);
  }
}

export function amplitudeSetUserProperties(userProperties: Object) {
  if (reactAppEnv === acceptedEnv && amplitudeActive) {
    amplitude.getInstance().setUserProperties(userProperties);
  } else {
    console.log(`Amplitude Event - Set User Properties: `, userProperties);
  }
}
