import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Spinner,
  Text,
  useDisclosure,
  useTheme,
} from '@chakra-ui/core';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import BottomBar from '~/components/BottomBar';
import Button from '~/components/Button';
import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import UnsupportedAddressModal from '~/components/UnsupportedAddressModal';
import OrderSummary from '~/models/OrderSummary';
import { advertiserMenu, listAddress, orderReview } from '~/routes/routeMap';
import { RootState } from '~/store';
import AddressActions from '~/store/ducks/addresses';
import { zipcodeMask } from '~/utils/masks';

import { mapStyleConfig } from './config';
interface ConfirmAddressGPSInputs {
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  zipcode: string;
}

interface AddressDetails extends ConfirmAddressGPSInputs {
  lat: number;
  lng: number;
}
interface UseLocationProps {
  addressDetails: AddressDetails;
  onConfirmBackTo?: string;
  orderSummaryData?: OrderSummary;
  selectExistingAddress?: boolean;
}

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const ConfirmAddressGPS: React.FC = () => {
  const dispatch = useDispatch();
  const { setCurrentAddress, saveAddress } = AddressActions;
  const { advertiserInfo } = useSelector((state: RootState) => state.advertiser);
  const loading = useSelector((state: RootState) => state.addresses.loading);

  const circleCenter = useRef<google.maps.LatLngLiteral>(null);
  const mapRef = useRef(null);
  const hoverPinRef = useRef<google.maps.Marker>(null);
  const fixedPinRef = useRef<google.maps.Marker>(null);
  const circleRef = useRef<google.maps.Circle>(null);

  const theme = useTheme();

  const location = useLocation<UseLocationProps>();
  const state = location?.state;
  const addressDetails = state?.addressDetails;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey,
  });

  useEffect(() => {
    if (!addressDetails) dispatch(push(advertiserMenu));
    const lat = addressDetails?.lat;
    const lng = addressDetails?.lng;

    circleCenter.current = { lat, lng };
  }, [addressDetails, dispatch]);

  const validateIfAdvertiserDeliversInCurrentAddress = () => {
    if (addressDetails) {
      const { lat, lng } = addressDetails;
      const advertiserAddress = advertiserInfo?.user?.address[0];
      const advertiserLatitude = parseFloat(String(advertiserAddress?.latitude));
      const advertiserLongitude = parseFloat(String(advertiserAddress?.longitude));

      const selectedAddressLatLng = new google.maps.LatLng(lat, lng);
      const advertiserLatLng = new google.maps.LatLng(advertiserLatitude, advertiserLongitude);
      const distanceToAdvertiserInMeters = google.maps.geometry.spherical.computeDistanceBetween(
        selectedAddressLatLng,
        advertiserLatLng,
      );
      const advertiserDeliversInCurrentAddress =
        distanceToAdvertiserInMeters <= advertiserInfo?.delivery_range_area * 1000;
      return advertiserDeliversInCurrentAddress;
    }
  };

  const onSubmit = () => {
    const advertiserDeliversInCurrentAddress = validateIfAdvertiserDeliversInCurrentAddress();
    if (!advertiserDeliversInCurrentAddress) {
      onOpen();
      return;
    }

    const addressToSave = {
      ...addressDetails,
      latitude: fixedPinRef.current?.getPosition().lat(),
      longitude: fixedPinRef.current?.getPosition().lng(),
      zipcode: zipcodeMask(addressDetails?.zipcode),
    };
    Reflect.deleteProperty(addressToSave, 'lat');
    Reflect.deleteProperty(addressToSave, 'lng');
    dispatch(setCurrentAddress(addressToSave));
    dispatch(saveAddress(addressToSave));

    if (state?.onConfirmBackTo) {
      dispatch(
        push(state?.onConfirmBackTo, {
          ...state,
          orderSummaryData: { ...state.orderSummaryData, deliveryAddress: addressToSave },
        }),
      );
    } else {
      dispatch(push(advertiserMenu));
    }
  };

  const onClickSelectAnotherAddress = () => {
    dispatch(
      push(listAddress, {
        onConfirmBackTo: orderReview,
        orderSummaryData: state.orderSummaryData,
      }),
    );
  };

  const onLoad = useCallback(
    (map) => {
      mapRef.current = map;
      hoverPinRef.current = new google.maps.Marker({
        position: mapRef.current.getCenter(),
        map: mapRef.current,
        icon: {
          url: '/images/pin-hover.png',
          size: new google.maps.Size(32, 58, 'px', 'px'),
        },
        visible: false,
      });

      fixedPinRef.current = new google.maps.Marker({
        position: mapRef.current.getCenter(),
        map: mapRef.current,
        icon: {
          url: '/images/pin-fixed.png',
          size: new google.maps.Size(32, 42, 'px', 'px'),
        },
        visible: true,
      });

      circleRef.current = new google.maps.Circle({
        strokeColor: theme.colors.red[500],
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: theme.colors.red[300],
        fillOpacity: 0.15,
        clickable: false,
        draggable: false,
        editable: false,
        visible: false,
        map: mapRef.current,
        center: circleCenter.current,
        radius: 250,
      });

      mapRef.current.addListener('dragstart', () => {
        hoverPinRef.current.setVisible(true);
        fixedPinRef.current.setVisible(false);
      });

      mapRef.current.addListener('dragend', () => {
        hoverPinRef.current.setVisible(false);
        fixedPinRef.current.setVisible(true);
        fixedPinRef.current.setPosition(mapRef.current.getCenter());
      });

      mapRef.current.addListener('bounds_changed', () => {
        hoverPinRef.current.setPosition(mapRef.current.getCenter());
      });

      // map.setCenter(new google.maps.LatLng(markerPosition.lat, markerPosition.lng));
    },
    [theme.colors.red],
  );

  const onMapDragEnd = useCallback(() => {
    const mapCenter = mapRef.current.getCenter();

    const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(circleCenter.current.lat, circleCenter.current.lng),
      mapCenter,
    );
    if (distanceInMeters > 250) {
      toast.error('O local selecionado está muito longe do endereço informado.');
      mapRef.current.panTo(circleCenter.current);
      setTimeout(() => {
        fixedPinRef.current.setPosition(circleCenter.current);
        circleRef.current.setVisible(true);
      }, 750);

      return;
    }
  }, []);

  const renderMap = useCallback(() => {
    const options = {
      zoom: 18,
      zoomControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT,
      },
      center: {
        lat: addressDetails.lat,
        lng: addressDetails.lng,
      },
      disableDoubleClickZoom: true,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      minZoom: 16,
      styles: mapStyleConfig,
      gestureHandling: 'greedy',
    } as google.maps.MapOptions;

    return (
      <>
        {/* @ts-ignore */}
        <GoogleMap
          options={options}
          onLoad={onLoad}
          onDragEnd={onMapDragEnd}
          mapContainerStyle={{ width: '100%', height: 'calc(100vh - 7rem)' }}
        />
      </>
    );
  }, [onMapDragEnd, addressDetails, onLoad]);

  return (
    <Box as="main" flexDir="column" w="100%" h="calc(100vh - 8rem)" pos="relative">
      <HeaderWithBackButton headerTitle="Você está aqui" px="3rem" />
      {addressDetails && Object.entries(addressDetails).some(([_, value]) => !!value) && (
        <Box
          p={4}
          bg="white"
          borderRadius="1rem"
          mb={4}
          pos="absolute"
          top="8rem"
          zIndex={500}
          ml="6rem"
          mr="2rem"
          width="calc(100% - 8rem)"
        >
          <Text fontSize="md">
            <Text as="span" fontWeight="700">
              {addressDetails.street}
              {addressDetails.number ? `, ${addressDetails.number}` : ''}
            </Text>
            <br /> {addressDetails.neighborhood ? `${addressDetails.neighborhood} - ` : ''}
            {addressDetails.city}, {addressDetails.state}
          </Text>
        </Box>
      )}
      {isLoaded ? (
        renderMap()
      ) : (
        <Box d="flex" justifyContent="center" alignItems="center" h="calc(100vh - 7rem)">
          <Spinner size="lg" />
        </Box>
      )}
      {loadError && (
        <Box d="flex" justifyContent="center" alignItems="center" h="calc(100vh - 7rem)">
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Ocorreu um erro ao carregar o mapa.</AlertTitle>
          </Alert>
        </Box>
      )}

      <Box
        width="min(100%, 900px)"
        pos="fixed"
        bottom="-2rem"
        left={['0', '', '', '50%']}
        transform={['', '', '', 'translateX(-50%)']}
        px={['2rem', '2rem', '2rem', '2rem', '12rem']}
        py="2rem"
        zIndex={600}
      >
        <BottomBar bg="transparent" position="relative" boxShadow="none" pb={8}>
          <Button
            flex="1"
            w="100%"
            type="submit"
            bg="green.300"
            isLoading={loading}
            onClick={onSubmit}
            isDisabled={!isLoaded || !!loadError}
          >
            Salvar endereço
          </Button>
        </BottomBar>
      </Box>

      <UnsupportedAddressModal
        isOpen={isOpen}
        onClose={onClose}
        onClickAction={onClickSelectAnotherAddress}
      />
    </Box>
  );
};

export default ConfirmAddressGPS;
