import { Box } from '@chakra-ui/core';
import React from 'react';

import { FooterBarImg, FooterBarMenuTitle } from '../styles';

interface ImgAndTextProps {
  src: any;
  text: string;
  prop?: any;
  marginLeft?: string;
  isCurrPage: boolean;
  isWeb?: boolean;
  bgColor?: string;
}

const ImgAndText: React.FC<ImgAndTextProps> = ({
  src,
  text,
  prop = null,
  marginLeft,
  isCurrPage,
  isWeb,
  bgColor,
}) => {
  return (
    <Box
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      display={'flex'}
      ml={marginLeft}
      w={'100%'}
      onClick={prop}
      bg={bgColor}
      cursor="pointer"
    >
      <FooterBarImg src={src} isWeb={isWeb} />
      <FooterBarMenuTitle color={isCurrPage && 'green.400'}>{text}</FooterBarMenuTitle>
    </Box>
  );
};

export default ImgAndText;
