import { Box, BoxProps, PseudoBox, Text } from '@chakra-ui/core';
import addMinutes from 'date-fns/addMinutes';
import isToday from 'date-fns/isToday';
import React, { useCallback } from 'react';

import { OrderSchedule } from '~/models/OrderSchedule';
import { formatDate } from '~/utils/formatDate';
export interface DeliveryPriceOptionProps extends BoxProps {
  schedule: OrderSchedule;
  isSelected: boolean;
  price?: string;
  timeToImmediate?: number;
}

const OrderScheduleOption: React.FC<DeliveryPriceOptionProps> = ({
  borderRadius = '0.5rem',
  borderWidth = '1px',
  borderStyle = 'solid',
  borderColor = 'gray.300',
  flexDir = 'column',
  schedule,
  fontSize = '1.3rem',
  minW = '15rem',
  isSelected,
  timeToImmediate,
  price,
  p = 5,
  ...rest
}) => {
  const formatTime = useCallback(() => {
    const timeFrom = schedule.timeFrom;
    const timeTo = schedule.timeTo;
    const type = schedule.type;
    const timeFromImmediate = addMinutes(timeFrom, timeToImmediate);

    if (isToday(timeFrom) && type === 'immediate') {
      return `hoje, ${formatDate(timeFrom, 'HH:mm')} - ${formatDate(timeFromImmediate, 'HH:mm')}`;
    } else if (isToday(timeFrom) && timeTo) {
      return `hoje, ${formatDate(timeFrom, 'HH:mm')} - ${formatDate(timeTo, 'HH:mm')}`;
    } else {
      return `${formatDate(timeFrom, 'cccccc dd/MM, HH:mm')} - ${formatDate(timeTo, 'HH:mm')}`;
    }
  }, [schedule, timeToImmediate]);
  return (
    <PseudoBox
      d="flex"
      as="button"
      minW={minW}
      h="10rem"
      cursor="pointer"
      pos="relative"
      p={p}
      flexDir={flexDir}
      borderWidth={isSelected ? '2px' : borderWidth}
      borderStyle={borderStyle}
      borderColor={isSelected ? 'green.400' : borderColor}
      borderRadius={borderRadius}
      fontSize={fontSize}
      transition="box-shadow 0.2s ease"
      _hover={{
        boxShadow: '0 0 8px 2px #349B2C40',
      }}
      {...rest}
    >
      {isSelected && (
        <Box
          w="1.4rem"
          h="1.4rem"
          borderRadius="0.7rem"
          borderColor="green.500"
          borderWidth="4px"
          pos="absolute"
          top="1.2rem"
          right="1.2rem"
        />
      )}
      <Text as="span" fontWeight="700" color="gray.700" mb={2}>
        {schedule.type === 'immediate' ? 'Padrão' : 'Agendado'}
      </Text>
      <Text as="span" color="gray.400" mb={2}>
        {formatTime()}
      </Text>
      <Text as="span" fontSize="1.4rem">
        {price}
      </Text>
    </PseudoBox>
  );
};

export default OrderScheduleOption;
