import { AddressFields, CreateAddressDTO } from '~/models/Address';
import { GetAddressDetails, GetAddressList } from '~/models/Autocomplete';

import { apiGlobal } from './api';

export const getAddressList = async (address: string): Promise<GetAddressList> => {
  const response = await apiGlobal.post('/get-address-list', {
    address,
  });

  return response.data;
};

export const getAddressDetails = async (
  placeId: string,
  callback?: () => void,
): Promise<GetAddressDetails> => {
  const response = await apiGlobal.post('/get-address-details', {
    placeId,
  });

  if (callback) {
    callback();
  }

  return response.data;
};

export const getAddressByLatLng = async ({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}): Promise<CreateAddressDTO> => {
  const response = await apiGlobal.post('/get-address', { latitude, longitude });
  return response.data;
};

export const getLatLngByAddressFields = async (
  fields: AddressFields,
): Promise<CreateAddressDTO> => {
  const formattedAddres = `${fields.street}, ${fields.number} ${fields.neighborhood} ${fields.city} ${fields.state} ${fields.zipcode}`;
  const response = await apiGlobal.post('/get-address', {
    address: formattedAddres,
  });
  return response.data;
};
