export function validateCellPhoneMask(value: string) {
  return /\(\d{2}\) [9]\d{4}-\d{4}/g.test(value);
}

export function formatPhoneCallAction(phoneNumber: string) {
  let phone = phoneNumber;

  // Remove 55 from start of the number, avoiding error when call button was clicked on IOS
  if (phoneNumber.length >= 12) {
    phone = phoneNumber.substring(2);
  }

  return `tel:${phone}`;
}
