import { Box, Skeleton, Stack, Text } from '@chakra-ui/core';
import { push } from 'connected-react-router';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import HeaderWithBackButton from '~/components/HeaderWithBackButton';
import LoyaltyCardItem from '~/components/LoyaltyCardItem';
import NavBar from '~/components/NavBar';
import TopRoundedBox from '~/components/TopRoundedBox';
import { advertiserMenu } from '~/routes/routeMap';
import { RootState } from '~/store';
import UserActions from '~/store/ducks/user';

interface UseLocationProps {
  isInViewOrder?: boolean;
}

const MyLoyaltyCards: React.FC = () => {
  const dispatch = useDispatch();
  const { state } = useLocation<UseLocationProps>();
  const isInViewOrder = state?.isInViewOrder || false;

  const { advertiserInfo, loyaltyCard } = useSelector((state: RootState) => state.advertiser);

  const userState = useSelector((state: RootState) => state.user);

  const userLoyaltyCard = userState?.loyaltyCard;
  const rescuedLoyaltyCards = userState?.rescuedLoyaltyCards;
  const loadingLoayltyCard = userState?.loadingLoyaltyCard;

  useEffect(() => {
    if (!loyaltyCard?.is_active) {
      dispatch(push(advertiserMenu));
    }
  }, [advertiserInfo]);

  useEffect(() => {
    if (loyaltyCard?.id && loyaltyCard?.is_active) {
      dispatch(UserActions.fetchUserLoyaltyCard(loyaltyCard.id));
      dispatch(UserActions.fetchUserRescuedLoyaltyCards(loyaltyCard.id));
    } else {
      // Clear persisted loyalty cards, avoiding loading loyalty card for other advertisers
      dispatch(UserActions.setUserLoyaltyCard(null));
      dispatch(UserActions.setUserRescuedLoyaltyCards([]));
    }
  }, [dispatch, loyaltyCard]);

  const pendingUseloyaltyCards = useMemo(() => {
    return rescuedLoyaltyCards?.filter((card) => {
      if (card.is_rescued && !card.is_rescued_benefit_used) {
        return true;
      } else {
        return false;
      }
    });
  }, [rescuedLoyaltyCards]);

  const usedLoyaltyCards = useMemo(() => {
    return rescuedLoyaltyCards?.filter((card) => {
      if (card.is_rescued && card.is_rescued_benefit_used) {
        return true;
      } else {
        return false;
      }
    });
  }, [rescuedLoyaltyCards]);

  return (
    <Box as="main" flexDir="column" w="100%" bg="green.400">
      <HeaderWithBackButton
        headerTitle="Cartões Fidelidade"
        px="3rem"
        to={isInViewOrder ? null : advertiserMenu}
      />

      <TopRoundedBox as="section" p={10}>
        <Text fontSize="lg" fontWeight="bold" textAlign="center" mb={5}>
          Cartão Ativo
        </Text>
        <Stack spacing={8}>
          <Skeleton w="100%" minH="8rem" isLoaded={!loadingLoayltyCard}>
            {userLoyaltyCard ? (
              <LoyaltyCardItem userLoyaltyCard={userLoyaltyCard} />
            ) : (
              <Text fontSize="md" textAlign="center" color="gray.400">
                Você não possui um cartão fidelidade ativo.
              </Text>
            )}
          </Skeleton>
        </Stack>
        <Text fontSize="lg" fontWeight="bold" textAlign="center" mt={10} mb={5}>
          Cartões Completados para Utilizar
        </Text>
        <Stack spacing={8}>
          <Skeleton w="100%" minH="8rem" isLoaded={!loadingLoayltyCard}>
            {pendingUseloyaltyCards.map((pendingUseCard) => (
              <LoyaltyCardItem key={pendingUseCard.id} userLoyaltyCard={pendingUseCard} />
            ))}
            {!pendingUseloyaltyCards?.length && (
              <Text fontSize="md" textAlign="center" color="gray.400">
                Você não tem nenhum cartão fidelidade completado para utilizar.
              </Text>
            )}
          </Skeleton>
        </Stack>
        <Text fontSize="lg" fontWeight="bold" textAlign="center" mt={10} mb={5}>
          Cartões Utilizados
        </Text>
        <Stack spacing={8} mb={80}>
          <Skeleton w="100%" minH="8rem" isLoaded={!loadingLoayltyCard}>
            {usedLoyaltyCards.map((usedLoyaltyCard) => (
              <LoyaltyCardItem key={usedLoyaltyCard.id} userLoyaltyCard={usedLoyaltyCard} />
            ))}
            {!usedLoyaltyCards?.length && (
              <Text fontSize="md" textAlign="center" color="gray.400">
                Você não tem nenhum cartão fidelidade utilizado.
              </Text>
            )}
          </Skeleton>
        </Stack>
      </TopRoundedBox>
      <NavBar />
    </Box>
  );
};

export default MyLoyaltyCards;
