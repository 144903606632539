import produce from 'immer';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

/* ============== ACTION TYPES ============== */

enum TypesNames {
  SET_ACCEPT_COOKIES = 'SET_ACCEPT_COOKIES',
}

export interface OnSetAcceptCookies extends Action<TypesNames.SET_ACCEPT_COOKIES> {
  accept: boolean;
}
/* ============== ACTION CREATORS AND TYPES ============== */
export const { Types, Creators } = createActions<
  {
    [TypesNames.SET_ACCEPT_COOKIES]: string;
  },
  {
    setAcceptCookies: (accept: boolean) => OnSetAcceptCookies;
  }
>({
  setAcceptCookies: ['accept'],
});

export const CookiesTypes = Types;
export default Creators;

/* ============== INITIAL STATE ============== */
export interface CookiesStateType {
  accept: boolean;
}

export const INITIAL_STATE: CookiesStateType = {
  accept: false,
};

/* ============== REDUCERS ============== */
export const setAcceptCookiesReducer = (state = INITIAL_STATE, { accept }: OnSetAcceptCookies) =>
  produce(state, (draft) => {
    draft.accept = accept;
  });

export const reducer = createReducer<typeof INITIAL_STATE, any>(INITIAL_STATE, {
  [Types.SET_ACCEPT_COOKIES]: setAcceptCookiesReducer,
});
